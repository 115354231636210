import React, { useState, FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import PricingPlan, { allPricingPlans } from '../pages/Pricing/PricingPlan'
import { colors, spacing } from '../styles/_var'
import uiStore from '../stores/uiStore'
import { seasonTypes } from '../utils/constants'

interface ISeasonTypeSelector {
  onChange?: (planCode: number) => void,
  subscriptionPlan?: number,
  controlled?: boolean,
  disabled?: boolean,
  overlayStamp?: string,
  cannotDowngrade?: boolean,
  canSelectPremier?: boolean,
}


const SeasonTypeSelector: FunctionComponent<ISeasonTypeSelector> = ({
  onChange,
  subscriptionPlan,
  controlled,
  disabled,
  overlayStamp,
  cannotDowngrade,
  canSelectPremier,
}) => {
  const [selectedPricingPlan, setSelectedPricingPlan] = useState<any>(subscriptionPlan)
  const canProceed = (planCode: number) => {
    if (disabled) return false

    if (cannotDowngrade && subscriptionPlan !== undefined) {
      if (!canSelectPremier && subscriptionPlan !== planCode && planCode === seasonTypes.PREMIER) {
        uiStore.errorModal('You are not allowed to switch to a premier plan at this point.')
        return false
      }

      if (planCode < subscriptionPlan) {
        uiStore.errorModal('You are not allowed to downgrade your plan.')
        return false
      }
    }

    return true
  }

  const onPricingPlanClick = (planCode: number) => {
    if (!canProceed(planCode)) return

    setSelectedPricingPlan(planCode);  
    onChange && onChange(planCode)
  }

  const isSelected = (planCode: number) => controlled ? planCode === subscriptionPlan : planCode === selectedPricingPlan

  return (
    <Wrapper>
      <Section mt={2} disabled={disabled}>
        <Grid container spacing={3}>
            {allPricingPlans.map((pricingPlan, i) => (
              <PricingPlan 
                {...pricingPlan}
                key={i}
                selected={isSelected(pricingPlan.planCode)}
                headerIcon={null}
                onClick={() => onPricingPlanClick(pricingPlan.planCode)}
                hideButton
                gridSizing={{xs: 12, sm: 6, md: 4, lg: 4}}
              />
            ))}
        </Grid>
      </Section>
      {!!overlayStamp && <OverlayWrapper>{overlayStamp}</OverlayWrapper>}
    </Wrapper>
  )
}

export default SeasonTypeSelector

const Wrapper = styled.div`
  position: relative;
`

const Section = styled(({ disabled, ...rest }) => <Box {...rest} />)`
  ${({ disabled }) => disabled && `
    filter: grayscale(1);
  `}
`

const OverlayWrapper = styled.div`
  position: absolute;
  font-size: 6rem;
  left: 50%;
  transform: rotate(-29deg) translate(-50%, -50%);
  top: 39%;
  border: solid 1rem ${colors.purple};
  text-align: center;
  padding: ${spacing.xs} ${spacing.rg};
  background: ${colors.white};
  color: ${colors.purple};
  width: 23rem;
  max-width: 100%;
`