import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, fontSize, spacing } from '../styles/_var'
import noCompetitionImg from '../assets/images/no-competitions.png'
import Link from './Link'
import Text from './Text'
import Button from './Button'

interface IEmptyResource {
  createResourceRoute?: string,
  createResourceAction?: () => void,
  createResourceButtonText: string,
  createResourceText: string
}

const EmptyResource: FunctionComponent<IEmptyResource> = React.memo(({
  createResourceButtonText,
  createResourceRoute,
  createResourceText,
  createResourceAction
}) => {
  return (
    <EmptyResourceWrapper>
      <NoResourceImage src={noCompetitionImg} />
      <NoResourceText variant="regular">{createResourceText}</NoResourceText>

      {createResourceRoute ? (
        <Link to={createResourceRoute} useButton>
          {createResourceButtonText}
        </Link>
      ): (
        <>
          {createResourceAction && (
            <Button onClick={createResourceAction}>
              {createResourceButtonText}
            </Button>
          )}
        </>
      )}
    </EmptyResourceWrapper>
  )
})

export default EmptyResource

const EmptyResourceWrapper = styled.div`
  min-height: 25rem;
  background-color: ${colors.subtlylightpurple};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${spacing.sm};

  border: 0.5px solid #A4AFC1;
  box-sizing: border-box;
  border-radius: 10px;

  a {
    width: 20rem;
    height: 4rem;
    font-size: ${fontSize.sm};
  }
`

const NoResourceText = styled(Text)`
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
  width: 30rem;
  text-align: center;
  line-height: 2.5rem;
`

const NoResourceImage = styled.img`
  height: 12rem;
  width: auto;
`