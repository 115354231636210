import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import { BannerWrapper } from "../../components/LandingPageBanner";
import LoadingComponent from "../../components/LoadingComponent";
import PageContent from "../../components/PageContent";
import Text, { BoldText } from "../../components/Text";
import demosStore from "../../stores/demosStore";
import { device, spacing, colors } from "../../styles/_var";
import Demo from "./Demo";


const Demos = () => {
  const { demos } = demosStore
  useEffect(() => {
    demosStore.fetchDemos()
  },[])

  return (
    <>
      <DemosBannerWrapper>
        <Title variant="title">See matuskii in action</Title>
        <BannerText variant="medium" color={colors.grey}>
          Get a tour of Matuskii. See how it works, and how it can meet your
          needs.
        </BannerText>
      </DemosBannerWrapper>
      <PageContent>
      {demosStore.fetchingDemos && <LoadingComponent message="Fetching Demos..." />}
      {demosStore.fetchedDemos && (
        <DemosWrapper>
        {demos?.map(({id, title, url }) => (
          <Demo text={title} url={url} key={id} />
        ))}
      </DemosWrapper>
      )} 
      </PageContent>
      <Footer />
    </>
  );
};

export default observer(Demos);

const DemosBannerWrapper = styled(BannerWrapper)`
  min-height: 14rem;
  background-position: center center;

  @media ${device.tablet} {
    margin-top: ${spacing.xl};
  }
`;

const Title = styled(BoldText)`
  margin-bottom: ${spacing.xs};
  text-transform: capitalize;
`;

const BannerText = styled(Text)`
  padding: 0 ${spacing.xxs};
`

const DemosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${spacing.lg} ${spacing.xs};
  grid-gap: ${spacing.rg};

  @media ${device.mobileVL} {
    grid-template-columns: 1fr 1fr;
  }
`;
