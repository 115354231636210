import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, fontSize, spacing } from '../styles/_var'
import Text from './Text'

interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
}

const TextArea:FunctionComponent<ITextareaProps> = React.memo(({
  errorMessage,
  className,
  hasError,
  ...rest
}) => {
  return (
    <TexareaWrapper className={className || ''}>
      <StyledTextarea {...rest} hasError={hasError || !!errorMessage} />
      {!!errorMessage && <Text color={colors.red}>{errorMessage}</Text>}
    </TexareaWrapper>
  )
})


const TexareaWrapper = styled.div`
  display: inline-block;
  width: 100%;
`

const StyledTextarea = styled(({ hasError, ...rest }) => <textarea {...rest} />)`
  background: ${colors.white};
  border: 1px solid ${colors.lightgrey};
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: ${spacing.xxs};
  width: 100%;

  font-family: 'Gilroy';
  font-size: ${fontSize.rg};
  line-height: ${fontSize.lg};
  color: ${colors.black};

  &:focus {
    outline: none;
    border-color: ${colors.purple};
  }

  ${({ hasError }) => hasError && `
    border-color: ${colors.red};
  `}
`

export default TextArea
