import { makeAutoObservable, runInAction } from "mobx"
import isEmpty from 'lodash/isEmpty'
import {
  ICompetition,
  Status,
  IFetchCompetitionStatus,
  ICompetitionsPayload,
  ICompetitionErrorMessages,
  ICreateCompetitionPayload,
  CompetitionType,
  IPayoutOptions,
  IFunc,
  ICompetitionDashboard,
  ISeason,
  ICompetitionStaff,
  SaveStaffRequest,
  ICompetitionCategory,
  IPagination,
  ICompetitionPage,
  IMyCompetitions,
  ICategory
} from "../utils/interfaces";
import {
  status,
  competitionRequest,
  NEW,
  FAV,
  ALL,
  INVOLVED,
  COMPETITION_LOGO_S3_FOLDER,
  COMPETITION_BANNER_S3_FOLDER,
  seasonTypes,
  steps as defaultSteps,
} from "../utils/constants";
import { exclude, getCompetitionUsername, isEmail, isPhoneNo, processError, retrieveErrorMessage, uploadImage } from "../utils/helpers";
import { 
  createCompetitionRequest,
  fetchCompetitionDetailsRequest,
  fetchMyCompetitionsRequest,
  savePayoutOptionsRequest,
  updateCompetitionRequest,
  getCompetitionStaffRequest,
  saveCompetitionStaffRequest,
  deleteCompetitionStaffRequest,
  deleteCompetitionRequest,
  fetchCompetitionCategoryRequest,
  fetchAllCompetitionsRequest,
  fetchCompetitionRequest,
  getPopularCompetitionsRequest,
  fetchFavouriteCompetitionRequest,
  toggleCompetitionFavouriteRequest,
  setCompetitionSubscriptionPlanRequest,
  setSeasonPaymentRequest,
  fetchMyPrivateCompetitionsRequest,
  setCompetitionTemplateRequest,
  createCategoriesRequest,
  deleteCategoriesRequest,
  updateCategoryRequest
} from "../utils/apiRequests";
import { AxiosResponse } from "axios";
import uiStore from "./uiStore";
import seasonStore from './seasonStore';
import contestantStore from "./contestantStore";
import authStore from "./authStore";

type FilterKey = 'username' | 'email' | 'phone_number'

interface IFetchMyCompetition {
  getNext?: boolean,
  page?: number,
}

interface ISetSubscriptionPlan {
  competitionUsername: string,
  subscriptionPlan: number,
  reference?: string,
  amount?: number,
  seasonId?: string,
}

const defaultCompetitionFields = {
  competitionDetails: ({} as ICompetition),
  payoutOptions: ({} as IPayoutOptions),
  createDetailsStatus: null,
  currentStep: 0,
  stepTitle: '',
}

const competitionDashboardData = {
  competitionDetails: {} as ICompetition,
  notPermitted: false,
  selectedCategory: {name: 'Default Competition', id: 0}
}

const competitionPageDefault: ICompetitionPage = {
  competition: {} as ICompetition,
  competitionStatus: null,
  contestants: [],
  contestantsStatus: null,
  currentSeason: {} as ISeason,
  votingRounds: [],
  votingRoundsStatus: null,
  selectedCategory: {name: 'Default Competition', id: 0}
}

class CompetitionStore {
  favouriteCompetitions: ICompetition[] = []
  favouriteCompetitionsStatus: Status = null

  popularCompetitions: ICompetition[] = []
  popularCompetitionsStatus: Status = null

  toggleFavouriteStatus: Status = null
  
  similarCompetitions: ICompetition[] = []
  similarCompetitionsStatus: Status = null

  categories: ICompetitionCategory[] = []
  categoriesStatus: Status = null

  createCompetitionStatus: Status = null
  createdCompetition: ICompetition | null = null
  createCompetitionErrMessage: string = ''

  fetchCompetitionStatus: IFetchCompetitionStatus = ({} as IFetchCompetitionStatus)
  dashboardCompetitions: ICompetitionsPayload = ({} as ICompetitionsPayload)
  fetchCompetitionErrMessages: ICompetitionErrorMessages = ({} as ICompetitionErrorMessages)

  myCompetitions:  IMyCompetitions = {}
  myCompetitionsStatus: Status = null

  myPrivateCompetitions: IMyCompetitions = {}
  myPrivateCompetitionsStatus: Status = null


  steps = exclude({...defaultSteps, SUMMARY: defaultSteps.PAYMENT_OPTIONS}, ['PAYMENT_OPTIONS'])
  competitions: { 
    results: ICompetition[],
    pagination: IPagination
  } = { results: [], pagination: {} as IPagination }
  competitionsStatus: Status = null

  competitionPage = {...competitionPageDefault}

  competitionStaff: ICompetitionStaff[] = []
  competitionStaffStatus: Status = null
  saveStaffStatus: Status = null

  competitionDashboard: ICompetitionDashboard = {...competitionDashboardData}
  competitionDashboardStatus: Status = null

  savePayoutOptionsStatus: Status = null
  setSubscriptionStatus: Status = null

  competitionFields: ICreateCompetitionPayload = {...defaultCompetitionFields}

  constructor() {
    makeAutoObservable(this)
  }

  async createCompetition(payload: ICompetition, isDashboard: boolean = false) {
    let response: AxiosResponse<ICompetition>

    if (this.createCompetitionLoading) return

    if (isDashboard) {
      uiStore.setLoadingScreenMessage('Updating competition...')
    }

    const { username, logo, banner, ...rest } = payload
    const requestPayload: ICompetition = {...rest, username}

    runInAction(() => {
      this.createCompetitionStatus = status.LOADING
      this.createCompetitionErrMessage = ''
    })

    try {
      if (logo && (logo !== this.competitionDetails.logo)) {
        const logoImageToRefresh = document.getElementsByClassName("competition-logo")
        requestPayload.logo = await uploadImage({
          key: username,
          imageUrl: logo,
          folderPath: COMPETITION_LOGO_S3_FOLDER,
          imageElementsToRefresh: logoImageToRefresh
        })
      }

      if (banner && (banner !== this.competitionDetails.banner)) {
        const bannerImageToRefresh = document.getElementsByClassName("competition-banner")
        requestPayload.banner = await uploadImage({
          key: username,
          imageUrl: banner,
          folderPath: COMPETITION_BANNER_S3_FOLDER,
          imageElementsToRefresh: bannerImageToRefresh
        })
      }

      if (this.competitionCreated) {
        response = await updateCompetitionRequest(requestPayload, this.competitionDetails.username, seasonStore.selectedSeasonId)
      } else {
        response = await createCompetitionRequest(requestPayload)
      }

      runInAction(() => {
        if (isDashboard) {
          this.competitionDashboard.competitionDetails = response.data
          seasonStore.setSeasons(response.data.seasons)
          seasonStore.setSelectedSeasonById(seasonStore.selectedSeasonId)
        } else {
          const successMessage = `${this.competitionCreated ? 'Competition Updated' : 'Competition Created'}, click next to continue.`
          uiStore.showToastMessage({ type: 'success', message: successMessage })
          this.competitionFields.competitionDetails = response.data
      
          localStorage.setItem('create_competition',JSON.stringify({...this.competitionFields}))
          seasonStore.setSeasons(response.data.seasons)
          seasonStore.setSelectedSeason(seasonStore.latestSeason)
        }
        this.createCompetitionStatus = status.COMPLETE
      })
    } catch (e) {
      runInAction(() => {
        this.createCompetitionStatus = status.FAILED
        uiStore.errorModal(retrieveErrorMessage(e))
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async toggleArchive() {
    const competitionDetails = {...this.competitionDetails}
    uiStore.setLoadingScreenMessage(`${competitionDetails.archived ? 'Un-archiving ' : 'Archiving '} competition...`)

    try {
      competitionDetails.archived = !competitionDetails.archived
      const response = await updateCompetitionRequest(competitionDetails, competitionDetails.username)
      
      uiStore.successModal('Update successful')
      runInAction(() => {
        this.competitionDashboard.competitionDetails = response.data
      })
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      processError(e)
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchDashboardCompetitions(type: CompetitionType = 'new') {
    if (this.fetchNewCompetitionLoading) return

    runInAction(() => {
      this.fetchCompetitionStatus[type] = status.LOADING
    })

    try {
      const response = await competitionRequest[type]()

      runInAction(() => {
        this.dashboardCompetitions[type] = response.data
        this.fetchCompetitionStatus[type] = status.COMPLETE
      })
    } catch (e) { 
      runInAction(() => {
        this.fetchCompetitionStatus[type] = status.FAILED
        this.fetchCompetitionErrMessages[type] = retrieveErrorMessage(e)
      })
    }
  }

  async fetchCompetitions({
    category,
    page: requiredPage,
    getNext 
  }: {
    category?: string,
    page?: number,
    getNext?: boolean
  }) {
    const { results, pagination } = this.competitions
    if (this.fetchingCompetitions) return

    runInAction(() => {
      this.competitionsStatus = status.LOADING
      if (!getNext && !this.competitions.results.length) {
        uiStore.setLoadingScreenMessage('Fetching competitions')
      }
    })

    try {
      const page = getNext ? (pagination.current_page || 0) + 1 : requiredPage
      const response = await fetchAllCompetitionsRequest(category, page)
      const { results: newResults, pagination: newPagination } = response.data

      runInAction(() => {
        this.competitionsStatus = status.COMPLETE
        if (getNext) {
          this.competitions = { 
            results: [...results, ...newResults ],
            pagination: newPagination
          }
        } else {
          this.competitions = response.data
        }
      })
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.competitionsStatus = status.FAILED
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async deleteCompetition(competitionUsername: string, onDelete?: () => void) {
    uiStore.setLoadingScreenMessage('Deleting competition...')

    try {
      await deleteCompetitionRequest(competitionUsername)
      if (onDelete) onDelete()
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  async savePayoutOptions(username: string, payload: IPayoutOptions, onComplete?: IFunc) {
    if (this.savingPayoutOptions) return 

    runInAction(() => this.savePayoutOptionsStatus = status.LOADING)

    try {
      await savePayoutOptionsRequest(username, payload)
      
      uiStore.successModal('Payout options saved!')
      runInAction(() => {
        this.savePayoutOptionsStatus = status.COMPLETE
        this.competitionFields.payoutOptions = payload
      })

      this.persistState()

      if (onComplete) onComplete()
    } catch (e) {
      runInAction(() => this.savePayoutOptionsStatus = status.FAILED)
      uiStore.errorModal(retrieveErrorMessage(e))
    }
  }

  async fetchMyCompetitions({ getNext = false, page = 1}: IFetchMyCompetition) {
    const { results = [], pagination  } = this.myCompetitions
    if (this.fetchingMyCompetitions) return

    if (!getNext) {
      runInAction(() => this.myCompetitionsStatus = status.LOADING)
    }

    try {
      const pageToFetch = getNext ? (pagination?.current_page || 0) + 1 : 1
      const response = await fetchMyCompetitionsRequest(pageToFetch)
      const { results: newResults = [], pagination: newPagination } = response.data
      
      runInAction(() => {
        this.myCompetitionsStatus = status.COMPLETE
        if (getNext) {
          this.myCompetitions = { 
            results: [...results, ...newResults ],
            pagination: newPagination
          }
        } else {
          this.myCompetitions = response.data
        }
      })
    } catch {
      runInAction(() => this.myCompetitionsStatus = status.FAILED)
    }
  }

  async fetchMyPrivateCompetitions({ getNext = false, page = 1}: IFetchMyCompetition) {
    const { results = [], pagination  } = this.myPrivateCompetitions
    if (this.fetchingMyPrivateCompetitions) return

    if (!getNext) {
      runInAction(() => this.myPrivateCompetitionsStatus = status.LOADING)
    }

    try {
      const pageToFetch = getNext ? (pagination?.current_page || 0) + 1 : 1
      const response = await fetchMyPrivateCompetitionsRequest(pageToFetch)
      const { results: newResults = [], pagination: newPagination } = response.data
      
      runInAction(() => {
        this.myPrivateCompetitionsStatus = status.COMPLETE
        this.myPrivateCompetitions = { 
          results: getNext ? [...results, ...newResults] : response.data.results,
          pagination: newPagination
        }
      })
    } catch {
      runInAction(() => this.myPrivateCompetitionsStatus = status.FAILED)
    }
  }

  async loadDashboardData() {
    if (this.loadingCompetitionDashboard) return null

    uiStore.setLoadingScreenMessage('Loading Competition Dashboard.')
    runInAction(() => {
      this.competitionDashboardStatus = status.LOADING
      this.competitionDashboard.notPermitted = false
    })

    try {
      const competitionUsername = getCompetitionUsername()
      if (competitionUsername) {
        const competitionDashboardResponse = await fetchCompetitionDetailsRequest(competitionUsername)
        await this.fetchCompetitionStaff()

        runInAction(() => {
          this.competitionDashboard.competitionDetails = competitionDashboardResponse.data
          
          seasonStore.setSeasons(competitionDashboardResponse.data.seasons || [])
          seasonStore.setSelectedSeason(seasonStore.latestSeason)

          const nonDefaultCategory = competitionDashboardResponse.data.categories?.find(e => e.id !== 0)
          if (nonDefaultCategory) this.competitionDashboard.selectedCategory = nonDefaultCategory
        })
        contestantStore.fetchContestants(competitionUsername, seasonStore.selectedSeasonId)
      }
    } catch (e: any) {
      const errMessage = retrieveErrorMessage(e)
      if ((errMessage.includes('not') && errMessage.includes('permission')) || e?.response?.status === 403) {
        runInAction(() => this.competitionDashboard.notPermitted = true)
      }
    }

    runInAction(() => this.competitionDashboardStatus = status.COMPLETE)
    uiStore.setLoadingScreenMessage('')
  }

  async fetchCompetitionStaff(username?: string) {
    if (this.fetchingCompetitionStaff) return

    runInAction(() => {
      this.competitionStaffStatus = status.LOADING
    })

    try {
      const competitionUsername = username || getCompetitionUsername()
      if (!competitionUsername) return

      const response = await getCompetitionStaffRequest(competitionUsername)

      runInAction(() => {
        this.competitionStaff = response.data
        this.competitionStaffStatus = status.COMPLETE
      })
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.competitionStaffStatus = status.FAILED
      })
    }
  }

  async saveCompetitionStaff(username: string, payload: SaveStaffRequest) {
    if (this.savingCompetitionStaff) return 

    runInAction(() => {
      this.saveStaffStatus = status.LOADING
    })

    try {
      const response = await saveCompetitionStaffRequest(username, payload)
      runInAction(() => {
        this.competitionStaff = this.competitionStaff.concat(response.data)
        this.saveStaffStatus = status.COMPLETE
      })
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      processError(e)

      runInAction(() => {
        this.saveStaffStatus = status.FAILED
      })
    }
  }

  async deleteCompetitionStaff(competitionUsername: string, username: string) {
    uiStore.setLoadingScreenMessage('Removing user...')

    try {
      await deleteCompetitionStaffRequest(competitionUsername, { email: username })
      runInAction(() => {
        let filterKey:FilterKey  = 'username'

        if (isPhoneNo(username)) {
          filterKey = 'phone_number'
        } else if (isEmail(username)) {
          filterKey = 'email'
        } else {
          filterKey = 'username'
        }

        this.competitionStaff = this.competitionStaff.filter(staff => staff.personnel[filterKey] !== username)
      })
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchCompetitionCategories() {
    if (this.fetchingCompetitionCategories) return 

    try {
      const response = await fetchCompetitionCategoryRequest()

      runInAction(() => {
        this.categoriesStatus = status.COMPLETE
        this.categories = response.data
      })
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.categoriesStatus = status.FAILED
      })
    }
  }

  async fetchCompetitionPageInfo(competitionUsername: string) {
    if (this.fetchingCompetitionPageInfo) return
    const competition = this.competitions.results.find(({ username }) => username === competitionUsername)

    runInAction(() => {
      this.competitionPage.competitionStatus = status.LOADING
    })

    try {
      if (competition) {
        runInAction(() => {
          const { seasons } = competition
          seasonStore.setSeasons(seasons)
          seasonStore.setSelectedSeason(seasonStore.latestSeason)

          this.competitionPage.competition = competition
          this.competitionPage.competitionStatus = status.COMPLETE
        })
      } else {
        runInAction(() => uiStore.setLoadingScreenMessage('Fetching competition information'))
        const response = await fetchCompetitionRequest(competitionUsername)
        runInAction(() => {
          const { seasons } = response.data
          seasonStore.setSeasons(seasons)
          seasonStore.setSelectedSeason(seasonStore.latestSeason)

          this.competitionPage.competition = response.data
          this.competitionPage.competitionStatus = status.COMPLETE
        })
      }
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.competitionPage.competitionStatus = status.FAILED
      })
    }

    runInAction(() => uiStore.setLoadingScreenMessage(''))
  }

  async fetchPopularCompetitions() {
    if (!this.popularCompetitionsPristine || this.fetchingPopularCompetitions) return
    this.setPopularCompetitionsStatus(status.LOADING)

    try {
      const response = await getPopularCompetitionsRequest()
      this.storePopularCompetitions(response.data.data)
      this.setPopularCompetitionsStatus(status.COMPLETE)
    } catch (e) {
      retrieveErrorMessage(e)
      this.setPopularCompetitionsStatus(status.FAILED)
    }
  }

  async fetchFavouriteCompetitions() {
    if (this.fetchingFavouriteCompetitions) return
    runInAction(() => this.favouriteCompetitionsStatus = status.LOADING)

    try {
      const response = await fetchFavouriteCompetitionRequest()
      runInAction(() => {
        this.favouriteCompetitionsStatus = status.COMPLETE
        this.favouriteCompetitions = response.data.data.map((res: any) => res.competition)
      })
    } catch (e) {
      runInAction(() => {
        this.favouriteCompetitionsStatus = status.FAILED
      })
    }
  }

  async toggleFavouriteCompetition(competition: ICompetition) {
    if (this.togglingFavouriteCompetition) return
    runInAction(() => this.toggleFavouriteStatus = status.LOADING)
    uiStore.setLoadingScreenMessage('Selecting Subscription Plan...')

    try {
      this.updateFavouritesData(competition)
      await toggleCompetitionFavouriteRequest(competition.username)

      uiStore.setLoadingScreenMessage('')
      runInAction(() => {
        this.toggleFavouriteStatus = status.COMPLETE
      })

    } catch (e) {
      this.updateFavouritesData(competition)
      runInAction(() => {
        this.toggleFavouriteStatus = status.FAILED
      })
    } 
  }

  async setSubscriptionPlan({
    competitionUsername,
    subscriptionPlan,
    reference,
    amount,
    seasonId
  }: ISetSubscriptionPlan) {
    if (this.settingSubscriptionPlan) return
    runInAction(() => this.setSubscriptionStatus = status.LOADING)

    try {
      if (amount && seasonId && reference) {
        await setSeasonPaymentRequest(seasonId, { amount, reference })
      }

      const response = await setCompetitionSubscriptionPlanRequest({
        competition_id: competitionUsername,
        pricing_subscription: subscriptionPlan
      })
      runInAction(() => {
        this.setSubscriptionStatus = status.COMPLETE
        this.competitionFields.competitionDetails.subscription_plan = subscriptionPlan
        this.competitionFields.competitionDetails.paid = true
      })
      localStorage.setItem('create_competition',JSON.stringify({...this.competitionFields}))
      uiStore.showToastMessage({type: 'success', message: response.data.message})

      if (this.subscriptionPlan === seasonTypes.PREMIER) {
        this.setSteps({...defaultSteps})
      } else {
        const newSteps = exclude(defaultSteps, ['PAYMENT_OPTIONS'])
        newSteps.SUMMARY = defaultSteps.PAYMENT_OPTIONS
        this.setSteps(newSteps)
      }
    } catch (e) {
      runInAction(() => this.setSubscriptionStatus = status.FAILED)
      uiStore.showToastMessage({type: 'error', message: retrieveErrorMessage(e)})
    }
  }

  async updateSubscriptionPlan({
    competitionUsername,
    subscriptionPlan,
    reference,
    amount,
    seasonId
  }: ISetSubscriptionPlan) {
    uiStore.setLoadingScreenMessage('Processing...')
    try {
      if (amount && seasonId && reference) {
        await setSeasonPaymentRequest(seasonId, { amount, reference })
      }

      const response = await setCompetitionSubscriptionPlanRequest({
        competition_id: competitionUsername,
        pricing_subscription: subscriptionPlan
      })
      this.setSubscriptionInfo(subscriptionPlan)
      uiStore.successModal(response.data.message)
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchSimilarCompetitions(category: string) {
    if (this.fetchingSimilarCompetitions) return
    this.setSimilarCompetitionsStatus(status.LOADING)

    try {
      const response = await fetchAllCompetitionsRequest(category, 1)
      this.storeSimilarCompetitions(response.data.results)
      this.setSimilarCompetitionsStatus(status.COMPLETE)
    } catch (e) {
      retrieveErrorMessage(e)
      this.setSimilarCompetitionsStatus(status.FAILED)
    }
  }

  async setCompetitionTemplate(template: number) {
    setCompetitionTemplateRequest(this.competitionDetails.username, template)
    runInAction(() => {
      this.competitionDashboard.competitionDetails = {...this.competitionDetails, template}
    })
  }

  async createCategory(name: string) {
    try {
      const response = await createCategoriesRequest(this.competitionDetails.username, name)
      const createdCategory = response.data.data
      const currentCategories = this.competitionDashboardDetails.categories || []
      const categories = currentCategories.concat(createdCategory)
      runInAction(() => {
        this.competitionDashboard.competitionDetails = {...this.competitionDetails, categories}
      })
      uiStore.showToastMessage({type: 'success', message: 'category created'})
    } catch (e) {
      uiStore.showToastMessage({type: 'error', message: retrieveErrorMessage(e)})
    }
  }

  async deleteCategory(categoryId: number) {
    try {
      await deleteCategoriesRequest(this.competitionDetails.username, categoryId)
      const categories = this.competitionDashboardDetails.categories?.filter(e => e.id !== categoryId)
      runInAction(() => {
        this.competitionDashboard.competitionDetails = {...this.competitionDetails, categories}
      })
      uiStore.showToastMessage({type: 'success', message: 'category deleted'})
    } catch (e) {
      uiStore.showToastMessage({type: 'error', message: retrieveErrorMessage(e)})
    }
  }

  async updateCategory(categoryId: number, name: string) {
    try {
      await updateCategoryRequest(this.competitionDetails.username, categoryId, name)
      const categories = this.competitionDashboardDetails.categories
        ?.map(e => e.id === categoryId ? ({ id: e.id, name }) : e)
      runInAction(() => {
        this.competitionDashboard.competitionDetails = {...this.competitionDetails, categories}
      })
      uiStore.showToastMessage({type: 'success', message: 'category updated!'})
    } catch (e) {
      uiStore.showToastMessage({type: 'error', message: retrieveErrorMessage(e)})
    }
  }

  setSubscriptionInfo(plan: number) {
    this.competitionDashboard.competitionDetails.subscription_plan = plan
    this.competitionDashboard.competitionDetails.paid = true
  }

  setSteps(steps: any) {
    this.steps = steps
  }

  updateFavouritesData(competition: ICompetition) {
    if (this.favouriteCompetitions.find(c => c.id === competition.id)) {
      this.favouriteCompetitions = this.favouriteCompetitions.filter(c => c.id !== competition.id)
    } else {
      this.favouriteCompetitions = this.favouriteCompetitions.concat(competition)
    }
  }

  isFavourite(competition: ICompetition) {
    return !!this.favouriteCompetitions.find(c => c.id === competition.id)
  }

  storePopularCompetitions(competitions: ICompetition[]) {
    this.popularCompetitions = competitions
  }

  setPopularCompetitionsStatus(status: Status) {
    this.popularCompetitionsStatus = status
  }

  storeSimilarCompetitions(competitions: ICompetition[]) {
    this.similarCompetitions = competitions
  }

  setSimilarCompetitionsStatus(status: Status) {
    this.similarCompetitionsStatus = status
  }

  persistState() {
    localStorage.setItem(
      'create_competition',
      JSON.stringify({...this.competitionFields})
    )
  }

  unPersistState() {
    localStorage.removeItem('create_competition')
  }

  setCurrentStep(step: number) {
    this.competitionFields.currentStep = step
  }

  setStepTitle(title: string) {
    this.competitionFields.stepTitle = title
  }

  loadExistingCompetition() {
    const payload = localStorage.getItem('create_competition')
    if (!payload) return

    const payloadObject = JSON.parse(payload)
    this.competitionFields = payloadObject

    seasonStore.setSeasons(payloadObject.competitionDetails.seasons)
    seasonStore.setSelectedSeason(seasonStore.latestSeason)
  }

  resetCompetitionFields() {
    this.competitionFields = {...defaultCompetitionFields}
    this.unPersistState()
  }

  resetCompetitionDashboardData() {
    if (!isEmpty(this.competitionDashboard.competitionDetails)) {
      this.competitionDashboard = {...competitionDashboardData}
    }
  }

  clearCompetitionData() {
    this.competitions = { results: [], pagination: {} as IPagination }
  }

  clearCompetitionPage() {
    this.competitionPage = {...competitionPageDefault}
  }

  setCompetitionCategory(category: ICategory, isCompetitionPage = false) {
    if (isCompetitionPage) {
      this.competitionPage.selectedCategory = category
    } else {
      this.competitionDashboard.selectedCategory = category
    }
  }

  get competitionPageUsername() {
    return this.competitionPage?.competition?.username
  }

  get createCompetitionStep() {
    return this.competitionFields.currentStep
  }

  get createCompetitionLoading() {
    return this.createCompetitionStatus === status.LOADING
  }

  get createCompetitionFailed() {
    return this.createCompetitionStatus === status.FAILED
  }

  get createCompetitionCompleted() {
    return this.createCompetitionStatus === status.COMPLETE
  }

  get createCompetitionError() {
    return this.createCompetitionErrMessage
  }

  get fetchNewCompetitionLoading() {
    return this.fetchCompetitionStatus[NEW] === status.LOADING
  }

  get fetchNewCompetitionComplete() {
    return this.fetchCompetitionStatus[NEW] === status.COMPLETE
  }

  get fetchNewCompetitionFailed() {
    return this.fetchCompetitionStatus[NEW] === status.FAILED
  }

  get fetchInvolvedCompetitionLoading() {
    return this.fetchCompetitionStatus[INVOLVED] === status.LOADING
  }

  get fetchInvolvedCompetitionComplete() {
    return this.fetchCompetitionStatus[INVOLVED] === status.COMPLETE
  }

  get voterDashboardHomeLoaded() {
    return this.fetchInvolvedCompetitionComplete && this.fetchFavouriteCompetitionComplete && this.fetchNewCompetitionComplete
  }

  get shouldShowDefaultStateOnDashboard() {
    return this.voterDashboardHomeLoaded && isEmpty(this.dashboardCompetitions.involved) && isEmpty(this.dashboardCompetitions.favourite) && isEmpty(this.dashboardCompetitions.new)
  }

  get fetchInvolvedCompetitionFailed() {
    return this.fetchCompetitionStatus[INVOLVED] === status.FAILED
  }

  get fetchFavouriteCompetitionLoading() {
    return this.fetchCompetitionStatus[FAV] === status.LOADING
  }

  get fetchFavouriteCompetitionComplete() {
    return this.favouriteCompetitionsStatus === status.COMPLETE
  }

  get fetchFavouriteCompetitionFailed() {
    return this.fetchCompetitionStatus[FAV] === status.FAILED
  }

  get fetchAllCompetitionsLoading() {
    return this.fetchCompetitionStatus[ALL] === status.LOADING
  }

  get fetchAllCompetitionsComplete() {
    return this.fetchCompetitionStatus[ALL] === status.COMPLETE
  }

  get fetchAllCompetitionsFailed() {
    return this.fetchCompetitionStatus[ALL] === status.FAILED
  }

  get competitionDetails() {
    if (isEmpty(this.competitionDashboardDetails)) {
      return this.competitionFields.competitionDetails
    } else {
      return this.competitionDashboardDetails
    }
  }

  get competitionCreated() {
    return !!this.competitionDetails.id
  }

  get currentStep() {
    return this.competitionFields.currentStep
  }

  get stepTitle() {
    return this.competitionFields.stepTitle
  }

  get savingPayoutOptions() {
    return this.savePayoutOptionsStatus === status.LOADING
  }

  get payoutOptions() {
    return this.competitionFields.payoutOptions
  }

  get fetchingMyCompetitions() {
    return this.myCompetitionsStatus === status.LOADING
  }

  get fetchedMyCompetitions() {
    return this.myCompetitionsStatus === status.COMPLETE
  }

  get loadingCompetitionDashboard() {
    return this.competitionDashboardStatus === status.LOADING
  }

  get competitionDashboardLoaded() {
    return this.competitionDashboardStatus === status.COMPLETE
  }

  get competitionDashboardDetails() {
    return this.competitionDashboard.competitionDetails
  }

  get subscriptionPlan() {
    return this.competitionDetails.subscription_plan
  }

  get isPremierSubscriptionPlan() {
    return this.subscriptionPlan === seasonTypes.PREMIER
  }

  get isFreeSubscriptionPlan() {
    return this.subscriptionPlan === seasonTypes.FREE
  }

  get isProPlan() {
    return this.subscriptionPlan === seasonTypes.PRO
  }

  get isEssentialPlan() {
    return this.subscriptionPlan === seasonTypes.ESSENTIAL
  }

  get fetchingCompetitionStaff() {
    return this.competitionStaffStatus === status.LOADING
  }

  get savingCompetitionStaff() {
    return this.saveStaffStatus === status.LOADING
  }
  
  get fetchingCompetitionCategories() {
    return this.categoriesStatus === status.LOADING
  }

  get fetchingCompetitions() {
    return this.competitionsStatus === status.LOADING
  }

  get competitionPageInfo() {
    return this.competitionPage.competition
  }

  get fetchingCompetitionPageInfo() {
    return this.competitionPage.competitionStatus === status.LOADING
  }

  get fetchedCompetitionPageInfo() {
    return this.competitionPage.competitionStatus === status.COMPLETE
  }

  get failedToFetchCompetitionPageInfo() {
    return this.competitionPage.competitionStatus === status.FAILED
  }

  get dashboardNotPermitted() {
    return this.competitionDashboard.notPermitted
  }

  get currentStaff() {
    const staffEmail = authStore.userEmail
    if (!staffEmail) return null

    return this.competitionStaff.find(c => c.personnel.email === staffEmail)
  }

  get currentStaffRole() {
    return this.currentStaff?.role || 0
  }

  get isCompetitionPublic() {
    return this.competitionDetails.visibility === 'Public'
  }

  get popularCompetitionsPristine() {
    return !this.popularCompetitions?.length && this.popularCompetitionsStatus === null
  }
  
  get fetchingPopularCompetitions() {
    return this.popularCompetitionsStatus === status.LOADING
  }

  get failedToFetchPopularCompetitions() {
    return this.popularCompetitionsStatus === status.FAILED
  }

  get fetchedPopularCompetitions() {
    return this.popularCompetitionsStatus === status.COMPLETE
  }

  get hasPopularCompetitions() {
    return !!this.popularCompetitions?.length
  }

  get similarCompetitionsPristine() {
    return !this.similarCompetitions?.length && this.similarCompetitionsStatus === null
  }
  
  get fetchingSimilarCompetitions() {
    return this.similarCompetitionsStatus === status.LOADING
  }

  get failedToFetchSimilarCompetitions() {
    return this.similarCompetitionsStatus === status.FAILED
  }

  get fetchedSimilarCompetitions() {
    return this.similarCompetitionsStatus === status.COMPLETE
  }

  get hasSimilarCompetitions() {
    return !!this.similarCompetitions?.length
  }

  get fetchingFavouriteCompetitions() {
    return this.favouriteCompetitionsStatus === status.LOADING
  }

  get togglingFavouriteCompetition() {
    return this.toggleFavouriteStatus === status.LOADING
  }

  get settingSubscriptionPlan() {
    return this.setSubscriptionStatus === status.LOADING
  }

  get successfullySetSubscriptionPlan() {
    return this.setSubscriptionStatus === status.COMPLETE
  }

  get userCompetitions() {
    return this.myCompetitions.results || []
  }

  get userCompetitionPaginationInfo() {
    return this.myCompetitions.pagination
  }

  get noUserCompetitions() {
    return !this.userCompetitions?.length
  }

  get fetchingMyPrivateCompetitions() {
    return this.myPrivateCompetitionsStatus === status.LOADING
  }

  get fetchedMyPrivateCompetitions() {
    return this.myPrivateCompetitionsStatus === status.COMPLETE
  }

  get failedToFetchMyPrivateCompetitions() {
    return this.myPrivateCompetitionsStatus === status.FAILED
  }

  get privateCompetitionsPaginationInfo() {
    return this.myPrivateCompetitions.pagination
  }

  get noPrivateCompetitions() {
    return !(this.myPrivateCompetitions.results || []).length
  }

  get privateCompetitions() {
    return this.myPrivateCompetitions.results || []
  }

  get selectedCategory() {
    return this.competitionDashboard.selectedCategory
  }
}

export default new CompetitionStore()
