import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Route, RouteChildrenProps, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import PageContent from '../../components/PageContent'
import { colors, spacing } from '../../styles/_var'
import AboutCompetition from './AboutCompetition'
import CompetitionBanner from './CompetitionBanner'
import Text from '../../components/Text'
import Results from './Results'
import Discussions from './Discussions'
import DiscussionPage from './DiscussionPage'
import { capitalize, getChannelID, getCompetitionLabels, isMobileScreen, wordify } from '../../utils/helpers'
import { TabItem } from '../../components/StyledComponents'
import competitionStore from '../../stores/competitionStore'
import creditStore from '../../stores/creditStore'
import uiStore from '../../stores/uiStore'
import contestantStore from '../../stores/contestantStore'
import VotingRoundOptions from './VotingRoundOptions'
import seasonStore from '../../stores/seasonStore'
import votingRoundStore from '../../stores/votingRoundStore'
import authStore from '../../stores/authStore'
import Aside from '../../components/Aside/Aside'
import CompetitionContestants from './CompetitionContestants'
import ErrorPage from '../ErrorPages/ErrorPage'
import { ADVERTISEMENTS, NOTES, POPULAR } from '../../components/Aside/constants'
import usePageTitle from '../../hooks/usePageTitle';
import * as gen from '../../utils/generated'
import VoteModal from './VoteModal';


interface ICompetitionPageProps extends RouteChildrenProps<{competition_id: string}> {
  className?: string
}

const extractPathComponents = (pathname: string) => pathname.match(/\/(\w*)\/(\w*)\/*/)
let subscription: any = null

const refreshContestantVotes = () => {
  if (!seasonStore.selectedSeasonId) return
  votingRoundStore.fetchVotingRounds(seasonStore.selectedSeasonId)
}

const CompetitionPage: FunctionComponent<ICompetitionPageProps> = (props) => {
  const pathname = props.match?.url
  const [contestantUsername, setContestantUsername] = useState('')
  const [voteCount, setVoteCount] = useState(1)
  const [canShowVoteCount, setShowVoteCount] = useState(false)
  const [error, setError] = useState('')
  const [,,selectedTabOnLoad] = extractPathComponents(props.location.pathname) || []
  const { competition } = competitionStore.competitionPage
  const CONTESTANT_TAB = getCompetitionLabels(competition?.template).contentstantLabel + 's'
  const RESULT_TAB = 'Results'
  const DISCUSSION_TAB = 'Discussions'
  const VOTING_OPTIONS_TAB = 'Voting_options'
  const [currentTab, setCurrentTab] = useState(selectedTabOnLoad ? capitalize(selectedTabOnLoad) : CONTESTANT_TAB)

  const routeNames = {
    [CONTESTANT_TAB]: 'contestants',
    [RESULT_TAB]: 'results',
    [DISCUSSION_TAB]: 'discussions',
    [VOTING_OPTIONS_TAB]: 'voting_options'
  }
  const tabs: string[] = [CONTESTANT_TAB, RESULT_TAB, VOTING_OPTIONS_TAB]

  const season = seasonStore.selectedSeason
  const competitionUsername = props.match?.params.competition_id
  const votingOptions = votingRoundStore.selectedVotingRound?.voting_options
  usePageTitle(competition.name)

  useEffect(() => {
    if (!competitionUsername) return
    if (!votingOptions?.show_vote_count || !votingOptions?.show_live_updates) return
    if (subscription != null) return

    const channelID = getChannelID(competitionUsername)
    subscription = gen.subscribe(channelID, debounce(refreshContestantVotes, 5000), console.log)
  }, [competitionUsername, votingOptions])

  useEffect(() => {
    return () => {
      competitionStore.clearCompetitionPage()

      if (subscription) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [])
  
  useEffect(() => {
    if (competitionUsername) {
      competitionStore.fetchCompetitionPageInfo(competitionUsername).then(() => {
        if (competitionStore.failedToFetchCompetitionPageInfo) return
        contestantStore.fetchContestants(competitionUsername, seasonStore.selectedSeasonId)
        votingRoundStore.fetchVotingRounds(seasonStore.selectedSeasonId)  
      })
    }
  }, [competitionUsername])

  const closeModal = () => {
    setVoteCount(1)
    setShowVoteCount(false)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setVoteCount(+e.target.value)
  }

  const onSubmitForm = () => {
    const votingError = getErrorIfAny()

    if (votingError) return setError(votingError)

    contestantStore.voteContestant(
      contestantUsername, 
      voteCount,
      competitionUsername
    )
    closeModal()
  }

  const getErrorIfAny = () => {
    const balance = creditStore.creditBalance
    const costTotal = (votingOptions?.vote_cost || 0) * voteCount

    if (costTotal > balance) {
      return 'You don\'t have enough balance'
    }
  }

  const onVote = (contestantUsername: string) => () => {
    const votingError = getErrorIfAny()
    if (votingError) {
      uiStore.errorModal(votingError)
      return
    }

    if (votingOptions?.max_no_of_votes === 1) {
      uiStore.openConfirmDialog('Are you sure you want to vote for this contestant?', () => {
        contestantStore.voteContestant(contestantUsername, voteCount, competitionUsername)
        setShowVoteCount(false)
      })
    } else {
      setContestantUsername(contestantUsername)
      setShowVoteCount(true)
    }
  }

  if (competitionStore.failedToFetchCompetitionPageInfo) return <ErrorPage code='404' />

  if (isEmpty(competition)) return null

  return (
    <CompetitionPageWrapper role="main">
      <VoteModal 
        closeModal={closeModal}
        canShowVoteCount={canShowVoteCount}
        error={error}
        onSubmitForm={onSubmitForm}
        onInputChange={onInputChange}
        voteCount={voteCount}
      />
      <Grid container spacing={isMobileScreen() ? 0 : 6}>
        <Grid item xs={12} sm={12} md={9}>
          <CompetitionBanner 
            {...competition} 
            seasons={seasonStore.seasons} 
            currentSeason={season}
            banner={season.banner}
            logo={season.logo}
            setSelectedSeason={seasonId => seasonStore.setSelectedSeasonById(seasonId)}
            showFavouriteIcon={authStore.userIsLoggedIn}
            onFavourite={() => competitionStore.toggleFavouriteCompetition(competition)}
            isFavourite={competitionStore.isFavourite(competition)} />
          <AboutCompetition about={season.description} />
          <CompetitionBody role="tab">
            <TabSection role="tabpanel">
              {tabs.map((tab, index) => (
                <TabItem
                  onClick={() => setCurrentTab(tab)}
                  to={`${pathname}/${routeNames[tab]}`}
                  key={index}
                  selected={currentTab === tab}
                >
                  <Text variant="regular" truncate>{wordify(tab)}</Text>
                </TabItem>
              ))}
            </TabSection>
            <TabBody role="tablist">
              <Switch>
                <Route
                  exact
                  path={`${pathname}/${routeNames[RESULT_TAB]}`}
                  component={Results} />
                <Route exact path={`${pathname}/${routeNames[CONTESTANT_TAB]}`}>
                  <CompetitionContestants
                    seasonConcluded={seasonStore.selectedSeason.concluded}
                    userIsLoggedIn={authStore.userIsLoggedIn}
                    votingClosed={votingRoundStore.selectedVotingRound?.has_ended || !votingRoundStore.selectedVotingRound?.has_started}
                    onVote={onVote}
                    contestants={contestantStore.pageContestants}
                    showVoteCount={votingOptions?.show_vote_count}
                    loading={contestantStore.fetchingContestants} />
                </Route>
                <Route
                  exact
                  path={`${pathname}/${routeNames[VOTING_OPTIONS_TAB]}`}
                  component={() => !!votingOptions ? <VotingRoundOptions {...votingOptions} /> : null} />
                <Route
                  exact
                  path={`${pathname}/${routeNames[DISCUSSION_TAB]}`}
                  component={Discussions} />
                <Route
                  exact
                  path={`${pathname}/${routeNames[DISCUSSION_TAB]}/:discussion_id`}
                  component={DiscussionPage} />
                <Route path='*'>
                  <CompetitionContestants
                    seasonConcluded={seasonStore.selectedSeason.concluded}
                    userIsLoggedIn={authStore.userIsLoggedIn}
                    votingClosed={votingRoundStore.selectedVotingRound?.has_ended || !votingRoundStore.selectedVotingRound?.has_started}
                    onVote={onVote}
                    contestants={contestantStore.pageContestants}
                    loading={contestantStore.fetchingContestants} />
                </Route>
              </Switch>
            </TabBody>
          </CompetitionBody>
        </Grid>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={3} sm={12} xs={12}>
            <Aside components={[NOTES, POPULAR, ADVERTISEMENTS]} />
          </Grid>
        </Hidden>
      </Grid>
    </CompetitionPageWrapper>
  )
}

export default observer(CompetitionPage)

export const CompetitionPageWrapper = styled(PageContent)`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding-top: ${spacing.sm};
  margin-bottom: ${spacing.rg};
`

export const CompetitionBody = styled.div`
  margin-top: ${spacing.sm};
  border: solid 1px ${colors.lightgrey};
  border-radius: 1rem;
  min-height: 40rem;
`

export const TabSection = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: ${spacing.xs} ${spacing.sm};
  justify-content: space-between;
`

export const TabBody = styled.div`
  box-sizing: border-box;
  padding: ${spacing.sm};
`

