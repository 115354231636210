import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { spacing } from '../../../styles/_var'
import uiStore from '../../../stores/uiStore'
import { RouteChildrenProps } from 'react-router-dom'
import ChangePassword from './ChangePassword'
import Notifications from './Notifications'
import DeleteAccount from './DeleteAccount'
import SavedCreditCards from './CreditCard'
import usePageTitle from '../../../hooks/usePageTitle'


interface IVoterSettings extends RouteChildrenProps {}

const VoterSettings: FunctionComponent<IVoterSettings> = () => {
  usePageTitle('Settings')
  useEffect(() => {
    uiStore.setNavTitle('Settings')
  }, [])

  return (
    <Wrapper>
      <ChangePassword />
      <Notifications />
      <SavedCreditCards />
      <DeleteAccount />
    </Wrapper>
  )
}


export default observer(VoterSettings)

const Wrapper = styled.div`
  padding: 0 ${spacing.sm};
`

