import React, { FunctionComponent, useEffect, useState } from 'react'
import { faBars, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import imagePlaceholder from '../assets/images/placeholder-person.jpg'
import { ReactComponent as Home } from '../assets/images/Home.svg'
import { colors, device, spacing } from '../styles/_var'
import { Avatar } from './StyledComponents'
import Text from './Text'
import Link from './Link'
import { routes } from '../utils/constants'
import { toCurrency } from '../utils/helpers'


interface INavUserInfo {
  avatar?: string,
  firstName: string,
  tushCredits: number,
  toggleSidebar: () => void,
}

export const NavUserInfo: FunctionComponent<INavUserInfo> = React.memo(({
  firstName,
  tushCredits,
  avatar,
  toggleSidebar
}) => {
  const closeMenu = () => setShowMenu(false)

  useEffect(() => {
    document.addEventListener('click', closeMenu)
    return () => {
      document.removeEventListener('click', closeMenu)
    }
  }, [])

  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  return (
    <RightSection>
      <GreetingText variant="regular" mr={1}>Hi, {firstName}</GreetingText>
      <UserAvatar
        className="user-avatar"
        onClick={toggleMenu}
        alt="user avatar"
        aria-label="user avatar"
        hideInMobile
        src={avatar || imagePlaceholder}
        width={40}
        height={40} />
      {tushCredits !== -1 && <TushCredits variant="small" aria-label="user credits amount">N{toCurrency(tushCredits, false, false)}</TushCredits>}
      <HamburgerIcon 
        icon={faBars} 
        color={colors.purple}
        size="3x"
        onClick={toggleSidebar} />
      <Menu showMenu={showMenu}>
        <MenuItem to={routes.DASHBOARD}>
          <Home />
          <Text variant="small" ml={1}>Dashboard</Text>
        </MenuItem>
        <MenuItem to={routes.LOGOUT}>
          <FontAwesomeIcon icon={faPowerOff} size="2x" />
          <Text variant="small" ml={1}>Logout</Text>
        </MenuItem>
      </Menu>
    </RightSection>
  )
})

const RightSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img {
    border-color: ${colors.lightgrey};
  }
`

export const TushCredits = styled(Text)`
  padding: ${spacing.xxs};
  background-color: rgba(151, 126, 247, 0.4);
  border-radius: 10px;
  margin-left: ${spacing.xxs};
`

const HamburgerIcon = styled(FontAwesomeIcon)`
  display: block;
  margin-left: ${spacing.xxs};

  @media ${device.tablet} {
    display: none;
  }
`

const UserAvatar = styled(Avatar)`
  cursor: pointer;
  transition: box-shadow ease .2s;

  &:hover {
    box-shadow: 0 0 1px 2px ${colors.lightgrey};
  }
`

const Menu = styled(({ showMenu, ...rest }) => <div {...rest} />)`
  position: absolute;
  background: ${colors.white};
  box-shadow: 0 0 3px 3px ${colors.subtlylightpurple};
  top: 5.6rem;
  width: 100%;
  border-radius: 1rem;
  transition: all ease .1s;

  ${({ showMenu }) => `
    opacity: ${showMenu ? 1 : 0 };
    transform: scale(${showMenu ? 1 : 0});
  `}
`

const MenuItem = styled(Link)`
  display: flex;
  padding: ${spacing.xxs} ${spacing.sm};
  cursor: pointer;
  transition: background-color ease .2s;
  align-items: center;
  color: ${colors.grey};
  text-decoration: none;

  svg > path {
    fill: ${colors.grey};
  }

  &:hover {
    background-color: ${colors.lightpurple};
    color: ${colors.white};

    svg > path {
      fill: ${colors.white};
    }
  }
`

const GreetingText = styled(Text)`
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`