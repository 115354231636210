import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing, device, colors } from '../styles/_var'
import Text, { BoldText } from './Text'
import PageContent from './PageContent'
import votingImage from '../assets/images/voting.png'
import speakingImage from '../assets/images/speaking.png'
import thumbImage from  '../assets/images/thumbs.png';
import Link from './Link'
import { routes } from '../utils/constants'

const VotingIcon = React.memo(() => {
  return (
    <VotingIconWrapper>
      <VotingImage src={votingImage} alt='voting-icon' />
      <ThumbIcon src={thumbImage} alt='thumb-icon' />
      <SpeakingIcon src={speakingImage} alt='thumb-icon' />
    </VotingIconWrapper>
  )
})


const LandingSectionThree: FunctionComponent= React.memo(() => {
  return (
    <SectionWrapper>
      <VotingIcon />
      <ContentSection>
        <TitleText variant="large">Get Started with Matuskii</TitleText>
        <Subtext variant="medium">Join matuskii today and experience a more pleasant way to cast votes.</Subtext>
        <Link useButton variant='primary' to={routes.SIGNUP}>Get Started</Link>
        <Link useButton variant="secondary" to={routes.CONTACT_US}>
          Contact Sales
        </Link>
      </ContentSection>
    </SectionWrapper>
  )
})

const SectionWrapper = styled(PageContent)`
  position: relative;
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  margin: 0 ${spacing.sm};
  text-align: center;

  @media ${device.tablet} {
    padding-top: ${spacing.rg};
    padding-bottom: ${spacing.rg};

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  @media ${device.laptop} {
    padding-top: ${spacing.xl};
    padding-bottom: ${spacing.xl};
  }

  & button, a {
    margin-right: ${spacing.xxs};
    width: 15rem;
  }
`

const TitleText = styled(BoldText)`
  color: ${colors.darkpurple};
  line-height: 4rem;
  box-sizing: border-box;
`

const Subtext = styled(Text)`
  margin-bottom: ${spacing.sm};
`

const ContentSection = styled.div`
  margin-bottom: ${spacing.sm};
  @media ${device.tablet} {
    max-width: 40rem;
  }
`

const VotingIconWrapper = styled.div`
  position: relative;
  width: 40rem;
  padding-top: ${spacing.sm};
  margin: auto;
  margin-bottom: ${spacing.rg};

  @media ${device.tablet} {
    width: 28rem;
    margin: inherit;
  }
`

const VotingImage = styled.img`
  width: 100%;
`

const ThumbIcon = styled.img`
  width: 10rem;
  position: absolute;
  bottom: 1rem;
  left: -3rem;

  @media ${device.tablet} {
    width: 7rem;
  }
`

const SpeakingIcon = styled.img`
  position: absolute;
  width: 10rem;
  right: 0;

  @media ${device.tablet} {
    width: 7rem;
  }
`

export default LandingSectionThree
