import { makeAutoObservable } from "mobx";
import { chargeCardRequest, deleteSavedCardRequest, fetchSavedCardsRequest, getPaystackInfoRequest, saveCreditCardRequest, setDefaultCardRequest } from "../utils/apiRequests";
import { status } from "../utils/constants";
import { getPaystackReference, retrieveErrorMessage } from "../utils/helpers";
import { Status } from "../utils/interfaces";
import authStore from "./authStore";
import creditStore from "./creditStore";
import uiStore from "./uiStore";


interface IPaymentCompletePayload {
  status: string,
  reference: string,
}

interface IPaymentPayload {
  email: string,
  reference: string,
  amount: number,
  onSuccess: (data: IPaymentCompletePayload) => void,
  publicKey?: string,
  paystackkey?: string,
}

interface ISaveCreditCard {
  reference_key: string,
  amount: number,
  silent?: boolean,
  refund_amount?: boolean,
}

export interface IPaystackInfo {
  cost?: number,
  paystack_key?: string,
}

export interface ICards {
  uuid: string,
  exp_month: number,
  exp_year: number,
  authorization_code: string,
  bank_name: string,
  last4: string,
  card_type: string,
  account_name: string,
  is_default: boolean,
}


class PaymentStore {
  paystackInfo: IPaystackInfo | null = null
  showPaystackModal: boolean = false
  paymentPayload: null | IPaymentPayload = null
  paystackInfoStatus: null | Status = null
  paystackInfoErrMessage: string = ''
  savedCards: ICards[] = []
  fetchCardsStatus: null | Status = null
  showCreditCardSelector: boolean = false
  shouldSaveNewCard: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setShowPaystackModal(status: boolean) {
    this.showPaystackModal = status
  }

  setPaymentPayload(payload: null | IPaymentPayload) {
    this.paymentPayload = payload
  }

  setPaystackInfo(info: IPaystackInfo | null) {
    this.paystackInfo = info
  }

  setPaystackInfoStatus(status: Status | null) {
    this.paystackInfoStatus = status
  }

  setPaystackInfoErrMessage(message: string) {
    this.paystackInfoErrMessage = message
  }

  closeCreditCardSelector() {
    this.showCreditCardSelector = false
  }

  closePayment() {
    this.setShowPaystackModal(false)
    this.setPaymentPayload(null)
    this.setPaystackInfoStatus(null)
    this.setPaystackInfoErrMessage('')
  }

  setSavedCards(cards: ICards[]) {
    this.savedCards = cards
  }

  setFetchCardsStatus(status: null | Status) {
    this.fetchCardsStatus = status
  }

  getCardById(cardId: string) {
    return this.savedCards.find(card => card.uuid === cardId)
  }

  openCreditCardSelector() {
    this.showCreditCardSelector = true
  }

  triggerNewCardPayment() {
    this.closeCreditCardSelector()
    this.displaySaveCardPromptThenProceed()
  }

  displaySaveCardPromptThenProceed() {
    uiStore.openConfirmDialog("You are about to use your card to make a payment, would you like to save this new card?", () => {
      this.shouldSaveNewCard = true
      this.startPaystackPaymentProcessing()
    }, () => {
      this.shouldSaveNewCard = false
      this.startPaystackPaymentProcessing()
    })
  }

  startPaystackPaymentProcessing() {
    this.setShowPaystackModal(true)
    this.firePaystack()
  }

  processAndSetPaymentPayload(payload: IPaymentPayload) {
    const paymentPayload = { 
      ...payload,
      publicKey: this.paystackInfo?.paystack_key,
      paystackkey: 'paystack4key',
      onSuccess: (data: IPaymentCompletePayload) => {
        if (payload.onSuccess) payload.onSuccess(data);
        this.closePayment();
      }
    }
    this.setPaymentPayload(paymentPayload)

    return paymentPayload
  }

  addNewCreditCard() {
    const defaultAmount = 5000
    const onPaystackComplete = (data: IPaymentCompletePayload) => {
      const { status, reference } = data
      if (status !== 'success') return

      this.saveCreditCard({
        reference_key: reference,
        amount: defaultAmount,
        silent: false,
        refund_amount: true
      })
    }
    const newCardPaymentPayload = {
      amount: defaultAmount,
      email: authStore.userEmail,
      reference: getPaystackReference(authStore.username),
      onSuccess: onPaystackComplete
    }

    uiStore.openConfirmDialog('To save your credit card we would need to charge your card N50 to ensure it works. This money would be refunded back in Matuskii credits ASAP.', async () => {
      if (!this.hasPaystackInfo) {
        await this.getPaystackInfo()
      }

      this.processAndSetPaymentPayload(newCardPaymentPayload)
      this.startPaystackPaymentProcessing()
    })
  }

  async proceedPaymentWithCard(card: ICards) {
    this.closeCreditCardSelector()
    uiStore.setLoadingScreenMessage("Charging your card, please wait a moment...")

    try {
      const response = await chargeCardRequest(card.uuid, this.paymentPayload?.amount || 0)
      if (this.paymentPayload) {
        this.paymentPayload.onSuccess(response.data.data)
      }
      this.setShowPaystackModal(false)
    } catch (e) {
      uiStore.displayModal({message: retrieveErrorMessage(e), variant: "error" })
    }
    uiStore.setLoadingScreenMessage("")
  }

  async setDefaultCard(cardId: string) {
    try {
      uiStore.setLoadingScreenMessage('setting default card...')
      await setDefaultCardRequest(cardId)
      this.setSavedCards(this.savedCards.map(card => {
        if (card.uuid === cardId) {
          card.is_default = true
        } else if (card.is_default) {
          card.is_default = false
        }

        return card
      }))
    } catch (e) {
      uiStore.showToastMessage({ message: retrieveErrorMessage(e) })
    } finally {
      uiStore.setLoadingScreenMessage('')
    }
  }

  async deleteSavedCard(cardId: string) {
    const cardToDelete = this.getCardById(cardId)

    if (this.savedCards.length === 1) {
      return uiStore.displayModal({ message: 'You need atleast one card', variant: 'error' })
    }

    if (cardToDelete?.is_default) {
      return uiStore.displayModal({ message: 'You cannot delete your default card', variant: 'error' })
    }

    try {
      uiStore.setLoadingScreenMessage('deleting card...')
      await deleteSavedCardRequest(cardId)
      this.setSavedCards(this.savedCards.filter(card => card.uuid !== cardId))
    } catch (e) {
      uiStore.showToastMessage({ message: retrieveErrorMessage(e) })
    } finally {
      uiStore.setLoadingScreenMessage('')
    }
  }

  async fetchSavedCards() {
    if (this.fetchingCards) return

    try {
      this.setFetchCardsStatus(status.LOADING)
      const response = await fetchSavedCardsRequest()
      
      this.setSavedCards(response.data.data)
      this.setFetchCardsStatus(status.COMPLETE)
    } catch (e) {
      console.log(retrieveErrorMessage(e))
      this.setFetchCardsStatus(status.FAILED)
    }
  }

  async saveCreditCard({
    reference_key,
    amount,
    silent=true,
    refund_amount=false
  }: ISaveCreditCard) {
    if (!silent) {
      uiStore.setLoadingScreenMessage('Saving your card...')
    }

    try {
      const response = await saveCreditCardRequest(reference_key, amount, refund_amount)
      const newlySavedCard = response.data.data

      if (newlySavedCard) this.setSavedCards([...this.savedCards, newlySavedCard])
      if (!silent) {
        uiStore.setLoadingScreenMessage('')
      }
    } catch (e) {
      if (!silent) {
        uiStore.displayModal({ message: retrieveErrorMessage(e), variant: 'error' })
      }
    }

    if (refund_amount) {
      creditStore.getUserCredit()
    }
  }

  async onPaystackSuccessful(data: any) {
    if (this.shouldSaveNewCard) {
      this.saveCreditCard({
        reference_key: data.reference,
        amount: this.paymentPayload?.amount || 0
      })
    }

    this.paymentPayload?.onSuccess && this.paymentPayload?.onSuccess(data)
  }

  async firePaystack() {
    setTimeout(() => {
      const paystackButton: any = document.getElementsByClassName('paystack-btn')[0]
      paystackButton?.click()
    }, 1000);
  }

  async startPayment(payload: IPaymentPayload) {
    if (!this.hasPaystackInfo) {
      await this.getPaystackInfo()
    }

    this.processAndSetPaymentPayload(payload)

    if (this.hasSavedCards) {
      this.openCreditCardSelector()
    } else {
      this.displaySaveCardPromptThenProceed()
    }
  }

  async getPaystackInfo() {
    uiStore.setLoadingScreenMessage('Initializing Payment')
    this.setPaystackInfoStatus(status.LOADING)

    try {
      const response = await getPaystackInfoRequest()
      this.setPaystackInfo(response.data)
      this.setPaystackInfoStatus(status.COMPLETE)
    } catch (e) {
      this.setPaystackInfoStatus(status.FAILED)
      this.setPaystackInfoErrMessage(retrieveErrorMessage(e));
    }

    uiStore.setLoadingScreenMessage('')
  }

  get hasActivePayment() {
    return !!this.paymentPayload
  }

  get hasPaystackInfo() {
    return !!this.paystackInfo
  }

  get fetchingCards() {
    return this.fetchCardsStatus === status.LOADING
  }

  get fetchedCards() {
    return this.fetchCardsStatus === status.COMPLETE
  }

  get failedToFetchCards() {
    return this.fetchCardsStatus === status.FAILED
  }

  get hasSavedCards() {
    return !!this.savedCards.length
  }
}

export default new PaymentStore()
