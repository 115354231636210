import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'


const LoadingComponent: React.FC<{message?: string}> = React.memo(({ message }) => {
  return (
    <Content>
      <FontAwesomeIcon icon={faSpinner} spin color={colors.darkpurple} size="4x" />
      <Text variant="medium">{message}</Text>
    </Content>
  )
})

export default LoadingComponent

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.sm};
  position: relative;
  text-align: center;

  p {
    color: ${colors.darkpurple};
  }
`