import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '../../components/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import uiStore from '../../stores/uiStore'
import { creditPurchaseFields, creditTransferFields } from '../../utils/constants'
import CreditSpendSummary from './components/CreditSpendSummary'
import { VotingCreditsWrapper, TransactionSection, Table, TableWrapper, TD } from './components/VoterDashboardStyled'
import Modal from '../../components/Modal';
import SimpleForm from '../../components/SimpleForm';
import creditStore, { ITransferCreditPayload } from '../../stores/creditStore';
import { observer } from 'mobx-react-lite';
import authStore from '../../stores/authStore';
import { colors } from '../../styles/_var';
import { beautifyDate, getPaystackReference, isPhoneNo, parseToPhone, toCurrency } from '../../utils/helpers';
import paymentStore from '../../stores/paymentStore';
import Button from '../../components/Button';
import usePageTitle from '../../hooks/usePageTitle';


const VotingCredits = () => {
  const [transferError, setTransferError] = useState('')
  const [enableFormSubmit, setEnableFormSubmit] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = useState(false)
  usePageTitle('Voting Credits')

  useEffect(() => {
    uiStore.setNavTitle('Voting Credits')
    creditStore.getCreditSpendSummary(true)
    creditStore.getTransactionHistory({ page: 1 })
  }, [])

  const transferAction = {
    label: 'Transfer',
    loading: creditStore.transferCreditLoading,
    invertOnHover: true,
    isSubmit: true,
    disabled: !enableFormSubmit
  }

  const purchaseAction = {
    label: 'Buy',
    loading:  paymentStore.showPaystackModal,
    invertOnHover: true,
    isSubmit: true
  }

  const transferCredit = (payload: ITransferCreditPayload) => {
    setTransferError('')
    payload.to_username = isPhoneNo(payload.to_username) ? parseToPhone(payload.to_username) : payload.to_username
    const canTransferError = creditStore.canTransferCreditError(payload)

    if (canTransferError) {
      setTransferError(canTransferError)
      return
    }

    creditStore.transferCredit(payload, undefined, () => {
      setShowTransferModal(false)
      uiStore.displayModal({variant: 'success', message: 'Credit transferred successfully!'})
    })
  }

  const purchaseCredit = (payload: any) => {
    const { amount } = payload
    const paymentAmount = +amount * 100

    setShowPurchaseCreditsModal(false)
    paymentStore.startPayment({
      amount: paymentAmount,
      email: authStore.userEmail,
      reference: getPaystackReference(authStore.username),
      onSuccess: paymentComplete(paymentAmount)
    })
  }

  const onUsernameValidated = (result: boolean) => {
    setEnableFormSubmit(result)
  }

  const getMoreTransactionHistory = () => {
    creditStore.getTransactionHistory({ getNext: true })
  }

  const paymentComplete = (paymentAmount: number) => (data: any) => {
    const { status, reference } = data

    if (status === 'success') {
      creditStore.completeCreditPayment({ amount: paymentAmount, reference })
    }
  }

  return (
    <>
      <VotingCreditsWrapper>
        <CreditSpendSummary
          {...creditStore.spendSummary}
          voting_credit={creditStore.userCreditBalance}
          onClickTransfer={() => setShowTransferModal(true)}
          onClickPurchase={() => setShowPurchaseCreditsModal(true)} />
        {creditStore.hasTransactionHistory && <TransactionSection>
          <Text variant="medium">TRANSACTIONS</Text>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <td><Text variant="regular">TYPE</Text></td>
                  <td><Text variant="regular">AMOUNT</Text></td>
                  <td><Text variant="regular">DATE</Text></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {creditStore.allTransactionHistory.map((transaction, index) => (
                  <tr key={index}>
                    <td><Text variant="subtext">{transaction.transaction_type}</Text></td>
                    <td><Text variant="subtext">{toCurrency(transaction.amount, false)}</Text></td>
                    <td><Text variant="subtext">{beautifyDate(transaction.created_at)}</Text></td>
                    <TD>
                      <Text variant="subtext">VIEW DETAILS</Text>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </TD>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          {creditStore.hasMoreTransactionHistory && (
            <Button
              variant='secondary'
              loading={creditStore.loadingTransactionHistory}
              onClick={getMoreTransactionHistory}
              mt={2}
              alignCenter
            >
              Load More
            </Button>
          )}
        </TransactionSection>}
      </VotingCreditsWrapper>
      <Modal
        onClose={() => setShowTransferModal(false)}
        showModal={showTransferModal}
      >
        <Text variant="medium" id="modal-message">Transfer Credits</Text>
        <Text variant="subtext">Fill in the details below to transfer credit.</Text>
        <SimpleForm 
          submitForm={transferCredit}
          formFields={creditTransferFields(onUsernameValidated)}
          actionButtons={transferAction}
        />
        {(!!creditStore.transferringCreditErrMessage || !!transferError) && (
          <ErrorMessage
            variant="small"
            mt={1}
            role="alert"
            aria-label={creditStore.transferringCreditErrMessage || transferError}
          >
            {creditStore.transferringCreditErrMessage || transferError}
          </ErrorMessage>
        )}
      </Modal>
      <Modal
        onClose={() => setShowPurchaseCreditsModal(false)}
        showModal={showPurchaseCreditsModal}
      >
        <Text variant="medium" id="modal-message">Purchase Tush Credits</Text>
        {showPurchaseCreditsModal && <SimpleForm 
          submitForm={purchaseCredit}
          formFields={creditPurchaseFields}
          actionButtons={purchaseAction}
        />}
      </Modal>
    </>
  )
}

export default observer(VotingCredits)

const ErrorMessage = styled(Text)`
  color: ${colors.red};
  text-align: center;
`
