import React from "react";
import styled from "styled-components";
import PageContent from "../../../components/PageContent";
import Text, { BoldText } from "../../../components/Text";
import { colors, device, spacing } from "../../../styles/_var";
import { IFeatures } from "../../../utils/interfaces";

const FeatureSection: React.FC<IFeatures> = ({
  title,
  desc,
  options,
  img,
  swap,
  id,
}) => {
  return (
    <SectionWrapper swap={swap} id={id}>
      <Feature>
        <Title variant="large" color={colors.darkpurple}>
          {title}
        </Title>
        <FeatureDescription variant="regular">{desc}</FeatureDescription>
        <ul>
          {options &&
            options.map((e, i) => (
              <Option key={i}>
                <OptionText variant="regular">{e}</OptionText>
              </Option>
            ))}
        </ul>
      </Feature>
      <img src={img} alt="section-one" />
    </SectionWrapper>
  );
};

export default FeatureSection;

const SectionWrapper = styled(({ swap, ...rest }) => <PageContent {...rest} />)`
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  background-color: ${({ swap }) => (swap ? colors.verylightpurple : "#0000")};
  img {
    width: 70%;
  }
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-right: ${spacing.sm};
  @media ${device.tablet} {
    flex-direction: ${({ swap }) => (swap ? "row-reverse" : "row")};
    justify-content: space-between;
    align-items: center;
    img {
      width: 50%;
    }
  }
`;

const Feature = styled.div`
  width: 100%;
  text-align: left;
  margin-top: ${spacing.xs};
  @media ${device.tablet} {
    width: 45%;
  }
`;

const Title = styled(BoldText)`
  padding-left: ${spacing.sm};
  text-transform: capitalize;
`;

const FeatureDescription = styled(Text)`
  margin: 10px 0 15px;
  padding-left: ${spacing.sm};
  line-height: 2.5rem;
`;

const Option = styled.li`
  margin-bottom: ${spacing.xxs};
  font-size: 2.5rem;
  color: ${colors.purple};
`;

const OptionText = styled(Text)`
  color: ${colors.black};
  line-height: 2.5rem;
`;
