import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing } from '../styles/_var'
import Button from './Button'
import Text from './Text'

interface IConfirmDialogBody {
  message: string,
  onClose: () => void,
  confirmAction: () => void,
  denyAction: () => void,
}


const ConfirmDialogBody: FunctionComponent<IConfirmDialogBody> = React.memo(({
  onClose,
  message,
  confirmAction,
  denyAction
}) => {
  const clickConfirm = () => {
    confirmAction()
    onClose()
  }

  const clickDeny = () => {
    denyAction()
    onClose()
  }

  return (
    <Wrapper>
      {!!message && (
        <Text variant="medium" mt={2} id="modal-message">
          {message}
        </Text>
      )}
      <ActionButtonSection>
        <Button onClick={clickConfirm}>Yes</Button>
        <Button variant="secondary" onClick={clickDeny}>
          No
        </Button>
      </ActionButtonSection>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  p {
    align-self: center;
  }

  p:first-child {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }
`

const ActionButtonSection = styled.div`
  margin-top: ${spacing.xxs};
  display: flex;
  justify-content: space-around;
`

export default ConfirmDialogBody
