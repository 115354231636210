import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import competitionStore from '../../../stores/competitionStore'
import CompetitionListContainer from '../../../components/CompetitionList'


const PrivateCompetitions = () => {
  const fetchCompetitions = useCallback((payload) => {
    competitionStore.fetchMyPrivateCompetitions(payload)
  }, [])

  return (
    <CompetitionListContainer 
      paginationInfo={competitionStore.privateCompetitionsPaginationInfo}
      fetchingCompetitions={competitionStore.fetchingMyPrivateCompetitions}
      noCompetitions={competitionStore.noPrivateCompetitions}
      fetchedCompetitions={competitionStore.fetchedMyPrivateCompetitions}
      competitions={competitionStore.privateCompetitions}
      fetchCompetitions={fetchCompetitions}
      emptyResourceText="You haven't been added as a participant to any exclusive competitions"
      emptyResourceActionRoute={null}
    />
  )
}

export default observer(PrivateCompetitions)
