import React, { useState } from 'react'
import SimpleForm from '../../components/SimpleForm'
import Box from '@material-ui/core/Box'
import { colors, device } from '../../styles/_var'
import Input from '../../components/Input'
import { retrieveErrorMessage } from '../../utils/helpers'
import TextArea from '../../components/TextArea'
import { IHelpRequest } from '../../utils/interfaces'
import { sendHelpRequest } from '../../utils/apiRequests'
import uiStore from '../../stores/uiStore'
import validators from '../../utils/validators'
import styled from 'styled-components'


const useContactUsRequest = () => {
  const [loading, setLoading] = useState(false)
  const sendMessage = async (payload: IHelpRequest, onSuccess: any, onFail: any) => {
    try {
      setLoading(true)
      await sendHelpRequest(payload)
      onSuccess()
    } catch (e) { 
      onFail(retrieveErrorMessage(e))
    } finally {
      setLoading(false)
    }
  }
  return { loading, sendMessage }
}

const ContactUsForm = () => {
  const onFailedToSendMessage = (errMessage: string) => uiStore.errorModal(errMessage)
  const onSuccessfullySendMessage = (form: any) => () => {
    uiStore.successModal('Your message has been sent')
    form.restart()
  }
  const { loading, sendMessage } = useContactUsRequest()

  return (
    <ContactFormWrapper
      mt={6}
      mb={4}
      maxWidth={400}
      paddingX={3}
      paddingY={5}
      bgcolor={colors.white}
      boxShadow={`0 0 2px 2px ${colors.lightpurple}`}
    >
      <SimpleForm
        formFields={contactFields}
        submitForm={(data, form) => sendMessage(
          data,
          onSuccessfullySendMessage(form),
          onFailedToSendMessage
        )}
        actionButtons={{
          label: 'Send Message',
          loading,
          invertOnHover: true,
          isSubmit: true
        }} />
    </ContactFormWrapper>
  )
}

export default ContactUsForm

const contactFields = [
  {
    name: 'fullname',
    placeholder: 'Please enter your full name',
    component: Input,
    validate: validators.required
  },
  {
    name: 'email',
    placeholder: 'Please enter your email',
    component: Input,
    validate: validators.isEmail,
  },
  {
    name: 'phone',
    placeholder: 'Please enter your phone number',
    component: Input
  },
  {
    name: 'message',
    placeholder: 'What do you need help with?',
    component: TextArea,
    validate: validators.required
  }
]

const ContactFormWrapper = styled(Box)`
  margin-right: auto;
  margin-left: auto;

  @media ${device.laptop} {
    margin-right: 0;
    margin-left: 0;
  }
`