import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { IModal } from '../stores/uiStore'
import { colors } from '../styles/_var'
import { MessageType } from '../utils/interfaces'
import Text, { BoldText } from './Text'


const ModalBody: FunctionComponent<IModal> = React.memo(({
  title,
  message,
  variant
}) => {
  const getModalIcon = (variant: MessageType) => {
    switch (variant) {
      case 'error':
        return faTimesCircle
      case 'success':
        return faCheckCircle
      default:
        return faExclamationCircle
    }
  }

  return (
    <Wrapper>
      {!!title && <BoldText variant="regular">{title}</BoldText>}
      <FontAwesomeIcon
        icon={getModalIcon(variant)}
        size="5x"
        color={colors.lightpurple} />
      {!!message && <Text variant="medium" mt={2} id="modal-message">{message}</Text>}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  svg {
    align-self: center;
  }

  p {
    align-self: center;
  }

  p:first-child {
    margin-top: -3rem;
    margin-bottom: 1rem;
  }
`

export default ModalBody
