import React from 'react'
import { faTimes, faUniversalAccess, faUserCog, faUserSecret} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../styles/_var'

export const EligibleVotersIcon = React.memo(() => {
  return (
    <FontAwesomeIcon
      color={colors.white}
      icon={faUniversalAccess}
      size="2x" />
  )
})

export const AccountIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faUserCog}
      color={colors.white}
      size="2x"
    />
  )
}

export const PrivateIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faUserSecret}
      color={colors.white}
      size="2x"
    />
  )
}

export const CloseIcon = (props: any) => {
  return (
    <FontAwesomeIcon
      {...props}
      icon={faTimes}
      color={colors.white}
      size="2x"
    />
  )
}