import React from 'react'
import Footer from '../../components/Footer'
import PageContent from '../../components/PageContent'
import usePageTitle from '../../hooks/usePageTitle'
import PricingBanner from './PricingBanner'
import PricingBody from './PricingBody'

const Pricing = () => {
  usePageTitle('Pricing')

  return (
    <>
      <PricingBanner />
      <PageContent>
        <PricingBody />
      </PageContent>
      <Footer />
    </>
  )
}

export default Pricing
