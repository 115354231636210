import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, Switch } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import VoterNavbar from '../components/VoterNavbar'
import uiStore from '../stores/uiStore'
import { getCompetitionMenu, routes } from '../utils/constants'
import { BodySection, DashboardContainer, DashboardWrapper } from './DashboardRoutes'
import CompetitionDashboard from './CompetitionDashboard/CompetitionDashboard'
import useRenderOnAuthenticated from '../hooks/useRenderOnAuthenticated'
import { getCompetitionUsername } from '../utils/helpers'
import competitionStore from '../stores/competitionStore'
import CompetitionPayouts from './CompetitionDashboard/CompetitionPayouts'
import VotingRounds from './CompetitionDashboard/VotingRounds'
import CompetitionSettings from './CompetitionDashboard/CompetitionSettings'
import AllowedVoters from './CompetitionDashboard/AllowedVoters'
import seasonStore from '../stores/seasonStore'
import ErrorPage from './ErrorPages/ErrorPage'
import authStore from '../stores/authStore'
import CompetitionAccount from './CompetitionDashboard/CompetitionAccount'


const CompetitionDashboardRoutes = () => {
  const shouldLoadPage = useRenderOnAuthenticated()
  const competitionName = competitionStore.competitionDetails.name

  useEffect(() => {
    competitionStore.loadDashboardData()
  }, [])

  useEffect(() => {
    uiStore.setNavTitle(competitionName)
  }, [competitionName])

  if (!shouldLoadPage) return null
  if (!competitionStore.competitionDashboardLoaded) return null
  if (competitionStore.dashboardNotPermitted) return <ErrorPage code="403" />

  return (
    <DashboardWrapper>
      <Sidebar
        setSeason={seasonStore.setSelectedSeasonById}
        currentSeason={seasonStore.selectedSeason}
        seasons={seasonStore.seasons}
        menuItems={getCompetitionMenu(competitionStore.competitionDetails)}
        isCompetitionDashboard
        avatar={authStore.userAvatar}
        firstName={authStore.userFirstName} />
      <BodySection>
        <VoterNavbar competitionPlan={competitionStore.subscriptionPlan} />
        <DashboardContainer>
          <Switch>
            <Route exact path='/:competition_username/dashboard'>
              <Redirect to={routes.COMPETITION_DASHBOARD(getCompetitionUsername() || '')} />
            </Route>
            <Route exact path='/:competition_username/dashboard/home' component={CompetitionDashboard} />
            <Route exact path='/:competition_username/dashboard/payouts' component={CompetitionPayouts} />
            <Route exact path='/:competition_username/dashboard/voting_rounds' component={VotingRounds} />
            <Route exact path='/:competition_username/dashboard/settings' component={CompetitionSettings} />
            <Route exact path='/:competition_username/dashboard/eligible_voters' component={AllowedVoters} />
            <Route exact path='/:competition_username/dashboard/account' component={CompetitionAccount} />
          </Switch>
        </DashboardContainer>
      </BodySection>
    </DashboardWrapper>
  )
}

export default observer(CompetitionDashboardRoutes)
