import React, { FunctionComponent, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Text, { BoldText } from "../../components/Text";
import { colors, device } from "../../styles/_var";
import { IFaq } from "../../utils/interfaces";

interface IQuestionAnswer {
  faq: IFaq;
}

const QuestionAnswer: FunctionComponent<IQuestionAnswer> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <QuestionAnswerContainer
      onClick={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
      key={faq.id}
    >
      <div>
        <Question color={colors.darkpurple} variant="medium">
          {faq.question}
        </Question>
        <Button onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? (
            <FontAwesomeIcon icon={faPlus} color={colors.black} />
          ) : (
            <FontAwesomeIcon icon={faMinus} color={colors.black} />
          )}
        </Button>
      </div>
      <Answer variant="regular" isOpen={isOpen}>
        {faq.answer}
      </Answer>
    </QuestionAnswerContainer>
  );
};

export default QuestionAnswer

const QuestionAnswerContainer = styled(({ isOpen, ...rest }) => <div {...rest} />)`
  cursor: pointer;
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-bottom: 1px solid #eee;
  transition: all 0.5s ease-in-out;

  ${({ isOpen }) => isOpen && `
    background: #F9F9F9;
  `}

  @media ${device.mobileVL} {
    padding: 20px 20px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    transition: margin-bottom 0.5s ease-in-out;

    ${({ isOpen }) => isOpen && `
      margin-bottom: 6px;
    `}
  }
`;

const Button = styled.button`
  all: unset;
  transform: scale(1.3);
  cursor: pointer;
`;

const Question = styled(BoldText)`
  padding-right: 30px;
`;

const Answer = styled(({ isOpen, ...rest }) => <Text {...rest} />)`
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0,1,0,1);

  ${({ isOpen }) => isOpen && `
    max-height: 1000px;
    transition: all 0.3s cubic-bezier(1,0,1,0)
  `}
`;
