import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import imageIcon from '../assets/images/pencil-icon.png'


const EditButton: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = React.memo((props) => {
  return (
    <div {...props}>
      <Container>
        <PencilIcon src={imageIcon} width="15px" height="15px" />
      </Container>
    </div>
  )
})

export default EditButton

const Container = styled.div`
  width: 4.4rem;
  height: 4.4rem;
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgba(240, 244, 249, 0.7);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity ease .2s;

  &:hover {
    opacity: 0.4;
  }
`

const PencilIcon = styled.img`
`