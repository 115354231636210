import React from "react";
import styled from "styled-components";
import Text from "../../../components/Text";
import { colors, spacing } from "../../../styles/_var";

const tabs = [
  {
    name: "create competitions",
    id: "create-competitions",
  },
  {
    name: "add staffs",
    id: "staffs",
  },
  {
    name: "private competitions",
    id: "private-competitions",
  },
  {
    name: "voting rounds",
    id: "voting-rounds",
  },
  {
    name: "payouts",
    id: "payouts",
  },
];

const FeatureTabs = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <TabsWrapper {...props} ref={ref}>
      {tabs.map(({ name, id }, index) => (
        <TabLink key={index} href={`#${id}`}>
          <Text variant="regular">{name}</Text>
        </TabLink>
      ))}
    </TabsWrapper>
  );
});

export default FeatureTabs;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${spacing.xs};
  background: ${colors.verylightpurple};
`;

const TabLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${colors.black};
  margin-right: ${spacing.xs};

  &:hover {
    color: ${colors.darkpurple};
  }
`;
