import React, { FunctionComponent, useState } from 'react';
import { Field } from 'react-final-form'
import styled from 'styled-components'
import Text from './Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../styles/_var';


type ComponentType = 'input' | 'select' | 'textarea'

export interface IFormInputProps {
  validate?: (text: string) => any,
  format?: (text: string) => any,
  parse?: (text: any | any[]) => any,
  onBlur?: (payload: any) => void,
  className?: string;
  style?: any;
  max_length?: number,
  formatOnBlur?: boolean,
  error?: string,
  touched?: boolean,
  name: string,
  label: string,
  type?: string
  component: ComponentType,
  prepend?: string,
  placeholder?: string,
  tooltip?: string,
}

const FormInput: FunctionComponent<IFormInputProps> = ({
    validate,
    error,
    touched,
    name,
    label,
    component,
    formatOnBlur,
    max_length,
    parse=undefined,
    format=undefined,
    type="text",
    prepend,
    placeholder,
    onBlur,
    tooltip,
    children,
    className='',
    style={},
    ...rest
}) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const toggleTooltip = (e: React.MouseEvent) => setShowToolTip(!showToolTip);

  const inputProps = {
    type,
    placeholder,
    onBlur,
  }

  const fieldProps: any = {
    name,
    parse,
    format,
    validate,
    component,
    formatOnBlur,
  }

  const hasError = !!error && !!touched
  if (max_length) fieldProps.maxLength = max_length

  return (
    <FormInputWrapper
      className={className}
      style={style}
      isCheckbox={inputProps.type === 'checkbox'}
      hasError={hasError}>
      {!!label && (
        <InputLabel>
          {typeof label === 'string' ? (
            <Textlabel id={`text-label-${inputProps.type}`} variant="small" inline hasError={hasError}>
              {label}
            </Textlabel>
          ) : label}
          {!!tooltip && (
            <FontAwesomeIcon
              icon={faQuestionCircle}
              onMouseEnter={toggleTooltip}
              onMouseLeave={toggleTooltip} />
          )}
          {!!validate && '*'}
        </InputLabel>
      )}
      {typeof fieldProps.component === 'string' ? (
        <Field {...fieldProps} {...inputProps} aria-labelledby={`text-label-${inputProps.type}`}>
          {children}
        </Field>
      ) : (
        <Field {...fieldProps}>
          {props => {
            const Component = fieldProps.component
            return (
              <Component
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                hasError={hasError}
                {...rest}
                {...inputProps} />
            )
          }}
        </Field>
      )}
      {!!prepend && <PrependLabel variant="medium">{prepend}</PrependLabel>}
      {hasError && <ErrorText variant="small" role="alert" aria-label={error}>{error}</ErrorText>}
      {!!tooltip && <Tooltip showToolTip><Text variant="small"><span>Hint:</span> {tooltip}</Text></Tooltip>}
    </FormInputWrapper>
  )
}

export default FormInput;



const FormInputWrapper = styled.div<{
  isCheckbox: boolean,
  hasError: boolean
}>`
  display: block;
  margin-bottom: 3rem;
  position: relative;
  text-align: left;

  ${({ hasError }) => hasError && `
    input {
      border-color: ${colors.red};
    }
  `}

  ${({ isCheckbox }) => isCheckbox && `
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    input {
      margin-right: 1rem;
    }
  `}
`

const Tooltip = styled.div<{showToolTip: boolean}>`
  background-color: lighten($yellow, 40);
  border: solid 1.2px $yellow;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 10px;
  ${({ showToolTip }) => showToolTip ? `
    opacity: 1;
  ` : `
    opacity: 0;
  `}

  & span {
    color: #1c7ff7;
  }
`

const InputLabel = styled.div``

const PrependLabel = styled(Text)`
  position: absolute;
  top: 2.7rem;
  left: 1rem;
`

const ErrorText = styled(Text)`
  color: ${colors.red};
  position: absolute;
  bottom: -1.8rem;
  left: 0.2rem;
`

const Textlabel = styled(({ hasError, ...rest }) => <Text {...rest} />)`
  margin-bottom: 1rem;
  ${({ hasError }) => hasError ? `
    color: ${colors.red};
  ` : `
    color: ${colors.purple};
  `}
`