import React, { useEffect, useState, FunctionComponent } from 'react'
import { IPagination } from '../utils/interfaces'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import CompetitionThumbnail from './CompetitionThumbnail'
import { routes } from '../utils/constants'
import Button from './Button'
import Input from './Input'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import competitionStore from '../stores/competitionStore'
import EmptyResource from './EmptyResource'
import Grid from '@material-ui/core/Grid'
import LoadingComponent from './LoadingComponent'


interface ICompetitionListContainer {
  paginationInfo: IPagination | undefined,
  fetchingCompetitions: boolean,
  noCompetitions: boolean,
  fetchedCompetitions: boolean,
  competitions: any[],
  fetchCompetitions: (payload?: any) => void,
  PrependedComponent?: FunctionComponent,
  linkToCompetitionDashboard?: boolean,
  emptyResourceText?: string,
  emptyResourceActionRoute?: string | null
}

const CompetitionListContainer: FunctionComponent<ICompetitionListContainer> = ({
  paginationInfo,
  fetchingCompetitions,
  noCompetitions,
  fetchedCompetitions,
  competitions,
  fetchCompetitions,
  PrependedComponent=null,
  linkToCompetitionDashboard,
  emptyResourceActionRoute,
  emptyResourceText
}) => {
  const [query, setQuery] = useState('')

  useEffect(() => {
    fetchCompetitions({})
  }, [fetchCompetitions])

  const getCompetitions = () => {
    return competitions.filter(competition => competition.name.toLowerCase().includes(query))
  }

  const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.toLocaleLowerCase())
  }

  const loadNext = async () => {
    fetchCompetitions({ getNext: true })
  }

  return (
    <>
      <Box mb={3}>
        <SearchInput
          icon={faSearch}
          placeholder="search..."
          onChange={onSearchQueryChange}
        />
      </Box>
      {(competitionStore.fetchedMyCompetitions || !noCompetitions) && (
        <>
          <Grid container>
            {!!PrependedComponent && <Grid item lg={3} md={4} sm={4} xs={6}>
              <PrependedComponent />
            </Grid>}
            {getCompetitions().map(competition => (
              <Grid item lg={3} md={4} sm={4} xs={6} key={competition.username}>
                <CompetitionThumbnail
                  small {...competition}
                  showVotes={false}
                  route={linkToCompetitionDashboard ? `/${competition.username}/dashboard` : `/${competition.username}`} />
              </Grid>
            ))}
          </Grid>
          {paginationInfo?.has_next && (
            <Button
              alignCenter variant='secondary'
              loading={fetchingCompetitions}
              onClick={loadNext}
            >
              Load More
            </Button>
          )}
        </>
      )}
      {(fetchingCompetitions && noCompetitions) && (
        <LoadingComponent message="Fetching your competitions" />
      )}
      {(noCompetitions && fetchedCompetitions) && (
        <EmptyResource 
          createResourceButtonText="Create Competition"
          createResourceRoute={emptyResourceActionRoute === null ? undefined : routes.CREATE_COMPETITION}
          createResourceText={emptyResourceText || "You have no competitions, click the button below to get started."} />
      )}
    </>
  )
}


export default CompetitionListContainer

const SearchInput = styled(Input)`
  width: 25rem;
  padding: 1rem;

  svg {
    right: 2rem;
  }
`
