import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TextWithIcon } from '../pages/VoterDashboard/components/VoterDashboardStyled'
import { colors, spacing } from '../styles/_var'
import { ContestantWithBio, IVotingRound } from '../utils/interfaces'
import Button from './Button'
import { Avatar } from './StyledComponents'
import Text, { BoldText } from './Text'


interface IVotingRoundBoard extends IVotingRound {
  getContestant: (username: string) => ContestantWithBio | undefined,
  actionButtonLabel: string,
  actionButtonHandler: () => void,
}

const VotingRoundBoard: FunctionComponent<IVotingRoundBoard> = ({
  label,
  active_contestants=[],
  restartable,
  has_ended,
  getContestant,
  actionButtonLabel,
  actionButtonHandler,
}) => {
  const isExpired = has_ended && !restartable

  return (
    <BoardWrapper role="widget" aria-label={`voting round ${label}`}>
      <TitleSection>
        <BoldText variant="medium">Voting Round - {label}</BoldText>
      </TitleSection>
      {active_contestants.map((activeContestant) => {
        const contestant = getContestant(activeContestant.user)
        if (!contestant) return null;

        return (
          <ContestantRow
            evicted={activeContestant.evicted}
            key={activeContestant.user}>
            <AvatarAndName>
              <Avatar
                src={contestant.avatar}
                width={35}
                height={35}
                borderColor={colors.lightgrey} />
              <Text variant="regular">
                {contestant.first_name} {contestant.last_name}
              </Text>
            </AvatarAndName>
            <BoldText variant="regular" color={colors.purple}>
              {activeContestant.votes} Votes
            </BoldText>
          </ContestantRow>
        )
      })}
      <FooterSection>
        {!isExpired ? (
          <Button onClick={actionButtonHandler}>
            <BoldText variant="regular">{actionButtonLabel}</BoldText>
          </Button>
        ) : (
          <StyledTextWithIcon>
            <FontAwesomeIcon icon={faTimesCircle} size="3x" />
            <Text ml={2} variant="medium">Voting Round Closed</Text>
          </StyledTextWithIcon>
        )}
      </FooterSection>
    </BoardWrapper>
  )
}

export default VotingRoundBoard

const BoardWrapper = styled.div`
  width: 30%;
  max-width: 50rem;
  min-width: 35rem;
  background-color: ${colors.verylightpurple};

  border: solid 1px ${colors.lightgrey};
  border-radius: 5px;
`

const TitleSection = styled.div`
  padding: ${spacing.xs};
  border-bottom: solid 1px ${colors.lightgrey};
`

const ContestantRow = styled(({ evicted, ...rest}) => <div {...rest} />)`
  padding: ${spacing.xs};
  border-bottom: solid 1px ${colors.lightgrey};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ evicted }) => evicted && `
    filter: grayscale(100%);
  `}
`

const StyledTextWithIcon = styled(TextWithIcon)`
  color: ${colors.red};
  opacity: .9;
`

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: ${spacing.xs};
  }
`

const FooterSection = styled.div`
  padding: ${spacing.xs};
  text-align: center;

  button {
    min-width: 20rem;
    padding-top: 3px;
    padding-bottom: 3px;
  }
`