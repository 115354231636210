import { faCheckCircle, faSpinner, faTimesCircle, faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent, useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import styled from 'styled-components'
import PageContent from '../components/PageContent'
import Box from '@material-ui/core/Box'
import { colors, spacing } from '../styles/_var'
import Text from '../components/Text'
import { observer } from 'mobx-react-lite'
import authStore from '../stores/authStore'
import { Status } from '../utils/interfaces'
import { status } from '../utils/constants'
import { TextWithIconHorizontal } from '../components/StyledComponents'
import uiStore from '../stores/uiStore'


interface IAccountVerification extends RouteChildrenProps<{competition_id: string}> {
  className?: string
}

const AccountVerification: FunctionComponent<IAccountVerification> = ({ match }) => {
  const activationCode = (match?.params as any)?.activation_code
  const sectionMessages: any = {
    loading: authStore.activateUserStatusMessage,
    complete: authStore.activateUserStatusMessage,
    failed: authStore.activateUserStatusErrorMessage
  }
  const sectionIcons: any = {
    loading: faSpinner,
    complete: faCheckCircle,
    failed: faTimesCircle
  }

  useEffect(() => {
    authStore.activateAccount(activationCode)
  }, [activationCode])

  useEffect(() => {
    if (authStore.resentActivationCode) {
      uiStore.showToastMessage({ message: 'We have sent you an activation link' })
    }
  })

  const resendActivationCode = () => {
    authStore.resendUserActivationCode(activationCode)
  }

  const renderStateView = (state: Status) => {
    if (!state) return null
    const sectionState = state.toLowerCase()
    const sectionMessage = sectionMessages[sectionState]
    const sectionIcon = sectionIcons[sectionState]
    const isLoading = state === status.LOADING

    return (
      <>
        <FontAwesomeIcon icon={sectionIcon} spin={isLoading} color={colors.purple} size="4x" />
        <Text variant="medium" color={colors.purple}>{sectionMessage}</Text>
      </>
    )
  }

  const renderResendActivationLink = () => {
    if (!authStore.canResendActivationCode) return null

    return (
      <TextWithIconHorizontal>
        <FontAwesomeIcon
          icon={faRedo} 
          color={colors.purple}
          spin={authStore.resendingActivationCode}
        />
        <ResetLink
          variant="subtext"
          center
          color={colors.purple}
          onClick={resendActivationCode}
        >
          {!authStore.resendingActivationCode && 'Resend activation link'}
          {authStore.resendingActivationCode && 'Resending activation link...'}
        </ResetLink>
      </TextWithIconHorizontal>
    )
  }


  return (
    <Wrapper>
      <ActivationSection
        maxWidth={400}
        padding={4}
        marginTop={17}
        textAlign="center"
        state={authStore.activateUserStatus}
      >
        {renderStateView(authStore.activateUserStatus)}
      </ActivationSection>
      {renderResendActivationLink()}
    </Wrapper>
  )
}

export default observer(AccountVerification)

const Wrapper = styled(PageContent)`
  margin-top: 8rem;
  display: block;
  position: relative;
`

const ResetLink = styled(Text)`
  transition: all ease-in-out .2s;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

const ActivationSection = styled(({ state, ...rest}) => <Box {...rest} />)`
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-bottom: ${spacing.xxs};
  box-shadow: ${colors.lightgrey} 0 0 4px 3px;
  background-color: ${colors.white};

  ${({ state }) => state === status.FAILED && `
    box-shadow: ${colors.red} 0 0 5px 1px;
    p {
      color: ${colors.red} !important;
    }
    svg {
      color: ${colors.red} !important;
    }
  `}

${({ state }) => state === status.COMPLETE && `
    box-shadow: ${colors.green} 0 0 5px 1px;
    p {
      color: ${colors.green} !important;
    }
    svg {
      color: ${colors.green} !important;
    }
  `}
`