import React from 'react'
import Grid from '@material-ui/core/Grid'
import { BoldText } from '../../components/Text'
import Box from '@material-ui/core/Box'
import PricingPlan, { allPricingPlans } from './PricingPlan'

const PricingBody = () => {
  return (
    <Box mb={8}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BoldText variant="large" center mt={3} mb={3}>
            Subscription Plans
          </BoldText>
        </Grid>
        {allPricingPlans.map((pricingPlan, i) => (
          <PricingPlan {...pricingPlan} key={i} />
        ))}
      </Grid>
    </Box>
  )
}

export default PricingBody
