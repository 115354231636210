import React from 'react'
import styled from 'styled-components'
import PageContent from '../../components/PageContent'
import Grid from '@material-ui/core/Grid'
import ContactUsPageHeader from './ContactUsPageHeader'
import ContactUsForm from './ContactUsForm'
import { spacing } from '../../styles/_var'
import ContactUsInstructions from './ContactUsInstructions'
import Footer from '../../components/Footer'
import usePageTitle from '../../hooks/usePageTitle'


const ContactUs = () => {
  usePageTitle('Contact us')

  return (
    <>
      <ContactUsWrapper>
        <ContactUsPageHeader />
        <Grid container>
          <Grid item xs={12} md={6}>
            <ContactUsForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactUsInstructions />
          </Grid>
        </Grid>
      </ContactUsWrapper>
      <Footer />
    </>
  )
}

export default ContactUs

const ContactUsWrapper = styled(PageContent)`
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding-top: ${spacing.rg};
  margin-bottom: ${spacing.lg};
`
