import React, { FunctionComponent, useEffect, useState } from 'react';
import Text from './Text';
import { observer } from 'mobx-react-lite';
import uiStore, { TOAST_DEFAULTS } from '../stores/uiStore';
import styled from 'styled-components';
import { colors, spacing } from '../styles/_var';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

let interval1: any;
let interval2: any;
let interval3: any;

const Toast: FunctionComponent = () => {
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    interval1 = setTimeout(() => { setShowToast(true) }, 100);
    interval2 = setTimeout(() => { setShowToast(false) }, uiStore.toastDuration);
    interval3 = setTimeout(() => { uiStore.showToastMessage(TOAST_DEFAULTS) }, (uiStore.toastDuration || 0) + 400);

    return () => {
      clearTimeout(interval1)
      clearTimeout(interval2)
      clearTimeout(interval3)
    }
  }, [setShowToast])

  let icon = faCheckCircle

  switch (uiStore.toastType) {
    case 'success':
      icon = faCheckCircle
      break
    case 'error':
      icon = faTimesCircle
      break
    default:
      icon = faInfoCircle
  }

  return (
    <ToastWrapper show={showToast}>
      <ToastOverlay type={uiStore.toastType} />
      <ToastMessage variant="regular" color="white">
        <FontAwesomeIcon icon={icon} size="1x" />
        {uiStore.toastMessage}
      </ToastMessage>
    </ToastWrapper>
  )
}

export default observer(Toast)

const ToastWrapper = styled(({ show, ...rest }) => <div {...rest} />)`
  position: fixed;
  z-index: 4;
  top: 8rem;
  text-align: center;
  max-width: 30rem;
  border-radius: 0.5rem;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all ease-in-out .3s;

  ${({ show }) => show && `
    opacity: 1
  `}
`

const ToastOverlay = styled(({ type, ...rest }) => <div {...rest} />)`
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  border-radius: 0.5rem;
  opacity: 1;
  ${({ type }) => type === 'warning' && `
    background-color: ${colors.darkyellow};
  `}
  ${({ type }) => type === 'success' && `
    background-color: ${colors.green};
  `}
  ${({ type }) => type === 'error' && `
    background-color: ${colors.red};
  `}
  ${({ type }) => type === 'info' && `
    background-color: ${colors.purple};
  `}
`

const ToastMessage = styled(Text)`
  display: inline-block;
  padding: 0.9rem 1.2rem;
  position: relative;
  z-index: 5;
  font-weight: 600;

  svg {
    margin-right: ${spacing.xxs};
  }
`