import { encrypt, decrypt } from './helpers';

class Auth {
  static isLoggedIn() {
    return !!localStorage.getItem('user_token');
  }

  static saveUserDetails(userPayload: any) {
    const { token, user, competition } = userPayload;

    localStorage.setItem('user_data', JSON.stringify(user));
    localStorage.setItem('user_token', encrypt(token));

    if (competition) {
      localStorage.setItem('competition', JSON.stringify(competition))
    }
  }

  static fetchToken() {
    var token = localStorage.getItem('user_token');
    return token ? decrypt(token) : null;
  }

  static fetchUserDetails() {
    try {
      return JSON.parse(localStorage.getItem('user_data') || '{}');
    } catch {
      return {}
    }
  }

  static updateUserDetails(userData: any) {
    localStorage.setItem('user_data', JSON.stringify(userData))
  }

  static logUserOut() {
    localStorage.clear();
  }
}

export default Auth
