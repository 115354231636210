import PopularCompetitions from './PopularCompetitions'
import Advertisements from './Advertisements'
import CompetitionUpdates from './CompetitionUpdates'
import SimilarCompetitions from './SimilarCompetitions'
import CompetitionNotes from './CompetitionNotes'


export const ADVERTISEMENTS = 'advertisements'
export const POPULAR = 'popular'
export const UPDATES = 'updates'
export const SIMILAR = 'similar'
export const NOTES = 'notes'


export const AsideComponentsMapper = {
  [ADVERTISEMENTS]: Advertisements,
  [POPULAR]: PopularCompetitions,
  [UPDATES]: CompetitionUpdates,
  [SIMILAR]: SimilarCompetitions,
  [NOTES]: CompetitionNotes
}
