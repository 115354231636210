import React, { FunctionComponent } from 'react'
import Text, { BoldText } from '../../components/Text'
import { IVotingRoundOptions } from '../../utils/interfaces'
import Grid from '@material-ui/core/Grid'
import { Option } from '../CompetitionPage/VotingRoundOptions'

interface ICompetitionOptions {
  options?: IVotingRoundOptions,
}


const CompetitionOptions: FunctionComponent<ICompetitionOptions> = ({ options }) => {
  if (!options) return null
  const {
    vote_cost,
    max_no_of_votes,
    max_rate,
    show_live_updates,
    show_vote_count
  } = options

  return (
    <Grid container>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">N{vote_cost}/vote</Text>
          <BoldText variant="regular">Vote Cost</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{max_no_of_votes} votes/{max_rate ? 'voting round' : 'day'}</Text>
          <BoldText variant="regular">Maximum Allowed Votes</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{show_live_updates ? 'YES' : 'NO'}</Text>
          <BoldText variant="regular">Show Live Updates</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{show_vote_count ? 'YES' : 'NO'}</Text>
          <BoldText variant="regular">Show Vote Count</BoldText>
        </Option>
      </Grid>
    </Grid>
  )
}

export default CompetitionOptions
