import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, device, spacing } from '../styles/_var'
import Link from './Link'
import PageContent from './PageContent'
import Text, { BoldText } from './Text'
import { routes } from '../utils/constants'

// Pricing
// Partners
// API

const Footer: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = React.memo(props => {
  return (
    <SectionWrapper {...props}>
      <SectionOne>
        <Text variant="subtext">
          Matuskii is a flexible contest and promotions tool headquartered in Lagos, Nigeria.<br /><br />
          Copyright 2021. Matuskii Software Limited.<br />All Rights Reserved. 
        </Text>
      </SectionOne>
      <SectionTwo>
        <BoldText variant="subtext">Products</BoldText>
        <Text variant="subtext"><Link to={routes.FEATURES}>Features</Link></Text>
        <Text variant="subtext"><Link to={routes.PRICING}>Pricing</Link></Text>
        {/* <Text variant="subtext"><Link to={routes.COMING_SOON}>Partners</Link></Text>
        <Text variant="subtext"><Link to={routes.COMING_SOON}>API</Link></Text> */}
      </SectionTwo>
      <SectionTwo>
        <BoldText variant="subtext">Resources</BoldText>
        <Text variant="subtext"><Link to={routes.FAQS}>FAQ</Link></Text>
        <Text variant="subtext"><Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link></Text>
        {/* <Text variant="subtext"> <Link to={routes.COMING_SOON}>Terms &amp; Conditions</Link></Text> */}
        <Text variant="subtext"><Link to={routes.DEMOS}>Demos</Link></Text>
      </SectionTwo>
      <SectionTwo>
        <BoldText variant="subtext">We Are Available:</BoldText>
        <Text variant="subtext">
          Monday to Saturday<br />8:00 AM - 8:00 PM
        </Text>
        <Text variant="subtext">
          +234 805 654 7516<br />
          support@matuskii.com
        </Text>
      </SectionTwo>
    </SectionWrapper>
  )
})

export default Footer


const SectionWrapper = styled(PageContent)`
  background-color: ${colors.white};
  position: relative;
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  box-shadow: 0 -1px 2px 1px #eee;

  @media ${device.mobileL} {
    padding-top: ${spacing.rg};
    padding-bottom: ${spacing.rg};

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    padding-top: ${spacing.xl};
    padding-bottom: ${spacing.xl};
  }
`

const SectionOne = styled.section`
  box-sizing: border-box;
  margin: 0 ${spacing.sm} ${spacing.sm};
  padding-bottom: ${spacing.sm};
  border-bottom: solid 1px ${colors.lightgrey};

  @media ${device.mobileL} {
    border-bottom: none;
    width: 20rem;

    &:first-child {
      max-width: 30rem;
    }
  }

  @media ${device.laptop} {
    border-bottom: none;
    margin: 0;
  }
`

const SectionTwo = styled(SectionOne)`
  & p {
    margin-bottom: ${spacing.xs};
  }
  & p:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }
  & a{
    text-decoration: none;
    color: ${colors.grey}
  }
`