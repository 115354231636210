import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Input from '../../../components/Input'
import SimpleForm from '../../../components/SimpleForm'
import { InputStyle } from '../../../components/StyledComponents'
import Text, { BoldText } from '../../../components/Text'
import { colors, spacing } from '../../../styles/_var'
import { required } from '../../../utils/helpers'
import { IPayoutOptions } from '../../../utils/interfaces'

interface IPayoutOptionsProps {
  initialValues: IPayoutOptions | {},
  submit: (payload: IPayoutOptions, form?: any) => void,
  saving: boolean,
}

const PayoutOptions: FunctionComponent<IPayoutOptionsProps> = ({
  initialValues,
  submit,
  saving,
}) => {
  const submitForm = (data: IPayoutOptions) => {
    submit(data)
  }

  return (
    <Wrapper>
      <StyledForm
        initialValues={initialValues}
        style={{minWidth: '50%'}}
        formFields={payoutFormFields}
        role="form"
        aria-label="payout options form"
        actionButtons={{
          label: 'Add Bank Account',
          isSubmit: true,
          loading: saving 
        }}
        submitForm={submitForm} />
      <Note>
        <BoldText variant="regular">Note</BoldText>
        <Text variant="regular">
          We collect your BVN for verification, please ensure that you fill in a bank account that belongs to you and no one else. Your BVN details and your bank account details must match.
        </Text>
      </Note>
    </Wrapper>
  )
}

export default PayoutOptions

export const payoutFormFields = [
  {
    name: 'bvn',
    placeholder: 'Bank Verification Number',
    component: Input,
    validate: required,
  },
  {
    name: 'bank_name',
    placeholder: 'Bank Name',
    component: Input,
    validate: required,
  },
  {
    name: 'account_name',
    placeholder: 'Account Name',
    component: Input,
    validate: required,
  },
  {
    name: 'account_number',
    placeholder: 'Account Number',
    component: Input,
    validate: required,
  },
  {
    name: 'account_type',
    placeholder: 'Account Type',
    component: 'select',
    validate: required,
    children: (
      <>
        <option value="">-- Select Account Type --</option>
        <option value="0">Savings</option>
        <option value="1">Current</option>
        <option value="2">Fixed</option>
        <option value="3">Other</option>
      </>
    )
  },
]

const Note = styled.div`
  background-color: ${colors.subtlylightpurple};
  padding: ${spacing.sm};
  max-height: 20rem;
  margin-left: ${spacing.sm};
  max-width: 30rem;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledForm = styled(SimpleForm)`
  select {
    ${InputStyle} 
  }
`