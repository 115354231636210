import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import { colors, device, spacing } from '../styles/_var'
import { ContestantVariant, ContestantWithBio } from '../utils/interfaces'
import Button from './Button'
import Text, { BoldText } from './Text'
import medal from '../assets/images/medal.png'
import placeholderImage from '../assets/images/placeholder-person.jpg'
import { VerySmallSpace } from './StyledComponents'
import { observer } from 'mobx-react-lite'
import authStore from '../stores/authStore'



interface IContestantThumbnailProps extends ContestantWithBio {
  variant?: ContestantVariant;
  winner?: boolean;
  votePercentage?: string;
  style?: any;
  className?: string;
  votes?: number;
  onDelete?: () => void;
  onUpdate?: () => void;
  onEvict?: () => void;
  onVote?: () => void;
  hideVoteButton?: boolean;
  contestantLabel?: string;
  showVoteCount?: boolean;
}

const ContestantThumbnail: FunctionComponent<IContestantThumbnailProps> = ({
  first_name,
  last_name,
  avatar,
  votePercentage,
  winner,
  variant,
  votes,
  evicted,
  style={},
  username,
  className='',
  onUpdate,
  onDelete,
  onEvict,
  onVote,
  hideVoteButton,
  showVoteCount,
  contestantLabel='Contestant'
}) => {
  const showResultVariation = variant === 'results'
  const inEditMode = variant === 'edit'
  const isNewlyAddedContestant = variant === 'new'
  const defaultAction = variant === 'default'
  const showUsernameVariation = variant === 'username'

  return (
    <ThumbnailWrapper 
      style={style} 
      className={className}
      role="widget"
      aria-label={`${username} ${contestantLabel} thumbnail`}>
      {winner && <Medal src={medal} width="100px" height="100px" />}
      <ContestantWrapper>
        <ContestantImage src={avatar || placeholderImage} />
        {evicted && <>
          <Overlay />
          <Text variant="medium">Evicted</Text>
        </>}
      </ContestantWrapper>
      <ThumbnailBody>
        <BodyHeader>
          <BoldText variant="regular">{first_name + " " + last_name}</BoldText>
          {(showResultVariation && showVoteCount) && <NoOfVotesText variant="regular">{votes || 0} Votes</NoOfVotesText>}
          {showUsernameVariation && <Text variant="subtext">{username}</Text>}
        </BodyHeader>
        {(defaultAction && onVote && !hideVoteButton) && (
          <Button 
            aria-label="vote now"
            block 
            disabled={evicted || !authStore.userIsLoggedIn} 
            onClick={onVote}>
            Vote Now
          </Button>
        )}
        {inEditMode && (
          <>
            <Button onClick={onUpdate} block>
              Edit {contestantLabel}
            </Button>
            <VerySmallSpace />
            <Button
              block
              variant="secondary"
              onClick={onEvict}>
              {evicted ? `Un-evict ${contestantLabel}` : `Evict ${contestantLabel}`}
            </Button>
            <VerySmallSpace />
            <Button block variant="danger" onClick={onDelete}>Delete</Button>
          </>
        )}
        {showResultVariation && (
          <VoteTotalSection>
            <VotePercentage variant="determinate" value={votePercentage ? +votePercentage.replace('%', '') : 0} />
          </VoteTotalSection>
        )}
        {winner && <WinnerLabel variant="medium">Winner</WinnerLabel>}
        {isNewlyAddedContestant && (
          <>
            <Button block onClick={onUpdate}>
              Edit
            </Button>
            <VerySmallSpace />
            <Button block variant="danger" onClick={onDelete}>Delete</Button>
          </>
        )}
      </ThumbnailBody>
    </ThumbnailWrapper>
  )
}

export default observer(ContestantThumbnail)

const ThumbnailWrapper = styled.div`
  border-radius: 1rem;
  border: solid 1px ${colors.lightgrey};
  box-sizing: border-box;
  position: relative;
`

const ContestantImage = styled(({ src, ...rest}) => <div {...rest} />)`
  width: 100%;
  height: 100%;
  ${({ src }) => src && `
    background-image: url(${src});
  `}
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  box-sizing: border-box;
`

const ContestantWrapper = styled.div`
  width: 100%;
  height: 16rem;
  position: relative;

  p {
    position: absolute;
    color: ${colors.white};
    z-index: 2;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  @media ${device.tablet} {
    height: 20rem;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 47, 47, 0.72);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`

const ThumbnailBody = styled.div`
  padding: ${spacing.xxs};

  @media ${device.tablet} {
    padding: ${spacing.xs};
  }
`

const NoOfVotesText = styled(BoldText)`
  color: ${colors.purple};
`

const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${spacing.xs};
  }
`

const Medal = styled.img`
  position: absolute;
  right: ${spacing.xs};
  top: 0;
  width: 6rem;
  height: 6rem;
  z-index: 2;
`

const VoteTotalSection = styled.div``

const VotePercentage = styled(({ percent, isWinner, ...rest }) => <LinearProgress {...rest} />)`
  height: 0.4rem;
  border-radius: 10rem;

  ${({ percent, isWinner }) => {
    return `
      & > .MuiLinearProgress-barColorPrimary {
        background-color: ${isWinner ? colors.purple : colors.lightpurple};
      }
    `
  }}
`

const WinnerLabel = styled(BoldText)`
  color: ${colors.purple};
`
