import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Text from '../../components/Text'
import creditStore from '../../stores/creditStore'
import { beautifyDate, getCompetitionUsername, getPercent, toCurrency } from '../../utils/helpers'
import { Table, TableWrapper, TransactionSection } from '../VoterDashboard/components/VoterDashboardStyled'
import PayoutSummary from './components/PayoutSummary'
import { CompetitionPageWrapper } from './StyledComponents'
import uiStore from '../../stores/uiStore'
import seasonStore from '../../stores/seasonStore'
import Button from '../../components/Button'
import usePageTitle from '../../hooks/usePageTitle'


const CompetitionPayouts: FunctionComponent = () => {
  const competitionUsername = getCompetitionUsername()
  usePageTitle('Payouts')

  useEffect(() => {
    const seasonID = seasonStore.selectedSeasonId

    if (competitionUsername) {
      if (!creditStore.payoutSummaryLoaded) {
        uiStore.setLoadingScreenMessage('Loading payouts.')
      }

      Promise.allSettled([
        creditStore.getPayoutHistory(competitionUsername, seasonID, { page: 1 }),
        creditStore.getPayoutSummary(competitionUsername, seasonID)
      ]).then(() => {
        uiStore.setLoadingScreenMessage('')
      })
    }

  }, [competitionUsername])

  const loadMorePayoutHistory = () => {
    if (competitionUsername) {
      const seasonID = seasonStore.selectedSeasonId
      creditStore.getPayoutHistory(competitionUsername, seasonID, { getNext: true })
    }
  }

  return (
    <CompetitionPageWrapper>
      <Text
        variant="large"
        mb={2}
        role="heading"
      >
        PAYOUTS
      </Text>
      <StyledPayoutSummary {...creditStore.payoutSummary} />
      {creditStore.hasPayoutHistory && <TransactionSection>
        <Text variant="medium">Payout History</Text>
        <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <td><Text variant="regular">Amount</Text></td>
                  <td><Text variant="regular">Service Fee (20%)</Text></td>
                  <td><Text variant="regular">Actual Payout</Text></td>
                  <td><Text variant="regular">Date</Text></td>
                </tr>
              </thead>
              <tbody>
                {creditStore.allPayoutHistory.map((history, index) => (
                  <tr key={index}>
                    <td><Text variant="subtext">{toCurrency(history.amount, false)}</Text></td>
                    <td>
                      <Text variant="subtext">
                        {toCurrency(getPercent(history.amount, 20), false)}
                      </Text>
                    </td>
                    <td>
                      <Text variant="subtext">
                        {toCurrency(history.amount - getPercent(history.amount, 20), false)}
                      </Text>
                    </td>
                    <td><Text variant="subtext">{beautifyDate(history.created_at)}</Text></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          {creditStore.hasMorePayoutHistory && (
            <Button
              loading={creditStore.fetchingPayoutHistory}
              onClick={loadMorePayoutHistory}
              variant='secondary'
              mt={2}
              alignCenter
            >
              Load More
            </Button>
          )}
      </TransactionSection>}
    </CompetitionPageWrapper>
  )
}


export default observer(CompetitionPayouts)


const StyledPayoutSummary = styled(PayoutSummary)`
  margin: 0;
  > div {
    margin: 0;
  }
`