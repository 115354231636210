import styled from 'styled-components'
import { colors, device, spacing } from '../../../styles/_var'

export const ContentBody = styled.div`
width: 70rem;
margin: auto;
max-width: 100%;
margin-bottom: ${spacing.sm};

button {
  width: 30rem;
  max-width: 100%;
}
`

export const NotificationBody = styled.div`
padding: ${spacing.xs};
background-color: ${colors.subtlylightpurple};
box-sizing: border-box;
display: flex;
justify-content: space-between;
`

export const FormContainer = styled.div`
background: rgba(0, 0, 0, 0.02);
border: 1px solid ${colors.lightgrey};
border-radius: 3px;
padding: ${spacing.sm};
margin-top: ${spacing.xs};
display: flex;
flex-direction: column;
justify-content: space-between;

@media ${device.tablet} {
  flex-direction: row;
}

> div:first-child {
  flex: 1;
}
`

export const NotificationsContainer = styled(FormContainer)``