import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { closeSeasonRequest, createSeasonRequest, getSeasonTransactionRequest } from '../utils/apiRequests'
import { status } from '../utils/constants'
import { getCompetitionUsername, processError, retrieveErrorMessage } from '../utils/helpers'
import { ISeason, Status } from '../utils/interfaces'
import competitionStore from './competitionStore'
import contestantStore from './contestantStore'
import creditStore from './creditStore'
import eligibleVoterStore from './eligibleVoterStore'
import uiStore from './uiStore'
import votingRoundStore from './votingRoundStore'

class SeasonStore {
  closeSeasonStatus: Status = null
  seasons: ISeason[] = []
  selectedSeason: ISeason = {} as ISeason
  createSeasonStatus: Status = null
  transactions: any[] = []
  seasonTransactionStatus: Status = null
  showSeasonTypeSelector: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  async createSeason(competitionUsername: string) {
    if (this.creatingSeason) return

    if (!this.latestSeason.concluded) {
      uiStore.errorModal('The previous season has not ended yet!')
      return
    }

    runInAction(() => {
      this.createSeasonStatus = status.LOADING
    })

    uiStore.setLoadingScreenMessage('Creating new season.')

    try {
      await createSeasonRequest(competitionUsername)
      runInAction(() => {
        this.createSeasonStatus = status.COMPLETE
      })

      uiStore.setLoadingScreenMessage('Creating new season.')
      window.location.reload()
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      uiStore.setLoadingScreenMessage('')
    }
  }

  async closeSeason(seasonId: string) {
    if (votingRoundStore.hasOpenVotingRound) {
      uiStore.errorModal('You cannot end a season while there is still an active voting round.')
      return
    }

    if (this.closingSeason) return
    uiStore.setLoadingScreenMessage('Ending Season')

    runInAction(() => {
      this.closeSeasonStatus = status.LOADING
    })

    try {
      await closeSeasonRequest(seasonId)
      runInAction(() => {
        this.closeSeasonStatus = status.COMPLETE
        this.markSeasonAsClosed(seasonId)
      })
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => this.closeSeasonStatus = status.FAILED)
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchSeasonTransactions(seasonId: string) {
    if (this.fetchingSeasonTransactions) return
    runInAction(() => this.seasonTransactionStatus = status.LOADING)

    try {
      const response = await getSeasonTransactionRequest(seasonId)
      runInAction(() => {
        this.seasonTransactionStatus = status.COMPLETE
        this.transactions = response.data.message
      })
    } catch (e) {
      runInAction(() => this.seasonTransactionStatus = status.FAILED)
      console.log(retrieveErrorMessage(e))
    }
  }

  toggleSeasonTypeSelector() {
    this.showSeasonTypeSelector = !this.showSeasonTypeSelector
  }

  markSeasonAsClosed(seasonId: string) {
    const seasons = [...this.seasons]
    const selectedSeason = {...this.selectedSeason}
    const seasonIndex = seasons?.findIndex(season => season.uuid === seasonId);

    if (this.seasons && seasonIndex !== undefined && seasonIndex > -1) {
      seasons[seasonIndex].concluded = true
      selectedSeason.concluded = true
    }

    this.setSeasons(seasons);
    this.setSelectedSeason(selectedSeason);
  }

  setSeasons(seasons?: ISeason[]) {
    if (!seasons) return
    this.seasons = seasons
  }

  setSelectedSeason(season?: ISeason) {
    if (!season) return
    this.selectedSeason = season
  }

  setSelectedSeasonById(seasonId: string) {
    const season = this.seasons.find(e => e.uuid === seasonId)
    this.setSelectedSeason(season)
  }

  get closingSeason() {
    return this.closeSeasonStatus === status.LOADING
  }

  get creatingSeason() {
    return this.createSeasonStatus === status.LOADING
  }

  get latestSeason() {
    return this.seasons[this.seasons.length - 1];
  }

  get latestSeasonId() {
    return this.latestSeason.uuid
  }

  get selectedSeasonId() {
    return this.selectedSeason.uuid
  }

  get fetchingSeasonTransactions() {
    return this.seasonTransactionStatus === status.LOADING
  }

  get hasTransactions() {
    return !!this.transactions.length
  }
}

const seasonStore = new SeasonStore()
export default seasonStore

reaction(
  () => seasonStore.selectedSeason.uuid,
  (seasonId) => {
    if (!seasonId) return

    const username = getCompetitionUsername() || competitionStore.competitionPageUsername
    votingRoundStore.fetchVotingRounds(seasonId)
    !!username && contestantStore.fetchContestants(username, seasonId)

    // only run this side effect if we are on the competition dashboard page
    if (!window?.location?.pathname?.includes('dashboard')) return

    if (username) {
      creditStore.getPayoutHistory(username, seasonId, { page: 1 })
      creditStore.getPayoutSummary(username, seasonId)
    }

    if (!competitionStore.isCompetitionPublic) {
      eligibleVoterStore.fetchEligibleVoters(seasonId, 1)
      eligibleVoterStore.fetchPendingInvitations(seasonId, 1)
    }
  }
)
