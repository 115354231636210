import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'


const LoadingScreen: React.FC<{message?: string}> = React.memo(({ message }) => {
  return (
    <Container>
      <Content>
        <FontAwesomeIcon icon={faSpinner} spin color={colors.verylightpurple} size="4x" />
        <Text variant="medium">{message}</Text>
      </Content>
    </Container>
  )
})

export default LoadingScreen

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 4;
`

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.sm};
  position: relative;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);

  p {
    color: ${colors.verylightpurple};
  }
`