import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, device, spacing } from '../styles/_var'
import Text from './Text'
import CameraIcon from '../assets/images/grey-camera-icon.png'
import { ReactComponent as Upload } from '../assets/images/upload.svg' 
import ImageUpload from './ImageUpload'


export interface IUploadPane extends React.HTMLAttributes<HTMLDivElement> {
  titleText?: string;
  dimensionsText?: string;
  uploadImageText?: string;
  aspectRatio?: number,
  initialImage?: string,
  onImageChange: (image: string) => void,
  className?: string,
  maxWidth?: number,
}


const UploadPane: FunctionComponent<IUploadPane> = React.memo(({
  titleText,
  dimensionsText,
  uploadImageText,
  className='',
  onImageChange,
  initialImage,
  maxWidth,
  aspectRatio=1/2,
  ...rest
}) => {

  return (
    <ImageUpload
      maxWidth={maxWidth || 1200}
      aspectRatio={aspectRatio}
      onImageChange={onImageChange}
      contentArea={({ croppedImage, openFileChooser }) => (
        <UploadSection image={croppedImage || initialImage } {...rest} className={className}>
          <PhotoContainer>
            <img src={CameraIcon} width="37px" height="32px" alt='camera-icon' />
          </PhotoContainer>
          <BodySection>
            <Text variant="regular">{titleText || 'Upload your image'}</Text>
            <Text variant="small">{dimensionsText || 'The best dimensions for a banner image is 1029px by 600px'}</Text>
            <UploadLink variant="small" onClick={openFileChooser}>
              <Upload /> {uploadImageText || 'Upload Image'}
            </UploadLink>
          </BodySection>
        </UploadSection>
      )}/>
  )
})


export default UploadPane

const UploadSection = styled(({ image, ...rest }) => <div {...rest} />)`
  box-sizing: border-box;
  padding: ${spacing.sm};
  min-height: 20rem;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;

    & > div:first-child {
      margin-right: ${spacing.xs};
    }
  }

  border-radius: 1rem;
  border: solid 1px ${colors.lightgrey};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ image }) => image && `
    background-image: url(${image});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  `}
`

const BodySection = styled.div`
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;

  p:nth-child(2) {
    width: 20rem;
    text-align: center;
  }
`

const PhotoContainer = styled.div`
  display: inline-block;
  padding: ${spacing.sm};
  border-radius: 50%;
  background-color: rgba(150, 150, 150, 0.5);
  margin-bottom: ${spacing.xxs};
`

const UploadLink = styled(Text)`
  color: ${colors.blue};
  cursor: pointer;
  transition: opacity ease .2s;
  display: inline-block;

  &:hover {
    opacity: .8;
  }

  svg {
    margin-right: 2px;
    top: 4px;
    position: relative;
  }
`
