import React, { FunctionComponent } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import Link from '../components/Link'
import SimpleForm from '../components/SimpleForm'
import { AuthenticationView, ErrorMessage, ComponentView } from '../components/StyledComponents'
import Text, { BoldText } from '../components/Text'
import { colors, spacing } from '../styles/_var'
import { loginFields, routes } from '../utils/constants'
import authStore from '../stores/authStore'
import { IUserCredentials } from '../utils/interfaces'
import { parseUsername } from '../utils/helpers'
import useRenderOnNotAuthenticated from '../hooks/useRenderOnNotAuthenticated'
import usePageTitle from '../hooks/usePageTitle'


interface ILoginProps extends RouteChildrenProps {
  className?: string
}

const Login: FunctionComponent<ILoginProps> = ({ className, history }) => {
  usePageTitle('Log In')

  const shouldLoadPage = useRenderOnNotAuthenticated()
  if (!shouldLoadPage) return null

  const logUserIn = async (data: IUserCredentials) => {
    await authStore.loginUser({
      ...data,
      username: parseUsername(data.username)
    })
  }

  const handleLogin = (data: CredentialResponse) => {
    const { credential } = data

    if (credential) {
      authStore.googleAuth(credential)
    }
  }

  const handleError = () => {}

  return (
    <AuthenticationView>
      <ComponentView className={className}>
        <TitleText variant="medium" role="heading">Log In</TitleText>
        <SimpleForm 
          formFields={loginFields}
          submitForm={logUserIn}
          actionButtons={{
            label: 'Login Now',
            loading: authStore.loginUserLoading,
            invertOnHover: true,
            isSubmit: true
          }}
        />
        <LoginErrorMessage>{authStore.loginUserError}</LoginErrorMessage>
        <BoldText variant='regular' center><em>Or</em></BoldText>
        <GoogleButtonContainer>
          <GoogleLogin 
            text="continue_with"
            onSuccess={handleLogin}
            onError={handleError} />
        </GoogleButtonContainer>
        <FooterText variant="small">Don't have an account? 
          <Link to={routes.SIGNUP}>Register Here!</Link>
        </FooterText>
        <ForgotPasswordText variant="small">
          <Link to={routes.FORGOT_PASSWORD}>Forgot Password?</Link>
        </ForgotPasswordText>
      </ComponentView>
    </AuthenticationView>
  )
}

export default observer(Login)


const LoginErrorMessage = styled(ErrorMessage)`
  position: relative;
`

export const GoogleButtonContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 30rem;
  color: ${colors.black} !important;
  cursor: pointer;

  > div {
    width: 100%;
  }
`
  
const FooterText = styled(Text)`
  margin-top: ${spacing.sm};
  text-align: center;

  & a {
    text-decoration: none;
    margin-left: 0.5rem;
  }
`

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xs};
`

const ForgotPasswordText = styled(Text)`
  margin-top: 0;
  position: absolute;
  right: 3rem;
  top: 23rem;
  
  a {
    text-decoration: none;
  }
`