import { makeAutoObservable, runInAction } from 'mobx'
import { IEligibleVoters, IPagination, IPendingInvitation, Status } from '../utils/interfaces'
import { status } from '../utils/constants'
import uiStore from './uiStore'
import { isEmail, processError, retrieveErrorMessage } from '../utils/helpers'
import {
  fetchEligibleVotersRequest,
  saveAllowedVotersRequest,
  fetchPendingInvitationsRequest,
  removeEligibleVoterRequest,
  revokePendingInvitationRequest,
} from '../utils/apiRequests'


class EligibleVoterStore {
  pendingInvitations: IPendingInvitation[] = []
  invitationPagination: IPagination = {} as IPagination
  invitationStatus: Status = null

  eligibleVoters: IEligibleVoters[] = []
  votersPagination: IPagination = {} as IPagination
  votersStatus: Status = null

  removeEligibleVoterStatus: Status = null
  revokeInvitationStatus: Status = null

  saveVoterStatus: Status = null

  constructor() {
    makeAutoObservable(this)
  }


  async fetchEligibleVoters(season_id: string, page?: number) {
    if (this.fetchingAllowedVoters) return
    const { current_page, has_next } = this.votersPagination
    const pageToLoad = page || ((current_page && has_next) ? current_page + 1 : 1)

    runInAction(() => {
      this.votersStatus = status.LOADING
    })

    try {
      const response = await fetchEligibleVotersRequest(season_id, pageToLoad)
      const { pagination, voters } = response.data

      runInAction(() => {
        this.eligibleVoters = pageToLoad === 1 ? voters : this.eligibleVoters.concat(voters)
        this.votersPagination = pagination
        this.votersStatus = status.COMPLETE
      })
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.votersStatus = status.FAILED
      })
    }
  }

  async fetchPendingInvitations(seasonId: string, page?: number) {
    if (this.fetchingPendingInvitations) return 

    const { current_page, has_next } = this.invitationPagination
    const pageToLoad = page || ((current_page && has_next) ? current_page + 1 : 1)

    runInAction(() => {
      this.invitationStatus = status.LOADING
    })

    try {
      const response = await fetchPendingInvitationsRequest(seasonId, pageToLoad)
      const { pagination, pending_invitations } = response.data

      runInAction(() => {
        this.pendingInvitations = pageToLoad === 1 ? pending_invitations : this.pendingInvitations.concat(pending_invitations)
        this.invitationPagination = pagination
        this.invitationStatus = status.COMPLETE
      })

    } catch (e) {
      processError(e)
      runInAction(() => {
        this.invitationStatus = status.FAILED
      })
    }
  }

  async saveEligibleVoters(seasonId: string, emails: string[]) {
    if (this.savingVoters) return
    uiStore.setLoadingScreenMessage('Saving eligible voters.')

    runInAction(() => {
      this.saveVoterStatus = status.LOADING
    })

    try {
      const response = await saveAllowedVotersRequest(seasonId, emails)
      const { voters, pending_invitations } = response.data

      runInAction(() => {
        this.eligibleVoters = [...voters, ...this.eligibleVoters]
        this.pendingInvitations = [...pending_invitations, ...this.pendingInvitations]
        this.saveVoterStatus = status.COMPLETE
      })
    } catch (e) {
      processError(e)
      runInAction(() => {
        this.saveVoterStatus = status.FAILED
      })
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  async removeEligibleVoter(seasonId: string, email: string) {
    if (this.removingEligibleVoter) return 
    uiStore.setLoadingScreenMessage('Removing Voter.')
    runInAction(() => this.removeEligibleVoterStatus = status.LOADING)

    try {
      const response = await removeEligibleVoterRequest(seasonId, email)
      uiStore.successModal(response.data.message)

      runInAction(() => {
        this.removeEligibleVoterStatus = status.COMPLETE
        this.eligibleVoters = this.eligibleVoters.filter(({ voter }) => voter.email !== email)
      })
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))

      runInAction(() => this.removeEligibleVoterStatus = status.FAILED)
    }

    uiStore.setLoadingScreenMessage('')
  }

  async revokeInvitation(seasonId: string, email: string) {
    if (this.revokingInvitation) return
    uiStore.setLoadingScreenMessage('Revoking invitation')
    runInAction(() => this.revokeInvitationStatus = status.LOADING)

    try {
      const response = await revokePendingInvitationRequest(seasonId, email)
      uiStore.successModal(response.data.message)
      runInAction(() => {
        this.revokeInvitationStatus = status.COMPLETE
        this.pendingInvitations = this.pendingInvitations.filter(({ email: inviteEmail }) => email !== inviteEmail)
      })
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => this.revokeInvitationStatus = status.FAILED)
    }

    uiStore.setLoadingScreenMessage('')
  }

  parseVoterString(voters: string) {
    const parsedVoters = voters.split(/[, ]/)
    const validVoters: string[] = []
    const invalidVoters: string[] = []

    let currentVoter: string;

    parsedVoters.forEach(voter => {
      currentVoter = voter.trim()
      if (!currentVoter) return

      if (isEmail(currentVoter)) {
        validVoters.push(currentVoter)
      } else {
        invalidVoters.push(currentVoter)
      }
    })

    return [
      Array.from(new Set(validVoters)),
      Array.from(new Set(invalidVoters))
    ]
  }

  constructErrorMessage(invalidVoters: string[]) {
    let errorMessage = `${invalidVoters.length} Invalid Entries: ${invalidVoters.slice(0,5).join(', ')}`
    if (invalidVoters.length > 5) {
      errorMessage += '...'
    }

    return errorMessage
  }

  get fetchingAllowedVoters() {
    return this.votersStatus === status.LOADING
  }

  get fetchingPendingInvitations() {
    return this.invitationStatus === status.LOADING
  }

  get hasEligibleVoters() {
    return !!this.eligibleVoters.length
  }

  get hasPendingInvitations() {
    return !!this.pendingInvitations.length
  }

  get savingVoters() {
    return this.saveVoterStatus === status.LOADING
  }

  get revokingInvitation() {
    return this.revokeInvitationStatus === status.LOADING
  }

  get removingEligibleVoter() {
    return this.removeEligibleVoterStatus === status.LOADING
  }
}

export default new EligibleVoterStore()
