import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import AddContestantForm from '../../../components/AddContestantForm'
import { spacing } from '../../../styles/_var'
import ContestantThumbnail from '../../../components/ContestantThumbnail'
import { ContestantWithBio } from '../../../utils/interfaces'
import uiStore from '../../../stores/uiStore'
import EditContestant from '../../../components/EditContestant'
import contestantStore from '../../../stores/contestantStore'
import competitionStore from '../../../stores/competitionStore'
import seasonStore from '../../../stores/seasonStore'


interface IContestantDetails {
  seasonId: string,
  contestants: ContestantWithBio[],
  savingContestant: boolean,
  saveContestant: (payload: ContestantWithBio, onComplete?: () => void) => void,
}


const ContestantDetails: FunctionComponent<IContestantDetails> = React.memo(({
  contestants,
  savingContestant,
  saveContestant,
  seasonId
}) => {
  const [avatar, setAvatar] = useState('')
  const [contestantToEdit, setContestantToEdit] = useState<any>(undefined)
  const [editModalOpen, setEditModalOpen] = useState(false)

  useEffect(() => {
    if (!seasonStore.selectedSeasonId) return

    contestantStore.fetchContestants(
      competitionStore.competitionDetails.username,
      seasonStore.selectedSeasonId
    )
  }, [])
  const onSubmit = (formData: any, onComplete?: () => void) => {
    const payload = { ...formData, avatar }

    saveContestant(payload, () => {
      setEditModalOpen(false)
      if (onComplete) onComplete()
    })
  }

  const updateContestant = (contestantUsername: string) => () => {
    const contestant = contestantStore.getContestant(contestantUsername)
    const initalValues = { ...contestant }

    setEditModalOpen(true)
    setContestantToEdit(initalValues)
  }

  const deleteContestant = (contestantUsername: string) => () => {
    uiStore.openConfirmDialog(
      "Are you sure you want to delete this contestant?",
      () => contestantStore.deleteContestant(seasonId, contestantUsername)
    )
  }

  return (
    <>
      <AddContestantForm
        alreadyAddedContestant={!!contestants.length}
        onAvatarUploaded={setAvatar}
        style={{marginTop: spacing.sm}}
        submit={onSubmit}
        submitting={savingContestant} />
      <ContestantWrapper>
        {contestants.map(contestant => (
          <ContestantThumbnail
            {...contestant}
            key={contestant.username}
            variant='new'
            onUpdate={updateContestant(contestant.username)}
            onDelete={deleteContestant(contestant.username)}
            style={{ maxWidth: '30rem', marginBottom: spacing.xs }} />
        ))}
      </ContestantWrapper>
      <EditContestant
        contestantToEdit={contestantToEdit}
        showModal={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        setAvatar={setAvatar}
        onSubmit={onSubmit}
        saving={savingContestant} />
    </>
  )
})

export default ContestantDetails


const ContestantWrapper = styled.div`
  display: grid;
  margin-top: ${spacing.sm};
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${spacing.sm};
`
