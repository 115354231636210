import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useCompetitionNotes from '../../hooks/useCompetitionNotes'
import { colors } from '../../styles/_var'
import { beautifyDate, plurify } from '../../utils/helpers'
import Text, { BoldText } from '../Text'
import { SidebarContent } from './styles'




const CompetitionNotes: FunctionComponent = observer(() => {
  const params: any = useParams()
  const competitionUsername = params.competition_id
  const notesPayload: any = useCompetitionNotes(competitionUsername)
  let nextVotingRoundText: any;

  if (notesPayload?.next_voting_round) {
    nextVotingRoundText = (
      <Text variant='small' color={colors.grey}>
        Watch our Live show to support your favourite contestants. New voting rounds starts on <Date>{beautifyDate(notesPayload.next_voting_round)}</Date>.
      </Text>
    )
  } else {
    nextVotingRoundText = (
      <Text variant='small' color={colors.grey}>
        The next voting has not yet being specified, this section would be updated when this information has been specified.
      </Text>
    )
  }


  if (!notesPayload) return null
  return (
    <SidebarContent>
      <Text variant="medium" color={colors.purple}>
        {plurify('Follower', notesPayload.num_of_followers)}
      </Text>
      <Box mt={1} mb={1}>
        <Divider />
      </Box>
      <BoldText variant="regular">NEXT VOTING ROUNDS</BoldText>
      {nextVotingRoundText}
    </SidebarContent>
  )
})

export default CompetitionNotes

const Date = styled.span`
  color: ${colors.purple};
  font-weight: bold;
`