import React, { FunctionComponent, ReactNode, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { ReactComponent as Coins } from '../../../assets/images/coins.svg'
import { colors, fontSize, spacing } from '../../../styles/_var'
import { ReactComponent as Trophy } from '../../../assets/images/trophy.svg'
import Text from '../../../components/Text'
import Link from '../../../components/Link'
import { routes } from '../../../utils/constants'
import { AccountIcon, CloseIcon, PrivateIcon } from '../../../components/Icons'

interface IAction {
  actionText: string,
  actionLink: string,
}

interface IStateItem {
  title: string,
  HeaderIcon: ReactNode,
  body: string,
  actions: IAction[]
}

const DashboardSections: IStateItem[] = [
  {
    title: "Competitions",
    HeaderIcon: <Trophy />,
    body: "Hello and welcome to Matuskii. If you would like to begin creating your own competition or campaign please click on the button below.",
    actions: [
      {
        actionText: "Create Competitions",
        actionLink: routes.MY_COMPETITIONS
      }
    ]
  },
  {
    title: "Exlcusive Competitions",
    HeaderIcon: <PrivateIcon />,
    body: "Have you been invited to participate in any private competitions? Visit your My Competitions page and check under the \"Exclusive Competitions\" Tab",
    actions: [
      {
        actionText: "My Competitions",
        actionLink: routes.MY_COMPETITIONS
      }
    ]
  },
  {
    title: "Profile Picture",
    HeaderIcon: <AccountIcon />,
    body: "Please update your profile with a recent picture because we would love to put a face to your name.",
    actions: [
      {
        actionText: "My Profile",
        actionLink: routes.MY_ACCOUNT
      }
    ]
  },
  {
    title: "Voting Credits",
    HeaderIcon: <Coins />,
    body: "Please purchase voting credits (also called Tush Credits) to participate in Competitions/campaigns that requires money to vote.",
    actions: [
      {
        actionText: "Voting Credits",
        actionLink: routes.VOTING_CREDITS
      }
    ]
  }
]

const DefaultStateItem: FunctionComponent<IStateItem> = ({
  title, HeaderIcon, body, actions
}) => {
  const [close, setClose] = useState(false)
  const closeStateItem = () => setClose(true)
  if (close) return null;


  return (
    <Grid md={4} xs={12} sm={6} item>
      <Card>
        <CardHeader>
          <HeaderLeft>
            {HeaderIcon}
            <Text variant='medium' ml={1}>{title}</Text>
          </HeaderLeft>
          <CloseIcon onClick={closeStateItem}/>
        </CardHeader>
        <CardBody>
          <Text variant='regular'>{body}</Text>
        </CardBody>
        <CardActionArea>
          {actions.map((action, i) => <Link to={action.actionLink} key={i}>{action.actionText}</Link>)}
        </CardActionArea>
      </Card>
    </Grid>
  )
}

const DashboardDefaultState = () => {
  return (
    <Wrapper>
      <Grid container spacing={5}>
        {DashboardSections.map((section, i) => <DefaultStateItem key={i} {...section} />)}
      </Grid>
    </Wrapper>
  )
}

export default DashboardDefaultState

const Wrapper = styled.div`
  padding: 0 ${spacing.rg} ${spacing.rg};
`

const Card = styled.div`
  background-color: ${colors.verylightpurple};
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`

const CardHeader = styled.div`
  padding: ${spacing.xxs} ${spacing.xs};
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${colors.lightgrey};
  justify-content: space-between;

  svg * {
    fill: ${colors.grey};
    cursor: pointer;
  }

  svg {
    transition: transform ease .2s;
  }

  svg:hover {
    transform: scale(1.3);
  }
`

const CardBody = styled.div`
  padding: 5px ${spacing.xs};
  border-bottom: solid 1px ${colors.lightgrey};
`

const CardActionArea = styled.div`
  padding: ${spacing.xxs} ${spacing.xs};
  a {
    font-size: ${fontSize.rg};
    text-decoration: none;
    font-weight: bold;
  }
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`