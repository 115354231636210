import React from 'react'
import styled from 'styled-components'
import PageContent from '../components/PageContent'
import { spacing } from '../styles/_var'

import Footer from '../components/Footer'
import usePageTitle from '../hooks/usePageTitle'
import Text, { BoldText } from '../components/Text'


const PrivacyPolicy = () => {
  usePageTitle('Privacy Policy')

  return (
    <>
      <Wrapper>
        <BoldText variant='large'>Privacy Policy</BoldText>
        <Text variant="regular">Last updated: September 03, 2022</Text>
        <Text variant="regular">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</Text>
        <BoldText variant="large">Interpretation</BoldText>
        <Text variant="regular">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Text>
        <BoldText variant="regular">Definitions</BoldText>
        <Text variant="regular">For the purposes of this Privacy Policy:</Text>
        <ul>
          <li><Text variant="regular"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</Text></li>
        <li><Text variant="regular"><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Matuskii Inc, 7 wulemotu Ajoke Street Akoka.</Text></li>
        <li>
        <Text variant="regular"><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Country</strong> refers to:  Nigeria</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Service</strong> refers to the Website.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</Text>
        </li>
        <li>
        <Text variant="regular"><strong>Website</strong> refers to Matuskii, accessible from <a href="https://matuskii.com" rel="noreferrer" target="_blank">https://matuskii.com</a></Text>
        </li>
        <li>
        <Text variant="regular"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</Text>
        </li>
        </ul>
        <h1>Collecting and Using Your Personal Data</h1>
        <BoldText variant="regular">Types of Data Collected</BoldText>
        <BoldText variant="large">Personal Data</BoldText>
        <Text variant="regular">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</Text>
        <ul>
        <li>
        <Text variant="regular">Email address</Text>
        </li>
        <li>
        <Text variant="regular">First name and last name</Text>
        </li>
        <li>
        <Text variant="regular">Phone number</Text>
        </li>
        <li>
        <Text variant="regular">Address, State, Province, ZIP/Postal code, City</Text>
        </li>
        <li>
        <Text variant="regular">Usage Data</Text>
        </li>
        </ul>
        <BoldText variant="large">Usage Data</BoldText>
        <Text variant="regular">Usage Data is collected automatically when using the Service.</Text>
        <Text variant="regular">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</Text>
        <Text variant="regular">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, the IP address of Your mobile device, the type of mobile Internet browser You use, and other diagnostic data.</Text>
        <Text variant="regular">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</Text>
        <BoldText variant="large">Tracking Technologies and Cookies</BoldText>
        <Text variant="regular">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are google analytics to collect and track information and to improve and analyze Our Service.</Text>
        <Text variant="regular">We use both Session and Persistent Cookies for the purposes set out below:</Text>
        <ul>
        <li>
        <Text variant="regular"><Text variant='regular'>Necessary / Essential Cookies</Text></Text>
        <Text variant="regular">Type: Session Cookies</Text>
        <Text variant="regular">Administered by: Us</Text>
        <Text variant="regular">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</Text>
        </li>
        <li>
        <Text variant="regular"><Text variant='regular'>Cookies Policy / Notice Acceptance Cookies</Text></Text>
        <Text variant="regular">Type: Persistent Cookies</Text>
        <Text variant="regular">Administered by: Us</Text>
        <Text variant="regular">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</Text>
        </li>
        <li>
        <Text variant="regular"><Text variant='regular'>Functionality Cookies</Text></Text>
        <Text variant="regular">Type: Persistent Cookies</Text>
        <Text variant="regular">Administered by: Us</Text>
        <Text variant="regular">Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</Text>
        </li>
        </ul>
        <Text variant="regular">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</Text>
        <BoldText variant="regular">Use of Your Personal Data</BoldText>
        <Text variant="regular">The Company may use Personal Data for the following purposes:</Text>
        <ul>
        <li>
        <Text variant="regular"><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</Text>
        </li>
        <li>
        <Text variant="regular"><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</Text>
        </li>
        </ul>
        <Text variant="regular">We may share Your personal information in the following situations:</Text>
        <ul>
          <li><Text variant='regular'><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</Text></li>
          <li><Text variant='regular'><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</Text></li>
          <li><Text variant='regular'><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</Text></li>
          <li><Text variant='regular'><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</Text></li>
          <li><Text variant='regular'><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</Text></li>
          <li><Text variant='regular'><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.</Text></li>
        </ul>
                <BoldText variant="regular">Retention of Your Personal Data</BoldText>
        <Text variant="regular">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Text>
                <Text variant="regular">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</Text>
                <BoldText variant="regular">Transfer of Your Personal Data</BoldText>
        <Text variant="regular">Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</Text>
        <Text variant="regular">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</Text>
                <Text variant="regular">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</Text>
                <BoldText variant="regular">Disclosure of Your Personal Data</BoldText>
        <BoldText variant="large">Business Transactions</BoldText>
        <Text variant="regular">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</Text>
        <BoldText variant="large">Law enforcement</BoldText>
        <Text variant="regular">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</Text>
        <BoldText variant="large">Other legal requirements</BoldText>
        <Text variant="regular">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</Text>
        <ul>
        <li><Text variant="regular">Comply with a legal obligation</Text></li>
        <li><Text variant="regular">Protect and defend the rights or property of the Company</Text></li>
        <li><Text variant="regular">Prevent or investigate possible wrongdoing in connection with the Service</Text></li>
        <li><Text variant="regular">Protect the personal safety of Users of the Service or the public</Text></li>
        <li><Text variant="regular">Protect against legal liability</Text></li>
                </ul>
                <BoldText variant="regular">Security of Your Personal Data</BoldText>
        <Text variant="regular">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</Text>
        <h1>Children's Privacy</h1>
        <Text variant="regular">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</Text>
        <Text variant="regular">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</Text>
        <h1>Links to Other Websites</h1>
        <Text variant="regular">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</Text>
        <Text variant="regular">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Text>
        <h1>Changes to this Privacy Policy</h1>
        <Text variant="regular">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</Text>
        <Text variant="regular">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</Text>
        <Text variant="regular">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Text>
        <h1>Contact Us</h1>
        <Text variant="regular">If you have any questions about this Privacy Policy, You can contact us:</Text>
        <ul>
        <li><Text variant="regular">By email: admin@matuskii.com</Text></li>
        </ul>
      </Wrapper>
      <Footer />
    </>
  )
}

export default PrivacyPolicy

const Wrapper = styled(PageContent)`
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding-top: ${spacing.rg};
  margin-bottom: ${spacing.lg};
`
