import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text, { BoldText } from '../../components/Text'
import { device } from '../../styles/_var'


interface IPricingSection {
  name: string,
  headerText: string,
  pricingInfo: string,
  color?: string,
  hidePricingSubText?: boolean
}

const PricingSection: FunctionComponent<IPricingSection> = ({
  name,
  headerText,
  pricingInfo,
  color,
  children,
  hidePricingSubText
}) => {
  return (
    <Wrapper>
      <Header color={color}>
        <BoldText variant="large">{name}</BoldText>
        <Text variant="regular">{headerText}</Text>
      </Header>
      <BodySection>
        <PricingContent>
          <BoldText variant="medium" center>
            {pricingInfo}
          </BoldText>
          {!hidePricingSubText && <BoldText variant='small' center>
            + N50/1000 votes
          </BoldText>}
        </PricingContent>
        {children}
      </BodySection>
    </Wrapper>
  )
}

export default PricingSection

const Wrapper = styled.div`
  box-shadow: 0 0 1px 2px #eee;
  @media ${device.laptop} {
    min-height: 270px;
  }
`

const PricingContent = styled.div`
  min-height: 48px;
`

const Header = styled(({ color, ...rest }) => <div {...rest} />)`
  padding: 20px;
  text-align: center;
  ${({ color }) => color && `
    background-color: ${color};
  `}
  @media ${device.laptop} {
    min-height: 100px;
  }
`

const BodySection = styled.div`
  padding: 20px;
`