import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { colors, device, spacing } from '../../styles/_var'
import { CompetitionBody, TabSection } from '../CompetitionPage/CompetitionPage'
import AboutCompetition from '../CompetitionPage/AboutCompetition'
import CompetitionBanner from '../CompetitionPage/CompetitionBanner'
import Text from '../../components/Text'
import Input from '../../components/Input'
import competitionStore from '../../stores/competitionStore'
import { ContestantWithBio } from '../../utils/interfaces'
import uiStore from '../../stores/uiStore'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import TextArea from '../../components/TextArea'
import AddContestantForm from '../../components/AddContestantForm'
import { CompetitionPageWrapper } from './StyledComponents'
import seasonStore from '../../stores/seasonStore'
import votingRoundStore from '../../stores/votingRoundStore'
import contestantStore from '../../stores/contestantStore'
import usePageTitle from '../../hooks/usePageTitle'
import { getCompetitionLabels } from '../../utils/helpers'
import TabContent from './components/TabContent'


const CompetitionDashboard: FunctionComponent = () => {
  const [showAddContestantForm, setShowAddContestantForm] = useState(false)
  const [editField, setEditField] = useState('')
  const [editValue, setEditValue] = useState('')
  const [editInputError, setEditInputError] = useState('')
  const [contestantAvatar, setContestantAvatar] = useState('')
  const { uuid: seasonId, concluded, banner, logo, description } = seasonStore.selectedSeason
  const competitionTemplate = competitionStore.competitionDetails.template
  const CONTESTANT_LABEL = getCompetitionLabels(competitionTemplate).contentstantLabel
  const CONTESTANT_TAB = CONTESTANT_LABEL  + 's'
  const OPTIONS_TAB = 'Options'
  const CATEGORIES_TAB = 'Categories'
  const tabs = [CONTESTANT_TAB, OPTIONS_TAB, CATEGORIES_TAB]
  const [selectedTab, setSelectedTab] = useState(CONTESTANT_TAB)
  usePageTitle('Competition Dashboard')

  const saveContestant = (contestant: ContestantWithBio) => {
    return contestantStore.saveContestant(contestant, false, seasonId)
  }

  const evictContestant = (contestantUsername: string) => {
    uiStore.openConfirmDialog('Are you sure you want to perform this action', () => {
      contestantStore.evictContestant(seasonId, contestantUsername)
    })
  }

  const deleteContestant = (contestantUsername: string) => {
    uiStore.openConfirmDialog("Are you sure you want to delete this contestant", () => {
      contestantStore.deleteContestant(seasonId, contestantUsername, false)
    })
  }

  const updateCompetitionDetails = (field: string, value: string) => {
    const competition: any = {...competitionStore.competitionDetails}
    competition[field] = value

    competitionStore.createCompetition(competition, true)
  }

  const onAddContestant = (payload: ContestantWithBio, onComplete?: () => void) => {
    payload.avatar = contestantAvatar
    contestantStore.saveContestant(payload, false, seasonId).then(() => {
      onComplete && onComplete()
      setShowAddContestantForm(false)
    })
  }

  const onCloseEditModal = () => {
    setEditField('')
    setEditValue('')
  }

  const onEditName = () => {
    clearInputErrors()
    setEditField('name')
    setEditValue(competitionStore.competitionDetails.name)
  }

  const onEditDescription = () => {
    clearInputErrors() 
    setEditField('description')
    setEditValue(description || '')
  }

  const clearInputErrors = () => {
    setEditInputError("")
  }

  const updateFields = () => {
    if (!editValue) {
      return setEditInputError("This field cannot be blank")
    }

    updateCompetitionDetails(editField, editValue)
    onCloseEditModal()
  }

  const closeSeason = () => {
    uiStore.openConfirmDialog('Are you sure you want to end this season?', () => {
      seasonStore.closeSeason(seasonId)
    })
  }

  return (
    <CompetitionPageWrapper>
      <CompetitionBanner
        seasonCompleted={concluded}
        endCompetitionAction={closeSeason}
        onBannerImageChange={image => updateCompetitionDetails('banner', image)}
        onLogoImageChange={image => updateCompetitionDetails('logo', image)}
        onClickEdit={onEditName}
        banner={banner}
        logo={logo}
        seasons={competitionStore.competitionDashboardDetails.seasons}
        username={competitionStore.competitionDashboardDetails.username}
        name={competitionStore.competitionDashboardDetails.name}
        editMode />
      <AboutCompetition
        onClickEdit={onEditDescription}
        about={description}
        editMode />
      <CompetitionBody role="tab">
        <TabbedSection role="tabpanel">
          {tabs.map((tab, index) => (
            <TabItem
              onClick={() => setSelectedTab(tab)}
              key={index}
              selected={tab === selectedTab}
            >
              <Text variant="regular" truncate>{tab}</Text>
            </TabItem>
          ))}
        </TabbedSection>
        <TabBody role="tablist">
          <BodyWrapper>
            <TabContent 
              selectedTab={selectedTab}
              contestantLabel={CONTESTANT_LABEL}
              tabs={{CONTESTANT_TAB, OPTIONS_TAB, CATEGORIES_TAB}}
              competitionTemplate={competitionTemplate}
              votingOptions={votingRoundStore.lastVotingRound?.voting_options}
              deleteContestant={deleteContestant}
              evictContestant={evictContestant}
              saveContestant={saveContestant}
              savingContestant={contestantStore.savingContestant}
              contestants={contestantStore.contestantsFilteredByCategory}
              onClickContestantButton={() => setShowAddContestantForm(true)}
            />
          </BodyWrapper>
        </TabBody>
      </CompetitionBody>
      <Modal onClose={onCloseEditModal} showModal={!!editField}>
        <FormWrapper>
          {editField === 'description' ? (
            <TextArea
              onFocus={clearInputErrors}
              errorMessage={editInputError}
              placeholder={`update ${editField}`}
              value={editValue}
              onChange={e => setEditValue(e.target.value)} />
          ) : (
            <Input
              onFocus={clearInputErrors}
              errorMessage={editInputError}
              placeholder={`update ${editField}`}
              value={editValue}
              onChange={e => setEditValue(e.target.value)} />
          )}
          <Button mt={2} invertOnHover onClick={updateFields}>Update</Button>
        </FormWrapper>
      </Modal>
      <ContestantModal
        onClose={() => setShowAddContestantForm(false)}
        showModal={showAddContestantForm}>
        <Text variant="medium" mb={2} center>Add New {CONTESTANT_LABEL}</Text>
        <AddContestantForm
          submit={onAddContestant}
          submitting={contestantStore.savingContestant}
          onAvatarUploaded={avatar => setContestantAvatar(avatar)}
          competitionTemplate={competitionTemplate} />
      </ContestantModal>
    </CompetitionPageWrapper>
  )
}

export default observer(CompetitionDashboard)

const FormWrapper = styled.div`
  text-align: center;
`

export const TabItem = styled(({ selected, ...rest }) => <div {...rest} />)`
  text-decoration: none;
  color: ${colors.black};
  width: 30%;
  max-width: 30rem;
  cursor: pointer;
  text-align: center;
  border-bottom: solid 2px ${colors.lightgrey};
  text-transform: uppercase;
  transition: all ease .2s;
  margin-right: ${spacing.rg};

  &:hover {
    color: ${colors.purple};
    border-bottom-color: ${colors.purple};
  }

  ${({ selected }) => selected && `
    color: ${colors.purple};
    border-bottom-color: ${colors.purple};
  `}
`

const BodyWrapper = styled.div`
  padding: ${spacing.xs} ${spacing.sm};
`

export const AddContestantButton = styled(Button)`
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: ${spacing.xxs};
  }
`

const ContestantModal = styled(Modal)`
  max-width: unset;
  width: 75%;

  @media ${device.tablet} {
    min-width: 70rem;
    max-width: 80rem;
    width: 80%
  }

  & > div:last-child {
    overflow: scroll;
    max-height: 70vh;
  }
`

const TabbedSection = styled(TabSection)`
  justify-content: flex-start;
`

const TabBody = styled.div`
box-sizing: border-box;
padding: 0 ${spacing.sm};
`