import React, { FunctionComponent, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'
import { faAngleRight, faCheck } from '@fortawesome/free-solid-svg-icons'


export interface IListItem {
  value: string;
  label: string;
}

interface IDropdownProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  list: IListItem[] | string[];
  selected: IListItem | string;
  onItemSelect: (item: any) => void;
  returnFullObject?: boolean
}

const Dropdown: FunctionComponent<IDropdownProps> = React.memo(({
  list,
  selected,
  onItemSelect,
  returnFullObject,
  ...rest
}) => {
  const [showList, setShowList] = useState(false)
  const label = typeof selected === 'string' ? selected : selected.label
  
  useEffect(() => {
    const onCloseDropdown = () => {
      if (showList) setShowList(false)
    }

    document.body.addEventListener('click', onCloseDropdown)

    return () => {
      document.body.removeEventListener('click', onCloseDropdown)
    }
  }, [showList])

  return (
    <DropdownWrapper role="listbox" {...rest}>
      <Selected onClick={() => setShowList(!showList)}>
        <Text variant="regular" role="listitem" aria-label={label} truncate>{label}</Text>
        <ArrowIcon icon={faAngleRight} size="2x" active={showList} />
      </Selected>
      {showList && (
        <DropdownList>
          {(list as any[]).map((item, index) => (
            <DropdownItem 
              role="listitem"
              key={index} 
              onClick={() => onItemSelect(returnFullObject ? item : (item?.value || item))}
              aria-label={item?.label || item}
            >
              <Text variant="regular" role="listitem">{item?.label || item}</Text>
              {(item?.label || item) === selected && <FontAwesomeIcon icon={faCheck} />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  )
})

export default Dropdown

const DropdownWrapper = styled.div`
  width: 20rem;
  cursor: pointer;
  position: relative;
  height: 5rem;
`

const Selected = styled.div`
  background: #FFFFFF;
  border: 1px solid ${colors.lightgrey};
  box-shadow: 0px 2px 10px rgba(116, 140, 173, 0.1);
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem ${spacing.xs};
  box-sizing: border-box;
  height: 100%;
`

const DropdownList = styled.div`
  position: absolute;
  background: ${colors.white};
  border: 1px solid rgba(223, 224, 235, 0.5);
  box-shadow: 0px 10px 15px rgba(171, 191, 216, 0.2);
  border-radius: 3px;
  width: 100%;
  margin-top: ${spacing.xxs};
  box-sizing: border-box;
  z-index: 3;
`

const DropdownItem = styled.div`
  padding: ${spacing.xxs} ${spacing.sm};
  border-bottom: solid thin ${colors.lightgrey};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: ${spacing.xs};
  }
  
  &:hover {
    background: ${colors.lightpurple};
    color: ${colors.white};
  }

  &:last-child {
    border-bottom: none;
  }
`

const ArrowIcon = styled(({ active, ...rest }) => <FontAwesomeIcon {...rest} />)`
  transition: all .2s ease;
  transform: rotate(0deg);

  ${({ active }) => active && `transform: rotate(90deg);`}
`