import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { RouteChildrenProps } from 'react-router-dom'
import Link from '../components/Link'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import SimpleForm from '../components/SimpleForm'
import { AuthenticationView, ErrorMessage, ComponentView } from '../components/StyledComponents'
import Text, { BoldText } from '../components/Text'
import { spacing, colors } from '../styles/_var'
import { routes } from '../utils/constants'
import authStore from '../stores/authStore'
import { formatToUsername, required, sanitizeUserRegisterPayload } from '../utils/helpers'
import useRenderOnNotAuthenticated from '../hooks/useRenderOnNotAuthenticated'
import usePageTitle from '../hooks/usePageTitle'
import Input from '../components/Input'
import validators from '../utils/validators'
import UsernameInput from '../components/UsernameInput'
import { GoogleButtonContainer } from './Login'


interface ISignupProps extends RouteChildrenProps {
  className?: string;
}

const Signup: FunctionComponent<ISignupProps> = ({ className='' }) => {
  usePageTitle('Sign Up')

  const shouldLoadPage = useRenderOnNotAuthenticated()
  if (!shouldLoadPage) return null

  const registerUser = async (formPayload: any) => {
    const userPayload = sanitizeUserRegisterPayload(formPayload)
    await authStore.registerUser(userPayload)
  }

  const handleLogin = (data: CredentialResponse) => {
    const { credential } = data

    if (credential) {
      authStore.googleAuth(credential)
    }
  }

  const registerActions = {
    label: 'Register Now',
    loading: authStore.registerUserLoading,
    invertOnHover: true,
    isSubmit: true,
  }

  return (
    <AuthenticationView>
      <Wrapper className={className}>
        <TitleText variant="medium">Register</TitleText>
        <SimpleForm
          submitForm={registerUser}
          formFields={registerFields}
          actionButtons={registerActions} />
        <LoginErrorMessage>{authStore.registerUserError}</LoginErrorMessage>
        <BoldText variant='regular' center><em>Or</em></BoldText>
        <GoogleButtonContainer>
          <GoogleLogin 
            text="continue_with"
            onSuccess={handleLogin}
            onError={() => {}} />
        </GoogleButtonContainer>
        <FooterText variant="small">Already have an account?
          <Link to={routes.LOGIN}>Login Here!</Link>
        </FooterText>
      </Wrapper>
    </AuthenticationView>
  )
}


export default observer(Signup)

const LoginErrorMessage = styled(ErrorMessage)`
  position: relative;
`

export const registerFields: any = [
  {
    name: 'full_name',
    placeholder: 'Full Name',
    component: Input,
    max_length: 50,
    validate: required
  },
  {
    name: 'username',
    placeholder: 'Username (No spaces allowed)',
    parse: formatToUsername,
    component: UsernameInput,
    max_length: 50,
    validate: required,
    invertValidation: true,
  },
  {
    name: 'email',
    placeholder: 'Email',
    component: Input,
    max_length: 40,
    validate: validators.isEmail
  },
  {
    name: 'phone_number',
    placeholder: 'Phone Number',
    component: Input,
    max_length: 50,
    validate: validators.isPhoneNo
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password',
    component: Input,
    max_length: 50,
    validate: required
  },
  {
    name: 'confirm_password',
    placeholder: 'Confirm Password',
    type: 'password',
    component: Input,
    max_length: 50,
    validate: required
  },
  {
    name: 'tandc',
    label: 'By clicking the register button, you hereby agree to our terms of use.',
    component: 'input',
    type: 'checkbox',
    className: 'tandc',
    validate: required
  }
]

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xs};
`
const FooterText = styled(Text)`
  margin-top: ${spacing.sm};
  text-align: center;

  & a {
    text-decoration: none;
    margin-left: 0.5rem;
  }
`

const Wrapper = styled(ComponentView)`
  & .tandc {
    color: ${colors.purple};
  }
`