import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device, spacing, colors } from '../styles/_var'
import Text, { BoldText } from './Text'
import { isMobileScreen } from '../utils/helpers'
import Link from './Link'
import { routes } from '../utils/constants'

const peopleDesktop = "https://matusky.s3.us-west-2.amazonaws.com/assets/images/people-desktop.png"
const peopleMobile = "https://matusky.s3.us-west-2.amazonaws.com/assets/images/people-mobile.png"

const LandingPageBanner = React.memo(() => {
  const [peopleImage, setPeopleImage] = useState(isMobileScreen() ? peopleMobile : "https://matusky.s3.us-west-2.amazonaws.com/assets/images/people-desktop.png")
  const onResize = () => {
    setPeopleImage(isMobileScreen() ? peopleMobile : peopleDesktop)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <BannerWrapper role="banner">
      <BannerMessage>
        <TitleText variant="title">Online Voting Made Easy</TitleText>
        <Tagline variant="medium">Your favourite shows. Your favourite contestants.<br />All in one place.</Tagline>
        <GetStarted to={routes.SIGNUP} useButton>Get Started</GetStarted>
      </BannerMessage>
      <PeopleImage src={peopleImage} alt='people-icon' />
    </BannerWrapper>
  )
})

export const BannerWrapper = styled.div`
  margin-top: ${spacing.xl};
  position: relative;
  width: 100%;
  padding-top: ${spacing.rg};
  background-image: url("https://matusky.s3.us-west-2.amazonaws.com/assets/images/background-mobile.png");
  text-align: center;
  min-height: 47.5rem;
  
  @media ${device.tablet} {
    min-height: 52rem;
    background-image: url("https://matusky.s3.us-west-2.amazonaws.com/assets/images/background-desktop.png");
    background-position: center center;
    margin-top: ${spacing.sm};
    padding-top: ${spacing.xxl};
  }
`

const TitleText = styled(BoldText)`
  color: ${colors.darkpurple};
  line-height: 5rem;
`

const BannerMessage = styled.div`
  text-align: center;
  padding: 0 ${spacing.rg};
`

const Tagline = styled(Text)`
  margin-top: ${spacing.xs};
  line-height: 3rem;
`

const GetStarted = styled(Link)`
  width: 20rem;
  margin-top: ${spacing.xs};
`

const PeopleImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 70%;

  @media ${device.laptop} {
    max-width: 100%;
  }
`

export default LandingPageBanner
