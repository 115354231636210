import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import Footer from "../../components/Footer";
import PageContent from "../../components/PageContent";
import faqsStore from "../../stores/faqsStore";
import { device, fontSize, spacing } from "../../styles/_var";
import QuestionAnswer from "./QuestionAnswer";
import LoadingComponent from "../../components/LoadingComponent";
import { BoldText } from "../../components/Text";
import usePageTitle from "../../hooks/usePageTitle";


const Faqs = () => {
  const { faqs } = faqsStore;
  usePageTitle('FAQs')

  useEffect(() => {
    faqsStore.fetchFaqs()
  }, []);

  return (
    <>
      <FaqsWrapper>
        <Heading variant="title">Frequently Asked Questions (FAQs)</Heading>
        {faqsStore.fetchingFaqs && <LoadingComponent message="Fetching Faqs..." />}
        {faqsStore.fetchedFaqs && (
          <FaqsContainer>
            {faqs?.map((faq) => (
              <QuestionAnswer faq={faq} key={faq.id} />
            ))}
          </FaqsContainer>
        )}
      </FaqsWrapper>
      <Footer />
    </>
  );
};



export default observer(Faqs);

const FaqsWrapper = styled(PageContent)`
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  padding-top: ${spacing.rg};
  margin-bottom: ${spacing.xl};
  text-align: center;
`;

const FaqsContainer = styled.div`
  margin: 20px auto;
  text-align: left;

  @media ${device.mobileVL} {
    width: 80%;
  }

  width: 95%;
`;

const Heading = styled(BoldText)`
  font-size: ${fontSize.lg};
  padding: 0 10px;

  @media ${device.mobileVL} {
    font-size: ${fontSize.xl};
  }
`;
