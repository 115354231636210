export const colors = {
  white: '#fff',
  purple: '#6F52ED',
  lightpurple: '#977EF7',
  verylightpurple: '#F8F8FB',
  subtlylightpurple: 'rgba(111,82,237,0.05)',
  darkpurple: '#3C2A8C',
  black: '#000',
  lightgrey: '#DFE0EB',
  red: '#F12B2C',
  grey: '#373B53',
  yellow: '#FFEB98',
  darkyellow: '#E69423',
  blue: '#0F8CFF',
  green: '#29CC97'
}

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileVL: '620px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}


 export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileVL: `(min-width: ${size.mobileVL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};


export const spacing = {
  xxs: '1rem',
  xs: '1.5rem',
  sm: '3rem',
  rg: '4.5rem',
  lg: '5rem',
  xl: '7rem',
  xxl: '12rem'
}


export const fontSize = {
  xs: '1.2rem',
  sm: '1.4rem',
  rg: '1.6rem',
  md: '2rem',
  lg: '3rem',
  xl: '4rem'
}
