import React from 'react'
import styled from 'styled-components'
import { colors, spacing, device } from '../styles/_var'
import PageContent from './PageContent'
import Text, { BoldText } from './Text'


const options = [
  'Visitors see their choices in a gallery that features captions, number of votes, and more.',
  'Easily create voting options by uploading an image and caption.',
  'Each vote contest entry gets its own unique URL to help your contest go viral.'
]

const ContestantSamples = React.memo(() => {
  return (
    <Samples>
      <img src="https://matusky.s3.us-west-2.amazonaws.com/assets/images/contestant-1.png" alt="contestant-1" />
      <img src="https://matusky.s3.us-west-2.amazonaws.com/assets/images/contestant-2.png" alt="contestant-2" />
      <img src="https://matusky.s3.us-west-2.amazonaws.com/assets/images/contestant-3.png" alt="contestant-3" />
    </Samples>
  )
})

const LandingSectionTwo = React.memo(() => {
  return (
    <SectionWrapper>
      <ContestantSamples />
      <VotingOptions>
        <TitleText variant="large">A Variety of Voting Options</TitleText>
        <ul>
          {options.map((e, i) => <Option key={i}><OptionText variant="regular">{e}</OptionText></Option>)}
        </ul>
      </VotingOptions>
    </SectionWrapper>
  )
})


const SectionWrapper = styled(PageContent)`
  position: relative;
  background-color: ${colors.verylightpurple};
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};

  @media ${device.tablet} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media ${device.tablet} {
    padding-top: ${spacing.rg};
    padding-bottom: ${spacing.rg};
  }

  @media ${device.laptop} {
    padding-top: ${spacing.xl};
    padding-bottom: ${spacing.xl};
  }
`

const VotingOptions = styled.div`
  @media ${device.tablet} {
    max-width: 30rem;
  }

  @media ${device.laptop} {
    max-width: 40rem;
  }
`

const TitleText = styled(BoldText)`
  color: ${colors.darkpurple};
  line-height: 3.5rem;
  padding: 0 ${spacing.sm};
  box-sizing: border-box;
  margin-bottom: ${spacing.sm};
`

const Option = styled.li`
  margin-bottom: ${spacing.xxs};
  font-size: 2.5rem;
  color: ${colors.purple};
`

const OptionText = styled(Text)`
  color: ${colors.black};
`

const Samples = styled.div`
  height: 30rem;
  padding: ${spacing.xs};
  width: 46rem;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: auto;

  & img {
    position: absolute;
    width: 20rem;
  }

  & img:first-child {
    left: 5rem;
    transform: rotate(-30deg);
  }

  & img:nth-child(2) {
    left: 13rem;
  }

  & img:last-child {
    left: 23rem;
    transform: rotate(30deg);
  }

  @media ${device.mobileL} {
    height: 40rem;
    width: 55rem;
    & img {
      width: 28rem;
    }
  }

  @media ${device.tablet} {
    height: 53rem;
    width: auto;
    padding: 0;
    margin: 0;

    & img {
      position: relative;
      width: 20rem;
    }

    & img:first-child {
      left: 3.2rem;
      transform: none;
      position: relative;
      top: 5rem;
    }

    & img:nth-child(2) {
      left: unset;
      position: relative;
    }

    & img:last-child {
      left: unset;
      transform: none;
      display: block;
      margin: auto;
      position: relative;
      top: -10rem;
    }
  }

  @media ${device.laptop} {
    height: 53rem;
    width: auto;
    & img {
      width: 25rem;
    }
  }
`

export default LandingSectionTwo

