import { useEffect } from 'react'
import uiStore from '../stores/uiStore'
import Auth from '../utils/auth'
import { routes } from '../utils/constants'

const Logout = () => {
  useEffect(() => {
    uiStore.setLoadingScreenMessage('Logging out')
    Auth.logUserOut()

    setTimeout(() => {
      window.location.href = routes.LANDING
    }, 300)
  }, [])

  return null
}

export default Logout
