import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import EmptyResource from '../../components/EmptyResource'
import Input from '../../components/Input'
import MInput from '../../components/MInput'
import Modal from '../../components/Modal'
import RadioButton from '../../components/RadioButton'
import SimpleForm, { IActionButton } from '../../components/SimpleForm'
import Text, { BoldText } from '../../components/Text'
import VotingRoundBoard from '../../components/VotingRoundBoard'
import { ContestantWithBio } from '../../utils/interfaces'
import validators from '../../utils/validators'
import { CompetitionPageWrapper } from './StyledComponents'
import { colors, spacing } from '../../styles/_var'
import Button from '../../components/Button'
import { TextWithIcon } from '../VoterDashboard/components/VoterDashboardStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import votingRoundStore from '../../stores/votingRoundStore'
import contestantStore from '../../stores/contestantStore'
import competitionStore from '../../stores/competitionStore'
import usePageTitle from '../../hooks/usePageTitle'
import CompetitionCategoryDropdown from '../../components/CompetitionCategoryDropdown'

const steps = {
  VOTING_ROUND: 0,
  VOTING_ROUND_OPTIONS: 1,
}

const VotingRounds = () => {
  const noVotingRounds = !votingRoundStore.votingRounds.length 
  usePageTitle('Voting Rounds')

  useEffect(() => {
    votingRoundStore.setVotingRoundPayload({})
  }, [])

  const getContestant = (username: string): ContestantWithBio | undefined => {
    return contestantStore.contestants.find(c => c.username === username)
  }

  const actionButton1: IActionButton = {
    label: 'Next',
    isSubmit: true,
    variant: 'primary',
  }
  
  const actionButton2: IActionButton[] = [
    {
      label: 'Finish',
      isSubmit: true,
      variant: 'primary',
      loading: false,
    },
    {
      label: 'Back',
      variant: 'secondary',
      onClick: () => votingRoundStore.setCurrentStep(steps.VOTING_ROUND)
    }
  ]

  const votingRoundOptionsFields: any = [
    {
      name: 'max_no_of_votes',
      placeholder: 'Maximum Number of Votes',
      component: MInput,
      type: 'number',
    },
    {
      name: 'max_rate',
      component: RadioButton,
      label: 'Maximum votes per?',
      "aria-label": 'maximum votes per',
      options: [
        {
          label: 'Per Day',
          value: 0,
        },
        {
          label: 'Per Round',
          value: 1
        },
      ],
      horizontal: true,
    },
  ]

  if (competitionStore.isPremierSubscriptionPlan) {
    votingRoundOptionsFields.push({
      name: 'vote_cost',
      inputLabel: 'Vote cost (in tush credits)',
      placeholder: 'Enter "0" if voting is free',
      component: MInput,
      type: 'number',
      validate: validators.requiredAndNotZero,
    })
  }

  votingRoundOptionsFields.push({
    name: 'show_vote_count',
    label: 'Show Vote Count',
    component: RadioButton,
    "aria-label": 'show vote count',
    options: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No'
      }
    ],
    horizontal: true,
  },
  {
    name: 'show_live_updates',
    label: 'Show Live Updates',
    "aria-label": 'show live updates',
    component: RadioButton,
    options: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No'
      }
    ],
    horizontal: true,
  })

  const renderVotingRoundsSection = () => {
    return (
      <RoundSection>
        <CompetitionCategoryDropdown />
        <VotingRoundsWrapper>
          {votingRoundStore.votingRounds.map((round, i) => {
            return (
              <VotingRoundBoard
                actionButtonHandler={votingRoundStore.onClickActionButton(round)}
                actionButtonLabel={votingRoundStore.getVotingRoundActionLabel(round)}
                {...round}
                active_contestants={votingRoundStore.filterContestantsByCategory(round.id)}
                key={i}
                getContestant={getContestant} />
            )
          })}
        </VotingRoundsWrapper>
      </RoundSection>
    )
  }

  return (
    <CompetitionPageWrapper>
      <HeaderSection>
        <Text variant="large" mb={2} role="heading">
          MY VOTING ROUNDS
        </Text>
        <StyledTextWithIcon
          onClick={() => votingRoundStore.onClickAddVotingRound()}
          role="button"
          aria-label="add voting round button"
        >
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />
          <BoldText variant="regular" ml={1}>ADD VOTING ROUND</BoldText>
        </StyledTextWithIcon>
      </HeaderSection>
      {noVotingRounds ? (
        <EmptyResource
          createResourceAction={() => votingRoundStore.setShowCreateModal(true)}
          createResourceButtonText="Create Voting Rounds"
          createResourceText="You have no voting rounds, click the button below to get started." />
      ) : renderVotingRoundsSection()}
      <Modal
        onClose={() => votingRoundStore.setShowCreateModal(false)}
        showModal={votingRoundStore.showCreateModal}
        closeWhenOverlayIsClicked={false}
      >
        <ModalWrapper>
          <Text mb={2} variant="medium">Voting Round Options</Text>
          {votingRoundStore.currentStep === steps.VOTING_ROUND ? (
            <SimpleForm
              initialValues={votingRoundStore.getForm1InitialValues()}
              role="form"
              aria-label="voting options 1"
              actionButtons={actionButton1}
              formFields={votingRoundInfoFields}
              submitForm={data => votingRoundStore.onSubmitStepOne(data)} />
          ): (
            <SimpleForm
              role="form"
              aria-label="voting options 2"
              formStyle={{ maxHeight: '47rem', overflow: 'scroll' }}
              initialValues={votingRoundStore.lastVotingOptionsUsed}
              actionButtons={actionButton2}
              formFields={votingRoundOptionsFields}
              submitForm={data => votingRoundStore.createVotingRound(data)} />
          )}
        </ModalWrapper>
      </Modal>
      <Modal
        showModal={votingRoundStore.showNewEndTimeModal}
        onClose={() => votingRoundStore.setShowNewEndTimeModal(false)}>
          <Text mb={1} variant="regular" id="modal-message">Select a new end date*</Text>
          <Input
            type="datetime-local"
            onChange={e => votingRoundStore.setEndDateTime(e.target.value)}
            placeholder="Select new endtime" />
          <StyledButton
            mt={1}
            onClick={() => votingRoundStore.editVotingRound()}
            disabled={!votingRoundStore.endDateTime}>
            Update voting round
          </StyledButton>
      </Modal>
    </CompetitionPageWrapper>
  )
}

const votingRoundInfoFields = [
  {
    name: 'label',
    inputLabel: 'Voting round label',
    placeholder: 'E.g Week 1 or Round 1',
    component: MInput,
    validate: validators.required,
  },
  {
    name: 'start_date',
    placeholder: 'Start Date',
    label: 'Start Date',
    component: Input,
    type: 'datetime-local',
    validate: validators.required,
  },
  {
    name: 'end_date',
    placeholder: 'End Date',
    label: 'End Date',
    component: Input,
    type: 'datetime-local',
    validate: validators.required,
  }
]

export default observer(VotingRounds)

const VotingRoundsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${spacing.sm};
  flex-wrap: wrap;
  & > div {
    margin-bottom: ${spacing.sm};
  }
`

const RoundSection = styled.div`
  border: solid 1px ${colors.lightgrey};
  border-radius: 1rem;
  box-sizing: border-box;
  padding: ${spacing.sm};
`

const StyledButton = styled(Button)`
  margin-right: auto;
  margin-left: auto;
  display: block;
`

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTextWithIcon = styled(TextWithIcon)`
  color: ${colors.purple};
  cursor: pointer;
  transition: all ease .2s;
  height: 2rem;

  &:hover {
    opacity: .8;
    text-shadow: 1px 1px ${colors.lightgrey};
  }
`

const ModalWrapper = styled.div`
  max-height: 100%;
  overflow: scroll;
`