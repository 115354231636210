import React, { useState } from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { colors, device, spacing } from '../styles/_var'
import logo from '../assets/images/logo.png'
import PageContent from './PageContent'
import Link from './Link'
import { routes } from '../utils/constants'
import { observer } from 'mobx-react-lite'
import uiStore from '../stores/uiStore'
import authStore from '../stores/authStore'
import { NavUserInfo, TushCredits } from './NavUserInfo'
import creditStore from '../stores/creditStore'
import { Avatar } from './StyledComponents'
import { toCurrency } from '../utils/helpers'

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const actionButtons = () => {
    if (authStore.userIsLoggedIn) {
      return (
        <NavUserInfo 
          avatar={authStore.userAvatar}
          firstName={authStore.userFirstName}
          tushCredits={creditStore.creditBalance}
          toggleSidebar={uiStore.toggleSidebar} />
      )
    }

    return (
      <>
        <Link to={routes.SIGNUP} useButton>Register</Link>
        <Link to={routes.LOGIN} useButton variant="secondary">
          Log In
        </Link>
      </>
    )
  }

  const closeMenu = () => setOpenMenu(false)

  return (
    <NavbarWrapper hideShadow={openMenu} role="navigation">
      <NavbarContent>
        <RouterLink to={routes.LANDING}>
          <SiteLogo 
            src={logo} 
            alt="site-logo" 
            aria-label="site logo"
            aria-roledescription="site logo" />
        </RouterLink>
        <ActionSection>
          <HelpLinks>
            <Link to={routes.PRICING} boldLink>Pricing</Link>
            <Link to={routes.CONTACT_US} boldLink>Contact Us</Link>
          </HelpLinks>
          {actionButtons()}
        </ActionSection>
        <IconAndAvater>
          {authStore.userIsLoggedIn && (
            <>
              <Avatar className='user-avatar' src={authStore.userAvatar} width={35} borderColor={colors.lightgrey} />
              {creditStore.creditBalance !== -1 && (
                <TushCredits variant="small" aria-label="user credits amount">
                  ${toCurrency(creditStore.creditBalance , false, false)}
                </TushCredits>
              )}
            </>
          )}
          <HamburgerIcon 
            icon={openMenu ? faTimes : faBars} 
            color={colors.purple} size="3x"
            onClick={() => setOpenMenu(!openMenu)} />
        </IconAndAvater>
      </NavbarContent>
      <DropdownMenu showMenu={openMenu} onClick={closeMenu}>
        <Link to={routes.PRICING} boldLink>Pricing</Link>
        <Link to={routes.CONTACT_US} boldLink>Contact Us</Link>
        {authStore.userIsLoggedIn ? (
          <>
            <Link to={routes.DASHBOARD} boldLink>Dashboard</Link>
            <Link to={routes.LOGOUT} boldLink>Logout</Link>
          </>
        ) : actionButtons()}
      </DropdownMenu>
      {openMenu && <Overlay onClick={closeMenu} />}
    </NavbarWrapper>
  )
}


export default observer(Navbar)

export const NavbarWrapper = styled(PageContent)<{hideShadow: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${colors.white};
  box-sizing: border-box;
  z-index: 2;
  ${({ hideShadow }) => !hideShadow && `
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  `}

  @media ${device.tablet} {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  }
`

const NavbarContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  position: relative;
  z-index: 2;
  background-color: ${colors.white};
`

const SiteLogo = styled.img`
  height: 4rem;
`

const HamburgerIcon = styled(FontAwesomeIcon)`
  display: block;
  margin-left: ${spacing.xxs};

  @media ${device.tablet} {
    display: none;
  }
`

const DropdownMenu = styled.div<{showMenu: boolean}>`
  transition: all ease .2s;
  flex-direction: column;
  align-items: center;
  transform: translateY(-100%);
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  background-color: ${colors.white};
  left: 0;

  a {
    width: 20rem;
    margin-bottom: ${spacing.xxs};
  }

  ${({ showMenu }) => showMenu && `
    transform: translateY(0%);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  `}

  @media ${device.tablet} {
    display: none;
    box-shadow: none;
  }

  a:first-child, a:nth-child(2), a:nth-child(3), a:nth-child(4) {
    text-align: center;
  }
`

const IconAndAvater = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 3.4rem !important;
  }

  p {
    margin-left: ${spacing.xxs};
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const ActionSection = styled.div`
  display: none;

  & > a {
    margin-right: ${spacing.xs}
  }

  & > a:last-child {
    margin-right: 0;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`

export const HelpLinks = styled.div`
  display: inline-block;
  margin-right: ${spacing.rg};

  & > a {
    margin-right: ${spacing.xs}
  }

  & > a:last-child {
    margin-right: 0;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

