import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Link from '../components/Link'
import SimpleForm from '../components/SimpleForm'
import { AuthenticationView, ComponentView, ErrorMessage } from '../components/StyledComponents'
import Text from '../components/Text'
import usePageTitle from '../hooks/usePageTitle'
import authStore from '../stores/authStore'
import { spacing, colors } from '../styles/_var'
import { forgotPasswordFields, routes } from '../utils/constants'




interface IForgotPassword {
  className?: string
}

const ForgotPassword: FunctionComponent<IForgotPassword> = ({ className='' }) => {
  const onSubmitPasswordResetForm = (payload: any) => {
    authStore.sendPasswordResetEmail(payload.email)
  }

  usePageTitle('Forgot Password')

  const renderSuccessMessage = () => {
    return (
      <SuccessComponent>
        <FontAwesomeIcon icon={faCheckCircle} size="6x" />
        <Text variant="large" mt={4} mb={1}>You've got mail</Text>
        <Text variant="regular" mb={4}>
          Please check your email address, we have sent you a link to to reset your password.
        </Text>
        <Link useButton variant="secondary" to={routes.LANDING}>Close</Link>
      </SuccessComponent>
    )
  }

  const renderPasswordResetForm = () => {
    return (
      <>
        <TitleText variant="medium" role="heading">Forgot Your Password?</TitleText>
        <SimpleForm 
          formFields={forgotPasswordFields}
          submitForm={onSubmitPasswordResetForm}
          actionButtons={{
            label: 'Reset Password',
            loading: authStore.sendingPasswordResetEmail,
            invertOnHover: true,
            isSubmit: true
          }}
        />
      </>
    )
  }

  return (
    <AuthenticationView>
      <ComponentView className={className}>
        {authStore.sentPasswordResetEmail && renderSuccessMessage()}
        {!authStore.sentPasswordResetEmail && renderPasswordResetForm()}
        <ErrorMessage>{authStore.sendPasswordResetErrMessage}</ErrorMessage>
      </ComponentView>
    </AuthenticationView>
  )
}

export default observer(ForgotPassword)

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xs};
`

const SuccessComponent = styled.div`
  text-align: left;
  color: ${colors.purple};
  p {
    color: ${colors.black};
  }
  & > p:nth-child(3) {
    line-height: 2.8rem;
  }
`