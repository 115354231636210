import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text, { BoldText } from '../../../components/Text'
import { SpendSummary, SummaryItem, VotingCreditsAction, CreditSpendSummaryWrapper } from '../../VoterDashboard/components/VoterDashboardStyled'
import { beautifyDate, toCurrency } from '../../../utils/helpers'
import { colors, device, spacing } from '../../../styles/_var'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { IPayoutSummary } from '../../../utils/interfaces'

interface IPayoutSummaryProps extends IPayoutSummary {
  className?: string,
}


const PayoutSummary: FunctionComponent<IPayoutSummaryProps> = ({
  className='',
  total_payouts=0,
  last_month_payout=0,
  total_amount_generated=0,
  pending_payout=0,
  payout_due='',
}) => {
  return (
    <CreditSpendSummaryWrapper className={className}>
      <SpendSummary>
        <SummaryItem>
          <Text variant="subtext">PENDING PAYOUTS</Text>
          <BoldText variant="large">{toCurrency(pending_payout)}</BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">THIS MONTH'S PAYOUT</Text>
          <BoldText variant="large">{toCurrency(last_month_payout)}</BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">TOTAL PAYOUT</Text>
          <BoldText variant="large">{toCurrency(total_payouts)}</BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">TOTAL AMOUNT GENERATED</Text>
          <BoldText variant="large">{toCurrency(total_amount_generated)}</BoldText>
        </SummaryItem>
      </SpendSummary>
      <PayoutDue>
        <PayoutTitle variant="medium">
          <FontAwesomeIcon icon={faClock} />
          Payout Due
        </PayoutTitle>
        <Text variant="regular">{payout_due ? beautifyDate(payout_due) : 'Nil'}</Text>
      </PayoutDue>
    </CreditSpendSummaryWrapper>
  )
}

export default PayoutSummary

const PayoutTitle = styled(BoldText)`
  color: ${colors.darkpurple};
  svg {
    margin-right: 4px;
  }
`

const PayoutDue = styled(VotingCreditsAction)`
  display: block;
  text-align: center;
  padding: ${spacing.xxs} ${spacing.xs};
  min-width: 220px;
  & > div:first-child {
    border-bottom: none;
  }

  @media ${device.tablet} {
    width: unset;
    max-width: unset;
    flex-direction: row;

    & > div:first-child {
      border-right: none;
      border-bottom: none;
    }
  }
`