import React, { FunctionComponent } from 'react'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { ICompetition } from '../../../utils/interfaces'
import { colors } from '../../../styles/_var'
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg'
import competitionPlaceholder from '../../../assets/images/competition-placeholder.png'
import Text, { BoldText } from '../../../components/Text'
import Link from '../../../components/Link'
import { getSliderSettings, routes } from '../../../utils/constants'
import { 
  NewCompetitionSection,
  TitleSection,
  TextWithIcon,
  FooterSection,
  MoreCompetition,
  Slide,
  Label,
  SlideName,
} from './VoterDashboardStyled'

interface INewCompetitions {
  competitions: ICompetition[]
}

const MoreCompetitions = (props: {hideInMobile?: boolean}) => (
  <MoreCompetition color={colors.purple} hideInMobile={props.hideInMobile}>
    <FontAwesomeIcon icon={faArrowUp} size="2x" />
    <Link to={routes.COMPETITIONS}>
      <BoldText variant="regular">BROWSE COMPETITIONS</BoldText>
    </Link>
  </MoreCompetition>
)

const NewCompetitions: FunctionComponent<INewCompetitions> = ({
  competitions
}) => {
  const settings = getSliderSettings(competitions.length)

  const renderCompetition = (competition: ICompetition, index: number) => {
    return (
      <Link key={index} to={`/${competition.username}`}>
        <Slide img={competition.logo || competitionPlaceholder}>
          <Label variant="small">New</Label>
          <SlideName variant="subtext" truncate>{competition.name}</SlideName>
        </Slide>
      </Link>
    )
  }

  if (!competitions.length) return null

  return (
    <NewCompetitionSection role="region" aria-label="new competitions">
      <TitleSection>
        <TextWithIcon>
          <RefreshIcon />
          <Text variant="medium">NEW COMPETITIONS</Text>
        </TextWithIcon>
        <MoreCompetitions hideInMobile />
      </TitleSection>
      <Slider {...settings} autoplay autoplaySpeed={3000}>
        {competitions.map(renderCompetition)}
      </Slider>
      <FooterSection>
        <MoreCompetitions />
      </FooterSection>
    </NewCompetitionSection>
  )
}

export default NewCompetitions
