import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Box from '@material-ui/core/Box'
import { ContestantWithBio } from '../../utils/interfaces'
import Text from '../../components/Text'
import AllContestants from '../CompetitionPage/AllContestants'
import { AddContestantButton } from './CompetitionDashboard'
import CompetitionCategoryDropdown from '../../components/CompetitionCategoryDropdown'


interface ICompetitionContestants {
  competitionTemplate: number | undefined,
  deleteContestant: (contestantUsername: string) => void,
  evictContestant: (contestantUsername: string) => void,
  onClickContestantButton: () => void,
  saveContestant: (payload: ContestantWithBio) => Promise<void>,
  contestantLabel: string,
  savingContestant: boolean,
  contestants: ContestantWithBio[],
}

const CompetitionContestants: FunctionComponent<ICompetitionContestants> = ({
  saveContestant,
  evictContestant,
  contestantLabel,
  contestants,
  savingContestant,
  deleteContestant,
  competitionTemplate,
  onClickContestantButton,
}) => {
  return (
    <>
      <Box
        flexDirection='row'
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <CompetitionCategoryDropdown />
        <AddContestantButton
          variant="secondary"
          onClick={onClickContestantButton}>
          <FontAwesomeIcon icon={faPlus} />
          <Text variant="regular">Add New {contestantLabel}</Text>
        </AddContestantButton>
      </Box>
      <AllContestants
        competitionTemplate={competitionTemplate}
        deleteContestant={deleteContestant}
        evictContestant={evictContestant}
        saveContestant={saveContestant}
        savingContestant={savingContestant}
        contestants={contestants}
        variant="edit" />
    </>
  )
}

export default CompetitionContestants