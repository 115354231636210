import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import ContestantThumbnail from '../../components/ContestantThumbnail';
import EditContestant from '../../components/EditContestant';
import Text from '../../components/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, device, spacing } from '../../styles/_var';
import { ContestantVariant, ContestantWithBio } from '../../utils/interfaces';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { getCompetitionLabels } from '../../utils/helpers';


interface IAllContestants {
  votingClosed?: boolean,
  onVote?: (contestantUsername: string) => () => void,
  contestants: ContestantWithBio[],
  variant: ContestantVariant,
  deleteContestant?: (contestantUsername: string) => void,
  saveContestant?: (payload: ContestantWithBio) => Promise<void>,
  savingContestant?: boolean,
  evictContestant?: (contestantUsername: string) => void,
  competitionTemplate?: number,
  showVoteCount?: boolean
}

const AllContestants: FunctionComponent<IAllContestants> = React.memo(({
  contestants=[],
  variant,
  saveContestant,
  savingContestant,
  evictContestant,
  deleteContestant,
  onVote,
  votingClosed,
  competitionTemplate,
  showVoteCount
}) => {
  const contestantLabel = getCompetitionLabels(competitionTemplate).contentstantLabel
  const [contestantToEdit, setContestantToEdit] = useState<any>(undefined)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [contestantAvatar, setContestantAvatar] = useState('')

  const onEditContestant = (contestant: ContestantWithBio) => () => {
    setContestantToEdit(contestant)
    setEditModalOpen(true)
  }

  const onEvictContestant = (username: string) => () => {
    evictContestant && evictContestant(username)
  }

  const onDeleteContestant = (username: string) => () => {
    deleteContestant && deleteContestant(username)
  }

  const onSaveContestant = (contestant: ContestantWithBio, onComplete?: () => void) => {
    contestant.avatar = contestantAvatar

    if (saveContestant) {
      saveContestant(contestant).then(() => {
        setEditModalOpen(false)
        setContestantAvatar('')
        if (onComplete) onComplete()
      })
    }
  }

  if (!contestants.length) {
    return (
      <ErrorText variant="regular">
        <FontAwesomeIcon icon={faFrown} /> You have not added any {(contestantLabel + 's').toLowerCase()}.
      </ErrorText>
    )
  }

  return (
    <AllContestantWrapper>
      {contestants.map((contestant, index) => (
        <ContestantThumbnail
          hideVoteButton={votingClosed}
          onEvict={onEvictContestant(contestant.username)}
          onUpdate={onEditContestant(contestant)}
          onDelete={onDeleteContestant(contestant.username)}
          onVote={onVote && onVote(contestant.username)}
          key={index}
          showVoteCount={showVoteCount}
          contestantLabel={contestantLabel}
          {...contestant}
          variant={variant} />
      ))}
      <EditContestant
        contestantToEdit={contestantToEdit}
        showModal={editModalOpen}
        setAvatar={avatar => setContestantAvatar(avatar)}
        closeModal={() => setEditModalOpen(false)}
        saving={savingContestant}
        onSubmit={onSaveContestant} />
    </AllContestantWrapper>
  );
})

export default AllContestants

export const AllContestantWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing.xxs};
  grid-row-gap: ${spacing.xs};

  @media ${device.tablet} {
    grid-column-gap: ${spacing.rg};
    grid-row-gap: ${spacing.xs};
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.laptopL} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ErrorText = styled(Text)`
  color: ${colors.red};
  text-align: center;
`