import React from "react";
import styled from "styled-components";
import { BoldText } from "../../components/Text";
import { spacing, colors } from "../../styles/_var";

interface IDemo {
  text: string;
  url: string;
}

const Demo: React.FC<IDemo> = ({ text, url }) => {
  return (
    <DemoWrapper>
      <iframe
        title={text}
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <BoldText variant="medium" color={colors.darkpurple}>
        {text}
      </BoldText>
    </DemoWrapper>
  );
};

export default Demo;

const DemoWrapper = styled.div`
  text-align: center;

  iframe {
    margin-bottom: ${spacing.xxs};
    width: 100%;
    height: 300px;
    background: ${colors.black};
  }
`;
