import React from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { colors, fontSize } from '../styles/_var';
import { ComponentSpacing } from '../utils/interfaces';
import { getStyleSpacing } from '../utils/helpers';

export type ButtonVariant = 'primary' | 'secondary' | 'danger'
export type ElementType = 'a' | 'button'

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ComponentSpacing {
  variant?: ButtonVariant;
  invertOnHover?: boolean;
  loading?: boolean;
  block?: boolean;
  alignCenter?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement | null, IButtonProps>(({
  variant,
  invertOnHover,
  loading,
  block,
  children,
  alignCenter,
  mb,
  mr,
  ml,
  mt,
  style={},
  ...props
}, ref) => {
  const spacingProperties = { mb, mr, ml, mt }
  const spacingStyle = getStyleSpacing(spacingProperties)
  const buttonStyle = {...spacingStyle, ...style}

  return (
    <StyledButton
      ref={ref}
      role="button"
      variant={variant}
      invertOnHover={invertOnHover}
      block={block}
      style={buttonStyle}
      alignCenter={alignCenter}
      {...props}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin size="1x" /> : children}
    </StyledButton>
  )
})

export const getStyle = (variant: ButtonVariant, invertOnHover: boolean, block: boolean=false) => {
  let style = `
    min-width: 11rem;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: solid 1px ${colors.purple};
    font-family: Gilroy-bold;
    font-size: ${fontSize.xs};
    box-sizing: border-box;
    transition: all ease .2s;
    text-align: center;
    text-decoration: none;
  `

  if (block) {
    style += 'width: 100%;'
  }

  if (variant === 'primary') {
    style += `
      background: ${colors.purple};
      color: ${colors.white};
      &:hover {
        background: ${colors.lightpurple};
        border-color: ${colors.lightpurple};
      }
    `

    if (invertOnHover) {
      style += `
        &:hover {
          background: ${colors.white};
          color: ${colors.purple};
        }
      `
    }
  } else if (variant === 'danger') {
    style += `
      background: ${colors.red};
      color: ${colors.white};
      border-color: ${colors.red};
      &:hover {
        opacity: 0.7;
      }
    `
  } else {
    style += `
      background: ${colors.white};
      color: ${colors.purple};
      &:hover {
        border-color: ${colors.lightpurple};
        color: ${colors.lightpurple};
      }
    `
    if (invertOnHover) {
      style = `
        &:hover {
          background: ${colors.purple};
          color: ${colors.white};
        } 
      `
    }
  }

  return style;
}

const StyledButton = styled.button<IButtonProps>`
  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
  ${props => getStyle(props.variant || 'primary', props.invertOnHover || false, props.block || false)}
  ${props => props.alignCenter && `
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  `}
`


Button.defaultProps = {
  variant: 'primary',
  invertOnHover: false,
  loading: false,
}

export default Button
