import React, { FunctionComponent } from 'react'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Text from '../../components/Text'

interface IVoteModal {
  closeModal: () => void,
  canShowVoteCount: boolean,
  error: string,
  voteCount: number,
  onSubmitForm: () => void,
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const VoteModal: FunctionComponent<IVoteModal> = ({
  closeModal,
  canShowVoteCount,
  error,
  voteCount,
  onSubmitForm,
  onInputChange
}) => {
  return (
    <Modal showModal={canShowVoteCount} onClose={closeModal}>
      <Text variant="medium" id="modal-message">Vote Now!</Text>
      <Input
        errorMessage={error}
        mt={1}
        value={voteCount}
        onChange={onInputChange}
        type="number" />
      <Button
        mt={1}
        invertOnHover
        alignCenter
        onClick={onSubmitForm}>
        Register vote
      </Button>
    </Modal>
  )
}

export default VoteModal