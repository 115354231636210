import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import { colors, device, spacing } from '../../../styles/_var'
import { capitalize, formatToUsername, required } from '../../../utils/helpers'
import SimpleForm, { IActionButton } from '../../../components/SimpleForm'
import UploadPane from '../../../components/UploadPane'
import MInput from '../../../components/MInput'
import RadioButton from '../../../components/RadioButton'
import { ICompetition } from '../../../utils/interfaces'
import competitionStore from '../../../stores/competitionStore'
import { observer } from 'mobx-react-lite'
import { InputStyle } from '../../../components/StyledComponents'

interface ICompetitionDetails {
  loading?: boolean,
  createCompetition: (payload: ICompetition) => void,
  initialValues?: any,
}

let logoImage = ''
let bannerImage = ''

const CompetitionDetails: FunctionComponent<ICompetitionDetails> = React.memo(({
  loading,
  createCompetition,
  initialValues={},
}) => {
  const { banner, logo, ...formValues } = initialValues

  useEffect(() => {
    logoImage = logo || ''
    bannerImage = banner || ''
  }, [logo, banner])

  const competitionDetailsFormAction: IActionButton = {
    label: 'Save',
    variant: 'primary',
    isSubmit: true,
    loading: loading,
  }

  const submitCompetitionDetails = (data: any) => {
    const payload = {...data, logo: logoImage, banner: bannerImage}
    createCompetition(payload)
  }

  // removes warning
  if (!formValues.username) {
    formValues.username = ''
  }

  return (
    <>
      <UploadBannerPane
        maxWidth={900}
        aspectRatio={3/1}
        titleText="Upload Banner Image"
        initialImage={banner}
        onImageChange={image => bannerImage = image} />
      <BodySection>
        <UploadLogoPane
          aspectRatio={1}
          onImageChange={image => logoImage = image}
          titleText="Upload Logo Image"
          initialImage={logo}
          dimensionsText="Best dimensions for logo image is 200px by 200px" />
        <StyledSimpleForm
          role="form"
          aria-label="competition details form"
          initialValues={formValues}
          formFields={competitionDetailsFormFields}
          actionButtons={competitionDetailsFormAction}
          submitForm={submitCompetitionDetails} />
      </BodySection>
    </>
  )
})

export default CompetitionDetails

const CategoriesDropdown = observer(() => {
  return (
    <>
      <option>-- Please select a category ---</option>
      {competitionStore.categories.map((category) => (
        <option value={category.id} key={category.id}>
          {capitalize(category.name)}
        </option>
      ))}
    </>
  )
})

const competitionDetailsFormFields = [
  {
    name: 'name',
    placeholder: 'Competition Name',
    component: MInput,
    validate: required
  },
  {
    name: 'username',
    placeholder: 'Competiton Username (No spaces)',
    format: formatToUsername,
    component: MInput,
    validate: required
  },
  {
    name: 'description',
    placeholder: 'Competition Description',
    component: MInput,
    validate: required
  },
  {
    name: 'category',
    label: 'Category',
    children: <CategoriesDropdown />,
    selected: 'Public',
    component: 'select',
    validate: required,
  },
  {
    name: 'visibility',
    label: 'Visibility',
    options: ['Public', 'Private'],
    component: RadioButton,
    horizontal: true,
    validate: required,
  }
]


const BodySection = styled.div`
  display: flex;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.xs};
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: unset;
  }
`

const StyledSimpleForm = styled(SimpleForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: ${spacing.sm};

  @media ${device.laptop} {
    width: unset;
    margin-top: unset;
  }

  select {${InputStyle}}
`

const UploadBannerPane = styled(UploadPane)`
  min-height: 22rem;
  flex-direction: row;
`

const UploadLogoPane = styled(UploadPane)`
  width: 25rem;
  height: 25rem;
  box-sizing: border-box;
  margin-right: ${spacing.sm};
  padding: ${spacing.xs};
  flex-direction: column;
  position: relative;
  margin-top: -50px;
  background-color: ${colors.white};

  @media ${device.laptop} {
    margin-top: unset;
    background-color: unset;
  }
`