import React, { FunctionComponent } from 'react'
import Text, { BoldText } from '../../../components/Text'
import { ReactComponent as Arrows } from '../../../assets/images/arrows.svg'
import { ReactComponent as Coins } from '../../../assets/images/coins2.svg'
import { SpendSummary, SummaryItem, VotingCreditsAction, ActionButtonWrapper, CreditSpendSummaryWrapper } from './VoterDashboardStyled'
import { toCurrency } from '../../../utils/helpers'
import { ISpendSummary } from '../../../stores/creditStore'

interface ICreditSpendSummaryProps extends ISpendSummary, React.HtmlHTMLAttributes<HTMLDivElement> {
  onClickTransfer: () => void,
  onClickPurchase: () => void,
  voting_credit?: number,
}


const ActionButton = (props: { Icon: any, title: string, onClick: () => void }) => {
  const { Icon, title, onClick } = props

  return (
    <ActionButtonWrapper
      onClick={onClick}
      role="button"
      aria-label={title.toLowerCase() + ' button'}>
      <Icon />
      <Text variant="regular">{title}</Text>
    </ActionButtonWrapper>
  )
}

const CreditSpendSummary: FunctionComponent<ICreditSpendSummaryProps> = ({
  onClickTransfer,
  onClickPurchase,
  voting_credit=0,
  received_credits=0,
  this_months_spend=0,
  transferred_credits=0,
  ...rest
}) => {
  return (
    <CreditSpendSummaryWrapper {...rest} role="widget">
      <SpendSummary>
        <SummaryItem>
          <Text variant="subtext">MY VOTING CREDITS</Text>
          <BoldText variant="large" aria-label="voting-credit">
            {toCurrency(voting_credit)}
          </BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">THIS MONTH'S SPEND</Text>
          <BoldText variant="large" aria-label="month-spend">
            {toCurrency(this_months_spend)}
          </BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">RECEIVED CREDITS</Text>
          <BoldText variant="large" aria-label="received-credit">
            {toCurrency(received_credits)}
          </BoldText>
        </SummaryItem>
        <SummaryItem>
          <Text variant="subtext">TRANSFERRED CREDITS</Text>
          <BoldText variant="large" aria-label="transferred-credit">
            {toCurrency(transferred_credits)}
          </BoldText>
        </SummaryItem>
      </SpendSummary>
      <VotingCreditsAction>
        <ActionButton
          Icon={Coins}
          title="PURCHASE CREDIT"
          onClick={onClickPurchase} />
        <ActionButton
          Icon={Arrows}
          title="TRANSFER CREDIT"
          onClick={onClickTransfer} />
      </VotingCreditsAction>
    </CreditSpendSummaryWrapper>
  )
}

export default CreditSpendSummary