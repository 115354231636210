import isEmpty from 'lodash/isEmpty'
import { makeAutoObservable } from 'mobx'
import { MessageType } from '../utils/interfaces'

type ToastPosition = 'top' | 'bottom' | 'left-bottom' | 'right-bottom' | 'left-top' | 'right-top'

export interface IModal {
  title?: string,
  message?: string,
  variant?: MessageType
}

export interface IToastMessage {
  message: string,
  type?: MessageType,
  position?: ToastPosition,
  duration?: number,
}

export const TOAST_DEFAULTS: IToastMessage = {
  message: '',
  type: 'success',
  position: 'top',
  duration: 5000
}

class UiStore {
  displaySidebar = false
  voterNavTitle = 'Home'
  loadingScreenMessage = ''
  confirmMessage = ''
  confirmAction: any = () => {}
  denyAction: any = () => {}
  modalPayload: IModal = {}
  toastMessageData: IToastMessage = {...TOAST_DEFAULTS}

  constructor() {
    makeAutoObservable(this)
  }

  toggleSidebar() {
    this.displaySidebar = !this.displaySidebar
  }

  setNavTitle(title: string) {
    this.voterNavTitle = title
  }

  setDisplaySidebar(status: boolean) {
    this.displaySidebar = status
  }

  setLoadingScreenMessage(message: string) {
    this.loadingScreenMessage = message
  }

  displayModal(modalPayload: IModal) {
    this.modalPayload = modalPayload
  }

  successModal(message: string) {
    this.displayModal({ variant: 'success', message })
  }

  errorModal(message: string) {
    this.displayModal({ variant: 'error', message })
  }

  closeModal() {
    this.modalPayload = {}
  }

  openConfirmDialog(confirmMessage: string, confirmAction: any, denyAction?: any) {
    this.confirmMessage = confirmMessage
    this.confirmAction = confirmAction
    this.denyAction = denyAction
  }

  closeConfirmDialog() {
    this.confirmMessage = ''
    this.confirmAction = () => {}
    this.denyAction = () => {}
  }

  showToastMessage(messageData: IToastMessage) {
    this.toastMessageData = {...this.toastMessageData, ...messageData}
  }

  get sidebarCollapsed() {
    return !this.displaySidebar
  }

  get displayLoadingScreen() {
    return !!this.loadingScreenMessage
  }

  get showModal() {
    return !isEmpty(this.modalPayload)
  }

  get showConfirmDialog() {
    return !!this.confirmMessage
  }

  get toastMessage() {
    return this.toastMessageData.message
  }

  get toastType() {
    return this.toastMessageData.type
  }

  get toastPosition() {
    return this.toastMessageData.position
  }

  get toastDuration() {
    return this.toastMessageData.duration
  }
}

export default new UiStore()
