import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import ActivityProgressBar from '../../../components/ActivityProgressBar'
import Breadcrumbs, { ICrumb } from '../../../components/BreadCrumbs'
import Button from '../../../components/Button'
import { BoldText } from '../../../components/Text'
import competitionStore from '../../../stores/competitionStore'
import uiStore from '../../../stores/uiStore'
import { colors, device, spacing } from '../../../styles/_var'
import { routes, subscriptionOptions } from '../../../utils/constants'
import CompetitionDetails from './CompetitionDetails'
import ContestantDetails from './ContestantDetails'
import Summary from './Summary'
import { ContestantWithBio, IPayoutOptions } from '../../../utils/interfaces'
import PayoutOptions from './PayoutOptions'
import seasonStore from '../../../stores/seasonStore'
import contestantStore from '../../../stores/contestantStore'
import SeasonTypeSelector from '../../../components/SeasonTypeSelector'
import { disabledNextResolver, disabledPreviousResolver, stepTitles } from './utils'
import paymentStore from '../../../stores/paymentStore'
import authStore from '../../../stores/authStore'
import { getPaystackReference } from '../../../utils/helpers'
import usePageTitle from '../../../hooks/usePageTitle'


const CreateCompetition = () => {
  const { currentStep, stepTitle } = competitionStore
  const max_no_steps = Object.keys(competitionStore.steps).length - 1  
  const steps = competitionStore.steps
  usePageTitle('Create Competition')

  useEffect(() => {
    uiStore.setNavTitle('Create Competition')
    competitionStore.loadExistingCompetition()
  }, [])

  useEffect(() => {
    competitionStore.setStepTitle(stepTitles[currentStep])
  }, [currentStep])

  const proceedToNextStep = () => {
    competitionStore.setCurrentStep(currentStep + 1)
  }

  const saveContestant = (payload: ContestantWithBio, onComplete?: () => void) => {
    contestantStore.saveContestant(payload).then(() => {
      if (onComplete) onComplete()
    })
  }

  const savePayouts = (payload: IPayoutOptions) => {
    const competitionUsername = competitionStore.competitionDetails.username
    competitionStore.savePayoutOptions(competitionUsername, payload)
  }

  const onSubscriptionTypeSelected = (plan: number) => {
    const competitionUsername = competitionStore.competitionDetails.username
    const subscriptionCost = subscriptionOptions[plan].PRICE
    const latestSeason: any = (competitionStore.competitionDetails.seasons || [])[0]

    if (!subscriptionCost) {
      competitionStore.setSubscriptionPlan({ competitionUsername, subscriptionPlan: plan })
      return
    }
    
    uiStore.openConfirmDialog('Are you sure you want to select this plan? It can\'t be undone', () => {
      paymentStore.startPayment({
        email: authStore.userEmail,
        reference: getPaystackReference(authStore.username),
        amount: subscriptionCost,
        onSuccess: (data) => {
          const { status, reference } = data
          if (status === 'success') {
            competitionStore.setSubscriptionPlan({
              competitionUsername,
              subscriptionPlan: plan,
              amount: subscriptionCost,
              seasonId: latestSeason?.uuid,
              reference,
            })
          }
        }
      })
    })
  }

  const totalSteps = Object.keys(steps).length - 1
  const hidePrevButton = currentStep === 0
  const hideNextButton = currentStep >= max_no_steps

  return (
    <Wrapper>
      <PageBreadCrumbs crumbs={breadcrumbdata} />
      <PageTitle variant="medium">{stepTitle}</PageTitle>
      <PageContent>
        <ActivityProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        <ContentArea>
          {(() => {
            switch (currentStep) {
              case steps.COMPETITION_DETAILS:
                return (
                  <CompetitionDetails
                    initialValues={competitionStore.competitionDetails}
                    createCompetition={data => competitionStore.createCompetition(data)}
                    loading={competitionStore.createCompetitionLoading}
                  />
                )
              case steps.CONTESTANT_DETAILS:
                return (
                  <ContestantDetails
                    seasonId={seasonStore.selectedSeasonId}
                    saveContestant={saveContestant}
                    savingContestant={contestantStore.savingContestant}
                    contestants={contestantStore.contestants} />
                )
              case steps.SEASON_TYPE_CHOOSER:
                return (
                  <SeasonTypeSelector
                    onChange={onSubscriptionTypeSelected}
                    subscriptionPlan={competitionStore.subscriptionPlan}
                    disabled={competitionStore.competitionDetails.paid}
                    overlayStamp={competitionStore.competitionDetails.paid ? 'PAID' : ''}
                    controlled
                  />
                )
              case steps.PAYMENT_OPTIONS:
                return (
                  <PayoutOptions
                    initialValues={competitionStore.payoutOptions}
                    submit={savePayouts}
                    saving={competitionStore.savingPayoutOptions}/>
                )
              case steps.SUMMARY:
                return (
                  <Summary
                    payoutOptions={competitionStore.payoutOptions}
                    contestants={contestantStore.contestants}
                    competitionDetails={competitionStore.competitionDetails}
                    resetCompetitionFields={() => competitionStore.resetCompetitionFields()} />
                )
              default:
                return null
            }
          })()}
        </ContentArea>
        <ButtonWrapper>
          <Button
            style={{ opacity: hidePrevButton ? 0 : undefined}}
            variant="secondary"
            disabled={disabledPreviousResolver(currentStep)}
            onClick={() => competitionStore.setCurrentStep(currentStep - 1)}
          >
            &lt;&lt; Prev
          </Button>
          <Button
            style={{ opacity: hideNextButton ? 0 : undefined}}
            variant="secondary" 
            disabled={disabledNextResolver(currentStep)}
            onClick={proceedToNextStep}
          >
            Next &gt;&gt;
          </Button>
        </ButtonWrapper>
      </PageContent>
    </Wrapper>
  )
}

export default observer(CreateCompetition)

const ContentArea = styled.div`
  box-sizing: border-box;
  padding: 0 ${spacing.sm};
  max-width: 100rem;
  margin: auto;
`

const PageBreadCrumbs = styled(Breadcrumbs)`
  position: relative;
  top: -2rem;
`

const Wrapper = styled.div`
  margin: ${spacing.xxs} ${spacing.sm};
  position: relative;
`

const breadcrumbdata: ICrumb[]  = [
  { name: 'My Competitions', route: routes.MY_COMPETITIONS },
  { name: 'Create Competition' }
]

const PageTitle = styled(BoldText)`
  margin-bottom: ${spacing.xs};
  text-transform: uppercase;

  @media ${device.tablet} {
    margin-bottom: ${spacing.sm};
  }
`

const PageContent = styled.div`
  border: 0.5px solid ${colors.lightgrey};
  border-radius: 1rem;
  padding: ${spacing.xs};

  & > div:first-child {
    margin-bottom: ${spacing.sm};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.sm};
`