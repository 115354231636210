import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import authStore from '../stores/authStore'
import { routes } from '../utils/constants'


const useRenderOnAuthenticated = () => {
  const history = useHistory()

  useEffect(() => {
    if (!authStore.userIsLoggedIn) history.push(routes.LOGIN)
  }, [history])

  return authStore.userIsLoggedIn
}

export default useRenderOnAuthenticated
