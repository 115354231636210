import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { BannerWrapper } from '../../components/LandingPageBanner'
import Text, { BoldText } from '../../components/Text'
import { spacing } from '../../styles/_var'
import Link from '../../components/Link'
import { routes } from '../../utils/constants'

const PricingBanner = () => {
  return (
    <PricingBannerWrapper>
      <BoldText variant="title">
        Explore the right plan for your business
      </BoldText>
      <Text variant="medium" mt={1} lighten>
        Get started for free, upgrade later in our application
      </Text>
      <GetStartedButton mt ={2} invertOnHover to={routes.LOGIN} useButton>
        <Text variant="regular">Start for free</Text>
      </GetStartedButton>
      <Box mt={3}>
        <Text variant="medium" inline>For custom plans,</Text>
        {" "}
        <Link to={routes.CONTACT_US}>
          <Text variant="medium" inline>Contact Us.</Text>
        </Link>
      </Box>
    </PricingBannerWrapper>
    
  )
}

export default PricingBanner


const PricingBannerWrapper = styled(BannerWrapper)`
  min-height: 25rem;
`

const GetStartedButton = styled(Link)`
  padding-left: ${spacing.sm};
  padding-right: ${spacing.sm};
`
