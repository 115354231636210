import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { SectionHeader } from '../../../components/StyledComponents'
import Text from '../../../components/Text'
import { colors, spacing } from '../../../styles/_var'
import CreatedCompetitions from './CreatedCompetitions'
import PrivateCompetitions from './PrivateCompetitions'
import uiStore from '../../../stores/uiStore'
import usePageTitle from '../../../hooks/usePageTitle'

const MY_COMPETITIONS = 1
const PRIVATE_COMPETITIONS = 2

const MyCompetitions = () => {
  const [selectedTab, setSelectedTab] = useState(MY_COMPETITIONS)
  const isMyCompetitionTab = selectedTab === MY_COMPETITIONS
  usePageTitle('My Competitions')

  useEffect(() => {
    uiStore.setNavTitle('My Competitions')
  }, [])

  return (
    <Wrapper>
      <HeaderSection>
        <TabSection>
          <TabItem 
            onClick={() => setSelectedTab(MY_COMPETITIONS)}
            variant="regular"
            selected={selectedTab === MY_COMPETITIONS}
            inline 
            center
          >
            MANAGED COMPETITIONS
          </TabItem>
          <TabItem
            onClick={() => setSelectedTab(PRIVATE_COMPETITIONS)}
            selected={selectedTab === PRIVATE_COMPETITIONS}
            variant="regular"
            inline
            center
          >
            EXCLUSIVE COMPETITIONS
          </TabItem>
        </TabSection>
      </HeaderSection>
      {isMyCompetitionTab ? <CreatedCompetitions /> : <PrivateCompetitions />}
    </Wrapper>
  )
}

export default observer(MyCompetitions)


const Wrapper = styled.div`
  margin: ${spacing.xxs} ${spacing.sm};
  position: relative;
`
const HeaderSection = styled(SectionHeader)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};

  p {
    line-height: 1.5rem;
  }
`

const TabSection = styled.div``

const TabItem = styled(({ selected, ...rest}) => <Text {...rest} />)`
  width: 21rem;
  padding-bottom: 3px;
  text-transform: uppercase;
  transition: all ease .2s;
  margin-right: ${spacing.xs};
  
  &:hover {
    ${({ selected }) => !selected && `
      cursor: pointer;
      color: ${colors.grey};
      border-bottom: solid 2px ${colors.lightgrey};
      opacity: .8;
    `}
  }

  ${({ selected }) => selected && `
    color: ${colors.purple};
    border-bottom: solid 2px ${colors.purple};
  `}
`

