import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import RadioButton from '../../components/RadioButton'
import StaffItem from '../../components/StaffItem'
import SimpleForm, { IActionButton } from '../../components/SimpleForm'
import Text, { BoldText } from '../../components/Text'
import { colors, device, spacing } from '../../styles/_var'
import validators from '../../utils/validators'
import competitionStore from '../../stores/competitionStore'
import UsernameInput from '../../components/UsernameInput'
import uiStore from '../../stores/uiStore'
import { payoutFormFields } from '../VoterDashboard/CreateCompetition/PayoutOptions'
import { InputStyle } from '../../components/StyledComponents'
import Button from '../../components/Button'
import { RouteChildrenProps } from 'react-router-dom'
import { routes } from '../../utils/constants'

import usePageTitle from '../../hooks/usePageTitle'
import { Box } from '@material-ui/core'

const COMPETITION = 1
const AWARDS = 2

const CompetitionSettings: FunctionComponent<RouteChildrenProps> = ({
  history,
}) => {
  const [isUsernameValid, setUsernameValid] = useState(false)
  const competitionDetails = competitionStore.competitionDashboardDetails
  const competitionUsername = competitionDetails.username
  const payoutOptions = competitionDetails.payout_options || {}
  const isAward = competitionDetails.template === AWARDS
  const competitionLabel = isAward ? 'Awards' : 'Competitions'
  const contestantLabel = isAward ? 'Nominees' : 'Contestants'


  usePageTitle('Competition Settings')

  const onSubmitForm = (data: any) => {
    competitionStore.saveCompetitionStaff(competitionUsername, data)
  }

  const onSavePayoutOptions = (data: any) => {
    competitionStore.savePayoutOptions(competitionUsername, data)
  }

  const onRemoveStaff = (username: string) => () => {
    uiStore.openConfirmDialog('Are you sure you want to remove this user?', () => {
      competitionStore.deleteCompetitionStaff(competitionUsername, username)
    })
  }

  const onArchiveCompetition = () => {
    uiStore.openConfirmDialog('Are you sure you want to perform this action?', () => {
      competitionStore.toggleArchive()
    })
  }

  const onDeleteCompetition = () => {
    uiStore.openConfirmDialog('Are you sure? This action is irreversible.', () => {
      competitionStore.deleteCompetition(competitionUsername, () => {
        history.push(routes.MY_COMPETITIONS)
      })
    })
  }

  const actionButton: IActionButton = {
    label: 'Add Staff',
    variant: 'secondary',
    isSubmit: true,
    loading: competitionStore.savingCompetitionStaff,
    disabled: !isUsernameValid
  }

  const formFields = [
    {
      name: 'email',
      placeholder: 'Enter staff username/email',
      label: 'Enter staff username/email',
      component: UsernameInput,
      type: 'email',
      validate: validators.required,
      onValidate: (result: boolean) => setUsernameValid(result)
    },
    {
      name: 'role',
      component: RadioButton,
      label: 'Assign Role',
      options: [
        {
          label: 'Staff',
          value: '0'
        },
        {
          label: 'Admin',
          value: '1'
        }
      ],
      validate: validators.required,
      horizontal: true,
    }
  ]

  const payoutActionButton: IActionButton = {
    label: 'Update Payout Options',
    variant: 'secondary',
    isSubmit: true,
    loading: competitionStore.savingPayoutOptions,
    disabled: false
  }

  return (
    <Wrapper>
        <ContentBody>
        <BoldText variant="medium">TEMPLATE - CHOOSE TEMPLATE</BoldText>
        <SectionContainer>
          <Box>
            <RadioButton
              placeholder='Select Template'
              options={[
                {label: 'Competition', value: COMPETITION},
                {label: 'Awards', value: AWARDS}
              ]}
              onChange={value => competitionStore.setCompetitionTemplate(value)}
              value={competitionDetails.template}
              horizontal
            />
            <Note style={{marginBottom: 0}}>
              <Text variant='regular' center>
                This template uses the word <strong>"{competitionLabel}"</strong> and the votable entities are referred to as <strong>"{contestantLabel}"</strong>
              </Text>
            </Note>
          </Box>
        </SectionContainer>
      </ContentBody>

      <ContentBody>
        <BoldText variant="medium">PERMISSIONS - ADD STAFF</BoldText>
        <SectionContainer>
          <SimpleForm
              formFields={formFields}
              actionButtons={actionButton} 
              submitForm={onSubmitForm} />
          <StaffSection>
            {competitionStore.competitionStaff.map((staff, i) => (
              <StaffItem
                {...staff}
                onRemoveStaff={onRemoveStaff(staff.personnel.username)}
                key={i} />
            ))}
          </StaffSection>
        </SectionContainer>
      </ContentBody>

      <ContentBody>
        <BoldText variant="medium">PAYOUT - UPDATE ACCOUNT DETAILS</BoldText>
        <SectionContainer>
          <SimpleForm
            landscape
            initialValues={payoutOptions}
            formFields={payoutFormFields}
            actionButtons={payoutActionButton} 
            submitForm={onSavePayoutOptions} />
        </SectionContainer>
      </ContentBody>

      <ContentBody>
        <BoldText variant="medium">ACCOUNT - ARCHIVE/DELETE</BoldText>
        <AccountUpdateSection>
          <Note>
            <BoldText variant="regular">Note</BoldText>
            <Text variant="regular">
              Please note that your competition account with all it’s information, and all associated details will be permanently deleted. This action is irreversible, we recommend that you archive this competition account instead.
            </Text>
          </Note>
          <ActionSection>
            <Button variant="secondary" onClick={onArchiveCompetition}>
              {competitionDetails.archived ? 'Un-archive' : 'Archive'}
            </Button>
            <Button variant="danger" onClick={onDeleteCompetition}>
              Delete
            </Button>
          </ActionSection>
        </AccountUpdateSection>
      </ContentBody>
    </Wrapper>
  )
}


export default observer(CompetitionSettings)

const Wrapper = styled.div`
  padding: 0 ${spacing.sm};
`

const ContentBody = styled.div`
  width: 100%;
  margin: auto;
  max-width: 70rem;
  margin-bottom: ${spacing.lg};

  button {
    width: 30rem;
    max-width: 100%;
  }
`

const SectionContainer = styled.div`
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  padding: ${spacing.sm};
  margin-top: ${spacing.xs};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: row;
  }

  > div:first-child {
    flex: 1;
  }

  select {
    ${InputStyle}
  }
`

const StaffSection = styled.div`
  width: 40%;
`

const Note = styled.div`
  padding: ${spacing.xs};
  background-color: ${colors.subtlylightpurple};
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};
`

const ActionSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const AccountUpdateSection = styled(SectionContainer)`
  flex-direction: column;
`