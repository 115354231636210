import React from 'react';
import { RouteChildrenProps } from 'react-router-dom'
import Footer from '../components/Footer';

import LandingPageBanner from '../components/LandingPageBanner';
import LandingSectionFour from '../components/LandingSectionFour';
import LandingSectionOne from '../components/LandingSectionOne';
import LandingSectionThree from '../components/LandingSectionThree';
import LandingSectionTwo from '../components/LandingSectionTwo';

interface ILandingPageProps extends RouteChildrenProps {
  className?: string;
}

function LandingPage(props: ILandingPageProps) {
  return (
    <div className="landing-page" role="main">
      <LandingPageBanner />
      <LandingSectionOne />
      <LandingSectionTwo />
      <LandingSectionThree />
      <LandingSectionFour />
      <Footer />
    </div>
  );
}

export default LandingPage;

