import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosResponse } from "axios";
import { 
  voteContestantRequest,
  createContestantRequest,
  deleteContestantRequest,
  evictContestantRequest,
  updateContestantRequest,
  fetchContestantsRequest
} from '../utils/apiRequests'
import { status, CONTESTANT_AVATAR_S3_FOLDER, subscriptionOptions, DEFAULT_CATEGORY_ID } from "../utils/constants";
import { getChannelID, processError, retrieveErrorMessage, uploadImage } from '../utils/helpers'
import { Status, ContestantWithBio, ContestantResponse } from '../utils/interfaces'
import creditStore from './creditStore'
import uiStore from './uiStore'
import seasonStore from './seasonStore';
import competitionStore from './competitionStore';
import * as gen from '../utils/generated'



class ContestantStore {
  contestants: ContestantWithBio[] = []
  contestantsStatus: Status = null

  addContestantFields = []
  voteStatus: Status = null

  saveContestantStatus: Status = null
  updateContestantStatus: Status = null
  deleteContestantStatus: Status = null

  constructor() {
    makeAutoObservable(this)
  }

  async voteContestant(contestantUsername: string, voteCount: number, competitionUsername?: string) {
    if (this.votingContestant) return

    uiStore.setLoadingScreenMessage('Registering your vote(s)')
    runInAction(() => {
      this.voteStatus = status.LOADING
    })

    try {
      const response = await voteContestantRequest(contestantUsername, {
        season_id: seasonStore.selectedSeasonId,
        vote_count: voteCount,
        category_id: competitionStore.competitionPage.selectedCategory.id || undefined
      })
      creditStore.getUserCredit()
      uiStore.showToastMessage({message: response.data.message, type: 'success'})

      runInAction(() => {
        this.voteStatus = status.COMPLETE
      })

      if (competitionUsername) {
        const channelID = getChannelID(competitionUsername)
        gen.publish(channelID, JSON.stringify({ update: 'vote_submitted' }))
      }
    } catch (e) {
      runInAction(() => {
        this.voteStatus = status.FAILED
      })

      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchContestants(competitionUsername:string, seasonId: string) {
    if (this.fetchingContestants) return
    runInAction(() => this.contestantsStatus = status.LOADING)

    try {
      const contestantsResponse = await fetchContestantsRequest(competitionUsername, seasonId)
      runInAction(() => {
        this.contestants = contestantsResponse.data
        this.contestantsStatus = status.COMPLETE
      })
    } catch (e) {
      processError(e)
      runInAction(() => this.contestantsStatus = status.FAILED)
    }
  }

  async saveContestant(payload: ContestantWithBio, creatingCompetition: boolean = true, currentSeasonId?: string,) {
    if (this.savingContestant) return
    const editMode = !!payload.id

    if (!this.canAddContestant && !editMode) {
      uiStore.errorModal('You have exceeded the maximum number of contestants, please upgrade your account plan.')
      return
    }

    let previousAvatar = null
    let response: AxiosResponse<ContestantResponse>

    if (editMode) previousAvatar = this.getContestant(payload.username, creatingCompetition)?.avatar
    
    runInAction(() => {
      this.saveContestantStatus = status.LOADING
    })

    try {
      if (!editMode || (editMode && previousAvatar !== payload.avatar)) {
        const s3Url = await uploadImage({
          key: payload.username,
          imageUrl: payload.avatar,
          folderPath: CONTESTANT_AVATAR_S3_FOLDER,
          async: false,
        })

        payload.avatar = s3Url
      }

      payload.season_id = currentSeasonId || seasonStore.selectedSeasonId
      if (competitionStore.selectedCategory.id !== DEFAULT_CATEGORY_ID) {
        payload.category_id = competitionStore.selectedCategory.id
      }

      response = await (editMode ? updateContestantRequest(payload) : createContestantRequest(payload))
      const contestant = {...response.data, ...response.data.bio, bio: undefined}

      if (editMode) {
        this.updateContestant(contestant, creatingCompetition)
      } else {
        this.addContestant(contestant, creatingCompetition)
      }
      
      runInAction(() => {
        this.saveContestantStatus = status.COMPLETE
      })
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => {
        this.saveContestantStatus = status.FAILED
      })
    }
  }

  async deleteContestant(season_id: string, contestantUsername: string, creatingCompetition: boolean=true) {
    uiStore.setLoadingScreenMessage('deleting contestant')

    try {
      await deleteContestantRequest({
        season_id,
        contestant_username: contestantUsername
      })

      runInAction(() => {
        uiStore.successModal('Contestant Deleted')
      })

      this.removeContestant(contestantUsername, creatingCompetition)
      // if (creatingCompetition) this.persistState()
    } catch (e) {
      runInAction(() => {
        uiStore.errorModal(retrieveErrorMessage(e))
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async evictContestant(season_id: string, username: string) {
    uiStore.setLoadingScreenMessage('Evicting Contestant...')

    try {
      const response = await evictContestantRequest({ season_id, username })
      this.updateContestant(response.data, false)
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
    }

    uiStore.setLoadingScreenMessage('')
  }

  addContestant(contestant: ContestantWithBio, creatingCompetition: boolean = true) {
    this.contestants = this.contestants.concat(contestant)
  }

  removeContestant(contestantUsername: string, creatingCompetition: boolean) {
    this.contestants = this.contestants.filter(c => c.username !== contestantUsername)
  }

  updateContestant(updatedContestant: ContestantWithBio, creatingCompetition: boolean = true) {
    const updatedList: ContestantWithBio[] = []

    this.contestants.forEach(contestant => {
      if (contestant.id === updatedContestant.id) {
        updatedList.push(updatedContestant)
      } else {
        updatedList.push(contestant)
      }
    })

    this.contestants = updatedList
  }

  getContestant(contestantUsername: string, creatingCompetition?: boolean) {
    return this.contestants.find(contestant => contestant.username === contestantUsername)
  }

  clearContestants() {
    this.contestants = []
  }

  get votingContestant() {
    return this.voteStatus === status.LOADING
  }

  get savingContestant() {
    return this.saveContestantStatus === status.LOADING
  }

  get fetchingContestants() {
    return this.contestantsStatus === status.LOADING
  }

  get canAddContestant() {
    const subscriptionPlan = competitionStore.subscriptionPlan
    if (!subscriptionPlan && subscriptionPlan !== 0) return false
    if (competitionStore.isPremierSubscriptionPlan || competitionStore.isProPlan) return true
    return this.contestants.length < subscriptionOptions[subscriptionPlan].MAX_CONTESTANTS
  }

  get contestantsFilteredByCategory() {
    const categoryId = competitionStore.selectedCategory.id
    return this.contestants.filter(contestant => {
      if (categoryId === DEFAULT_CATEGORY_ID) {
        return !contestant.category_id
      }

      return categoryId === contestant.category_id
    })
  }

  get pageContestants() {
    const categoryId = competitionStore.competitionPage.selectedCategory.id
    return this.contestants.filter(contestant => {
      if (categoryId === DEFAULT_CATEGORY_ID) {
        return !contestant.category_id
      }

      return categoryId === contestant.category_id
    })
  }
}

export default new ContestantStore()
