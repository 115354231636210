import { makeAutoObservable, runInAction, reaction } from "mobx"
import isEmpty from 'lodash/isEmpty'
import {
  activateUserRequest,
  changeUserPasswordRequest,
  deleteUserAccountRequest,
  fetchUserDetailsRequest,
  googleAuthRequest,
  loginRequest,
  resendActivationCodeRequest,
  resetUserPasswordRequest,
  sendPasswordResetRequest,
  signupRequest,
  updateUserRequest,
  updateUserSettingsRequest
} from "../utils/apiRequests";
import Auth from "../utils/auth";
import { routes, status } from "../utils/constants";
import { retrieveErrorMessage } from "../utils/helpers";
import history from "../utils/history";
import { Status, IUserCredentials, IUserPayload, IChangePassword, IUserSettings } from "../utils/interfaces";
import uiStore from "./uiStore";
import creditStore from "./creditStore";
import competitionStore from "./competitionStore";
import paymentStore from "./paymentStore";

class AuthStore {
  user: any = {}
  userSettings: any = {}
  registerUserStatus: Status = null
  registerUserErrorMessage = ''
  loginUserStatus: Status = null
  loginUserErrorMessage = ''
  updatingUserStatus: Status = null
  changePasswordStatus: Status = null
  deleteUserAccountStatus: Status = null
  activateUserStatus: Status = null
  activateUserStatusErrorMessage: string = ''
  activateUserStatusMessage: string = ''
  resendActivationCodeStatus: Status = null
  sendPasswordResetStatus: Status = null
  sendPasswordResetErrMessage: string = ''
  resetUserPasswordStatus: Status = null
  resetUserPasswordErrMessage: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  setUserDetails(payload: IUserPayload) {
    this.user = payload
  }

  async googleAuth(idToken: string, gotoDashboard=true) {
    uiStore.setLoadingScreenMessage('Please give us a moment')
    try {
      const response = await googleAuthRequest(idToken)
      Auth.saveUserDetails(response.data)
      uiStore.showToastMessage({
        message: 'Successful! redirecting you to dashboard.',
        type: 'success'
      })

      setTimeout(() => {
        window.location.href = routes.DASHBOARD
      }, 1000);
    } catch (e) {
      console.log('err:: ', e)
      uiStore.showToastMessage({
        message: retrieveErrorMessage(e),
        type: 'error'
      })
    } finally {
      uiStore.setLoadingScreenMessage('')
    }
  }

  async changePassword(payload: IChangePassword) {
    if (this.changePasswordLoading) return null

    runInAction(() => this.changePasswordStatus = status.LOADING)

    try {
      await changeUserPasswordRequest(payload)
      uiStore.successModal('Password changed successfully!')

      runInAction(() => this.changePasswordStatus = status.COMPLETE)
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => this.changePasswordStatus = status.FAILED)
    }
  }

  async updateSettings(payload: IUserSettings) {
    try {
      const response = await updateUserSettingsRequest(payload)
      runInAction(() => {
        this.userSettings = response.data
      })
    } catch {
      // fail silently
    }
  }

  async registerUser(payload: IUserPayload, gotoDashboard=true) {
    runInAction(() => {
      this.registerUserStatus = status.LOADING
      this.registerUserErrorMessage = ''
    })

    try {
      const response = await signupRequest(payload)
      Auth.saveUserDetails(response.data)

      runInAction(() => {
        this.registerUserStatus = status.COMPLETE
        this.user = response.data.user
      })

      if (gotoDashboard) history.push(routes.DASHBOARD)
    } catch (e) {
      runInAction(() => {
        this.registerUserStatus = status.FAILED
        this.registerUserErrorMessage = retrieveErrorMessage(e)
      })
    }
  }

  async loginUser(payload: IUserCredentials, gotoDashboard=true) {
    if (this.loginUserStatus === status.LOADING) return

    runInAction(() => {
      this.loginUserStatus = status.LOADING
      this.loginUserErrorMessage = ''
    })

    try {
      const response = await loginRequest(payload)
      Auth.saveUserDetails(response.data)

      runInAction(() => {
        this.loginUserStatus = status.COMPLETE
        this.user = response.data.user
      })

      if (gotoDashboard) history.push(routes.DASHBOARD)
    } catch (e) {
      runInAction(() => {
        this.loginUserStatus = status.FAILED
        this.loginUserErrorMessage = retrieveErrorMessage(e)
      })
    }
  }

  async updateUser(payload: IUserPayload) {
    if (this.updatingUserLoading) return
    runInAction(() => this.updatingUserStatus = status.LOADING)

    try {
      const response = await updateUserRequest(payload)
      const user = response.data
      localStorage.setItem('user_data', JSON.stringify(user))

      runInAction(() => {
        this.user = user
        this.updatingUserStatus = status.COMPLETE
      })

      uiStore.successModal('Your profile has been updated')
    } catch (e) {
      runInAction(() => this.updatingUserStatus = status.FAILED)
      uiStore.errorModal(retrieveErrorMessage(e))
    }
  }

  async deleteUserAccount(userPassword: string) {
    if (this.deletingUserAccount) return
    runInAction(() => {
      this.deleteUserAccountStatus = status.LOADING
    })

    try {
      runInAction(() => this.deleteUserAccountStatus = status.COMPLETE)

      await deleteUserAccountRequest(userPassword)
      Auth.logUserOut()

      return true
    } catch (e) {
      runInAction(() => this.deleteUserAccountStatus = status.FAILED)

      uiStore.errorModal(retrieveErrorMessage(e))
    }
  }

  async activateAccount(activationCode: string) {
    if (this.activatingUser) return

    try {
      runInAction(() => {
        this.activateUserStatusMessage = 'Activating Account...'
        this.activateUserStatus = status.LOADING
      })
      await activateUserRequest(activationCode)
      runInAction(() => {
        this.activateUserStatusMessage = 'Your account has been successfully activated!'
        this.activateUserStatus = status.COMPLETE
      })

      if (this.userIsLoggedIn) {
        this.user.activated = true
        Auth.updateUserDetails(this.user)
      }
    } catch (e) {
      runInAction(() => {
        this.activateUserStatus = status.FAILED
        this.activateUserStatusErrorMessage = retrieveErrorMessage(e)
      })
    }
  }

  async resendUserActivationCode(expiredActivationCode: string) {
    if (this.resendingActivationCode || this.resentActivationCode) return null

    runInAction(() => {
      this.resendActivationCodeStatus = status.LOADING
    })

    try {
      await resendActivationCodeRequest(expiredActivationCode)
      runInAction(() => this.resendActivationCodeStatus = status.COMPLETE)
    } catch (e) {
      runInAction(() => {
        this.resendActivationCodeStatus = status.FAILED
        this.activateUserStatusErrorMessage = retrieveErrorMessage(e)
      })
    }
  }

  async sendPasswordResetEmail(username: string) {
    if (this.sendingPasswordResetEmail) return
    runInAction(() => {
      this.sendPasswordResetStatus = status.LOADING
      this.sendPasswordResetErrMessage = ''
    })

    try {
      await sendPasswordResetRequest(username)
      runInAction(() => this.sendPasswordResetStatus = status.COMPLETE)
    } catch (e) {
      runInAction(() => {
        this.sendPasswordResetErrMessage = retrieveErrorMessage(e)
        this.sendPasswordResetStatus = status.FAILED
      })
    }
  }

  async resetPassword(token: string, newPassword: string) {
    if (this.resettingPassword) return

    runInAction(() => {
      this.resetUserPasswordErrMessage = ''
      this.resetUserPasswordStatus = status.LOADING
    })

    try {
      await resetUserPasswordRequest({
        reset_token: token,
        new_password: newPassword
      })
      runInAction(() => this.resetUserPasswordStatus = status.COMPLETE)
    } catch (e) {
      runInAction(() => {
        this.resetUserPasswordStatus = status.FAILED
        this.resetUserPasswordErrMessage = retrieveErrorMessage(e)
      })
    }
  }

  async fetchUserDetails() {
    try {
      const response = await fetchUserDetailsRequest()
      const user = response.data.user

      this.setUserDetails(user)
      localStorage.setItem('user_data', JSON.stringify(user))
    } catch {}
  }

  clearData(fields: string[]=[]) {
    if (!fields.length) {
      this.registerUserStatus = null
      this.registerUserErrorMessage = ''
      this.loginUserStatus = null
      this.loginUserErrorMessage = ''
    } else {
      fields.forEach(field => {
        (this as any)[field] = ''
      })
    }
  }

  get canResendActivationCode() {
    return this.failedToActivateUser && this.activateUserStatusErrorMessage.includes('expired')
  }

  get userData() {
    return {...this.user}
  }

  get avatarFilename() {
    return `${this.userFirstName}_${this.user.id}_${+(new Date())}`
  }

  get userFirstName() {
    return this.user?.first_name
  }

  get userAvatar() {
    return this.user?.avatar
  }

  get userEmail() {
    return this.user?.email
  }

  get username() {
    return this.user?.username
  }

  get userIsLoggedIn() {
    return !isEmpty(this.user)
  }

  get registerUserLoading() {
    return this.registerUserStatus === status.LOADING
  }

  get registerUserFailed() {
    return this.registerUserStatus === status.FAILED
  }
  
  get registerUserCompleted() {
    return this.registerUserStatus === status.COMPLETE
  }

  get registerUserError() {
    return this.registerUserErrorMessage
  }

  get loginUserLoading() {
    return this.loginUserStatus === status.LOADING
  }

  get loginUserFailed() {
    return this.loginUserStatus === status.FAILED
  }
  
  get loginUserCompleted() {
    return this.loginUserStatus === status.COMPLETE
  }

  get loginUserError() {
    return this.loginUserErrorMessage
  }

  get updatingUserLoading() {
    return this.updatingUserStatus === status.LOADING
  }

  get changePasswordLoading() {
    return this.changePasswordStatus === status.LOADING
  }

  get deletingUserAccount() {
    return this.deleteUserAccountStatus === status.LOADING
  }

  get activatingUser() {
    return this.activateUserStatus === status.LOADING
  }

  get userActivated() {
    return this.activateUserStatus === status.COMPLETE
  }

  get failedToActivateUser() {
    return this.activateUserStatus === status.FAILED
  }

  get resendingActivationCode() {
    return this.resendActivationCodeStatus === status.LOADING
  }

  get resentActivationCode() {
    return this.resendActivationCodeStatus === status.COMPLETE
  }

  get failedToResendActivationCode() {
    return this.resendActivationCodeStatus === status.FAILED
  }

  get isUserActive() {
    return this.user.activated
  }

  get sendingPasswordResetEmail() {
    return this.sendPasswordResetStatus === status.LOADING
  }

  get sentPasswordResetEmail() {
    return this.sendPasswordResetStatus === status.COMPLETE
  }

  get resettingPassword() {
    return this.resetUserPasswordStatus === status.LOADING
  }

  get resetPasswordComplete() {
    return this.resetUserPasswordStatus === status.COMPLETE
  }
}

const authStore = new AuthStore()
export default authStore

reaction(
  () => [authStore.isUserActive, authStore.userIsLoggedIn],
  ([isUserActive, userIsLoggedIn]) => {
    if (isUserActive && userIsLoggedIn) {
      competitionStore.fetchFavouriteCompetitions()
      creditStore.getUserCredit()
      paymentStore.fetchSavedCards()      
    }
  }
)
