import Input from '../components/Input'
import { required, isPassword, getCompetitionUsername } from "./helpers"
import TextArea from '../components/TextArea'
import {
  IMenuItem,
  IStatusProps,
  ICompetitionRequest,
  ICompetition,
  IPagination
} from './interfaces'
import { 
  fetchAllCompetitionsRequest,
  fetchFavouriteCompetitionRequest,
  fetchInvolvedCompetitionRequest,
  fetchNewCompetitionsRequest
} from './apiRequests'
import { ReactComponent as Home } from '../assets/images/Home.svg'
import { ReactComponent as Coins } from '../assets/images/coins.svg'
import { ReactComponent as User } from '../assets/images/user.svg'
import { ReactComponent as Settings } from '../assets/images/settings.svg'
import { ReactComponent as Trophy } from '../assets/images/trophy.svg'
import { ReactComponent as Logout } from '../assets/images/logout.svg'
import { ReactComponent as Payout } from '../assets/images/payout.svg'
import { ReactComponent as VotingRounds } from '../assets/images/voting-rounds.svg'
import { colors, size } from '../styles/_var'
import UsernameInput from '../components/UsernameInput'
import { EligibleVotersIcon, AccountIcon } from '../components/Icons'


export const errorMessage: string = 'Something went wrong, please try again';


export const loginFields = [
  {
    name: 'username',
    placeholder: 'username/email address',
    component: Input,
    max_length: 50,
    validate: required
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password',
    component: Input,
    max_length: 50,
    validate: required
  },
]

export const forgotPasswordFields = [
  {
    name: 'email',
    placeholder: 'Enter your Email',
    component: Input,
    max_length: 50,
    validate: required
  }
]

export const resetPasswordFields = [
  {
    name: 'password',
    placeholder: 'Enter your new password',
    component: Input,
    type: 'password',
    max_length: 100,
    validate: isPassword
  },
  {
    name: 'confirm_password',
    placeholder: 'Confirm Password',
    type: 'password',
    component: Input,
    max_length: 100,
  }
]

export const competitionFields = [
  {
    name: 'name',
    placeholder: 'Competition Name',
    component: Input,
    max_length: 50,
    validate: required
  },
  {
    name: 'username',
    placeholder: 'Competition Username',
    component: Input,
    max_length: 50,
    validate: required,
    parse: (value: string) => value.trim()
  },
  {
    name: 'address',
    placeholder: 'Address',
    component: 'location',
    validate: required
  },
  {
    name: 'description',
    placeholder: 'Description',
    component: TextArea,
    validate: required
  }
]

export const creditTransferFields = (onValidate: (result: boolean) => void) => [
  {
    name: 'to_username',
    placeholder: 'Recipient’s Username',
    component: UsernameInput,
    max_length: 50,
    validate: required,
    onValidate: onValidate
  },
  {
    name: 'amount',
    placeholder: 'Amount to transfer',
    type: 'number',
    component: Input,
    max_length: 10,
    validate: required
  },
  {
    name: 'password',
    label: 'Enter your password below to authenticate transfer',
    placeholder: 'Password',
    component: Input,
    type: 'password',
    validate: isPassword
  }
]

export const creditPurchaseFields = [
  {
    name: 'amount',
    placeholder: 'Amount to Purchase',
    type: 'number',
    component: Input,
    max_length: 10,
    validate: required
  },
]

export const competitionLoginFields = [
  {
    name: 'competition_username',
    placeholder: 'Competition Username',
    component: Input,
    max_length: 50,
    validate: required
  },
  ...loginFields
]

export const status: IStatusProps = {
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED'
}

export const routes = {
  LANDING: '/',
  DASHBOARD: '/dashboard/home',
  VOTING_CREDITS: '/dashboard/voting-credits',
  MY_ACCOUNT: '/dashboard/my-account',
  SUPPORT: '/dashboard/support',
  SETTINGS: '/dashboard/settings',
  MY_COMPETITIONS: '/dashboard/competitions',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  CATEGORIES: '/categories',
  COMPETITIONS: '/competitions',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password/:token',
  COMPETITION_DASHBOARD: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/home`,
  COMPETITION_PAYOUTS: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/payouts`,
  VOTING_ROUNDS: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/voting_rounds`,
  COMPETITION_SETTINGS: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/settings`,
  ELIGBILE_VOTERS: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/eligible_voters`,
  ACCOUNT: (username?: string) => `/${username || getCompetitionUsername()}/dashboard/account`,
  CREATE_COMPETITION: '/dashboard/competitions/create',
  ACCOUNT_VERIFICATION: '/account_activation/:activation_code',
  ERROR_PAGE: '/error',
  NOT_FOUND: '/error?code=404',
  UNAUTHORIZED: '/error?code=401',
  INTERNAL_SERVER: '/error?code=500',
  FORBIDDEN: '/error?code=403',
  PRICING: '/pricing',
  CONTACT_US: '/contact',
  COMING_SOON: '/under_construction',
  FAQS: '/faqs',
  FEATURES: '/features',
  DEMOS: '/demos',
  PRIVACY_POLICY: '/privacy_policy'
}

export const getCompetitionMenu = (competition: ICompetition): IMenuItem[] => {
  const menuList = [
    {
      name: 'Home',
      Icon: Home,
      route: routes.COMPETITION_DASHBOARD(),
      minimumRole: 0,
    }, 
    {
      name: 'Payouts',
      Icon: Payout,
      route: routes.COMPETITION_PAYOUTS(),
      minimumRole: 0,
    },
  ]

  if (competition.visibility === 'Private') {
    menuList.push({
      name: 'Eligible Voters',
      Icon: EligibleVotersIcon,
      route: routes.ELIGBILE_VOTERS(),
      minimumRole: 0,
    })
  }

  menuList.push({
    name: 'Voting Rounds',
    Icon: VotingRounds,
    route: routes.VOTING_ROUNDS(),
    minimumRole: 0,
  },
  {
    name: 'Settings',
    Icon: Settings,
    route: routes.COMPETITION_SETTINGS(),
    minimumRole: 1,
  },
  {
    name: 'Account',
    Icon: AccountIcon,
    minimumRole: 1,
    route: routes.ACCOUNT(),
  },
  {
    name: 'Back to dashboard',
    Icon: Logout,
    route: routes.MY_COMPETITIONS,
    minimumRole: 0,
  })

  return menuList
}

export const menuItems: IMenuItem[] = [
  {
    name: 'Home',
    Icon: Home,
    route: routes.DASHBOARD,
  }, 
  {
    name: 'Voting Credits',
    Icon: Coins,
    route: routes.VOTING_CREDITS
  },
  {
    name: 'My Competitions',
    Icon: Trophy,
    route: routes.MY_COMPETITIONS
  },
  {
    name: 'My Profile',
    Icon: User,
    route: routes.MY_ACCOUNT
  },
  {
    name: 'Settings',
    Icon: Settings,
    route: routes.SETTINGS
  },
  {
    name: 'Logout',
    Icon: Logout,
    route: routes.LOGOUT
  }
]

export function getSliderSettings(noOfSlides: number) {
  const maxSlidesToShow = (maxSlides: number) => noOfSlides <= maxSlides ? noOfSlides : maxSlides

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: maxSlidesToShow(4),
    slidesToScroll: 3,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: Number(size.desktop.replace('px', '')),
        settings: {
          slidesToShow: maxSlidesToShow(4),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: maxSlidesToShow(3),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: maxSlidesToShow(1),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: maxSlidesToShow(1),
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: Number(size.tablet.replace('px', '')),
        settings: {
          slidesToShow: maxSlidesToShow(2),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: maxSlidesToShow(2),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: maxSlidesToShow(1),
          slidesToScroll: 1,
        }
      },
    ]
  }

  return settings
}

export const transactionHistory = [
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  },
  {
    type: 'Credit Purchase',
    amount: 'N10,000',
    date: 'Oct 29, 2020'
  }
]

export const categories = [
  {
    name: 'PAGEANTS',
    url: '/competitions?category=pageants',
    image: 'https://heritagepageantsbelgium.be/____impro/1/onewebmedia/logo_vrouw_Heritage%20Pageants%20Belgium_HannD_LC02.png?etag=W%2F%2221a2a-5e5bc7a8%22&sourceContentType=image%2Fpng'
  },
  {
    name: 'REALITY',
    url: '/competitions?category=reality',
    image: 'https://www.serbianmonitor.com/wp-content/uploads/2018/09/reality-tv-logo.png'
  },
  {
    name: 'MUSIC',
    url: '/competitions?category=music',
    image: 'https://i.pinimg.com/originals/9e/df/af/9edfaf9d82b6d107b25cbe6824926572.png'
  },
  {
    name: 'DANCE',
    url: '/competitions?category=dance',
    image: 'https://cdn.dribbble.com/users/726319/screenshots/4606564/untitled-61-01.jpg'
  },
  {
    name: 'COMEDY',
    url: '/competitions?category=comedy',
    image: 'https://statusstories.com/wp-content/uploads/2019/04/kosher-comedy-logo.jpg'
  },
  {
    name: 'TALENT',
    url: '/competitions?category=talent',
    image: 'https://www.pngitem.com/pimgs/m/157-1570920_global-innovative-forefront-talent-talented-icon-png-transparent.png'
  }
]

export const loremIpslum = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries..."

export const sliderSettings = (noOfSlides: number) => {
  const maxSlidesToShow = (maxSlides: number) => noOfSlides <= maxSlides ? noOfSlides : maxSlides

  return {
    infinite: true,
    speed: 500,
    slidesToShow: maxSlidesToShow(4),
    slidesToScroll: 3,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: Number(size.desktop.replace('px', '')),
        settings: {
          slidesToShow: maxSlidesToShow(4),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: maxSlidesToShow(4),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: maxSlidesToShow(4),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: maxSlidesToShow(2),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: Number(size.tablet.replace('px', '')),
        settings: {
          slidesToShow: maxSlidesToShow(3),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: maxSlidesToShow(3),
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: maxSlidesToShow(3),
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: maxSlidesToShow(1),
          slidesToScroll: 1,
        }
      },
    ]
  }
}

const isProduction = process.env.NODE_ENV === 'production'
const aws_folder_pf = isProduction ? '_p' : ''

export const AWS_BASE_URL = 'https://matusky.s3-us-west-2.amazonaws.com'
export const USER_AVATAR_S3_FOLDER = `user_avatars${aws_folder_pf}/`
export const COMPETITION_BANNER_S3_FOLDER = `competition_banners${aws_folder_pf}/`
export const COMPETITION_LOGO_S3_FOLDER = `competition_logos${aws_folder_pf}/`
export const CONTESTANT_AVATAR_S3_FOLDER = `contestants${aws_folder_pf}/`

export const NEW = 'new'
export const FAV = 'favourite'
export const ALL = 'all'
export const INVOLVED = 'involved'

export const steps = {
  COMPETITION_DETAILS: 0,
  SEASON_TYPE_CHOOSER: 1,
  // CONTESTANT_DETAILS: 2,
  PAYMENT_OPTIONS: 2,
  SUMMARY: 3,
}

export const seasonTypes = {
  FREE: 1,
  ESSENTIAL: 2,
  PRO: 3,
  PREMIER: 4,
}


export const subscriptionOptions = {
  [seasonTypes.FREE]: {
      PRICE: 0,
      MAX_VOTING_ROUNDS: 3,
      MAX_CONTESTANTS: 4,
      MAX_STAFF: 0,
      SLUG: 'free',
      LABEL_COLOR: colors.subtlylightpurple,
  },
  [seasonTypes.ESSENTIAL]: {
      PRICE: 1000000,
      MAX_CONTESTANTS: 6,
      MAX_VOTING_ROUNDS: 10,
      MAX_STAFF: 4,
      SLUG: 'essential',
      LABEL_COLOR: "#e0ffef",
  },
  [seasonTypes.PRO]: {
      PRICE: 3000000,
      MAX_CONTESTANTS: -1,
      MAX_VOTING_ROUNDS: -1,
      MAX_STAFF: -1,
      SLUG: 'pro',
      LABEL_COLOR: colors.yellow,
  },
  [seasonTypes.PREMIER]: {
      PRICE: 0,
      MAX_CONTESTANTS: -1,
      MAX_VOTING_ROUNDS: -1,
      MAX_STAFF: -1,
      SLUG: 'premier',
      LABEL_COLOR: colors.verylightpurple
  }
}

export const competitionRequest: ICompetitionRequest = {
  all: fetchAllCompetitionsRequest,
  new: fetchNewCompetitionsRequest,
  favourite: fetchFavouriteCompetitionRequest,
  involved: fetchInvolvedCompetitionRequest
}

export const StaffRoles = ['Staff', 'Admin', 'Owner']

export const regexPatterns = {
  phone: /^(\+)*(\d+)(-)*/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const defaultCreditCardIcon = 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Visa.png'

export const CreditCardIcons: any = {
  alipay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Alipay.png',
  amazonpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/AmazonPay.png',
  amex: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Amex.png',
  applepay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/ApplePay.png',
  bitcoin: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Bitcoin.png',
  bitpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/BitPay.png',
  dinersclub: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/DinersClub.png',
  discover: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Discover.png',
  etherium: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Etherium.png',
  facebookpay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/FacebookPay.png',
  googlepay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/GooglePay.png',
  klarna: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Klarna.png',
  lightcoin: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Lightcoin.png',
  mastercard: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Mastercard.png',
  paypal: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/PayPal.png',
  qiwi: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Qiwi.png',
  shoppay: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/ShopPay.png',
  skrill: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Skrill.png',
  stripe: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Stripe.png',
  visa: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Visa.png',
  wechat: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/WeChat.png',
  yandex: 'https://matusky.s3.us-west-2.amazonaws.com/credit-cards/Yandex.png'
}

export const PaginationDefaults: IPagination = {
  num_of_pages: 0,
  has_next: false,
  current_page: 0,
}

export const DEFAULT_CATEGORY_ID = 0