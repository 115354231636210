import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text, { BoldText } from '../../components/Text'
import { colors, spacing } from '../../styles/_var'
import { IVotingRoundOptions } from '../../utils/interfaces'
import Grid from '@material-ui/core/Grid'


const VotingOptions: FunctionComponent<IVotingRoundOptions> = ({
  max_no_of_votes,
  vote_cost,
  max_rate,
  show_vote_count,
  show_live_updates
}) => {
  return (
    <Grid container>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">N{vote_cost}/vote</Text>
          <BoldText variant="regular">Vote Cost</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{max_no_of_votes} votes/{max_rate ? 'voting round' : 'day'}</Text>
          <BoldText variant="regular">Maximum Allowed Votes</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{show_live_updates ? 'YES' : 'NO'}</Text>
          <BoldText variant="regular">Show Live Updates</BoldText>
        </Option>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <Option>
          <Text variant="medium">{show_vote_count ? 'YES' : 'NO'}</Text>
          <BoldText variant="regular">Show Vote Count</BoldText>
        </Option>
      </Grid>
    </Grid>
  )
}


export default VotingOptions

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 5px 2px ${colors.lightpurple};
  padding: ${spacing.xs};
  border-radius: 10px;
  width: 20rem;
  margin-bottom: ${spacing.sm};
  transition: all ease .2s;

  &:hover {
    transform: scale(1.1);
  }
`