import React, { useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { colors, fontSize } from '../../../styles/_var'
import { routes } from '../../../utils/constants'
import plus from '../../../assets/images/plus.png'
import Button from '../../../components/Button'
import Link from '../../../components/Link'
import competitionStore from '../../../stores/competitionStore'
import CompetitionListContainer from '../../../components/CompetitionList'


const AddCompetitionButton = React.memo(() => {
  return (
    <Link to={routes.CREATE_COMPETITION}>
      <ButtonWrapper>
        <PlusIcon src={plus} />
        <Button block>Create Competition</Button>
      </ButtonWrapper>
    </Link>
  )
})

const CreatedCompetitions = () => {
  const fetchCompetitions = useCallback((payload) => {
    competitionStore.fetchMyCompetitions(payload)
  }, [])

  return (
    <CompetitionListContainer 
      paginationInfo={competitionStore.userCompetitionPaginationInfo}
      fetchingCompetitions={competitionStore.fetchingMyCompetitions}
      noCompetitions={competitionStore.noUserCompetitions}
      fetchedCompetitions={competitionStore.fetchedMyCompetitions}
      competitions={competitionStore.userCompetitions}
      fetchCompetitions={fetchCompetitions}
      PrependedComponent={!competitionStore.noUserCompetitions ? () => <AddCompetitionButton /> : undefined}
      linkToCompetitionDashboard
    />
  )
}

export default observer(CreatedCompetitions)


const ButtonWrapper = styled.div`
  position: relative;
  background: rgba(111, 82, 237, 0.1);
  width: 25rem;
  height: 22rem;
  border-radius: 1rem;
  transition: box-shadow ease .2s;
  box-shadow: 0 0 10px 3px ${colors.lightgrey};

  button {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: ${fontSize.rg};
  }

  &:hover {
    box-shadow: 0 0 8px 7px #dad1fc;
  }
`

const PlusIcon = styled.img`
  width: 10rem;
  height: 10rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

