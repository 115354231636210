import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import { BannerWrapper } from "../../components/LandingPageBanner";
import Text, { BoldText } from "../../components/Text";
import { IFeatures } from "../../utils/interfaces";
import FeatureSection from "./components/FeatureSection";
import { colors, device, fontSize, spacing } from "../../styles/_var";
import FeatureTabs from "./components/FeatureTabs";
import Link from "../../components/Link";
import { routes } from "../../utils/constants";
import throttle from "lodash/throttle";
import usePageTitle from "../../hooks/usePageTitle";

const FeaturesData: IFeatures[] = [
  {
    title: "Create a platform for your competition",
    desc: "With Matuskii, you can create competitions easily, and for free! Your competition could be public or private.",
    options: [
      "Only specific users of choice, can participate in a private competiton.",
      "Public competitions are for everyone.",
    ],
    img: "https://matusky.s3.us-west-2.amazonaws.com/assets/images/featureImgOne.png",
    id: "create-competitions",
  },
  {
    title: "Add staffs to your competition",
    desc: "Matuskii allows you add other users as staffs, to help you manage your competition.",
    img: "https://matusky.s3.us-west-2.amazonaws.com/assets/images/featureImgTwo.png",
    options: [
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur, molestiae fugit ullam similique totam hic aut placeat",
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur,",
    ],
    swap: true,
    id: "staffs",
  },
  {
    title: "Private competitions",
    options: [
      "For private competitions you can manage who has access to participate in your competiton.",
      "You can also send invitations to non-matuskii users to join and participate in your competition.",
    ],
    img: "https://matusky.s3.us-west-2.amazonaws.com/assets/images/featureImgThree.png",
    id: "private-competitions",
  },
  {
    title: "multiple voting rounds",
    desc: "You can create multiple voting rounds per season and multiple seasons within your competitions.",
    options: [
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur, molestiae fugit ullam similique totam hic aut placeat",
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur,",
    ],
    img: "https://matusky.s3.us-west-2.amazonaws.com/assets/images/featureImgFour.png",
    swap: true,
    id: "voting-rounds",
  },
  {
    title: "Process payouts",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur, molestiae fugit ullam similique",
    options: [
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur, molestiae fugit ullam similique totam hic aut placeat",
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus pariatur,",
    ],
    img: "https://matusky.s3.us-west-2.amazonaws.com/assets/images/featureImgFive.png",
    id: "payouts",
  },
];

const NAVBAR_SIZE = 69

const Features = () => {
  const featureTabElement = useRef<HTMLDivElement>(null)
  const fixedFeatureTabElement = useRef<HTMLDivElement>(null)
  usePageTitle('Features')
  
  const onWindowScroll = () => {
    if (!fixedFeatureTabElement.current || !featureTabElement.current) {
      return
    }

    const top = featureTabElement.current.getBoundingClientRect().top
    const fixedElementVisible = fixedFeatureTabElement.current.style.visibility === 'visible'
    
    if (top && top < NAVBAR_SIZE && !fixedElementVisible) {
      fixedFeatureTabElement.current.style.visibility = 'visible'
      featureTabElement.current.style.visibility = 'hidden'
    } else if (top && top > NAVBAR_SIZE && fixedElementVisible) {
      fixedFeatureTabElement.current.style.visibility = 'hidden'
      featureTabElement.current.style.visibility = 'visible'
    }
  }

  useEffect(() => {
    const throttledHandler = throttle(onWindowScroll, 160)
    window.addEventListener('scroll', throttledHandler)

    return () => {
      window.removeEventListener('scroll', throttledHandler)
    }
  }, [])

  return (
    <>
      <FeaturesBannerWrapper>
        <FixedFeatureTab ref={fixedFeatureTabElement}/>
        <Title variant="title">Our Features & Services</Title>
        <Text variant="medium" color={colors.grey}>
          See all we have to offer
        </Text>
      </FeaturesBannerWrapper>
      <FeatureTabs ref={featureTabElement} />
      {FeaturesData.map(({ title, desc, options, img, swap, id }, index) => (
        <FeatureSection
          key={index}
          title={title}
          desc={desc}
          options={options}
          img={img}
          swap={swap}
          id={id}
        />
      ))}
      <ButtonWrapper>
      <GetStarted to={routes.SIGNUP} useButton>Get Started With Matuskii</GetStarted>
      </ButtonWrapper>
      <Footer />
    </>
  );
};

export default Features;

const FixedFeatureTab = styled(FeatureTabs)`
  top: 7rem;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  visibility: hidden;
`

const FeaturesBannerWrapper = styled(BannerWrapper)`
  min-height: 14rem;
  background-position: center center;

  @media ${device.tablet} {
    margin-top: ${spacing.xl};
  }
`;

const Title = styled(BoldText)`
  margin-bottom: ${spacing.xs};
`;

const GetStarted = styled(Link)`
font-size: ${fontSize.rg};
margin-bottom: ${spacing.xxl};
padding: 10px 15px;
text-transform: uppercase;

@media ${device.tablet} {
  padding: 16px 20px;
  font-size: ${fontSize.md};
}
`
const ButtonWrapper = styled.div`
text-align: center;
`
