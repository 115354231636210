import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Box from '@material-ui/core/Box'
import Text, { BoldText } from '../../components/Text'
import { colors } from '../../styles/_var'
import { TextWithIconHorizontal } from '../../components/StyledComponents'

const ListItem: FunctionComponent = ({ children }) => {
  return (
    <TextWithIconHorizontal style={{ justifyContent: 'flex-start', marginBottom: 5}}>
      <FontAwesomeIcon icon={faCheckCircle} size="2x" />
      <Text variant="regular">{children}</Text>
    </TextWithIconHorizontal>
  )
}

const ContactUsInstructions = () => {
  return (
    <>
      <Box mt={6}>
        <BoldText variant="medium">We're here to help</BoldText>
        <ListItem>Find the right solution for you</ListItem>
        <ListItem>Explain options for pricing</ListItem>
        <ListItem>Connect you with helpful resources</ListItem>
      </Box>
      <Box mt={4}>
        <BoldText variant="medium">Support</BoldText>
        <Text variant="regular" color={colors.grey}>Already a customer? If you are encountering a technical or payment issue, the customer support team will be happy to assist you.</Text>
      </Box>
      <Box mt={4}>
        <BoldText variant="medium">Report Abuse</BoldText>
        <Text variant="regular" color={colors.grey}>
          The Matuskii team is responsible for this platform service and not the competitions being displayed on it. Please report any abuse or illegal competitions to us via this contact form and we will be in touch
        </Text>
      </Box>
    </>
  )
}

export default ContactUsInstructions
