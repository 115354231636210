import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import competitionStore from '../stores/competitionStore'
import Dropdown, { IListItem } from './Dropdown'
import { BoldText } from './Text'
import { colors } from '../styles/_var'


interface ICompetitionCategoryDropdown {
  isCompetitionPage?: boolean 
}

const CompetitionCategoryDropdown: FunctionComponent<ICompetitionCategoryDropdown> = ({
  isCompetitionPage=false
}) => {
  let categories, selectedCategory;

  if (isCompetitionPage) {
    categories = competitionStore.competitionPage.competition.categories || []
    selectedCategory = competitionStore.competitionPage.selectedCategory
  } else {
    categories = competitionStore.competitionDetails.categories || []
    selectedCategory = competitionStore.selectedCategory
  }

  const dropdownList = categories.map(e => ({ label: e.name, value: `${e.id}` }))
  const selected = { label: selectedCategory.name, value: `${selectedCategory.id}`}
  const onSelectCategory = (category: IListItem) => {
    competitionStore.setCompetitionCategory({id: +category.value, name: category.label}, isCompetitionPage)
  }


  return (
    <div>
      <BoldText variant='small' color={colors.purple} ml={1}>Categories</BoldText>
      <Dropdown 
        style={{minWidth: '270px'}}
        list={dropdownList}
        selected={selected}
        onItemSelect={e => onSelectCategory(e)}
        returnFullObject
      />
    </div>
  )
}

export default observer(CompetitionCategoryDropdown)
