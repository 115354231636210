import styled from 'styled-components'
import { spacing } from '../../styles/_var'


export const CompetitionPageWrapper = styled.div`
  max-width: 1200px;
  margin-bottom: ${spacing.rg};
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${spacing.sm};
`