import React from 'react'
import { ContentBody, FormContainer } from './style'
import { BoldText } from '../../../components/Text'
import SimpleForm, { IActionButton } from '../../../components/SimpleForm'
import MInput from '../../../components/MInput'
import { isPassword } from '../../../utils/helpers'
import authStore from '../../../stores/authStore'
import { IChangePassword } from '../../../utils/interfaces'
import { observer } from 'mobx-react-lite'



const ChangePassword = () => {
  const actionButton: IActionButton = {
    isSubmit: true,
    label: 'Save',
    variant: 'secondary',
    loading: authStore.changePasswordLoading
  }

  const changePassword = (payload: IChangePassword) => {
    authStore.changePassword(payload)
  }

  return (
    <ContentBody>
      <BoldText variant="medium">CHANGE PASSWORD</BoldText>
      <FormContainer>
        <SimpleForm
          role="form"
          aria-label="change password form"
          formFields={accountDetailsFormFields}
          actionButtons={actionButton}
          submitForm={changePassword} />
      </FormContainer>
    </ContentBody>
  )      
}

export default observer(ChangePassword)

const accountDetailsFormFields = [
  {
    name: 'current_password',
    placeholder: 'Current Password',
    type: 'password',
    component: MInput,
    validate: isPassword
  },
  {
    name: 'password',
    placeholder: 'New Password',
    type: 'password',
    component: MInput,
    validate: isPassword
  },
  {
    name: 'confirm_password',
    placeholder: 'Confirm Password',
    type: 'password',
    component: MInput,
  }
]