import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid'
import Dropdown from '../components/Dropdown'
import Input from '../components/Input'
import PageContent from '../components/PageContent'
import { SectionHeader, SectionBody } from '../components/StyledComponents'
import Text, { BoldText } from '../components/Text'
import { colors, device, spacing } from '../styles/_var'
import CompetitionThumbnail from '../components/CompetitionThumbnail'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import competitionStore from '../stores/competitionStore'
import Button from '../components/Button'
import { filterByQuery, filterByStatus } from '../utils/helpers'
import Aside from '../components/Aside/Aside'
import usePageTitle from '../hooks/usePageTitle'

const statuses = ['All Statuses', 'NEW', 'ACTIVE', 'COMPLETED']

const Competitions: FunctionComponent<RouteChildrenProps> = ({ location }) => {
  const [selectedStatus, setSelectedStatus] = useState(statuses[0])
  const [searchQuery, setSearchQuery] = useState("")
  const [showMobileSearch, setMobileSearch] = useState(false)

  const history = useHistory()
  const loadNextRef = useRef<HTMLButtonElement>(null)

  const { results: competitions, pagination } = competitionStore.competitions
  const fetchingCompetitions = competitionStore.fetchingCompetitions
  const searchParameters = location.search
  const category = (searchParameters.match(/\?category=(.*)/) || [])[1]
  usePageTitle('Competitions')

  const getFilteredCompetitions = () => {
    const query = searchQuery.toLowerCase()
    let results = competitions

    if (query) {
      results = filterByQuery(results, query)
    }

    if (selectedStatus) {
      results = filterByStatus(results, selectedStatus)
    }

    return results
  }

  useEffect(() => {
    // competitionStore.clearCompetitionData()
  }, [])
  
  useEffect(() => {
    competitionStore.fetchCompetitions({ category, page: 1 })
  }, [category])

  const scrollNewCompetitionsIntoView = () => {
    window.scrollBy({
      top: window.outerHeight / 2,
      behavior: 'smooth',
    })
  }


  const loadNext = async () => {
    await competitionStore.fetchCompetitions({ category, getNext: true })
    scrollNewCompetitionsIntoView()
  }

  const onSelectStatus = (status: string) => {
    setSelectedStatus(status)
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const onClickSearch = () => setMobileSearch(!showMobileSearch)

  const filteredCompetitions = getFilteredCompetitions()
  const hasCompetitions = !!filteredCompetitions.length

  const renderCompetitions = () => {
    return (
      <Grid container spacing={4}>
        {hasCompetitions && filteredCompetitions.map(competition => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={competition.username}>
            <CompetitionThumbnail {...competition} block />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <CompetitionsWrapper role="main">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={9}>
          <Grid container>
            <Grid item xs={12}>
              <SectionHeader>
                <TitleLink onClick={history.goBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                  <BoldText variant="regular">Go Back</BoldText>
                </TitleLink>
                <FilterSection>
                  <SearchIcon
                    icon={faSearch}
                    size="2x"
                    color={colors.grey}
                    onClick={onClickSearch} />
                  {showMobileSearch && (
                    <MobileSearchInput
                      placeholder="Filter"
                      value={searchQuery}
                      onChange={onSearchChange} />
                  )}
                  <SearchInput
                    value={searchQuery}
                    placeholder="Filter"
                    icon={faSearch}
                    onChange={onSearchChange} />
                  {!showMobileSearch && <Dropdown
                    list={statuses}
                    onItemSelect={onSelectStatus}
                    selected={selectedStatus} />}
                </FilterSection>
              </SectionHeader>
            </Grid>
            <Grid item xs={12}>
              {renderCompetitions()}
            </Grid>
            <Grid item xs={12}>
              {!hasCompetitions && <Text variant='medium' color={colors.red} center>No competitions found</Text>}
              {pagination.has_next && (
                <LoadMoreWrapper>
                  <Button
                    ref={loadNextRef}
                    variant="secondary"
                    onClick={loadNext}
                    loading={fetchingCompetitions}
                  >
                    Load More
                  </Button>
                </LoadMoreWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Hidden only="sm">
          <Grid item md={3}>
            <Aside components={['popular', 'advertisements']} />
          </Grid>
        </Hidden>
      </Grid>
    </CompetitionsWrapper>
  )
}


export default observer(Competitions)

export const CompetitionBody = styled(SectionBody)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing.xxs};
  grid-row-gap: ${spacing.xs};

  @media ${device.tablet} {
    grid-column-gap: ${spacing.rg};
    grid-row-gap: ${spacing.xs};
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.laptopL} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const CompetitionsWrapper = styled(PageContent)`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding-top: ${spacing.sm};
`

const TitleLink = styled.span`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: ${colors.purple};
  transition: all ease .2s;

  &:hover {
    opacity: .7;
  }

  svg {
    margin-right: ${spacing.xxs};
  }
`

const SearchInput = styled(Input)`
  width: 25rem;
  margin-right: ${spacing.xxs};
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`

const MobileSearchInput = styled(SearchInput)`
  display: block;
  width: 20rem;
  box-sizing: border-box;
  margin-right: 0;
  height: 5rem;

  @media ${device.tablet} {
    display: none;
  }
`

const FilterSection = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    align-items: initial;
  }
`

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: ${spacing.xxs};

  @media ${device.tablet} {
    display: none;
  }
`

const LoadMoreWrapper = styled.div`
  text-align: center;
  margin-top: ${spacing.xs};
`