import React, { FunctionComponent, useEffect } from 'react'
import { BoldText } from '../Text'
import { SidebarContent } from './styles'
import AsideCompetitionList from '../AsideCompetitionList'
import competitionStore from '../../stores/competitionStore'
import { observer } from 'mobx-react-lite'
import { mapToProps } from '../../utils/helpers'

interface ISimilarCompetitions {
  store: typeof competitionStore
}

const SimilarCompetitions: FunctionComponent<ISimilarCompetitions> = observer(({
  store
}) => {
  const competitionCategory = store?.competitionPage?.competition?.category

  useEffect(() => {
    if (competitionCategory) store.fetchSimilarCompetitions(competitionCategory)
  }, [competitionCategory, store])

  if (!store.hasSimilarCompetitions) return null

  return (
    <SidebarContent>
      <BoldText variant="medium">SIMILAR COMPETITIONS</BoldText>
      <AsideCompetitionList competitions={store.similarCompetitions} />
    </SidebarContent>
  )
})

export default mapToProps({ store: competitionStore }, SimilarCompetitions)
