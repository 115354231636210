import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import advertStore from '../../stores/advertStore'
import { mapToProps } from '../../utils/helpers'
import { SidebarContent } from './styles'

interface IAdvertisements {
  adsStore: typeof advertStore
}

const Advertisements: FunctionComponent<IAdvertisements> = observer(({ adsStore }) => {
  useEffect(() => {
    adsStore.fetchAdverts()
  }, [adsStore])

  return (
    <>
      {adsStore.adverts.map((ad, i) => (
        <PublicAd key={i}>
          <a href={ad.action_link} target="_blank" rel="noreferrer">
            <img
              src={ad.media_content}
              alt={ad.description}
              width="100%" />
          </a>
        </PublicAd>
      ))}
    </>
  )
})

const AdvertisementsContainer = mapToProps({ adsStore: advertStore }, Advertisements)
export default AdvertisementsContainer

const PublicAd = styled(SidebarContent)``