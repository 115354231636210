import React, { FunctionComponent } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Text, { BoldText } from './Text'
import { Avatar } from './StyledComponents'
import placeholderImage from '../assets/images/placeholder-person.jpg'
import { IUserPayload } from '../utils/interfaces'
import { spacing, colors } from '../styles/_var'
import { getStaffRole } from '../utils/helpers'

interface IStaffItem {
  personnel: IUserPayload,
  role: number,
  onRemoveStaff: () => void,
}

const OWNER = 2

const StaffItem: FunctionComponent<IStaffItem> = ({
  role,
  personnel: { first_name, last_name, email, avatar },
  onRemoveStaff,
}) => {
  const isOwner = role === OWNER
  return (
    <StaffItemWrapper role="widget" aria-label="competition staff">
      <StyledAvatar width={40} height={40} src={avatar || placeholderImage} />
      <UserInfo>
        <BoldText variant="regular">{first_name} {last_name}</BoldText>
        <Text variant="subtext">{email}</Text>
        <Footer>
          <BoldText variant="subtext" color={colors.grey}>
            {getStaffRole(role)}
          </BoldText>
          <ActionSection role="button">
            {!isOwner && <FontAwesomeIcon
              icon={faTrash}
              color={colors.red}
              size="1x"
              onClick={onRemoveStaff} />}
          </ActionSection>
        </Footer>
      </UserInfo>
    </StaffItemWrapper>
  )
}

export default StaffItem

const StaffItemWrapper = styled.div`
  display: flex;
  padding: ${spacing.xs} ${spacing.sm};
  align-items: center;
`

const StyledAvatar = styled(Avatar)`
  margin-right: ${spacing.xxs};
`

const UserInfo = styled.div`
  width: 100%;
  p {
    line-height: 2.3rem;
  }
`

const ActionSection = styled.div`
  margin-left: ${spacing.xs};

  svg {
    font-size: 12px;
    cursor: pointer;
    transition: all ease .2s;

    &:hover {
      opacity: 0.6;
    }
    &:first-child {
      margin-right: 5px;
    }
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
