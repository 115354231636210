import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { colors, spacing } from '../styles/_var'


interface IModalProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  showModal: boolean;
  className?: string;
  style?: React.CSSProperties,
  hideCloseButton?: boolean,
  closeWhenOverlayIsClicked?: boolean,
}


const Modal: FunctionComponent<IModalProps> = React.memo(({
  children,
  showModal,
  onClose,
  style,
  hideCloseButton,
  closeWhenOverlayIsClicked=true,
  className='',
  ...rest
}) => {
  const onClickWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!closeWhenOverlayIsClicked) return
    e.stopPropagation()
    onClose()
  }

  if (!showModal) return null

  return (
    <ModalWrapper
      onClick={onClickWrapper}
      style={style}
      {...rest}
      role="dialog"
      aria-labelledby="modal-message"
    >
      <ModalContent
        onClick={e => e.stopPropagation()}
        className={className}
      >
        {!hideCloseButton && <CloseIconWrapper onClick={onClose} aria-label="close-modal-button">
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </CloseIconWrapper>}
        {children}
      </ModalContent>
    </ModalWrapper>
  )
})

export default Modal

const CloseIconWrapper = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  background: ${colors.lightpurple};
  right: -20px;
  top: -20px;
  cursor: pointer;
  transition: all ease .2s;

  &:hover {
    transform: scale(1.2);
    color: ${colors.white};
  }

  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`

const ModalContent = styled.div`
  background-color: ${colors.white};
  width: 80%;
  min-height: 10rem;
  max-width: 40rem;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  padding: ${spacing.lg} ${spacing.rg};
`