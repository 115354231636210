import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/_var'
import { validateUsernameRequest } from '../utils/apiRequests'
import Input, { IInputProps } from './Input'

interface IUsernameInput extends IInputProps {
  onValidate?: (result: boolean) => void,
  invertValidation?: boolean,
}

const UsernameInput: FunctionComponent<IUsernameInput> = ({
  invertValidation=false,
  onValidate,
  ...props
}) => {
  const [valid, setValid] = useState(null)
  const username: any = props.value
  const onBlur = (e: any) => {
    if (username) {
      validateUsernameRequest(username).then(response => {
        setValid(invertValidation ? !response.data.result : response.data.result)
        onValidate && onValidate(response.data.result)
      }).catch(_ => {})
    }
    props?.onBlur && props.onBlur(e)
  }

  const getIcon = () => {
    if (valid === true) return faCheck
    if (valid === false) return faTimes
    return undefined
  }

  return (
    <StyledInput
      valid={valid}
      {...props}
      onBlur={onBlur}
      icon={getIcon()}
      iconPosition='right' />
  )
}

export default UsernameInput

const StyledInput = styled(({ valid, ...rest }) => <Input {...rest} />)`
  ${({ valid }) => valid === true && `
    svg {
      color: ${colors.lightpurple};
    }
  `}
  ${({ valid }) => valid === false && `
    svg {
      color: ${colors.red};
    }
  `}
`