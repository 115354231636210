import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { NavbarWrapper } from './Navbar'
import { colors, device, fontSize, spacing } from '../styles/_var'
import icon from '../assets/images/icon.png'
import Text from './Text'
import uiStore from '../stores/uiStore'
import authStore from '../stores/authStore'
import { NavUserInfo } from './NavUserInfo'
import creditStore from '../stores/creditStore'
import { subscriptionOptions } from '../utils/constants'

interface IVoterNavbar {
  competitionPlan?: number,
}


const VoterNavbar: FunctionComponent<IVoterNavbar> = ({
  competitionPlan,
}) => {
  // because competition plan can 0 which is falsey
  const subscriptionType = competitionPlan !== undefined ? subscriptionOptions[competitionPlan] : undefined

  return (
    <Navbar hideShadow={false}>
      <NavTitle>
        <img src={icon} alt='' />
        <Text variant="large" role="heading" truncate>
          {uiStore.voterNavTitle}
        </Text>
        {competitionPlan !== undefined && (
          <Label color={subscriptionType?.LABEL_COLOR}>
            {subscriptionType?.SLUG}
          </Label>
        )}
      </NavTitle>
      <NavUserInfo
        avatar={authStore.userAvatar}
        firstName={authStore.userFirstName}
        tushCredits={creditStore.creditBalance}
        toggleSidebar={() => uiStore.toggleSidebar()}
      />
    </Navbar>
  )
}

export default observer(VoterNavbar)

const Navbar = styled(NavbarWrapper)`
  position: relative;
  height: 10rem;
  padding: 0 ${spacing.sm};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.tablet} {
    height: 8rem;
  }
`

const NavTitle = styled.div`
  display: flex;
  img {
    height: 3.5rem;
    margin-right: ${spacing.xxs};

    @media ${device.tablet} {
      display: none;
    }
  }
`

const Label = styled(({ color, ...rest }) => <span {...rest} />)`
  ${({ color }) => color && `
    background-color: ${color};
  `}
  color: ${colors.darkyellow};
  padding: 0.5rem 1rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-family: Gilroy-bold;
  border-radius: 0.3rem;
  font-size: ${fontSize.xs}
`