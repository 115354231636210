import { makeAutoObservable, runInAction } from 'mobx'
import isEmpty from 'lodash/isEmpty'
import { IPaginatedResource, IPagination, IPayoutHistory, IPayoutSummary, Status } from '../utils/interfaces'
import {
  completeCreditPaymentRequest,
  getCreditSpendSummaryRequest,
  getPayoutHistoryRequest,
  getPayoutSummaryRequest,
  getTransactionHistoryRequest,
  getUserCreditRequest,
  transferCreditRequest
} from '../utils/apiRequests'
import { PaginationDefaults, status } from '../utils/constants'
import { retrieveErrorMessage } from '../utils/helpers'
import uiStore from './uiStore'
import authStore from './authStore'

export interface ITransaction {
  actor: any,
  amount: number,
  created_at: string,
  transaction_type: string,
  object: any,
}

export interface ITransactionResponse {
  results: ITransaction[],
  pagination: IPagination
}

export interface IPayoutHistoryResponse {
  results: IPayoutHistory[],
  pagination: IPagination
}

export interface ISpendSummary {
  received_credits?: number;
  this_months_spend?: number;
  transferred_credits?: number;
}

export interface ITransferCreditPayload {
  amount: number,
  to_username: string,
  password: string
}

export interface IPaymentCompletion {
  reference?: string,
  amount?: number,
}

class CreditStore {
  userCreditStatus: Status = null
  userCreditErrMessage: string = ''
  creditBalance: number = -1

  spendSummaryStatus: Status = null
  spendSummaryErrMessage: string = ''
  spendSummary: ISpendSummary = {}

  transactionHistoryStatus: Status = null
  transactionHistory: ITransactionResponse = {
    results: [],
    pagination: PaginationDefaults
  }
  transactionHistoryErrMessage: string = ''

  payoutHistory: IPayoutHistoryResponse = {
    results: [],
    pagination: PaginationDefaults
  }
  payoutHistoryStatus: Status = null
  payoutHistoryErrMessage: string = ''

  payoutSummary: IPayoutSummary = {} as IPayoutSummary
  payoutSummaryStatus: Status = null
  payoutSummaryErrMessage: string = ''

  transferringCreditStatus: Status = null
  transferringCreditErrMessage: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  async getUserCredit() {
    if (!authStore.userIsLoggedIn) return
    this.setUserCreditStatus(status.LOADING)

    try {
      const response = await getUserCreditRequest()
      runInAction(() => {
        this.creditBalance = response.data.balance
        this.userCreditStatus = status.COMPLETE
      })
    } catch (e) {
      runInAction(() => {
        this.userCreditErrMessage = retrieveErrorMessage(e)
        this.userCreditStatus = status.FAILED
      })
    }
  }

  async getCreditSpendSummary(showLoading?: boolean) {
    if (showLoading && !this.fetchedSpendSummary) {
      uiStore.setLoadingScreenMessage('Fetching your spend summary')
    }
    this.setSpendSummaryStatus(status.LOADING)

    try {
      const response = await getCreditSpendSummaryRequest()
      runInAction(() => {
        this.spendSummary = response.data
        this.spendSummaryStatus = status.COMPLETE
      })
    } catch (e) {
      runInAction(() => {
        this.spendSummaryErrMessage = retrieveErrorMessage(e)
        this.spendSummaryStatus = status.FAILED
      })
    }

    if (showLoading) uiStore.setLoadingScreenMessage('')
  }

  async transferCredit(payload: ITransferCreditPayload, onError?: () => void, onSuccess?: () => void) {
    this.setTransferringCreditStatus(status.LOADING)

    try {
      const response = await transferCreditRequest(payload)
      runInAction(() => {
        this.creditBalance = response.data.balance
        this.transferringCreditStatus = status.COMPLETE
      })

      if (onSuccess) onSuccess()
      this.getCreditSpendSummary()
      this.getTransactionHistory({ page: 1 })
    } catch (e) {
      runInAction(() => {
        this.transferringCreditErrMessage = retrieveErrorMessage(e)
        this.transferringCreditStatus = status.FAILED
      })

      if (onError) onError()
    }
  }

  async getTransactionHistory({ getNext, page=1 }: IPaginatedResource) {
    if (this.transactionHistoryStatus === status.LOADING) return

    if (!getNext) this.setTransactionHistoryStatus(status.LOADING)

    try {
      const pageToFetch = getNext ? this.transactionHistory.pagination.current_page + 1 : page
      const response = await getTransactionHistoryRequest(pageToFetch)

      runInAction(() => {
        const { results, pagination } = response.data
        this.transactionHistory = {
          results: getNext ? [...this.transactionHistory.results, ...results] : results,
          pagination,
        }
        this.transactionHistoryStatus = status.COMPLETE
      })
    } catch (e) {
      runInAction(() => {
        this.transactionHistoryStatus = status.FAILED
        this.transactionHistoryErrMessage = retrieveErrorMessage(e)
      })
    }
  }

  async completeCreditPayment(payload: IPaymentCompletion) {
    uiStore.setLoadingScreenMessage('Confirming your payment...')

    try {
      const response = await completeCreditPaymentRequest(payload)
      
      runInAction(() => {
        this.creditBalance = response.data.balance
      })

      uiStore.displayModal({
        message: 'Credit Purchase Successful',
        variant: 'success'
      })

      this.getTransactionHistory({ page: 1 })
    } catch {
      uiStore.displayModal({
        message: 'Failed to comfirm payment!',
        variant: 'error'
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async getPayoutSummary(competitionUsername: string, seasonId: string) {
    if (this.fetchingPayoutSummary) return 

    runInAction(() => {
      this.payoutSummaryStatus = status.LOADING
    })

    try {
      const response = await getPayoutSummaryRequest(competitionUsername, seasonId)
      
      runInAction(() => {
        this.payoutSummaryStatus = status.COMPLETE
        this.payoutSummary = response.data
      })
    } catch (e) {
      console.error(retrieveErrorMessage(e))
      runInAction(() => {
        this.payoutSummaryStatus = status.FAILED
      })
    }
  }

  async getPayoutHistory(competitionUsername: string, seasonId: string, { page, getNext }: IPaginatedResource) {
    if (this.fetchingPayoutHistory) return
    runInAction(() => this.payoutHistoryStatus = status.LOADING)

    try {
      const pageToFetch = getNext ? this.payoutHistory.pagination.current_page + 1 : page
      const response = await getPayoutHistoryRequest(competitionUsername, seasonId, pageToFetch)
      const { results, pagination } = response.data
      
      runInAction(() => {
        this.payoutHistory = {
          results: getNext ? [...this.payoutHistory.results, ...results] : results,
          pagination,
        }
        this.payoutHistoryStatus = status.COMPLETE
      })
    } catch (e) {
      console.error(retrieveErrorMessage(e))
      runInAction(() => {
        this.payoutHistoryStatus = status.FAILED
      })
    }
  }

  setUserCreditStatus(status: Status) {
    this.userCreditStatus = status
  }

  setSpendSummaryStatus(status: Status) {
    this.spendSummaryStatus = status
  }

  setTransactionHistoryStatus(status: Status) {
    this.transactionHistoryStatus = status
  }

  setTransferringCreditStatus(status: Status) {
    this.transferringCreditStatus = status
  }

  canTransferCreditError = (payload: ITransferCreditPayload) => {
    const { amount, to_username } = payload
    
    if (to_username === authStore.username) {
      return 'You cannot transfer credit to yourself'
    }

    if (this.creditBalance < amount) {
      return 'You do not have sufficient balance for this operation'
    }

    return ''
  }

  get userCreditBalance() {
    return this.creditBalance || 0
  }

  get transferCreditLoading() {
    return this.transferringCreditStatus === status.LOADING
  }

  get transferCreditFailed() {
    return this.transferringCreditStatus === status.FAILED
  }

  get transferCreditComplete() {
    return this.transferringCreditStatus === status.COMPLETE
  }

  get hasTransactionHistory() {
    return !!this.transactionHistory.results.length
  }

  get fetchingPayoutSummary() {
    return this.payoutSummaryStatus === status.LOADING
  }

  get fetchingPayoutHistory() {
    return this.payoutHistoryStatus === status.LOADING
  }

  get fetchingSpendSummary() {
    return this.spendSummaryStatus === status.LOADING
  }

  get fetchedSpendSummary() {
    return this.spendSummaryStatus === status.COMPLETE
  }

  get payoutSummaryLoaded() {
    return !isEmpty(this.payoutSummary)
  }

  get hasMoreTransactionHistory() {
    return this.transactionHistory.pagination.has_next
  }

  get allTransactionHistory() {
    return this.transactionHistory.results
  }

  get loadingTransactionHistory() {
    return this.transactionHistoryStatus === status.LOADING
  }

  get hasMorePayoutHistory() {
    return this.payoutHistory.pagination.has_next
  }

  get hasPayoutHistory() {
    return !!this.payoutHistory.results.length
  }

  get allPayoutHistory() {
    return this.payoutHistory.results
  }
}

export default new CreditStore()