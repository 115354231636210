import React, { FunctionComponent } from 'react'
import { faClock, faComments } from '@fortawesome/free-solid-svg-icons'
import { RouteChildrenProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import Button from '../../components/Button'
import Link from '../../components/Link'
import Text, { BoldText } from '../../components/Text'
import { colors, fontSize, spacing } from '../../styles/_var'
import { TextAndIcon } from '../../components/StyledComponents'
import Message from '../../components/Message'

interface IDiscussionsProps extends RouteChildrenProps {
  className?: string
}

const randomNumber = () => Math.floor(Math.random() * 1221)

interface IDiscussionItemProps {
  pathname?: string;
  fullPost?: boolean;
}

export const DiscussionItem: FunctionComponent<IDiscussionItemProps> = React.memo(({
  pathname='',
  fullPost
}) => {
  return (
    <ItemWrapper fullPost={fullPost}>
      <IconWrapper>
        <FontAwesomeIcon icon={faComments} size="2x" color={colors.purple} />
      </IconWrapper>
      <DiscussionSummary>
        <TitleSection>
          <BoldText variant="medium">Nengi is fucking crazy!</BoldText>
          <TextAndIcon>
            <FontAwesomeIcon icon={faClock} />
            <Text variant="small">4 days ago</Text>
          </TextAndIcon>
        </TitleSection>
        <Text variant="regular">The way this works i really fucking hate nengi and the bullshit she does, she needs to mind her business!</Text>
        {!fullPost && <Link to={pathname}>
          <Text variant="subtext">Read more...</Text>
        </Link>}
      </DiscussionSummary>
    </ItemWrapper>
  )
})

const Discussions: FunctionComponent<IDiscussionsProps> = (props) => {
  const pathname = props.match?.url.replace(/\/$/, '')
  const ready = false;

  if (!ready) {
    return (
      <Message message="Section not enabled" type="error" />
    )
  }

  return (
    <Container>
      <NewDiscussionButton>
        Start New Discussion
      </NewDiscussionButton>
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
      <DiscussionItem pathname={`${pathname}/${randomNumber()}`} />
    </Container>
  )
}

export default Discussions

const NewDiscussionButton = styled(Button)`
  font-size: ${fontSize.md};
  margin: auto;
  display: block;
`

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  padding: 0 ${spacing.xs};
`

const ItemWrapper = styled.div<{fullPost?: boolean}>`
  padding: ${spacing.xs} 0;
  display: flex;
  ${({ fullPost }) => !fullPost && `border-bottom: solid 1px ${colors.lightgrey};`}

  & a {
    display: inline-block;
  }
`

const DiscussionSummary = styled.div`
  flex: 1;
`

const IconWrapper = styled.div`
  padding: ${spacing.xs};
  border-radius: 1rem;
  background: ${colors.verylightpurple};
  margin-right: ${spacing.xs};
  max-height: 2rem;
`
