import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Link from './Link'
import Text from './Text'
import { colors, spacing } from '../styles/_var'
import { ICompetition } from '../utils/interfaces'
import { plurify } from '../utils/helpers'
import competitionPlaceholder from '../assets/images/competition-placeholder.png'
import competitionStore from '../stores/competitionStore'

interface IAsideCompetitionList {
  competitions: ICompetition[],
}

const getCategoryName = (categoryId?: string) => {
  if (!categoryId) return

  const categoryObject = competitionStore.categories.find((category) => category.id === +categoryId)
  if (categoryObject) return categoryObject.name

  return categoryId
}


const CompetitionItem: FunctionComponent<Partial<ICompetition>> = ({
  name,
  logo,
  category,
  username,
  seasons=[]
}) => (
  <AVWrapper role="link" to={`/${username}`}>
    <AVLogo src={logo || competitionPlaceholder} />
    <Content>
      <Text variant="regular" color={colors.darkpurple}>{name}</Text>
      <CompetitionSummary role="status" variant="small" color={colors.grey}>
        {getCategoryName(category)} | {plurify('season', seasons.length)}
      </CompetitionSummary>
    </Content>
  </AVWrapper>
)

const AsideCompetitionList: FunctionComponent<IAsideCompetitionList> = ({
  competitions
}) => {
  return (
    <>
      {competitions.map(competition => (
        <CompetitionItem 
          key={competition.id}
          {...competition} />
      ))}
    </>
  )
}

export default AsideCompetitionList

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    line-height: 2.2rem;
    font-weight: 600;
    opacity: 0.8;
  }

  a p {
    margin-top: 10px;
  }
`

const AVWrapper = styled(Link)`
  margin-top: ${spacing.xs};
  display: flex;
  
  text-decoration: none;
  transition: all ease .2s;
  
  &:hover {
    opacity: .7;
  }
`

const AVLogo = styled(({ src, ...rest}) => <div {...rest} />)`
  box-shadow: 0 0 5px 1px ${colors.lightgrey};
  border-radius: 4px;
  width: 60px;
  height: 45px;
  margin-right: ${spacing.xs};
  background-size: cover;
  background-repeat: no-repeat;
  ${({src}) => src && `
    background-image: url("${src}");
  `};
`

const CompetitionSummary = styled(Text)`
  opacity: 0.1;
`


