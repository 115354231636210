import React, { FunctionComponent, useState } from 'react'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import Input from './Input'
import SimpleForm, { IActionButton } from './SimpleForm'
import TextArea from './TextArea'
import UploadPane from './UploadPane'
import { device, spacing } from '../styles/_var'
import { ContestantWithBio } from '../utils/interfaces'
import validators from '../utils/validators'
import { formatToUsername, getCompetitionLabels } from '../utils/helpers'


interface IAddContestantForm {
  style?: any;
  className?: string;
  submit: (data: ContestantWithBio, onComplete?: () => void) => void,
  submitting?: boolean,
  onAvatarUploaded: (avatar: string) => void,
  alreadyAddedContestant?: boolean,
  editMode?: boolean,
  initialValues?: any,
  competitionTemplate?: number
}

const AddContestantForm: FunctionComponent<IAddContestantForm> = ({
  submit,
  submitting,
  onAvatarUploaded,
  alreadyAddedContestant,
  editMode,
  initialValues,
  competitionTemplate,
  ...rest
}) => {
  const contestantLabel = getCompetitionLabels(competitionTemplate).contentstantLabel
  const addContestantFormFields = [
    {
      name: 'first_name',
      placeholder: 'First Name',
      component: Input,
      validate: validators.required
    },
    {
      name: 'last_name',
      placeholder: 'Last Name',
      component: Input,
      validate: validators.required
    },
    {
      name: 'username',
      placeholder: `Username/${contestantLabel} Number`,
      format: formatToUsername,
      component: Input,
      validate: validators.max(20)
    },
    {
      name: 'about_me',
      placeholder: 'About',
      component: TextArea
    },
    {
      name: 'dob',
      placeholder: 'Date of birth',
      type: 'date',
      component: Input
    },
    {
      name: 'nationality',
      placeholder: 'Nationality',
      component: Input
    },
    {
      name: 'state_of_origin',
      placeholder: 'State of Origin',
      component: Input,
    },
    {
      name: 'occupation',
      placeholder: 'Occupation',
      component: Input,
    },
    {
      name: 'height',
      placeholder: 'Height (in ft)',
      type: 'number',
      component: Input,
    },
    {
      name: 'weight',
      placeholder: 'Weight (in kg)',
      type: 'number',
      component: Input,
      iconPosition: 'right',
      icon: faBalanceScale,
    },
    {
      name: 'degree',
      placeholder: 'Degree',
      component: Input
    },
    {
      name: 'university',
      placeholder: 'University',
      component: Input
    }
  ]

  const contestantId = initialValues?.id
  const [showContestantAvatar, setShowContestantAvatar] = useState(true)

  const resetUploadImage = () => {
    setShowContestantAvatar(false)
    setTimeout(() => setShowContestantAvatar(true), 100);
  }

  const getButtonLabel = () => {
    if (editMode) return `Update ${contestantLabel}`
    if (alreadyAddedContestant) return 'Add Another'
    return `Add ${contestantLabel}`
  }

  const addContestantFormAction: IActionButton = {
    label: getButtonLabel(),
    isSubmit: true,
    variant: 'secondary',
    loading: submitting
  }

  const submitForm = (data: any, form: any) => {
    const onComplete = () => {
      setTimeout(() => {
        form.restart()
        resetUploadImage()
      }, 100)
    }

    if (editMode) {
      submit({...data, id: contestantId}, onComplete)
    } else {
      submit(data, onComplete)
    }
  }

  return (
    <Wrapper {...rest}>
      <ComponentBody>
        {showContestantAvatar && <StyledUploadPane
          initialImage={(editMode && initialValues.avatar) ? initialValues.avatar : undefined}
          title={`Upload ${contestantLabel} Image`}
          aspectRatio={1}
          maxWidth={400}
          onImageChange={onAvatarUploaded}
          dimensionsText="The best dimensions for this image is 200px by 200px" />}
        <StyledSimpleForm
          role="form"
          aria-label={editMode ? `edit ${contestantLabel} form` : `add ${contestantLabel} form`}
          initialValues={initialValues}
          fieldsToShow={3}
          formFields={addContestantFormFields}
          actionButtons={addContestantFormAction}
          submitForm={submitForm} />
      </ComponentBody>
    </Wrapper>
  )
}

export default AddContestantForm

const Wrapper = styled(({ ...rest }) => <div {...rest} />)`
`

const ComponentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: unset;
  }
`

const StyledUploadPane = styled(UploadPane)`
  height: 28rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.sm};
  box-sizing: border-box;
`

const StyledSimpleForm = styled(SimpleForm)`
  width: 100%;
  margin-top: ${spacing.sm};

  @media ${device.laptop} {
    margin-top: unset;
  }
`