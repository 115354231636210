import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Text, { BoldText } from '../../components/Text'
import TextArea from '../../components/TextArea'
import seasonStore from '../../stores/seasonStore'
import { colors, fontSize, spacing } from '../../styles/_var'
import { Table, TableWrapper, TextWithIcon } from '../VoterDashboard/components/VoterDashboardStyled'
import { CompetitionPageWrapper } from './StyledComponents'
import EmptyResource from '../../components/EmptyResource'
import { observer } from 'mobx-react-lite'
import { beautifyDate } from '../../utils/helpers'
import uiStore from '../../stores/uiStore'
import eligibleVoterStore from '../../stores/eligibleVoterStore'
import competitionStore from '../../stores/competitionStore'
import { useHistory } from 'react-router'
import { routes } from '../../utils/constants'
import { ErrorMessage } from '../../components/StyledComponents'
import usePageTitle from '../../hooks/usePageTitle'

const AllowedVoters = () => {
  const {
    eligibleVoters,
    pendingInvitations,
    hasEligibleVoters,
    hasPendingInvitations,
    invitationPagination,
    votersPagination
  } = eligibleVoterStore
  const history = useHistory()
  const { has_next: inviteHasNext } = invitationPagination
  const { has_next: votersHasNext } = votersPagination
  const [canShowAddModal, setShowAddModal] = useState(false)
  const [voters, setVoters] = useState('')
  const [validationError, setValidationError] = useState('')
  const seasonId = seasonStore.selectedSeasonId
  usePageTitle('Eligible Voters')

  useEffect(() => {
    if (competitionStore.isCompetitionPublic) {
      history.push(routes.FORBIDDEN)
    } else {
      eligibleVoterStore.fetchEligibleVoters(seasonId, 1)
      eligibleVoterStore.fetchPendingInvitations(seasonId, 1)
          
    }

    
  }, [seasonId, history])

  const toggleModal = () => setShowAddModal(!canShowAddModal)

  const onVotersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVoters(e.target.value)
  }

  const onRevokeInvitation = (email: string) => () => {
    uiStore.openConfirmDialog("Are you sure you want to revoke this invitation", () => {
      eligibleVoterStore.revokeInvitation(seasonId, email)
    })
  }

  const onRemoveEligibleVoter = (email: string) => () => {
    uiStore.openConfirmDialog("Are you sure you want to remove this voter", () => {
      eligibleVoterStore.removeEligibleVoter(seasonId, email)
    })
  }

  const addVoters = async () => {
    const [validVoters, invalidVoters] = eligibleVoterStore.parseVoterString(voters)

    if (invalidVoters.length) {
      setValidationError(eligibleVoterStore.constructErrorMessage(invalidVoters))
    } else {
      await eligibleVoterStore.saveEligibleVoters(seasonId, validVoters)
      toggleModal()
      setVoters('')
    }
  }

  const onVoterInputFieldFocus = () => {
    setValidationError('')
  }

  const renderAllowedVoters = () => {
    return (
      <div role="region" aria-label="allowed voters section">
        <Text variant="medium">Users</Text>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <td><Text variant="regular">First Name</Text></td>
                <td><Text variant="regular">Last Name</Text></td>
                <td><Text variant="regular">Email</Text></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {eligibleVoters.map(({ voter }, i) => (
                <tr key={i} role="listitem" aria-label={voter.email}>
                  <td><Text variant="subtext">{voter.first_name}</Text></td>
                  <td><Text variant="subtext">{voter.last_name}</Text></td>
                  <td><Text variant="subtext">{voter.email}</Text></td>
                  <td>
                    <DeleteLink
                      variant="subtext"
                      onClick={onRemoveEligibleVoter(voter.email)}
                      role="button">
                      delete
                    </DeleteLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        {votersHasNext && (
          <Button
            alignCenter
            variant='secondary'
            mt={1}
            onClick={() => eligibleVoterStore.fetchEligibleVoters(seasonId)}
          >
            Load More
          </Button>
        )}
      </div>
    )
  }

  const renderPendingInvitations = () => {
    return (
      <PendingInvitationSection role="region" aria-label="pending invitations section">
        <Text variant="medium">Pending Invitations</Text>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <td><Text variant="regular">Email</Text></td>
                <td><Text variant="regular">Date sent</Text></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {pendingInvitations.map((invite, i) => (
                <tr key={i} role="listitem" aria-label={invite.email}>
                  <td><Text variant="subtext">{invite.email}</Text></td>
                  <td><Text variant="subtext">{beautifyDate(invite.created_at)}</Text></td>
                  <td>
                    <DeleteLink
                      variant="subtext"
                      onClick={onRevokeInvitation(invite.email)}
                      role="button"
                    >
                      delete
                    </DeleteLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        {inviteHasNext && (
          <Button
            variant='secondary'
            alignCenter
            loading={eligibleVoterStore.fetchingAllowedVoters}
            mt={1}
            onClick={() => eligibleVoterStore.fetchPendingInvitations(seasonId)}>
            Load More
          </Button>
        )}
      </PendingInvitationSection>
    )
  }

  return (
    <CompetitionPageWrapper>
      <HeaderSection>
        <Text variant="large" mb={2}>ELIGIBLE VOTERS</Text>
        <StyledTextWithIcon
          onClick={toggleModal}
          role="button"
          aria-label="add-voter-button"
        >
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />
          <BoldText variant="regular" ml={1} id="add-voter-button">
            ADD ELIGIBLE VOTERS
          </BoldText>
        </StyledTextWithIcon>
      </HeaderSection>
      {!hasEligibleVoters && (
        <EmptyResource
          createResourceAction={() => toggleModal()}
          createResourceButtonText="Add Eligible Voters"
          createResourceText="You haven't added any voters to this competition" />
      )}
      {hasEligibleVoters && renderAllowedVoters()}
      {hasPendingInvitations && renderPendingInvitations()}
      <Modal showModal={canShowAddModal} onClose={toggleModal}>
        <Text variant="medium" id="modal-message">Enter Voter Email</Text>
        <EligibleVotersInput 
          onFocus={onVoterInputFieldFocus}
          onChange={onVotersChange}
          value={voters} />
        <Text variant="subtext">separate multiple users with a comma (,) or space</Text>
        <Button
          disabled={!voters.trim()}
          mt={1}
          onClick={addVoters}
          invertOnHover
          alignCenter>
          Add Voter(s)
        </Button>
        {!!validationError && (
          <ValidationErrorMessage role="alert" aria-label={validationError}>
            {validationError}
          </ValidationErrorMessage>
        )}
      </Modal>
    </CompetitionPageWrapper>
  )
}

export default observer(AllowedVoters)

const ValidationErrorMessage = styled(ErrorMessage)`
  position: relative;
  box-sizing: border-box;
  color: ${colors.red};
`

const EligibleVotersInput = styled(TextArea)`
  font-size: ${fontSize.sm};
  line-height: 1.9rem;
`

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.sm};
`

const StyledTextWithIcon = styled(TextWithIcon)`
  color: ${colors.purple};
  cursor: pointer;
  transition: all ease .2s;
  height: 2rem;

  &:hover {
    opacity: .8;
    text-shadow: 1px 1px ${colors.lightgrey};
  }
`

const DeleteLink = styled(Text)`
  cursor: pointer;
  color: ${colors.purple};

  &:hover {
    text-decoration: underline;
  }
`

const PendingInvitationSection = styled.div`
  margin-top: ${spacing.sm};
`
