import { observer } from 'mobx-react-lite'
import React, { FunctionComponent, useMemo } from 'react'
import orderBy from 'lodash/orderBy'
import styled from 'styled-components'
import Button from '../../components/Button'
import ContestantThumbnail from '../../components/ContestantThumbnail'
import Dropdown from '../../components/Dropdown'
import Message from '../../components/Message'
import Text from '../../components/Text'
import { device, spacing } from '../../styles/_var'
import { ContestantWithBio } from '../../utils/interfaces'
import { AllContestantWrapper } from './AllContestants'
import votingRoundStore from '../../stores/votingRoundStore'
import contestantStore from '../../stores/contestantStore'
import uiStore from '../../stores/uiStore'
import seasonStore from '../../stores/seasonStore'
import CompetitionCategoryDropdown from '../../components/CompetitionCategoryDropdown'

interface IResults {
  editMode?: boolean;
}

const Results: FunctionComponent<IResults> = ({ editMode }) => {
  const {
    fetchingVotingRound,
    votingRounds,
    selectedVotingRound
  } = votingRoundStore
  const { contestants } = contestantStore

  const votingRoundsDropdownList = useMemo(() => {
    return votingRounds.map(({ label }) => label)
  }, [votingRounds])

  const activeContestants = orderBy(votingRoundStore.filterContestantsPageByCategory(selectedVotingRound), 'votes', 'desc')


  const getContestantByUsername = (username: string): ContestantWithBio => {
    return contestants.find(c => c.username === username) || ({} as ContestantWithBio)
  }

  if (fetchingVotingRound && !activeContestants.length) {
    return <Message message="Fetching voting rounds" />
  }

  if (!votingRounds.length) {
    return <Message message="There are no voting rounds." type="error" />
  }

  const totalVotes = activeContestants.reduce((acc, c) => acc + c.votes, 0)

  const calculateVotePercent = (vote: number) => {
    return totalVotes === 0 ? '0%' : `${Math.floor((vote/totalVotes * 100))}%`
  }

  return (
    <Container>
      <ActionButtons>
        <CompetitionCategoryDropdown isCompetitionPage />
        <Dropdown
          style={{marginTop: spacing.sm}}
          aria-label="voting rounds"
          list={votingRoundsDropdownList}
          onItemSelect={label => votingRoundStore.setSelectedVotingRoundByLabel(label)}
          selected={selectedVotingRound.label} />
      </ActionButtons>
      <AllContestantWrapper>
        {activeContestants.map((contestant, index) => {
          const activeContestant = getContestantByUsername(contestant.user)
          return (
            <ContestantThumbnail
              variant="results"
              key={index}
              winner={selectedVotingRound.has_ended && index === 0}
              votes={contestant.votes}
              showVoteCount={selectedVotingRound?.voting_options?.show_vote_count}
              votePercentage={calculateVotePercent(contestant.votes)}
              {...activeContestant}
              evicted={contestant.evicted}
            />
          )
        })}
      </AllContestantWrapper>
      { editMode && <EndVotingButton><Text variant="medium">End Voting Round</Text></EndVotingButton>}
      { !editMode && (
        <ReloadButton
          variant="secondary"
          onClick={() => {
            votingRoundStore.fetchVotingRounds(
              seasonStore.selectedSeasonId,
              () => uiStore.showToastMessage({
                message: 'Page has been refreshed',
                duration: 3000,
              })
            )
          }}
        >
          Reload Page
        </ReloadButton>
      )}
    </Container>
  )
}

export default observer(Results)

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin: 0 0 ${spacing.xs} 0;
  }
`

const EndVotingButton = styled(Button)`
  margin: auto;
  width: 25rem;
  display: block;
  margin-bottom: ${spacing.xs};
  max-width: 100%;
`

const ReloadButton = styled(Button)`
  display: block;
  margin: ${spacing.sm} auto;
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: row;
  }
`
