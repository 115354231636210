import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { BoldText } from '../Text'
import { SidebarContent } from './styles'
import AsideCompetitionList from '../AsideCompetitionList'
import competitionStore from '../../stores/competitionStore'
import { mapToProps } from '../../utils/helpers'

interface IPopularCompetitions {
  store: typeof competitionStore
}

const PopularCompetitions: FunctionComponent<IPopularCompetitions> = observer(({
  store
}) => {
  useEffect(() => {
    store.fetchPopularCompetitions()
  }, [store])


  if (!store.hasPopularCompetitions) return null

  return (
    <SidebarContent>
      <BoldText variant="medium">POPULAR COMPETITIONS</BoldText>
      <AsideCompetitionList competitions={store.popularCompetitions} />
    </SidebarContent>
  )
})

export default mapToProps({ store: competitionStore }, PopularCompetitions)

