import React, { FunctionComponent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { colors, spacing } from '../../styles/_var'
import { AddContestantButton } from './CompetitionDashboard'
import Text from '../../components/Text'
import competitionStore from '../../stores/competitionStore'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import uiStore from '../../stores/uiStore'

interface ICompetitionCategories {

}

const CompetitionCategories: FunctionComponent<ICompetitionCategories> = () => {
  const [showCategoryForm, setShowCategoryForm] = useState(false)
  const [editID, setEditID] = useState<null | number>(null)
  const [categoryName, setCategoryName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const categories = competitionStore.competitionDashboardDetails.categories || []
  const noCategories = !categories?.length

  const submitForm = () => {
    if (!categoryName.trim()) return setErrorMessage('This field is required')

    if (editID) {
      competitionStore.updateCategory(editID, categoryName)
    } else {
      competitionStore.createCategory(categoryName)
    }

    setCategoryName('')
    setShowCategoryForm(false)
  }

  const deleteCategory = (categoryId: number) => () => {
    uiStore.openConfirmDialog('This would also delete all the contestants created under this category. Are you sure you want to continue?', () => {
      competitionStore.deleteCategory(categoryId)
    })
  }

  const closeForm = () => {
    setEditID(null)
    setCategoryName('')
    setShowCategoryForm(false)
  }

  const updateCategory = (id: number, name: string) => () => {
    setEditID(id)
    setCategoryName(name)
    setShowCategoryForm(true)
  }

  const onInputKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode || e.charCode
    const key = e.code || e.key 

    if (keyCode === 13 || key === 'Enter') submitForm()
  }


  return (
    <>
      <Modal onClose={closeForm} showModal={showCategoryForm}>
        <Box textAlign='center'>
          <Input 
            onKeyPress={onInputKeyPress}
            onFocus={() => setErrorMessage('')}
            errorMessage={errorMessage}
            placeholder='Enter category name'
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
          />
          <Button mt={2} onClick={submitForm}>
            {!!editID ? 'Update' : 'Create'} Category
          </Button>
        </Box>
      </Modal>
      <CategoriesWrapper>
        <AddContestantButton
          mb={3}
          variant="secondary"
          onClick={() => setShowCategoryForm(true)}>
          <FontAwesomeIcon icon={faPlus} />
          <Text variant="regular">Add New Category</Text>
        </AddContestantButton>
        {noCategories && <Text variant='regular' center color={colors.red}>You haven't created any categories yet</Text>}
        {!noCategories && (
          <Grid container spacing={2}>
            {categories.map(({ id, name }) => (
              <Grid item xs={12} sm={6} md={4}  key={id}>
                <Paper style={{padding: spacing.sm, textAlign: 'center'}} elevation={5}>
                  <Text variant='medium' center>{name}</Text>
                  {!!id && (
                    <>
                      <Button variant='secondary' mr={1} onClick={updateCategory(id, name)}>
                        Edit
                      </Button>
                      <Button variant='danger' onClick={deleteCategory(id)}>
                        Delete
                      </Button>
                    </>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </CategoriesWrapper>
    </>
  )
}

export default observer(CompetitionCategories)

const CategoriesWrapper = styled.div``
