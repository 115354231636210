import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text, { BoldText } from '../Text'
import { colors, spacing } from '../../styles/_var'
import { SidebarContent } from './styles'

const CompetitionUpdates: FunctionComponent = () => {
  return (
    <Updates>
      <BoldText variant="medium">UPDATES</BoldText>
      {updates.map((update, i) => (
        <Text variant="regular" key={i}>{update}</Text>
      ))}
    </Updates>
  )
}

export default CompetitionUpdates

const Updates = styled(SidebarContent)`
  p {
    margin-bottom: ${spacing.xs};
    padding-bottom: ${spacing.xs};
    border-bottom: solid 2px ${colors.lightgrey};
    opacity: 0.7;
  }
`
const updates = [
  '2 contestants have been evicted in the Gulder Ultimate Competition',
  'A new voting round has started in the Misis Nigeria Competition',
  'Voting round has closed for Big Brother Competition'
]