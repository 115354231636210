import React, { FunctionComponent, useRef } from 'react'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'


interface IMInput extends React.InputHTMLAttributes<HTMLInputElement> {
  inputLabel: string;
  hasError?: boolean;
}

const MInput: FunctionComponent<IMInput> = (props) => {
  const { className, hasError, inputLabel, ...rest } = props
  const { placeholder } = props

  if (!inputLabel) delete rest.placeholder

  const showLabel = !!(inputLabel || placeholder)
  const inputRef = useRef<HTMLInputElement>(null)

  const onWrapperClicked = () => inputRef?.current?.focus()

  return (
    <MInputWrapper
      disabled={props.disabled}
      className={className}
      onClick={onWrapperClicked}>
      <Content>
        <InputSection>
          {!!showLabel && <Label htmlFor={props.name}>
            <Text variant='small'>{inputLabel || placeholder}</Text>
          </Label>}
          <input {...rest} ref={inputRef} aria-label={inputLabel || placeholder} />
        </InputSection>
        <FontAwesomeIcon
          icon={hasError ? faTimesCircle : faCheckCircle}
          color={hasError ? colors.red : colors.purple}
          size="2x" />
      </Content>
    </MInputWrapper>
  )
}

export default MInput

const MInputWrapper = styled(({ disabled, ...rest }) => <div {...rest} />)`
  ${({ disabled }) => disabled && `
    background: ${colors.lightpurple};
  `}
  background: ${colors.white};
  box-sizing: border-box;
  border: solid 1px ${colors.lightgrey};
  padding: ${spacing.xxs} ${spacing.xs};

  input {
    border: none;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Gilroy';

    &:focus {
      outline: none;
    }
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: ${spacing.xxs};
  padding-left: 2px;
  color: ${colors.grey};
`

const InputSection = styled.div`
  flex: 1;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`