import React, { FunctionComponent, useRef, useState } from 'react'
import ImagePreviewer from './ImagePreviewer'
import { HiddenFileInput } from './StyledComponents'

export interface IContentAreaArgs {
  croppedImage: string,
  rawImage: string,
  openFileChooser: () => void,
}

interface IImageUpload {
  contentArea: (payload: IContentAreaArgs) => any,
  aspectRatio?: number,
  maxWidth?: number,
  onImageChange?: (image: string) => void,
}

const ImageUpload: FunctionComponent<IImageUpload> = ({
  onImageChange,
  aspectRatio=1,
  maxWidth=500,
  contentArea
}) => {
  const input:any = useRef(null);
  const [croppedImage, setCroppedImage] = useState('')
  const [rawImage, setRawImage] = useState('')

  const openFileChooser = () => {
    input.current?.click()
  }

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = evt.target
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      
      reader.onload = (e:any) => {
        setRawImage(e.target.result)
      }
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  const onComplete = (imageUrl: string) => {
    onImageChange && onImageChange(imageUrl)
    setCroppedImage(imageUrl)
  }

  return (
    <>
      {contentArea({ openFileChooser, rawImage, croppedImage })}
      <HiddenFileInput
        type="file"
        name="myImage"
        accept="image/*"
        ref={input}
        onChange={onInputChange} />
      {!!rawImage && (
        <ImagePreviewer
          aspectRatio={aspectRatio}
          imageUrl={rawImage}
          maxWidth={maxWidth}
          onClose={() => setRawImage('')}
          onComplete={onComplete} />
      )}
    </>
  )
}

export default ImageUpload



