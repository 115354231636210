import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Link from './Link'
import Text from './Text'

export interface ICrumb {
  name: string;
  route?: string;
}

interface IBreadcrumbs extends React.HTMLAttributes<HTMLDivElement> {
  crumbs: ICrumb[],
}

const Breadcrumbs: FunctionComponent<IBreadcrumbs> = React.memo(({ crumbs=[], ...rest }) => {
  const renderCrumb = (crumb: ICrumb, index: number) => {
    const hasLink = !!crumb.route

    return (
      <Crumb role="section" key={index} hasLink={hasLink} {...rest}>
        {!hasLink && <Text variant="small" role="paragraph" inline>{crumb.name}</Text>}
        {crumb.route && <Link to={crumb.route} role="link"><Text variant="small" inline>{crumb.name}</Text></Link>}
      </Crumb>
    )
  }

  return (
    <BreadcrumbWrapper>
      {crumbs.map(renderCrumb)}
    </BreadcrumbWrapper>
  )
})

export default Breadcrumbs


const BreadcrumbWrapper = styled.div`
  display: flex;
`

const Crumb = styled(({ hasLink, ...rest }) => <div {...rest} />)`
  p {
    display: inline-block;
    margin-right: ${spacing.xxs};
  }
  ${({ hasLink }) => !hasLink && `
    p {
      font-family: "Gilroy-bold";
    }
  `}
  ${({ hasLink }) => hasLink && `
    color: ${colors.purple};
    a:hover {
      text-decoration: underline;
    }
    &::after {
      content: '>';
      margin-right: ${spacing.xxs};
    }
  `}
`