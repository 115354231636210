import React from 'react'
import Box from '@material-ui/core/Box'
import Text, { BoldText } from '../../components/Text'
import { colors } from '../../styles/_var'


const ContactUsPageHeader  = () => {
  return (
    <Box textAlign="center" padding={2} borderBottom="3px solid #ccc">
      <BoldText variant="title" center>
        Contact Us
      </BoldText>
      <Text variant="medium" mt={3} maxWidth={600} color={colors.grey}>
        Please fill out the form below to contact us and one of our representatives would get back to you within 24 hours.
      </Text>
      <Text variant="regular" mt={3} mb={3} color={colors.grey}>
        If you'd like to talk to an expert, call <br />+234 810 533 1490 (Monday - Friday, 9am - 4pm)
      </Text>
    </Box>
  )
}


export default ContactUsPageHeader