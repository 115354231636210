import React from 'react'
import ErrorPage from '../pages/ErrorPages/ErrorPage';
import { sendUIErrorRequest } from '../utils/apiRequests';

type MyState = {
  hasError: boolean
}

type MyProps = {}

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(_: any, errorInfo: any) {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return
    }

    sendUIErrorRequest(errorInfo.componentStack).catch((_) => {
      // do nothing
    })
  }

  render() {
    const reloadPage = <a href="/">Please Reload Page!</a>
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage code="500" action={reloadPage} />
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary
