import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Text, { BoldText } from '../../../components/Text'
import ToggleSwitch from '../../../components/ToggleSwitch'
import { ContentBody, FormContainer, NotificationBody } from './style'
import authStore from '../../../stores/authStore'
import { spacing } from '../../../styles/_var'


const Notification = () => {
  const [switchState, setSwitchState] = useState(false)

  const onNotificationsChange = (notificationState: boolean) => {
    setSwitchState(notificationState)
    authStore.updateSettings({ notification: notificationState })
  }

  return (
    <ContentBody>
      <BoldText variant="medium">NOTIFICATIONS</BoldText>
      <FormContainer>
        <NotificationBody>
          <NotificationText variant="regular">By turning on notifications switch, you have authorized matuskii to send you notifications to your mobile device</NotificationText>
          <ToggleSwitch
            id="notifications"
            checked={switchState}
            optionLabels={['On', 'Off']}
            onChange={onNotificationsChange} />
        </NotificationBody>
      </FormContainer>
    </ContentBody>
  )
}

export default observer(Notification)

const NotificationText = styled(Text)`
  margin-right: ${spacing.xs};
  flex: 1;
`