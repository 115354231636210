import React, { useState} from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Text, { BoldText } from '../../../components/Text'
import Button from '../../../components/Button'
import { ContentBody, NotificationBody, FormContainer } from './style'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import { spacing } from '../../../styles/_var'
import authStore from '../../../stores/authStore'

const DeleteAccount = () => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [deletePassword, setDeletePassword] = useState('')

  const onCloseDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(false)
  }

  const deleteUserAccount = async () => {
    if (!deletePassword) return

    const deleted = await authStore.deleteUserAccount(deletePassword)
    setShowDeleteConfirmModal(false)
    if (deleted) window.location.reload()
  }

  
  return (
    <>
      <ContentBody>
        <BoldText variant="medium">DELETE ACCOUNT</BoldText>
        <DeleteAccountContainer>
          <DeleteAccountBody>
            <BoldText variant="regular">Note</BoldText>
            <Text variant="regular">You will receive an email to confirm your decision. Please note that your account with all it’s information, products, transactions and all associated details will be permanently deleted. This action is irreversible.</Text>
          </DeleteAccountBody>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(true)}>
            Delete Account
          </Button>
        </DeleteAccountContainer>
      </ContentBody>
      <Modal
        showModal={showDeleteConfirmModal}
        onClose={onCloseDeleteConfirmModal}>
        <Text variant="regular" mb={1}>This action is Irreversible. Enter your password to proceed.</Text>
        <Input
          placeholder="password"
          type="password"
          value={deletePassword}
          onChange={e => setDeletePassword(e.target.value)} />
        <DeleteButton
          loading={authStore.deletingUserAccount}
          disabled={!deletePassword}
          variant="danger"
          onClick={deleteUserAccount}>
          Delete account!
        </DeleteButton>
      </Modal>
    </>
  )
}

export default observer(DeleteAccount)

const DeleteAccountContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DeleteButton = styled(Button)`
  margin-top: ${spacing.xs};
`
const DeleteAccountBody = styled(NotificationBody)`
  display: block;
  margin-bottom: ${spacing.sm};
`