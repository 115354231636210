import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/_var'
import { getStyleSpacing } from '../utils/helpers'
import { ComponentSpacing } from '../utils/interfaces'
import { InputStyle } from './StyledComponents'
import Text from './Text'

export interface IInputProps extends React.InputHTMLAttributes<any>, ComponentSpacing {
  disabled?: boolean;
  errorMessage?: string;
  icon?: IconDefinition;
  iconPosition?: 'left' | 'right';
  iconSpin?: boolean;
}

const Input:FunctionComponent<IInputProps> = React.memo(props => {
  const {
    icon,
    iconPosition='right',
    iconSpin,
    mt,
    mb,
    mr,
    ml,
    style={},
    errorMessage,
    ...rest
  } = props
  const iconLeft = iconPosition === 'left'
  const iconRight = iconPosition === 'right'
  
  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  if (props.errorMessage) {
    componentStyle.borderColor = colors.red
  }


  const renderIcon = () => !!icon && <FontAwesomeIcon icon={icon} size="2x" color={colors.lightgrey} spin={iconSpin} />

  return (
    <InputWrapper className={props.className || ''} style={componentStyle}>
      {!!icon && iconLeft && renderIcon()}
      <StyledInput 
        {...rest}
        style={!!errorMessage ? {borderColor: colors.red} : {}}/>
      {!!props.errorMessage && <Text color={colors.red} role="alert">{props.errorMessage}</Text>}
      {!!icon && iconRight && renderIcon()}
    </InputWrapper>
  )
})


const InputWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

const StyledInput = styled.input`${InputStyle}`

export default Input