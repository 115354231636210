import React, { FunctionComponent } from 'react'
import Slider from 'react-slick'
import { InvolvedCompetitionSection, TitleSection, TextWithIcon, Slide } from './VoterDashboardStyled'
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg'
import competitionPlaceholder from '../../../assets/images/competition-placeholder.png'
import Text from '../../../components/Text'
import { ICompetition } from '../../../utils/interfaces'
import { sliderSettings } from '../../../utils/constants'
import Link from '../../../components/Link'

interface IMyFavourites {
  competitions: ICompetition[]
}

const MyFavourites: FunctionComponent<IMyFavourites> = ({
  competitions
}) => {
  const renderCompetition = (competition: ICompetition, index: number) => {
    return (
      <Link key={index} to={`/${competition.username}`}>
        <Slide
          small
          img={competition.logo || competitionPlaceholder} />
      </Link>
    )
  }

  if (!competitions.length) return null

  return (
    <InvolvedCompetitionSection role="region" aria-label="my favourites">
      <TitleSection>
        <TextWithIcon>
          <RefreshIcon />
          <Text variant="medium">MY FAVOURITES</Text>
        </TextWithIcon>
      </TitleSection>
      <Slider {...sliderSettings(competitions.length)}>
        {competitions.map(renderCompetition)}
      </Slider>
    </InvolvedCompetitionSection>
  )
}

export default MyFavourites
