import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text, { BoldText } from '../../components/Text'
import { colors, device, spacing } from '../../styles/_var'
import Dropdown from '../../components/Dropdown'
import { ICompetition, ISeason } from '../../utils/interfaces'
import { Avatar, TextWithIconHorizontal } from '../../components/StyledComponents'
import ImageEditButton from '../../components/ImageEditButton'
import EditButton from '../../components/EditButton'
import ImageUpload from '../../components/ImageUpload'
import Button from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarO } from '@fortawesome/free-regular-svg-icons'

interface ICompetitionBannerProps extends ICompetition {
  editMode?: boolean;
  previewMode?: boolean;
  onBannerImageChange?: (image: string) => void,
  onLogoImageChange?: (image: string) => void,
  onClickEdit?: () => void,
  currentSeason?: ISeason,
  endCompetitionAction?: () => void,
  seasonCompleted?: boolean,
  setSelectedSeason?: (seasonId: string) => void,
  showFavouriteIcon?: boolean,
  isFavourite?: boolean,
  onFavourite?: () => void,
}

const CompetitionBanner: FunctionComponent<ICompetitionBannerProps> = React.memo(({
  banner,
  logo,
  name,
  editMode,
  previewMode,
  seasons: competitionSeasons,
  currentSeason,
  onBannerImageChange,
  onLogoImageChange,
  onClickEdit,
  endCompetitionAction,
  seasonCompleted,
  setSelectedSeason,
  showFavouriteIcon=false,
  isFavourite=false,
  onFavourite
}) => {
  const seasons = competitionSeasons?.map(({ number, uuid }) => ({ value: uuid, label: `Season ${number}`})) || []
  const selectedSeason = `Season ${currentSeason?.number}`

  const onSelectSeason = (seasonId: string) => {
    if (setSelectedSeason) setSelectedSeason(seasonId)
  }
  const shouldRenderBannerAction = !editMode && !previewMode

  const renderBannerAction = (container: any) => {
    const Wrapper = container;

    return (
      <Wrapper role="banner">
        {shouldRenderBannerAction && <Dropdown
          onItemSelect={onSelectSeason}
          list={seasons}
          selected={selectedSeason} />}
        {!!endCompetitionAction && !seasonCompleted && (
          <EndSeason 
            invertOnHover 
            variant="danger" 
            onClick={endCompetitionAction}
          >
            <TextWithIconHorizontal style={{color: colors.white, marginBottom: 0}}>
              <FontAwesomeIcon icon={faBan} size="2x" style={{fontSize: '16px' }} />
              <Text variant="regular">End Season</Text>
            </TextWithIconHorizontal>
          </EndSeason>
        )}
        {seasonCompleted &&  (
          <SeasonCompleteLabel>
            <Text variant="subtext" role="alert" aria-label="season completed">Season Completed</Text>
          </SeasonCompleteLabel>
        )}
      </Wrapper>
    )
  }

  return (
    <BannerSection>
      <ImageUpload
        maxWidth={900}
        aspectRatio={3/1}
        onImageChange={onBannerImageChange}
        contentArea={({ croppedImage, openFileChooser }) => (
          <>
            <BannerImage className="competition-banner" source={banner} />
            {editMode && <EditBannerImageButton onClick={openFileChooser} />}
          </>
        )} />
      <BannerBody>
        <LeftSide>
          <ImageUpload
            maxWidth={400}
            aspectRatio={1}
            onImageChange={onLogoImageChange}
            contentArea={({ croppedImage, openFileChooser }) => (
              <>
                <CompetitionAvatar className="competition-logo" src={logo} />  
                {editMode && <EditLogoButton onClick={openFileChooser} />}
              </>
            )} />
          <BoldText variant="medium" inline>
            {name}
          </BoldText>
          {editMode && <EditCompetitionNameButton onClick={onClickEdit} />}
        </LeftSide>
        {renderBannerAction(RightSide)}
      </BannerBody>
      {renderBannerAction(BannerAction)}
      {showFavouriteIcon && (
        <FavouriteIcon
          onClick={() => onFavourite && onFavourite()}
          icon={isFavourite ? faStar : faStarO}
          size="3x" />
      )}
    </BannerSection>
  )
})

export default CompetitionBanner


const BannerImage = styled(({ source, ...rest }) => <div {...rest} />)`
  ${({ source }) => `background-image: url(${source});`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  @media ${device.laptop} {
    height: 25rem;
  }
`

const BannerSection = styled.div`
  border-radius: 1rem;
  border: solid 1px ${colors.lightgrey};
  position: relative;
`

const BannerBody = styled.div`
  box-sizing: border-box;
  padding: ${spacing.xs} ${spacing.sm} ${spacing.xs} calc(${spacing.xs} + 15rem) ;
  display: flex;
  position: relative;
  height: 7.5rem;
  justify-content: space-between;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    height: 8.5rem;
  }
`

const LeftSide = styled.div`
  /* position: relative; */
`

const RightSide = styled.div`
  display: none;
  & > div,
  & > button {
    height: 4.2rem;
  }

  & button {
    margin-left: ${spacing.xxs};
    min-width: 13rem;
  }

  @media ${device.tablet} {
    display: flex;
  }
`

const BannerAction = styled(RightSide)`
  display: flex;
  flex-direction: row-reverse;
  margin: ${spacing.xxs};
  justify-content: space-between;

  & button {
    margin-left: 0;
  }
  
  @media ${device.tablet} {
    display: none;
  }
`

const CompetitionAvatar = styled(Avatar)`
  width: 13rem;
  height: 13rem;
  border: solid 5px ${colors.white};
  margin-right: ${spacing.xs};
  position: absolute;
  top: calc(-50% - ${spacing.xs});
  left: ${spacing.sm};
`

const EditBannerImageButton = styled(ImageEditButton)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 3;
`

const EditLogoButton = styled(ImageEditButton)`
  position: absolute;
  top: -1.5rem;
  left: 7.5rem;
`

const EditCompetitionNameButton = styled(EditButton)`
  display: inline-block;
  margin-left: ${spacing.xs};
`

const EndSeason = styled(Button)`
  padding: 0;
`

const SeasonCompleteLabel = styled.div`
  padding: 1rem;
  border: solid 1px ${colors.red};
  background: rgba(250, 182, 182, 0.8);
  border-radius: 10px;
  height: 1rem !important;
  display: flex;
  align-items: center;

  p {
    height: unset;
  }
`

const FavouriteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${colors.darkyellow};
  transition: all ease .2s;
  cursor: pointer;
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px;

  &:hover {
    opacity: .6;
    transform: scale(1.1);
  }

`