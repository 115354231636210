import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import isEmpty from 'lodash/isEmpty'
import Button from '../../../components/Button'
import Text, { BoldText } from '../../../components/Text'
import { spacing, fontSize, colors } from '../../../styles/_var'
import { ContestantWithBio, ICompetition, IPayoutOptions } from '../../../utils/interfaces'
import AboutCompetition from '../../CompetitionPage/AboutCompetition'
import AllContestants from '../../CompetitionPage/AllContestants'
import CompetitionBanner from '../../CompetitionPage/CompetitionBanner'
import Modal from '../../../components/Modal'
import goalIcon from '../../../assets/images/goal-icon.png'
import { routes } from '../../../utils/constants';

interface ISummary {
  payoutOptions: IPayoutOptions,
  contestants: ContestantWithBio[],
  competitionDetails: ICompetition,
  resetCompetitionFields: () => void,
}

const Summary: FunctionComponent<ISummary> = React.memo(({
  payoutOptions,
  contestants,
  competitionDetails,
  resetCompetitionFields
}) => {
  const [showCompletionModal, setShowCompletionModal] = useState(false)
  const hasPayoutOptions = !isEmpty(payoutOptions)
  const history = useHistory()

  const gotoCompetitionDashboard = () => {
    history.push(`/${competitionDetails.username}/dashboard`)
    resetCompetitionFields()
  }

  const gotoHome = () => {
    history.push(routes.DASHBOARD)
    resetCompetitionFields()
  }

  const renderCompletionModal = () => {
    return (
      <CompletionModal
        hideCloseButton
        showModal={showCompletionModal}
        onClose={() => setShowCompletionModal(false)}>
        <ModalBody>
          <img src={goalIcon} alt="goal-icon" width={130} height={130} />
          <BoldText variant="medium" mt={2} mb={2}>Congratulations!</BoldText>
          <Text
            mb={1}
            variant="regular" 
            style={{lineHeight: '2.5rem'}}>
            You have successfully created your competition
          </Text>
          <Button block mt={2} onClick={gotoHome}>
            Back to Home
          </Button>
          <Button
            block
            mt={1}
            variant="secondary"
            onClick={gotoCompetitionDashboard}>
            Open Competition Dashboard
          </Button>
        </ModalBody>
      </CompletionModal>
    )
  }

  return (
    <SummaryWrapper>
      {renderCompletionModal()}
      <CompetitionBanner {...competitionDetails} previewMode />
      <AboutCompetition about={competitionDetails.description} />
      <Contestants>
        <BoldText variant="medium" mb={1}>Contestants</BoldText>
        <AllContestants contestants={contestants} variant="username" />
      </Contestants>
      {hasPayoutOptions && (
        <PayoutDetails>
          <BoldText variant="medium" mb={1}>Payout Details</BoldText>
          <PayoutContentWrapper>
            <PayoutItem>
              <Text variant="regular">Bank</Text>
              <BoldText variant="medium">{payoutOptions.bank_name}</BoldText>
            </PayoutItem>
            <PayoutItem>
              <Text variant="regular">Account Number</Text>
              <BoldText variant="medium">{payoutOptions.account_number}</BoldText>
            </PayoutItem>
            <PayoutItem>
              <Text variant="regular">Account Name</Text>
              <BoldText variant="medium">{payoutOptions.account_name}</BoldText>
            </PayoutItem>
          </PayoutContentWrapper>
        </PayoutDetails>
      )}
      <FinishButton onClick={() => setShowCompletionModal(true)}>
        Finish
      </FinishButton>
    </SummaryWrapper>
  )
})

export default Summary


const SummaryWrapper = styled.div``

const Contestants = styled.div`
  padding: ${spacing.xs};
  border-radius: 1rem;
  border: solid 1px ${colors.lightgrey};
  margin-top: ${spacing.sm};
`

const PayoutDetails = styled(Contestants)``

const PayoutContentWrapper = styled.div`
  background-color: ${colors.subtlylightpurple};
  padding: ${spacing.sm};
  display: flex;
`

const PayoutItem = styled.div`
  flex: 1;
  border-right: solid 1px ${colors.lightgrey};
  text-align: center;

  p:first-child {
    color: ${colors.purple};
  }

  &:last-child {
    border-right: none;
  }
`

const FinishButton = styled(Button)`
  display: block;
  margin: ${spacing.sm} auto;
  width: 20rem;
  font-size: ${fontSize.sm};
`

const ModalBody = styled.div`
  width: 100%;
  background-color: ${colors.white};
  text-align: center;
`

const CompletionModal = styled(Modal)`
  max-width: 30rem;
`