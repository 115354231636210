import { makeAutoObservable, runInAction } from "mobx";
import { getDemosRequest } from "../utils/apiRequests";
import { status } from "../utils/constants";
import { IDemos, Status } from "../utils/interfaces";

export class DemosStores {
  demos: IDemos[] = [];
  demosStatus: Status = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDemos() {
    if (this.fetchingDemos || this.fetchedDemos) return
    runInAction(() => this.demosStatus = status.LOADING);

    try {
      const response = await getDemosRequest();

      runInAction(() => {
        this.demos = response.data.data;
        this.demosStatus = status.COMPLETE;
      });
    } catch (e) {
      runInAction(() => this.demosStatus = status.FAILED);
    }
  }

  get fetchingDemos() {
    return this.demosStatus === status.LOADING
  }

  get fetchedDemos() {
    return this.demosStatus === status.COMPLETE
  }

  get failedToFetchDemos() {
    return this.demosStatus === status.FAILED
  }
}

export default new DemosStores()