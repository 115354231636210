import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { ICards } from '../stores/paymentStore'
import Modal from './Modal'
import Text from './Text'
import { CreditCardItem } from '../pages/VoterDashboard/VoterSettings/CreditCard'
import Button from './Button'


interface ICreditCardSelector {
  show: boolean,
  close: () => void,
  creditCards: ICards[],
  onCardSelected: (card: ICards) => void,
  triggerNewPayment: () => void,
}

const CreditCardSelector: FunctionComponent<ICreditCardSelector> = ({
  show,
  close,
  creditCards=[],
  onCardSelected,
  triggerNewPayment
}) => {
  return (
    <Modal
      onClose={close}
      showModal={show}
      closeWhenOverlayIsClicked
    >
      <Text variant='medium'>Choose a credit card</Text>
      <Divider />
      <Box>
        {creditCards.map((card, i) => (
          <div key={card.uuid}>
            <CreditCardItem 
              {...card}
              onClick={() => onCardSelected(card)}
              hideActionButtons />
            <Divider />
          </div>
        ))}
        <Button
          mt={2}
          ml={1}
          variant='secondary'
          style={{minWidth: 200}}
          onClick={triggerNewPayment}
        >
          Use New Card
        </Button>
      </Box>
    </Modal>
  )
}

export default  observer(CreditCardSelector)
