import { makeAutoObservable, runInAction } from "mobx";
import { getFaqsRequest } from "../utils/apiRequests";
import { status } from "../utils/constants";
import { IFaq, Status } from "../utils/interfaces";

class FaqsStore {
  faqs: IFaq[] = [];
  faqsStatus: Status = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchFaqs() {
    if (this.fetchingFaqs || this.fetchedFaqs) return
    runInAction(() => this.faqsStatus = status.LOADING);

    try {
      const response = await getFaqsRequest();

      runInAction(() => {
        this.faqs = response.data.data;
        this.faqsStatus = status.COMPLETE;
      });
    } catch (e) {
      runInAction(() => this.faqsStatus = status.FAILED);
    }
  }

  get fetchingFaqs() {
    return this.faqsStatus === status.LOADING
  }

  get fetchedFaqs() {
    return this.faqsStatus === status.COMPLETE
  }

  get failedToFetchFaqs() {
    return this.faqsStatus === status.FAILED
  }
}

export default new FaqsStore();
