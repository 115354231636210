import styled from 'styled-components'
import { device, spacing } from '../styles/_var'

export default styled.div`
  padding: 0 ${spacing.xxs};

  @media ${device.tablet} {
    padding: 0 ${spacing.rg};
  }

  @media ${device.laptop} {
    padding: 0 ${spacing.xxl};
  }
`
