import { makeAutoObservable, runInAction } from 'mobx'
import moment from 'moment'
import uiStore from './uiStore'
import {
  createVotingRoundRequest,
  fetchVotingRoundsRequest,
  setVotingRoundStatusRequest,
} from '../utils/apiRequests'
import { DEFAULT_CATEGORY_ID, status } from '../utils/constants'
import { processError, retrieveErrorMessage, toUTC } from '../utils/helpers'
import { IVotingRound, Status } from '../utils/interfaces'
import seasonStore from './seasonStore'
import competitionStore from './competitionStore'

class VotingRoundStore {
  steps = {
    VOTING_ROUND: 0,
    VOTING_ROUND_OPTIONS: 1,
  }
  votingRoundPayload: any = {}
  votingRounds: IVotingRound[] = []
  selectedVotingRound: IVotingRound = {} as IVotingRound
  createVotingRoundStatus: Status = null
  fetchVotingRoundStatus: Status = null
  setVotingRoundStatus: Status = null
  showCreateModal: boolean = false
  currentStep: number = this.steps.VOTING_ROUND
  roundToUpdate: IVotingRound | null = null
  endDateTime: string = ''
  showNewEndTimeModal: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  async createVotingRound(data:any) {
    this.setShowCreateModal(false)
    this.setCurrentStep(this.steps.VOTING_ROUND)
    const payload = {...data, ...this.votingRoundPayload}
    const seasonId = seasonStore.selectedSeasonId

    if (this.creatingVotingRound) return 

    uiStore.setLoadingScreenMessage('Creating voting round')

    try {
      const response = await createVotingRoundRequest(seasonId, payload)

      runInAction(() => {
        this.createVotingRoundStatus = status.COMPLETE
        this.votingRounds = this.votingRounds.concat(response.data)
      })

      this.fetchVotingRounds(seasonId)
    } catch (e) {
      processError(e)
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => {
        this.createVotingRoundStatus = status.FAILED
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async fetchVotingRounds(season_id: string, onComplete?: () => void) {
    if (this.fetchingVotingRound) return 
    this.fetchVotingRoundStatus = status.LOADING

    if (!this.votingRounds.length) {
      uiStore.setLoadingScreenMessage('Fetching voting rounds.')
    }

    try {
      const response = await fetchVotingRoundsRequest(season_id)
      
      runInAction(() => {
        this.votingRounds = response.data
        this.selectedVotingRound = this.votingRounds[this.votingRounds.length - 1]
        this.fetchVotingRoundStatus = status.COMPLETE
        onComplete && onComplete()
      })
    } catch (e) {
      processError(e)

      runInAction(() => {
        this.fetchVotingRoundStatus = status.FAILED
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  async updateVotingRoundStatus(season_id: string, payload: any) {
    if (this.updatingVotingRound) return 

    runInAction(() => {
      this.setVotingRoundStatus = status.LOADING
    })

    uiStore.setLoadingScreenMessage('Setting voting round status')

    try {
      const response = await setVotingRoundStatusRequest(season_id, payload)
      this.updateVotingRound(response.data)

      runInAction(() => {
        this.setVotingRoundStatus = status.COMPLETE
      })
    } catch (e) {
      uiStore.errorModal(retrieveErrorMessage(e))
      runInAction(() => {
        this.setVotingRoundStatus = status.FAILED
      })
    }

    uiStore.setLoadingScreenMessage('')
  }

  setShowCreateModal(value: boolean) {
    this.showCreateModal = value
  }

  setCurrentStep(value: number) {
    this.currentStep = value
  }

  setRoundToUpdate(value: IVotingRound | null) {
    this.roundToUpdate = value
  }

  setEndDateTime(value: string) {
    this.endDateTime = value
  }

  setShowNewEndTimeModal(value: boolean) {
    this.showNewEndTimeModal = value
  }

  getVotingRoundActionLabel(votingRound: IVotingRound) {
    if (votingRound.has_ended) {
      return 'Re-open voting round'
    } else if (votingRound.has_started && !votingRound.has_ended) {
      return 'End voting round'
    } else {
      return 'Start voting round'
    }
  }

  updateVotingRound(votingRound: IVotingRound) {
    const updatedRounds: IVotingRound[] = []

    this.votingRounds.forEach(round => {
      if (round.id === votingRound.id) {
        updatedRounds.push(votingRound)
      } else {
        updatedRounds.push(round)
      }
    })

    this.votingRounds = updatedRounds
  }

  setSelectedVotingRound(votingRound: IVotingRound) {
    this.selectedVotingRound = votingRound
  }

  setSelectedVotingRoundByLabel(votingRoundLabel: string) {
    const votingRound = this.votingRounds.find(({ label }) => label === votingRoundLabel)
    if (votingRound) {
      this.setSelectedVotingRound(votingRound)
    }
  }

  setVotingRoundPayload(value: any) {
    this.votingRoundPayload = value
  }

  runChecksAndBeginVotingRound(beginVotingRound: () => void) {
    if (seasonStore.selectedSeason.concluded) {
      uiStore.errorModal('This season has ended.')
    } else if (!this.lastVotingRound || this.lastVotingRound.has_ended) {
      beginVotingRound()
    } else if (!this?.lastVotingRound?.has_started) {
      uiStore.errorModal('Previous round has not been started yet!')
    } else {
      uiStore.errorModal('Previous round has not ended yet!')
    }
  }

  editVotingRound() {
    if (!this.roundToUpdate?.id) return

    const utcEndDate = (new Date(this.endDateTime)).toISOString()
    this.updateVotingRoundStatus(seasonStore.selectedSeasonId, {
      voting_round_id: this.roundToUpdate.id,
      new_time: utcEndDate,
      action: 'reopen'
    })

    this.setRoundToUpdate(null)
    this.setShowNewEndTimeModal(false)
    this.setEndDateTime('')
  }

  onClickAddVotingRound = () => {
    this.runChecksAndBeginVotingRound(() => {
      this.setShowCreateModal(true)
    })
  }

  onClickActionButton = (round: IVotingRound) => () => {
    const actionLabel = this.getVotingRoundActionLabel(round)
    let actionPayload = { voting_round_id: round.id, action: '', new_time: undefined }
    let action: any;

    if (seasonStore.selectedSeason.concluded) {
      return uiStore.errorModal('This season has ended already!')
    }

    if (actionLabel === 'Re-open voting round') {
      action = () => {
        this.setRoundToUpdate(round)
        this.setShowNewEndTimeModal(true)
      }
    } else if (actionLabel === 'Start voting round') {
      actionPayload.action = 'start'
      action = () => this.updateVotingRoundStatus(seasonStore.selectedSeasonId, actionPayload)
    } else {
      actionPayload.action = 'end'
      action = () => this.updateVotingRoundStatus(seasonStore.selectedSeasonId, actionPayload)
    }

    uiStore.openConfirmDialog('Are you sure you want to perform this action?', action)
  }

  onSubmitStepOne = (data: IVotingRound) => {
    data.end_date = toUTC(data.end_date)
    data.start_date = toUTC(data.start_date)

    this.setVotingRoundPayload(data)
    this.setCurrentStep(this.steps.VOTING_ROUND_OPTIONS)
  }

  getForm1InitialValues = () => {
    const { start_date, end_date } = this.votingRoundPayload

    return {
      ...this.votingRoundPayload,
      start_date: start_date ? moment(start_date).format('YYYY-MM-DDThh:ss'): undefined,
      end_date: end_date ? moment(end_date).format('YYYY-MM-DDThh:ss') : undefined
    }
  }

  filterContestantsPageByCategory = (votingRound: IVotingRound) => {
    const selectedCategory = competitionStore.competitionPage.selectedCategory
    return votingRound.active_contestants?.filter(c => {
      if (selectedCategory.id === DEFAULT_CATEGORY_ID) {
        return !c.category_id
      }

      return c.category_id === selectedCategory.id
    })
  }

  filterContestantsByCategory = (roundId?: number) => {
    const round = this.votingRounds.find(r => r.id === roundId)
    if (!round) return []

    return round.active_contestants?.filter(c => {
      if (competitionStore.selectedCategory.id === DEFAULT_CATEGORY_ID) {
        return !c.category_id
      }

      return c.category_id === competitionStore.selectedCategory.id
    })
  }

  get creatingVotingRound() {
    return this.createVotingRoundStatus === status.LOADING
  }

  get hasOpenVotingRound() {
    if (this.lastVotingRound) return !this.lastVotingRound.has_ended
    return false;
  }

  get fetchingVotingRound() {
    return this.fetchVotingRoundStatus === status.LOADING
  }

  get updatingVotingRound() {
    return this.setVotingRoundStatus === status.LOADING
  }

  get lastVotingRound() {
    if (!this.votingRounds.length) return null
    return this.votingRounds[this.votingRounds.length - 1]
  }

  get lastVotingOptionsUsed() {
    return this.lastVotingRound?.voting_options || {}
  }

  get hasVotingRounds() {
    return !!this.votingRounds.length
  }
}



export default new VotingRoundStore()
