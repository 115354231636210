import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/_var'


interface IActivityProgressBar {
  currentStep: number;
  totalSteps: number;
}

interface IStep {
  completed?: boolean;
}

const Step: FunctionComponent<IStep> = React.memo(({ completed }) => {
  return (
    <StepWrapper completed={completed} role="listitem">
      <Bar role="progressbar" />
      <FontAwesomeIcon icon={faCheck} color={colors.white} />
    </StepWrapper>
  )
})

const ActivityProgressBar: FunctionComponent<IActivityProgressBar> = React.memo(({
  currentStep,
  totalSteps
}) => {
  const steps = () => {
    let stepUI = []
    let counter = 0

    for (; counter < totalSteps; counter++) {
      stepUI.push(<Step completed={counter < currentStep} key={`${counter}`} />)
    }

    return stepUI
  }

  return (
    <Wrapper role="list">
      <StepContainer>
        {steps()}
      </StepContainer>
    </Wrapper>
  )
})

export default ActivityProgressBar

const Wrapper = styled.div`

`

const StepWrapper = styled(({ completed, ...rest }) => <div {...rest} />)`
  display: flex;
  align-items: center;
  flex: 1;

  ${({ completed }) => completed ? `
    div, svg {
      background: ${colors.purple};
    }
  ` : `
    div, svg {
      background: ${colors.lightgrey};
    }
  `}
  
  svg {
    border-radius: 50%;
    padding: 5px;
    border: solid 3px ${colors.white};
  }
`

const Bar = styled.div`
  flex: 1;
  height: 2px;
  border: none;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
`
