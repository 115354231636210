import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { getFromQueryString } from '../../utils/helpers'
import Text, { BoldText } from '../../components/Text'
import { colors, size, spacing } from '../../styles/_var'
import usePageTitle from '../../hooks/usePageTitle'

interface IErrorPageProps {
  code?: string,
  message?: string,
  comingSoon?: boolean,
  action?: React.ReactNode
}

const errorMessages: any = {
  404: 'Sorry, Page Not found',
  400: 'Bad Request',
  401: 'Sorry, Unauthorized Page',
  403: 'Sorry, Forbidden Page',
  500: 'Sorry, Something went wrong!'
}

const ErrorPage: FunctionComponent<IErrorPageProps> = ({
  message,
  code,
  comingSoon,
  action
}) => {
  const location = useLocation()
  const errorCode = code || getFromQueryString(location.search, 'code', '404') || ''
  const errorMessage = message || errorMessages[errorCode]
  usePageTitle(errorMessage)

  return (
    <ErrorPageWrapper>
      <ErrorMessageSection comingSoon={comingSoon}>
        <BoldText variant="title">{ comingSoon ? errorMessage : errorCode}</BoldText>
        {!comingSoon && <BoldText variant="large">{errorMessage}</BoldText>}
        <Text variant="medium">
          {action || <Link href="/">Go back to Homepage</Link>}
        </Text>
      </ErrorMessageSection>
      <SubImage src="https://matusky.s3.us-west-2.amazonaws.com/assets/images/error-sub-bg.png" alt="sub-bg" />
    </ErrorPageWrapper>
  )
}

export default ErrorPage

const ErrorPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 8rem;
  background-image: url("https://matusky.s3.us-west-2.amazonaws.com/assets/images/error-background.png");
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow-x:hidden;
`

const SubImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const ErrorMessageSection = styled(({ comingSoon, ...rest }) => <div {...rest} />)`
  color: ${colors.darkpurple};
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  p:first-child {
    font-weight: 500;
    margin-bottom: ${spacing.sm};
    ${({ comingSoon }) => comingSoon ? `font-size: 6rem;` : `font-size: 10rem;`};
  }
  @media(max-width: ${size.laptopL}){
    p:first-child {
      ${({ comingSoon }) => comingSoon ? `font-size: 4rem;` : `font-size: 8rem;`};
    }
 
  } 
  @media(max-width: ${size.mobileL}){
    p:first-child {
      ${({ comingSoon }) => comingSoon ? `font-size: 3.7rem;` : `font-size: 6rem;`};
    }
 
  } 
`

const Link = styled.a``