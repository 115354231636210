import React, { FunctionComponent } from 'react'
import { ContestantWithBio } from '../../../utils/interfaces'
import Text from '../../../components/Text'
import CompetitionCategories from '../CompetitionCategories'
import CompetitionOptions from '../CompetitionOptions'
import CompetitionContestants from '../CompetitionContestants'


interface ITabContent {
  selectedTab: string,
  tabs: {
    CONTESTANT_TAB: string,
    OPTIONS_TAB: string,
    CATEGORIES_TAB: string
  },
  votingOptions: any,
  competitionTemplate: number | undefined,
  deleteContestant: (contestantUsername: string) => void,
  evictContestant: (contestantUsername: string) => void,
  onClickContestantButton: () => void,
  saveContestant: (payload: ContestantWithBio) => Promise<void>,
  contestantLabel: string,
  savingContestant: boolean,
  contestants: ContestantWithBio[],
}

const TabContent: FunctionComponent<ITabContent> = React.memo(({
  tabs,
  selectedTab,
  votingOptions,
  ...rest
}) => {
  switch (selectedTab) {
    case tabs.CONTESTANT_TAB:
      return (
        <CompetitionContestants {...rest} />
      )
    case tabs.OPTIONS_TAB:
      return <CompetitionOptions options={votingOptions} />
    case tabs.CATEGORIES_TAB:
      return <CompetitionCategories />
    default:
      return <Text>Section under construction</Text>
  }
})

export default TabContent
