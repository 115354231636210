import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Text, { BoldText } from '../../../components/Text'
import { ContentBody } from './style'
import { colors, spacing } from '../../../styles/_var'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@material-ui/core/Box'
import paymentStore, { ICards } from '../../../stores/paymentStore'
import { CreditCardIcons, defaultCreditCardIcon } from '../../../utils/constants'
import Button from '../../../components/Button'
import uiStore from '../../../stores/uiStore'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { sanitizeCardType } from '../../../utils/helpers'


interface ICreditCardList {
  cards: ICards[]
}

interface ICreditCardItem extends ICards {
  hideActionButtons?: boolean,
  onClick?: () => void,
}


export const CreditCardItem: FunctionComponent<ICreditCardItem> = ({
  hideActionButtons,
  card_type,
  last4,
  exp_month,
  exp_year,
  is_default,
  uuid,
  onClick
}) => {
  const sanitizedCardType = sanitizeCardType(card_type)
  const iconUrl = sanitizedCardType ? CreditCardIcons[sanitizedCardType] : defaultCreditCardIcon
  const onDeleteCard = (cardId: string) => {
    uiStore.openConfirmDialog('Are you sure you want to delete this credit card? It cannot be undone.', () => {
      paymentStore.deleteSavedCard(cardId)
    })
  }
  const onMakeDefault = (cardId: string) => {
    paymentStore.setDefaultCard(cardId)
  }

  return (
    <CreditCardItemWrapper
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      isClickable={!!onClick}
    >
      <Box display="flex" alignItems="center">
        <CCICon src={iconUrl} />
        <Box mr={2}>
          <BoldText variant='regular'>**** **** **** {last4}</BoldText>
          <Text variant='subtext'>{exp_month}/{exp_year}</Text>
        </Box>
        {is_default && <FontAwesomeIcon icon={faCheckCircle} color={colors.purple} size="2x" />}
      </Box>
      {!hideActionButtons && (
        <Box>
          {!is_default && <Button variant='secondary' style={{width: 80}} onClick={() => onMakeDefault(uuid)}>
            Make Default
          </Button>}
          <Button variant='danger' ml={1} style={{width: 80}} onClick={() => onDeleteCard(uuid)}>
            Delete
          </Button>
        </Box>
      )}
    </CreditCardItemWrapper>
  )
}

const CreditCardList: FunctionComponent<ICreditCardList> = ({ cards }) => {
  return (
    <Box>
      {cards.map(card => <CreditCardItem {...card} key={card.uuid} />)}
    </Box>
  )
}

const CreditCards = () => {
  return (
    <ContentBody>
      <BoldText variant="medium">Saved Credit Card</BoldText>
      <CardList>
        {!paymentStore.hasSavedCards && <Text variant='regular' color='red'>You have no saved cards yet.</Text>}
        {paymentStore.hasSavedCards && <CreditCardList cards={paymentStore.savedCards} />}
        <Button variant='secondary' mt={3} onClick={() => paymentStore.addNewCreditCard()} alignCenter>
          Add New Card
        </Button>
      </CardList>
    </ContentBody>
  )
}

export default observer(CreditCards)


const CCICon = styled.img`
  width: 90px;
  height: auto;
`

const CreditCardItemWrapper = styled(({ isClickable, ...rest }) => <Box {...rest} />)`
  transition: all ease .2s;
  ${({ isClickable }) => isClickable && `
    &:hover {
      cursor: pointer;
      box-shadow: ${colors.lightpurple} 0 0 5px 1px;
    }
  `}
`

const CardList = styled.div`
background: rgba(0, 0, 0, 0.02);
border: 1px solid ${colors.lightgrey};
border-radius: 3px;
padding: ${spacing.sm};
margin-top: ${spacing.xs};

`