import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import EditButton from '../../components/EditButton'
import Text, { BoldText } from '../../components/Text'
import { colors, spacing } from '../../styles/_var'


interface IAboutCompetition {
  editMode?: boolean;
  about?: string;
  onClickEdit?: () => void,
}

const AboutCompetition: FunctionComponent<IAboutCompetition> = React.memo(({
  about='',
  editMode,
  onClickEdit
}) => {
  return (
    <AboutSection >
      <BoldText variant="regular" role="heading">About</BoldText>
      <Text variant="regular">{about}</Text>
      {editMode && (
        <EditAboutButton 
          role="button"
          aria-label="edit about button" 
          onClick={onClickEdit} />
      )}
    </AboutSection>
  )
})

export default AboutCompetition


const AboutSection = styled.div`
  position: relative;
  padding: ${spacing.xs} ${spacing.sm};
  box-sizing: border-box;
  border-radius: 1rem;
  border: solid 1px ${colors.lightgrey};
  margin-top: ${spacing.sm};
  text-align: justify;
`

const EditAboutButton = styled(EditButton)`
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  transform: scale(0.7);
`