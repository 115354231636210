import React, { FunctionComponent, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import logo from '../assets/images/logo-inverse.png'
import { colors, device, fontSize, spacing } from '../styles/_var'
import { routes } from '../utils/constants'
import { IMenuItem, ISeason } from '../utils/interfaces'
import Text from './Text'
import uiStore from '../stores/uiStore'
import Button from './Button'
import competitionStore from '../stores/competitionStore'
import seasonStore from '../stores/seasonStore'
import { Avatar } from './StyledComponents'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'

interface ISidebar {
  menuItems: IMenuItem[],
  isCompetitionDashboard?: boolean,
  seasons?: ISeason[],
  currentSeason?: ISeason,
  setSeason?: (season: string) => void,
  disabled?: boolean,
  firstName?: string,
  avatar?: string,
}

const SidebarMenu: FunctionComponent<IMenuItem> = React.memo(({
  name,
  Icon,
  route,
  minimumRole=0,
}) => {
  if (minimumRole > competitionStore.currentStaffRole) return null
  return (
    <MenuContainer
      onClick={() => uiStore.toggleSidebar()}
      to={route}
      activeClassName='-active'>
      <Icon />
      <Text variant="regular">{name}</Text>
    </MenuContainer>
  )
})

const SeasonDropdown: FunctionComponent<{
  seasons: ISeason[],
  currentSeason?: ISeason,
  setSeason?: (seasonId: string) => void,
}> = React.memo(({
  seasons,
  currentSeason,
  setSeason,
}) => {
  return (
    <Select
      aria-label="competition-seasons-list"
      value={currentSeason?.uuid}
      onChange={e => setSeason && setSeason(e.target.value)}>
      {seasons.map(({ number, uuid }) => (
        <option value={uuid} key={uuid}>Season {number}</option>
      ))}
    </Select>
  )
})

const Sidebar: FunctionComponent<ISidebar> = ({
  menuItems,
  isCompetitionDashboard,
  seasons=[],
  currentSeason,
  disabled,
  firstName,
  avatar
}) => {
  const [initialPosition, setInitialPosition] = useState<number | null>(null)
  const [dragPosition, setDragPosition] = useState<number | null>(null)
  const { username } = competitionStore.competitionDetails

  const onWrapperDragStart = (evt: React.TouchEvent<HTMLDivElement>) => {
    setInitialPosition(evt.changedTouches[0].clientX)
  }

  const onWrapperDrag = (evt: React.TouchEvent<HTMLDivElement>) => {
    setDragPosition(evt.changedTouches[0].clientX)
  }

  const wrapperPositionDifference = () => {
    if (!initialPosition || !dragPosition ) return

    return (dragPosition * 100) / initialPosition
  }

  const getSidebarPositionStyle = () => {
    const percentageDifference = wrapperPositionDifference()

    if (!percentageDifference || percentageDifference  >=100 ) return {}
    return { transform: `translateX(${percentageDifference - 100}%` }
  }

  const onWrapperDragEnd = (_: React.TouchEvent<HTMLDivElement>) => {
    const difference = wrapperPositionDifference()

    if (difference && difference < 50) {
      uiStore.setDisplaySidebar(false)
    } else {
      uiStore.setDisplaySidebar(true)
    }

    setInitialPosition(null)
    setDragPosition(null)
  }

  const onCreateNewSeason = () => {
    uiStore.openConfirmDialog('Are you sure you want to start a new season!', () => {
      seasonStore.createSeason(username)
    })
  }

  const setSeason = (seasonUUID: string) => {
    seasonStore.setSelectedSeasonById(seasonUUID)
  }

  return (
    <SidebarWrapper
      role="widget"
      aria-label="dashboard sidebar"
      collapsed={uiStore.sidebarCollapsed}
      onTouchStart={onWrapperDragStart}
      onTouchMove={onWrapperDrag}
      onTouchEnd={onWrapperDragEnd}
      style={getSidebarPositionStyle()}
      disabled={disabled}
    >
      <HeaderSection to={routes.LANDING}>
        <SiteLogo src={logo} alt="site-logo" />
      </HeaderSection>
      <Hidden smUp>
        <Box alignItems="center" flexDirection="column" display="flex">
          {!!avatar && <Avatar src={avatar} className="user-avatar" width={80} height={80} />}
          {!!firstName && (
            <GreetingText variant="regular">
              Hi, {firstName}
            </GreetingText>
          )}
        </Box>
      </Hidden>
      {isCompetitionDashboard && (
        <ActionSection>
          <SeasonDropdown
            seasons={seasons}
            currentSeason={currentSeason}
            setSeason={setSeason} />
          <NewSeasonButton onClick={onCreateNewSeason}>
            Start New Season
          </NewSeasonButton>
        </ActionSection>
      )}
      <SidebarLinks>
        {menuItems.map((menu, index) => <SidebarMenu key={index} {...menu} />)}
      </SidebarLinks>
      <CloseSection onClick={() => uiStore.toggleSidebar()}>
        <FontAwesomeIcon icon={faTimes} size="5x" />
      </CloseSection>
    </SidebarWrapper>
  )
}

export default observer(Sidebar)

const MenuContainer = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
  padding-top: ${spacing.xxs};
  padding-bottom: ${spacing.xxs};
  padding-left: ${spacing.xs};
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;

  & p {
    color: ${colors.white};
    margin-left: ${spacing.xs};
  }

  &.-active, :hover {
    background-color: ${colors.lightpurple};
  }
`

const SidebarWrapper = styled(({ collapsed, disabled, ...rest }) => <div {...rest} />)`
  height: 100%;
  width: 95%;
  max-width: 50rem;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.grey};
  transition: all ease .2s;
  z-index: 3;

  ${({ collapsed }) => collapsed && `
    transform: translateX(-100%);
  `}

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: .6;
  `}

  @media ${device.tablet} {
    transform: translateX(0) !important;
    width: 28rem;
  }
`

const SiteLogo = styled.img`
  height: 4rem;
  display: block;
  margin: auto;
`

const SidebarLinks = styled.div`
  padding-left: ${spacing.sm};
`

const HeaderSection = styled(Link)`
  margin-bottom: ${spacing.xxs};
  display: flex;
  height: 10rem;

  @media ${device.tablet} {
    margin-bottom: ${spacing.rg};
  }
`

const Select = styled.select`
  padding: ${spacing.xxs};
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
`

const CloseSection = styled.div`
color: ${colors.white};
  position: absolute;
  bottom: ${spacing.xl};
  display: block;
  text-align: center;
  left: 50%;
  width: 5rem;
  height: 5rem;
  transform: translateX(-50%);
  background-color: rgba(151, 126, 247, 0.3);
  padding: ${spacing.xs};
  border-radius: 50%;

  @media ${device.tablet} {
    display: none;
  }
`

const NewSeasonButton = styled(Button)`
  box-sizing: border-box;
  height: 4.4rem;
  font-size: ${fontSize.sm};
  display: block;
  width: 100%;
  margin-top: ${spacing.xs};

  &:hover {
    background: ${colors.white};
    color: ${colors.purple};
  }
`

const GreetingText = styled(Text)`
  color: ${colors.white};
  @media ${device.tablet} {
    display: none;
  }
`

const ActionSection = styled.div`
  padding-left: ${spacing.sm};
  padding-right: ${spacing.sm};
`

