import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { Link, RouteChildrenProps } from 'react-router-dom'
import styled from 'styled-components'
import SimpleForm from '../components/SimpleForm'
import { AuthenticationView, ComponentView, ErrorMessage } from '../components/StyledComponents'
import Text from '../components/Text'
import usePageTitle from '../hooks/usePageTitle'
import authStore from '../stores/authStore'
import { spacing, colors } from '../styles/_var'
import { resetPasswordFields, routes } from '../utils/constants'


interface IForgotPassword extends RouteChildrenProps {
  className?: string
}

const ResetPassword: FunctionComponent<IForgotPassword> = ({ className='', match }) => {
  const resetPasswordToken = (match?.params as any)?.token
  const onSubmitPasswordResetForm = (payload: any) => {
    authStore.resetPassword(resetPasswordToken, payload.password)
  }

  usePageTitle('Reset Password')

  const renderSuccessMessage = () => {
    return (
      <SuccessComponent>
        <FontAwesomeIcon icon={faCheckCircle} size="4x" />
        <Text variant="regular">Your password has been successfully changed{"  "}<Link to={routes.LOGIN}>Login Now!</Link></Text>
      </SuccessComponent>
    )
  }

  const renderPasswordResetForm = () => {
    return (
      <>
        <TitleText variant="medium" role="heading">Reset your password</TitleText>
        <SimpleForm 
          formFields={resetPasswordFields}
          submitForm={onSubmitPasswordResetForm}
          actionButtons={{
            label: 'Change Password',
            loading: authStore.resettingPassword,
            invertOnHover: true,
            isSubmit: true
          }}
        />
      </>
    )
  }

  return (
    <AuthenticationView>
      <ComponentView className={className}>
        {authStore.resetPasswordComplete && renderSuccessMessage()}
        {!authStore.resetPasswordComplete && renderPasswordResetForm()}
        <ErrorMessage color={colors.red}>{authStore.resetUserPasswordErrMessage}</ErrorMessage>
      </ComponentView>
    </AuthenticationView>
  )
}

export default observer(ResetPassword)

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xs};
`

const SuccessComponent = styled.div`
  text-align: center;
  color: ${colors.darkpurple}
`