import React from 'react'
import styled from 'styled-components'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { getAddressComp, pick } from '../utils/helpers'
import { ILocationSearchInputProps, ICoordinateProps } from '../utils/interfaces'
import { colors } from '../styles/_var'
import Input from './Input'


class LocationInput extends React.Component<ILocationSearchInputProps> {
  state = { address: '' };

  static defaultProps = {
    placeholder: 'Search Places ...'
  }

  handleChange = (address: string): void => {
    const { onChange } = this.props;
    onChange(address);
  };

  storeAddressComponents = (addressObject: any, latLng: ICoordinateProps): void => {
    const { storeAddressComponents } = this.props;

    const addressComponents =  {
      full_address: addressObject.formatted_address,
      county: getAddressComp(addressObject, 'administrative_area_level_2'),
      state: getAddressComp(addressObject, 'administrative_area_level_1'),
      street_name: getAddressComp(addressObject, 'route'),
      street_number: getAddressComp(addressObject, 'street_number'),
      zip_code: getAddressComp(addressObject, 'postal_code'),
      coordinates: `${latLng.lat},${latLng.lng}`
    };

    if (storeAddressComponents) storeAddressComponents(addressComponents);
  }
 
  handleSelect = async (address: string) => {
    const { onChange } = this.props;
    onChange(address);

    try {
      const results = await geocodeByAddress(address);
      const latLng: any = await getLatLng(results[0]);

      this.storeAddressComponents(results[0], latLng);
    } catch (error) {
      console.log('Error:: ', error);
    }
  };
 
  render() {
    const { value, debounce, onBlur=null, placeholder } = this.props;
    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={debounce || 300}
      >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input {...getInputProps({ placeholder, onBlur })} />
          <DropdownContainer>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
              const props = getSuggestionItemProps(suggestion, { className });
              const usefulProps = pick(props, ['key', 'onClick', 'onTouchStart', 'onTouchEnd', 'className', 'onMouseUp', 'onMouseDown'])
              return (
                <div {...usefulProps}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
            </DropdownContainer>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}


export default LocationInput

const DropdownContainer = styled.div`
  background-color: ${colors.white};
  border: solid 0.1rem ${colors.lightgrey};
  border-top: none;
  border-bottom: none;
  z-index: 5;
  position: absolute;
  width: 100%;

  .suggestion-item {
    padding: 1.4rem;
    border-bottom: solid 0.1rem ${colors.lightgrey};
    cursor: pointer;
    &:hover {
      background-color: ${colors.purple};
    }
  }

  .suggestion-item--active {
    background-color: ${colors.lightpurple};
  }
`