import competitionStore from "../../../stores/competitionStore";
import { steps } from "../../../utils/constants";

export const stepTitles = {
  [steps.COMPETITION_DETAILS]: 'fill your competition details below',
  // [steps.CONTESTANT_DETAILS]: 'Fill in your contestants details',
  [steps.PAYMENT_OPTIONS]: 'Enter payout options',
  [steps.SEASON_TYPE_CHOOSER]: 'Select your competition type',
  [steps.SUMMARY]: 'Competition Summary'
}

export const disabledPreviousResolver = (currentStep: number) => {
  if (stepPreviousDisabledResolver[currentStep]) {
    return stepPreviousDisabledResolver[currentStep]()
  }

  return false
}

export const disabledNextResolver = (currentStep: number) => {
  if (stepNextDisabledResolver[currentStep]) {
    return stepNextDisabledResolver[currentStep]()
  }

  return false
}

const stepPreviousDisabledResolver = {
  [steps.COMPETITION_DETAILS]: () => true,
}

const stepNextDisabledResolver = {
  [steps.COMPETITION_DETAILS]: () => !competitionStore.competitionCreated,
  [steps.SEASON_TYPE_CHOOSER]: () => !competitionStore.subscriptionPlan && competitionStore.subscriptionPlan !== 0,
  [steps.SUMMARY]: () => true
}