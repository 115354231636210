export interface IPixelCrop {
  width: number,
  height: number,
  x: number,
  y: number
}

class ImageCropper {
  private createdImage: HTMLImageElement;
  private url: string;

  constructor(imageUrl: string) {
    this.url = imageUrl
    this.createdImage = new Image()
  }

  createImage(url: string) {
    const imagePromise = new Promise<boolean>((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => {
        this.createdImage = image
        resolve(true)
      })
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

    return imagePromise
  }

  getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 100
  }

  async getCroppedImage(pixelCrop: IPixelCrop, rotation: number = 0, resize?: number) {
    await this.createImage(this.url)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
  
    const maxSize = Math.max(this.createdImage.width, this.createdImage.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
  
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea
  
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2)
    ctx?.rotate(this.getRadianAngle(rotation))
    ctx?.translate(-safeArea / 2, -safeArea / 2)
  
    // draw rotated image and store data.
    ctx?.drawImage(
      this.createdImage,
      safeArea / 2 - this.createdImage.width * 0.5,
      safeArea / 2 - this.createdImage.height * 0.5
    )
    const data = ctx?.getImageData(0, 0, safeArea, safeArea)
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    // paste generated rotate image with correct offsets for x,y crop values.
    if (data) {
      ctx?.putImageData(
        data,
        Math.round(0 - safeArea / 2 + this.createdImage.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + this.createdImage.height * 0.5 - pixelCrop.y)
      )
    } else {
      throw Error('Error occured')
    }
  
    // As Base64 string
    // return canvas.toDataURL('image/jpeg');
  
    // As a blob
    return new Promise(resolve => {
      if (resize) {
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = canvas.width * resize;
        scaledCanvas.height = canvas.height * resize;

        scaledCanvas.getContext('2d')?.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
        scaledCanvas.toBlob((file: any) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      } else {
        canvas.toBlob((file: any) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      }
    })
  }
}

export default ImageCropper