import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import Link from '../components/Link'
import PageContent from '../components/PageContent'
import Text, { BoldText } from '../components/Text'
import { colors, device, spacing } from '../styles/_var'
import { SectionBody, SectionHeader } from '../components/StyledComponents'
import competitionStore from '../stores/competitionStore'
import { ICompetitionCategory } from '../utils/interfaces'
import { routes } from '../utils/constants'
import uiStore from '../stores/uiStore'


const CategoryThumb: FunctionComponent<ICompetitionCategory> = React.memo(({
  name,
  logo,
}) => {
  return (
    <CategoryLink to={`/competitions?category=${name}`}>
      <CategoryThumbView bg={logo}>
        <Overlay />
        <CategoryTitle variant="medium">{name?.toUpperCase()}</CategoryTitle>
      </CategoryThumbView>
    </CategoryLink>
  )
})


const Categories = () => {
  const fetchingCategories = competitionStore.fetchingCompetitionCategories
  const categories = competitionStore.categories

  useEffect(() => {
    if (fetchingCategories && categories) {
      uiStore.setLoadingScreenMessage('Fetching categories.')
    } else {
      uiStore.setLoadingScreenMessage('')
    }
  }, [categories, fetchingCategories])

  return (
    <CategoryWrapper role="main">
      <SectionHeader>
        <BoldText variant="regular">ALL CATEGORIES</BoldText>
        <ShowAllLink to={routes.COMPETITIONS}>
          <FontAwesomeIcon icon={faArrowUp} size="2x" />
          <Text variant="regular">SHOW ALL COMPETITIONS</Text>
        </ShowAllLink>
      </SectionHeader>
      {!fetchingCategories && <SectionBody>
        {categories.map((category, index) => <CategoryThumb key={index} {...category} />)}
      </SectionBody>}
    </CategoryWrapper>
  )
}

export default observer(Categories)

const CategoryWrapper = styled(PageContent)`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding-top: ${spacing.sm};
`

const ShowAllLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: ${spacing.xxs};
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
`

const CategoryLink = styled(Link)`
  display: block;
  position: relative;
  width: 45%;
  height: 25rem;
  margin-bottom: ${spacing.rg};
  transition: all .2s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media ${device.tablet} {
    width: 30%;
  }
`

const CategoryThumbView = styled.div<{bg: string}>`
  ${({ bg }) => `background: url(${bg});`}
  display: block;
  position: relative;
  width: 100%;
  height: 25rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 1rem;
`

const CategoryTitle = styled(BoldText)`
  color: ${colors.white};
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`