import { faArrowLeft, faClock, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import styled from 'styled-components'
import { TextAndIcon } from '../../components/StyledComponents'
import Text, { BoldText } from '../../components/Text'
import TextArea from '../../components/TextArea'
import { colors, device, spacing } from '../../styles/_var'
import { loremIpslum } from '../../utils/constants'
import { DiscussionItem } from './Discussions'

interface IDiscussionPageProps extends RouteChildrenProps<{discussion_id: string}> {
  className?: string
}

const MessageItem: FunctionComponent<{hasReply?: boolean}> = ({ hasReply=false }) => {
  return (
    <MessageWrapper>
      <Avatar src='https://ichef.bbci.co.uk/images/ic/1200x675/p074mmrq.jpg' />
      <BodySection>
        <Header>
          <BoldText variant='regular'>James Frank</BoldText>
          <TextAndIcon>
            <FontAwesomeIcon icon={faClock} />
            <Text variant="small">4 days ago</Text>
          </TextAndIcon>
        </Header>
        <Text variant='regular'>{loremIpslum}</Text>
        {hasReply && (
          <ReplyIcon style={{color: colors.purple}}>
            <FontAwesomeIcon icon={faReply} />
            <Text variant='small'>Reply</Text>
          </ReplyIcon>
        )}
      </BodySection>
    </MessageWrapper>
  )
}

const Comment: FunctionComponent = () => {
  return (
    <CommentWrapper>
      <MessageItem hasReply />
      <RepliesSection>
        <MessageItem />
        <MessageItem />
        <MessageItem />
      </RepliesSection>
    </CommentWrapper>
  )
}

const PostForm: FunctionComponent = () => {
  return (
    <FormWrapper>
      <Avatar src='https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fdam%2Fimageserve%2F1150881602%2F0x0.jpg%3Ffit%3Dscale' />
      <BodySection>
        <PostInput placeholder="Contribute to the discussion" />
      </BodySection>
    </FormWrapper>
  )
}

const DiscussionPage: FunctionComponent<IDiscussionPageProps> = (props) => {
  return (
    <Wrapper>
      <BackButton onClick={props.history.goBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <Text variant="small">Back</Text>
      </BackButton>
      <DiscussionItem fullPost />
      <PostForm />
      <Comment />
      <Comment />
      <Comment />
      <Comment />
      <Comment />
      <Comment />
      <Comment />
      <Comment />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${spacing.xxs};

  @media ${device.tablet} {
    padding: ${spacing.xs};
  }
`

const CommentWrapper = styled.div`
  border-bottom: solid 1px ${colors.lightgrey};
  padding-bottom: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`

const MessageWrapper = styled.div`
  padding: ${spacing.xs} 0;
  box-sizing: border-box;
  display: flex;
`

const FormWrapper = styled(CommentWrapper)`
  border-bottom: none;
  display: flex;
  margin-bottom: ${spacing.sm};
  margin-top: ${spacing.xs};
`

const Avatar = styled(({ src, ...rest }) => <div {...rest} />)`
  ${({ src }) => `background-image: url(${src});`}
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  width: 5rem;
  height: 5rem;
  margin-right: ${spacing.xs};
  box-sizing: border-box;
`

const BodySection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const PostInput = styled(TextArea)`
  height: 10rem;
`

const ReplyIcon = styled(TextAndIcon)`
  color: ${colors.purple};
  align-self: flex-end;
  cursor: pointer;
`

const BackButton = styled(ReplyIcon)`
  position: absolute;
  transform: translateY(-110%);
`

const RepliesSection = styled.div`
  padding-left: 6.5rem;
  box-sizing: border-box;
`


export default DiscussionPage

