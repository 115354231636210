import React, { useState, FunctionComponent } from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'
import PricingSection from './PricingSection'
import { colors } from '../../styles/_var'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Box from '@material-ui/core/Box'
import Link from '../../components/Link'
import LinkWithIcon from '../../components/ListWithIcon'
import { essentialPlan, freePlan, proPlan, premierPlan } from './copy'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { routes, seasonTypes } from '../../utils/constants'
import Text from '../../components/Text'
import styled from 'styled-components'

interface IPricingLimit {
  label: string,
  tooltip: string,
}

export interface IPricingPlan {
  name: string,
  headerText: string,
  pricingInfo: string,
  planColor: string,
  planCode: number,
  planFeatures: IPricingLimit[],
  buttonText?: string,
  headerIcon?: IconProp | null,
  buttonLink?: string,
  gridSizing?: Partial<GridProps>,
  hideButton?: boolean,
  selected?: boolean,
  onClick?: () => void,
}


const PricingPlan: FunctionComponent<IPricingPlan> = ({
  name,
  headerText,
  pricingInfo,
  planColor,
  planFeatures=[],
  headerIcon,
  buttonText,
  buttonLink,
  gridSizing={},
  hideButton,
  selected,
  onClick
}) => {
  const [showFeatures, setShowFeatures] = useState(true)
  const hasPlanFeatures = !!planFeatures.length
  const featuresLinkText = `${showFeatures ? 'Hide' : 'Show'} Features`

  const onClickShowFeaturesLink = (e: any) => {
    e.stopPropagation()
    setShowFeatures(!showFeatures)
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      {...gridSizing}
    >
      <PricingPlanWrapper
        mb={3}
        selected={selected}
        selectable={!!onClick}
        onClick={() => onClick && onClick()}
      >
        <PricingSection
          name={name}
          headerText={headerText}
          pricingInfo={pricingInfo}
          color={planColor}
          hidePricingSubText={name === premierPlan.name}
        >
          <Box textAlign="center" mt={1}>
            {!!headerIcon && <FontAwesomeIcon icon={headerIcon} size="4x" color={colors.lightpurple} />}
            {(!headerIcon && headerIcon !== null) && <FontAwesomeIcon icon={faPaperPlane} size="4x" color={colors.lightpurple} />}
            {!hideButton && <Link useButton to={buttonLink || routes.SIGNUP} mt={3} block>
              {buttonText || 'Start for free'}
            </Link>}
          </Box>
          {hasPlanFeatures && (
            <Box mt={2.2}>
              <ShowFeaturesLink 
                onClick={onClickShowFeaturesLink}
                mb={1} 
                variant="regular" 
                center
              >
                {featuresLinkText}
              </ShowFeaturesLink>
              {showFeatures && <Box>
                {planFeatures.map((features, i) => (
                  <LinkWithIcon key={i} mb={2} tooltip={features.tooltip}>
                    {features.label}
                  </LinkWithIcon>
                ))}
              </Box>}
            </Box>
          )}
        </PricingSection>
      </PricingPlanWrapper>
    </Grid>
  )
}

export default PricingPlan

export const allPricingPlans: IPricingPlan[] = [
  {
    name: freePlan.name,
    headerText: freePlan.description,
    pricingInfo: freePlan.price,
    planColor: colors.subtlylightpurple,
    planCode: seasonTypes.FREE,
    buttonText: 'Start Now',
    planFeatures: [
      freePlan.contestantLimit,
      freePlan.votingRoundsLimit,
      freePlan.votingDurationLimit,
      freePlan.staffMemberLimit,
    ]
  },
  {
    name: essentialPlan.name,
    headerText: essentialPlan.description,
    pricingInfo: essentialPlan.price,
    planColor: "#e0ffef",
    planCode: seasonTypes.ESSENTIAL,
    buttonText: 'Start Now',
    planFeatures: [
      essentialPlan.contestantLimit,
      essentialPlan.votingRoundsLimit,
      essentialPlan.votingDurationLimit,
      essentialPlan.staffMemberLimit,
    ]
  },
  {
    name: proPlan.name,
    headerText: proPlan.description,
    pricingInfo: proPlan.price,
    planColor: colors.yellow,
    planCode: seasonTypes.PRO,
    buttonText: 'Start Now',
    planFeatures: [
      proPlan.contestantLimit,
      proPlan.votingRoundsLimit,
      proPlan.votingDurationLimit,
      proPlan.staffMemberLimit,
    ]
  },
  {
    name: premierPlan.name,
    headerText: premierPlan.description,
    pricingInfo: premierPlan.price,
    planColor: colors.verylightpurple,
    buttonText: 'Talk to an Expert',
    buttonLink: routes.CONTACT_US,
    headerIcon: faEnvelope,
    planCode: seasonTypes.PREMIER,
    planFeatures: [
      proPlan.contestantLimit,
      proPlan.votingRoundsLimit,
      proPlan.votingDurationLimit,
      proPlan.staffMemberLimit,
    ]
  }
]

const PricingPlanWrapper = styled(({ selected, selectable, ...rest }) => <Box {...rest} />)`
  ${({ selectable, selected }) => (selectable && !selected) && `
    &:hover {
      box-shadow: 0 0 5px 2px ${colors.lightpurple};
    }
  `}

  ${({ selected }) => selected && `
    box-shadow: 0 0 0px 4px ${colors.purple};
  `}
`

const ShowFeaturesLink = styled(Text)`
  color: ${colors.purple};
  cursor: pointer;
  transition: opacity ease .4;
  
  &:hover {
    text-decoration: underline;
    opacity: .6
  }
`