import React from 'react'
import usePageTitle from '../hooks/usePageTitle'
import ErrorPage from './ErrorPages/ErrorPage'

const ComingSoon = () => {
  usePageTitle('Coming soon')

  return (
    <ErrorPage message='UNDER CONSTRUCTION' comingSoon={true} />
  )
}

export default ComingSoon

