import { getCurrency } from "../../utils/helpers"

export const freePlan = {
  name: 'Free',
  description: "Try it out for small and short voting campaigns.",
  price: `${getCurrency()}0.00`,
  contestantLimit: {
    label: "Max of 4 Contestants",
    tooltip: "With this plan you can only have four contestants in a single competition, if you need more contestants please upgrade your plan.",
  },
  votingRoundsLimit: {
    label: "Max of 3 Voting Rounds",
    tooltip: "With this plan you can only have a maximum of 3 voting rounds, if you competition needs more voting rounds please upgrade your plan.",
  },
  votingDurationLimit: {
    label: "Max voting duration of 3 days",
    tooltip: "With this plan you voting campaign has a max duration of 3 days, if your campaign is going to last longer than 3 days please upgrade your plan.",
  },
  staffMemberLimit: {
    label: "Single admin, no staff members.",
    tooltip: "With this plan only the creator of the competition (the admin) has access to this competitions backend, you cannot assign users as a staff member. If you need to have staff members manage your competition then please upgrade your plan.",
  },
  seasonLimit: {
    label: "Max of 1 Season per competition",
    tooltip: "You can only have a single season for your competition, to have multiple seasons please upgrade your plan"
  }
}

export const essentialPlan = {
  name: 'Essentials',
  description: 'Try out this plan for a medium and fairly large voting campaigns.',
  price: `Starts at ${getCurrency()}10,000`,
  contestantLimit: {
    label: "Max of 10 Contestants",
    tooltip: "With this plan you can only have 10 contestants in a single competition, if you need more contestants please upgrade your plan.",
  },
  votingRoundsLimit: {
    label: "Max of 10 Voting Rounds",
    tooltip: "With this plan you can only have a maximum of 10 voting rounds per competition, if your competition needs more voting rounds please upgrade your plan.",
  },
  votingDurationLimit: {
    label: "Max voting duration of 1 month",
    tooltip: "With this plan you voting campaign has a max duration of 1 month, if your campaign is going to last longer than 1 month please upgrade your plan.",
  },
  staffMemberLimit: {
    label: "4 staff members",
    tooltip: "With this plan you can add staff members to your competition, giving them access to your backend. If you need more than 4 staff members to manage your competition then please upgrade your plan.",
  },
  seasonLimit: {
    label: "Max of 6 Season per competition",
    tooltip: "You can only have a maximum of 6 seasons for your competition, to have more than 6 seasons please upgrade your plan"
  }
}

export const proPlan = {
  name: 'Pro',
  description: 'Go limitless with the pro plan for your large campaigns',
  price: `Starts at ${getCurrency()}30,000`,
  contestantLimit: {
    label: "Unlimited Contestants",
    tooltip: "You can have as many contestants as you need",
  },
  votingRoundsLimit: {
    label: "Unlimited Voting Rounds",
    tooltip: "Run an unlimited amount of voting rounds with this plan",
  },
  votingDurationLimit: {
    label: "Unlimited Voting Duration",
    tooltip: "Have your campaign run for as long as needed with this plan",
  },
  staffMemberLimit: {
    label: "Unlimited number of Staff",
    tooltip: "Assign an unlimited number of staff members to help manage your campaign",
  },
  seasonLimit: {
    label: "Unlimited number of Seasons",
    tooltip: "You can have an unlimited number of seasons"
  }
}

export const premierPlan = {
  name: 'Premier',
  description: 'For campaigns with paid voting we charge a percentage of the votes',
  price: '30% of Payout due'
}