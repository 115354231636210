import React from 'react'
import crypto from 'crypto';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import Auth from './auth';
import { RequestType, RequestOptions, IUploadImage, ComponentSpacing, ICompetition } from './interfaces';
import { errorMessage, AWS_BASE_URL, StaffRoles, regexPatterns, CreditCardIcons } from './constants';
import { size, spacing } from '../styles/_var';
import { getPresignedUrlRequest } from './apiRequests';


declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENCRYPTION_KEY: string,
      REACT_APP_IV: string,
      REACT_GOOGLE_API_KEY: any,
      REACT_APP_NODE_ENV: string,
      REACT_APP_GOOGLE_OAUTH_CLIENT_KEY: string,
    }
  }
}

interface IFetchPayload {
  url: string,
  method?: RequestType,
  body?: any,
  headerOptions?: any,
  useToken?: boolean,
  useBaseUrl?: boolean,
}

interface IUploadToS3 {
  filename: string,
  fileUrl: string,
  presignedUrl?: any,
  imageElementsToRefresh?: any
}

export const parseToNumber = (value: string): number | string => {
  if (!value) return '';
  return Number(value.replace(/[^\d]/g, ''));
}

export const formatToCurrency = (value: string): string => {
  if (!value) return '';

  let newValue = String(value);
  newValue = newValue.replace(/[^\d]/g, '')

  if (newValue.length < 4) return `N${newValue}`;

  return toCurrency(Number(newValue), false, true);
}

export function beautifyTime(rawDate: string) {
  return moment(rawDate).format('hh:mm a')
}

export function beautifyDate(rawDate: string | Date) {
  return moment.utc(rawDate).local().format('Do of MMM, YYYY');
}

export function maskCardNumber(cardNumber: string): string {
    return `**** **** **** ${cardNumber.substr(-4)}`;
}

export function toCurrency(value: number, showDecimal:boolean = true, showCurrency:boolean = true) {
  let formattedCurrency = `${showCurrency ? "N" : ""}${Number(value === -1 ? 0 : value).toLocaleString()}${
    showDecimal ? ".00" : ""
    }`;

  if (formattedCurrency.includes('-')) {
    formattedCurrency = `-${formattedCurrency.replace('-', '')}`
  }

  return formattedCurrency
}

export function capitalize(text: string): string {
  return text[0].toUpperCase() + text.slice(1);
}

export function camelCasize(text: string): string {
  if (!text.includes(' ')) return text.toLowerCase();
  const [firstWord, secondWord] = text.split(' ');

  return firstWord.toLowerCase() + capitalize(secondWord);
}

export function required(text: string): undefined | string {
  return text ? undefined : 'Required'
}

export function requiredOrZero(text: any): undefined | string {
  if (text === 0) return undefined;
  return text ? undefined : 'Required'
}

export function isEmail(value: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
}

export function isPhoneNo(phoneNumber: string): boolean {
  return regexPatterns.phone.test(phoneNumber)
}

export function parseToPhone(rawPhone: string): string {
  return rawPhone ? `+234${rawPhone.replace(/^(\+?234)?0?/, '')}` : ''
}

export function parseUsername(username: string): string {
  if (isPhoneNo(username)) {
    return parseToPhone(username)
  }

  return username
}

export function isPassword(password: string): string | undefined {
  return (password || '').length > 5 ? undefined : 'Password is too short';
}

export const isSame = (password: string ) => (confirmPassword: string): string | undefined => {
  return password === confirmPassword ? undefined : 'Password does not match'
}

export function encrypt(text: string): string {
  var cipher = crypto.createCipheriv(
      'aes-256-cbc', 
      Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY), 
      process.env.REACT_APP_IV
  );
  var encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('hex');
}

export function decrypt(cipherText: string): string {
  let encryptedText: Buffer = Buffer.from(cipherText, 'hex');
  let decipher = crypto.createDecipheriv(
      'aes-256-cbc', 
      process.env.REACT_APP_ENCRYPTION_KEY, 
      process.env.REACT_APP_IV
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

export function fetch(fetchPayload: IFetchPayload): Promise<AxiosResponse<any>> {
  const { 
    url,
    method="GET",
    body,
    headerOptions,
    useBaseUrl=true,
    useToken=true 
  } = fetchPayload

  if (useBaseUrl) {
    const baseUrls: any = {
      development: process.env.REACT_APP_DEV_API,
      staging: process.env.REACT_APP_STAGING_API,
      production: process.env.REACT_APP_PROD_API
    }
    axios.defaults.baseURL = baseUrls[process.env.REACT_APP_NODE_ENV]
  } else {
    axios.defaults.baseURL = ''
  }

  const generateRequestOptions = () => {
      const userToken:string | null = Auth.fetchToken();
      const options:RequestOptions = { url, method: method };

      if (body) options.data = body;

      if (userToken && useToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
      } else {
        delete axios.defaults.headers.common['Authorization'];
      }

      return { ...options, ...(headerOptions || {}) };
  }
  const requestOptions = generateRequestOptions();

  return new Promise((resolve, reject) => {
    axios
      .request(requestOptions)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          localStorage.clear()
        } 
        reject(error)
      });
  })
}

export const retrieveErrorMessage = (errorObject: any): string => {
  const serverResponse = errorObject?.response?.data || {};
  const errors = Object.values(serverResponse)[0];

  if (Array.isArray(errors)) {
    return `${errors}`;
  }

  return serverResponse.message || serverResponse.detail || errorMessage;
}

export const max = (number: number) => (value: string) => {
  if (!value) return '';
  if (value.length > number) return value.substring(0, number-1)
  return value;
}

export const formatPhoneNumber = (rawPhone: string): string => {
  let phone = rawPhone;

  if (!phone || phone.trim() === '') return phone;

  if (phone.length > 7) {
    if (phone.startsWith('+234') || phone.startsWith('234') || phone.startsWith('+2340')) {
      phone = phone.replace(/\+?2340?/, '');
    }
  
    if (phone.startsWith('0')) {
      phone = phone.replace(/^0/, '');
    }
  }

  return phone.replace(/[^\d]/g, '');
}

export const formatToNumber = (value: string): string => {
  if (!value || value.trim() === '') return value;
  return value.replace(/[^\d]/g, '');
}

export const formatToFloat = (value: string): string => {
  if (!value || value.trim() === '') return value;
  if (/^\d*\.?\d*$/.test(value)) {
    return value
  } else {
    return value.substring(0, value.length - 1)
  }
}

export const formatToPhone = (rawPhone: string): string => {
  if (!rawPhone || rawPhone.trim() === '') return rawPhone;
  return `+234${rawPhone.replace(/\+?2340?/, '')}`;
}

export var formatToUsername = (username: string) => {
  if (username) return username.replace(/ /g,  '').toLowerCase()
  return ''
}

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

export const getAddressComp = (addressObject: any, compName: string): string => {
  return (addressObject.address_components
    .find((component: any) => 
      component.types.some((e: any) => e.includes(compName))
    ) || { long_name: ''}).long_name
}

export const addPaystackCommission = (amount: number = 0): number => {
  let commission = 0

  if (amount > 2000) {
    commission += 100 + (0.015 * amount)
  } else {
    commission += (0.015 * amount)
  }

  if (commission > 2000) commission = 2000

  return Math.round(amount + commission)
}

export const getAppSettings = (): any => {
  const settings = sessionStorage.getItem('app_settings');
  return settings ? JSON.parse(decrypt(settings)) : null
}

export const padToLengthWithZero = (toLength:number, str?: string) => {
  let res = `${str}`;
  if (!res || res.length >= toLength) return str
  const numberOfZeros = toLength - res.length
  for (let i=0; i<numberOfZeros; i++) res = '0' + res

  return res;
}

export const isMobileScreen = () => {
  return window.screen.width <= Number(size.tablet.replace('px', ''));
}

export const pick = (payload: any, fields: string[]) => {
  const results: any = {}

  fields.forEach(field => {
    if (field in payload) results[field] = payload[field]
  })
  
  return results
}

export const exclude = (payload: any, fieldsToExclude: string[]) => {
  const results: any = {}

  Object.keys(payload).forEach(field => {
    if (!fieldsToExclude.includes(field)) results[field] = payload[field]
  })

  return results
}

export const sanitizeUserRegisterPayload = (payload: any) => {
  const [first_name, last_name] = payload.full_name.split(' ')
  return {
    ...payload,
    phone_number: parseToPhone(payload.phone_number),
    first_name,
    last_name
  }
}

export const getPaystackReference = (username: string) => {
  return `${+(new Date())}_${username.substr(1)}`
}

export const uploadToS3 = async ({ filename, fileUrl, presignedUrl, imageElementsToRefresh }: IUploadToS3) => {
  let signedUrl = presignedUrl

  try {
    if (!signedUrl) {
      const response = await getPresignedUrlRequest(filename)
      signedUrl = response.data.presigned_url
    }

    const formData = new FormData()
    const response = await fetch({
      url: fileUrl,
      useBaseUrl: false,
      useToken: false,
      headerOptions: { responseType: 'blob' }
    })
    const fileBlob = response.data
    const { url, fields } = signedUrl

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    formData.append('file', fileBlob, filename)
    await fetch({
      url,
      method: 'POST',
      body: formData,
      useBaseUrl: false,
      useToken: false,
    });

    if (imageElementsToRefresh) {
      refreshElements(imageElementsToRefresh, fileUrl)
    }

  } catch (e) {
    console.log('err: ', e)
  }

}

/** Hacks to reflect uploads after uploading avatar to s3 */
export const refreshElements = (elementsToRefresh: any, fileUrl?: string) => {
  if (elementsToRefresh.length) {
    let counter = 0;
    for (;  counter < elementsToRefresh.length; counter++) {
      refreshElement(elementsToRefresh[counter], fileUrl)
    }
  } else {
    refreshElement(elementsToRefresh, fileUrl)
  }
}

export const refreshElement = (element: any, fileUrl?: string) => {
  if (element.src) {
    element.src = ""
    element.src = fileUrl
  } else if (element.style) {
    element.style.backgroundImage = ""
    element.style.backgroundImage = `url("${fileUrl}")`
  }
}
/** End if hack */

export const generateFileUploadPath = (filekey: string, folderPath: string) => {
  let uploadPath = folderPath
  uploadPath += `${filekey}_${+(new Date())}.jpg`

  return [uploadPath, `${AWS_BASE_URL}/${uploadPath}`]
}

export const uploadImage = async ({ key, imageUrl, folderPath, async=true, imageElementsToRefresh }: IUploadImage) => {
  if (!imageUrl) return

  const [filename, s3Path] = generateFileUploadPath(key, folderPath)

  if (async) {
    uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
  } else {
    await uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
  }

  return s3Path
}

export const getStyleSpacing = ({ mt, mb, mr, ml }: ComponentSpacing): any => {
  const style: any = {}
  const spacingKeys = Object.keys(spacing)

  if (mt && mt < spacingKeys.length) {
    style.marginTop = (spacing as any)[spacingKeys[mt - 1]]
  }

  if (mb && mb < spacingKeys.length) {
    style.marginBottom = (spacing as any)[spacingKeys[mb - 1]]
  } 
  
  if (mr && mr < spacingKeys.length) {
    style.marginRight = (spacing as any)[spacingKeys[mr - 1]]
  }
  
  if (ml && ml < spacingKeys.length) {
    style.marginLeft = (spacing as any)[spacingKeys[ml - 1]]
  }

  return style
}

export const getCompetitionUsername = () => {
  const regexMatch = window.location.pathname.match(/\/(\w+)\/dashboard/)

  if (regexMatch) return regexMatch[1]
}

export const getPercent = (value: number, percent: number) => value * (percent/100)

export const processError = (errorObject: any) => {
  console.log('err:: ', errorObject)
  try {
    console.error(retrieveErrorMessage(errorObject))
  } catch {}
}

export const toUTC = (value: Date | string) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return new Date(value).toISOString()
  }

  return value.toISOString()
}

export const queryStringConstructor = (payload: any) => {
  let qs = ''

  Object.keys(payload).forEach(key => {
    if (payload[key]) {
      qs += !qs ? '?' : '&'
      qs += `${key}=${payload[key]}`
    }
  })

  return qs
}

export const filterByQuery = (payload: ICompetition[], query: string) => {
  return payload.filter(({ name }) => name.toLowerCase().includes(query))
}

export const filterByStatus = (payload: ICompetition[], status: string) => {
  switch (status) {
    case 'ACTIVE':
      return payload.filter(({ active }) => active)
    case 'COMPLETED':
      return payload.filter(({ active }) => !active)
    case 'NEW':
      const now = moment.utc((new Date()).toISOString())
      return payload.filter(({ created_at }) => {
        const createdAt = moment.utc(created_at)
        const daysDifference = now.diff(createdAt, 'days')

        return daysDifference < 10
      })
    default:
      return payload
  }
}

export const getFromQueryString = (queryString: string, field: string, defaultValue?: string) => {
  const matcher = new RegExp(`${field}=([^&]*)`, 'i')
  return queryString.match(matcher)?.[1] || defaultValue
}

export const wordify = (word: string) => word.replace(/_/g, ' ')

export const getStaffRole = (role: number) => StaffRoles[role]

export const mapToProps = (mappings: any, Container: any) => {
  return (props: any) => {
    return React.createElement(Container, {...props, ...mappings})
  }
}

export const plurify = (word: string, count: number) => {
  return `${count} ${word}${count > 1 ? 's' : ''}`
}

export const getCurrency = () => String.fromCharCode(8358)

export const setPageTitle = (title: string) => {
  let titleText = 'Matuskii'

  if (title && title !== 'undefined') {
    titleText += `:: ${title}`
  }
  document.title = titleText
}

export const sanitizeCardType = (card_type: string) => {
  const creditCardKeys = Object.keys(CreditCardIcons)
  let sanitizedCardType = card_type.toLowerCase()
  return creditCardKeys.find((key: string) =>  sanitizedCardType?.includes(key))
}

export const hashString = (str: string) => {
  let hash = 0, i, chr;
  if (str.length === 0) return '';

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }

  return String(hash);
}

export const getChannelID = (username: string) => {
  const unHashed = `${username}_${process.env.REACT_APP_NODE_ENV}`
  return hashString(unHashed)
}

export const getCompetitionLabels = (competitionTemplate?: number) => {
  let competitionLabel = ''
  let contentstantLabel = ''
  const AWARDS = 2

  if (competitionTemplate === AWARDS) {
    competitionLabel = 'Award'
    contentstantLabel = 'Nominee'
  } else {
    competitionLabel = 'Competition'
    contentstantLabel = 'Contestant'
  }

  return {competitionLabel, contentstantLabel}
}