import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MInput from '../../components/MInput'
import SimpleForm from '../../components/SimpleForm'
import Text, { BoldText} from '../../components/Text'
import uiStore from '../../stores/uiStore'
import { colors, spacing, device } from '../../styles/_var'
import { required, uploadToS3 } from '../../utils/helpers'
import ImageUpload from '../../components/ImageUpload'
import { ReactComponent as Upload } from '../../assets/images/upload.svg' 
import imageIcon from '../../assets/images/image-icon.png'
import authStore from '../../stores/authStore'
import { AWS_BASE_URL, USER_AVATAR_S3_FOLDER } from '../../utils/constants'
import imagePlaceholder from '../../assets/images/placeholder-person.jpg'
import validators from '../../utils/validators'
import usePageTitle from '../../hooks/usePageTitle'

const MyAccount = () => {
  const [userAvatar, setUserAvatar] = useState('')
  const filename  = `${USER_AVATAR_S3_FOLDER}${authStore.avatarFilename}.jpg`
  const avatarUrl =  `${AWS_BASE_URL}/${filename}`
  usePageTitle('My Account')

  useEffect(() => {
    uiStore.setNavTitle('My Account')
  }, [])
  

  const actionButton = {
    isSubmit: true,
    label: 'Save',
    loading: authStore.updatingUserLoading,
  }

  const submitForm = (data: any) => {
    if (userAvatar) {
      const imageElementsToRefresh = document.getElementsByClassName("user-avatar")
      uploadToS3({ filename, fileUrl: userAvatar, imageElementsToRefresh })
      data.avatar = avatarUrl
    }

    authStore.updateUser(data)
  }

  return (
    <Wrapper>
      <ContentBody>
        <BoldText variant="medium" role="heading">
          YOUR PROFILE INFORMATION
        </BoldText>
        <FormContainer>
          <ImageUpload
            contentArea={({ croppedImage, openFileChooser }) => (
              <AvatarSection>
                <Avatar 
                  className="user-avatar"
                  image={croppedImage || authStore.userAvatar || imagePlaceholder} 
                  onClick={openFileChooser}>
                  <Overlay>
                    <img src={imageIcon} alt="account-user" />
                  </Overlay>
                </Avatar>
                <UploadLink variant="small" onClick={openFileChooser}>
                  <Upload /> Upload Image
                </UploadLink>
              </AvatarSection>
            )}
            onImageChange={(image) => setUserAvatar(image)} />
          <SimpleForm
            formFields={profileFormFields}
            initialValues={{...authStore.userData}}
            submitForm={submitForm}
            actionButtons={actionButton} />
        </FormContainer>
      </ContentBody>
    </Wrapper>
  )
}

export default MyAccount

const profileFormFields = [
  {
    name: 'first_name',
    placeholder: 'First Name',
    component: MInput,
    validate: required
  },
  {
    name: 'last_name',
    placeholder: 'Last Name',
    component: MInput,
    validate: required
  },
  {
    name: 'username',
    placeholder: 'Username',
    component: MInput,
    validate: required,
    disabled: true,
  }, 
  {
    name: 'phone_number',
    placeholder: 'Phone Number',
    component: MInput,
    validate: validators.isPhoneNo
  }, 
  {
    name: 'email',
    placeholder: 'Email Address',
    component: MInput,
    validate: validators.isEmail,
    disabled: true,
  },
]

const Wrapper = styled.div`
  padding: 0 ${spacing.sm};
`

const ContentBody = styled.div`
  width: 70rem;
  margin: auto;
  max-width: 100%;
`

const FormContainer = styled.div`
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  padding: ${spacing.sm};
  margin-top: ${spacing.xs};
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }

  > div:last-child {
    flex: 1;
  }
`

const AvatarSection = styled.div`
  margin-bottom: ${spacing.sm};
  text-align: center;

  @media ${device.tablet} {
    margin-right: ${spacing.sm};
    margin-bottom: 0;
  }
`

const Avatar = styled(({ image, ...rest }) => <div {...rest} />)`
  border-radius: 1rem;
  ${({ image }) => image && `
    background-image: url("${image}");
  `}
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  border: 1px solid rgba(80, 80, 80, 0.3);
  box-sizing: border-box;
  position: relative;

  width: 18rem;
  height: 17rem;
  margin-bottom: ${spacing.xs};
  margin-left: auto;
  margin-right: auto;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: ${colors.black};
  opacity: 0;
  transition: opacity ease .25s;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    opacity: 0.3;
  }
`
const UploadLink = styled(Text)`
  color: ${colors.blue};
  cursor: pointer;
  transition: opacity ease .2s;
  display: inline-block;

  &:hover {
    opacity: .8;
  }

  svg {
    margin-right: 2px;
    top: 4px;
    position: relative;
  }
`
