import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import competitionPlaceholder from '../assets/images/competition-placeholder.png'
import { colors, spacing } from '../styles/_var'
import { ICompetition } from '../utils/interfaces'
import { BoldText } from './Text'

interface ICompetitionThumbnail extends ICompetition {
  small?: boolean;
  showVotes?: boolean;
  route?: string;
  block?: boolean;
}

const CompetitionThumbnail: FunctionComponent<ICompetitionThumbnail> = React.memo(({
  name,
  logo,
  username,
  small,
  // showVotes=true,
  route,
  block
}) => {
  return (
    <ThumbnailWrapper
      small={small}
      src={logo || competitionPlaceholder}
      block={block}
    >
      <Link to={route || `/${username}`}>
        <ThumbnailFooter small={small}>
          <CompetitionName variant="regular" truncate>{name}</CompetitionName>
          {/* {showVotes && <VotesText variant="regular">240 Votes</VotesText>} */}
        </ThumbnailFooter>
      </Link>
    </ThumbnailWrapper>
  )
})

export default CompetitionThumbnail

const ThumbnailWrapper = styled(({ small, src, block, ...rest }) => <div {...rest} />)`
  min-height: 22rem;
  margin-bottom: ${spacing.sm};
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow ease .2s;
  position: relative;

  ${({ src }) => `background-image: url(${src});`}
  background-size: cover;
  background-position: center center;

  ${({ small }) => small ? `
    width: 25rem;
    height: 22rem;
    ` : `
    width: 30rem;
    height: 30rem;
    `
  }

  ${({ block }) => block && `
    width: 100%;
  `}

  border-radius: 1rem;
  box-shadow: 0 0 10px 3px ${colors.lightgrey};

  &:hover {
    box-shadow: 0 0 8px 7px #dad1fc;
    & > a > div {
      background-color: rgba(255, 255, 255, 0.8);

      p {
        color: ${colors.purple};
      }
    }
  }

  & a {
    color: inherit;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }
`

const ThumbnailFooter = styled(({ small, ...rest }) => <div {...rest} />)`
  box-sizing: border-box;
  padding: ${spacing.xxs};
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all ease .2s;

  ${({ small }) => small && `
    box-sizing: border-box;
    padding: 0.5rem;
  `}
`

const CompetitionName = styled(BoldText)``

// const VotesText = styled(BoldText)`
//   width: 10rem;
//   text-align: right;
//   color: ${colors.purple};

//   @media ${device.tablet} {
//     width: 15rem;
//   }
// `