import { AxiosResponse } from "axios";
import { fetch, queryStringConstructor } from "./helpers";
import {
  IChangePassword,
  ICompetition,
  IUserCredentials,
  IUserPayload,
  IUserSettings,
  IPayoutOptions,
  ContestantResponse,
  ContestantWithBio,
  DeleteContestantRequest,
  IPayoutSummary,
  CreateVotingRoundRequest,
  IVotingRound,
  ICompetitionStaff,
  SaveStaffRequest,
  ICompetitionCategory,
  PendingInvitationResponse,
  AllowedVotersResponse,
  SaveAllowedVotersResponse,
  IAdvert,
  IResetPasswordPayload,
  IHelpRequest,
  ISetSubscriptionRequest,
  IPaymentConfirmation,
  IMyCompetitions,
  IFaqResponse,
  IDemosResponse,
} from "./interfaces";
import { ISpendSummary, ITransferCreditPayload, IPaymentCompletion, IPayoutHistoryResponse } from '../stores/creditStore'
import { IPaystackInfo } from '../stores/paymentStore'



export const getPresignedUrlRequest = async (filename: string): Promise<AxiosResponse<any>> => {
  const url = '/presigned_url'
  return fetch({ url, method: 'POST', body: { filename } })
}

export const changeUserPasswordRequest = async (payload: IChangePassword): Promise<AxiosResponse<any>> => {
  const url = '/users/change_password'
  return fetch({ url, method: 'POST', body: payload })
}

export const signupRequest = async (payload: IUserPayload): Promise<AxiosResponse<any>> => {
  const url = '/users/register'
  return fetch({ url, method: 'POST', body: payload })
}

export const loginRequest = async (payload: IUserCredentials): Promise<AxiosResponse<any>> => {
  const url = '/users/login'
  return fetch({ url, method: 'POST', body: payload })
}

export const validateUsernameRequest = async (payload: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/users/validate_username?username=${payload}` })
}

export const updateUserRequest = async (payload: IUserPayload): Promise<AxiosResponse<IUserPayload>> => {
  return fetch({ url: '/users/update', method: 'POST', body: payload})
}

export const updateUserSettingsRequest = async (payload: IUserSettings): Promise<AxiosResponse<IUserSettings>> => {
  return fetch({ url: '/users/settings', method: 'POST', body: payload })
} 

export const deleteUserAccountRequest = async (password: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/users/destroy_account', method: 'POST', body: { password } })
}

export const fetchNewCompetitionsRequest = async (): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/competitions?type=new' })
}

export const fetchInvolvedCompetitionRequest = async (): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/competitions?type=involved' })
}

export const fetchAllCompetitionsRequest = async (category?: string, page?: number): Promise<AxiosResponse<any>> => {
  const queryString = queryStringConstructor({ category, page })
  return fetch({ url: `/competitions${queryString}`})
}

export const fetchMyCompetitionsRequest = async (page: number = 1): Promise<AxiosResponse<IMyCompetitions>> => {
  return fetch({ url: `/users/my_competitions?page=${page}` })
}

export const fetchMyPrivateCompetitionsRequest = async (page: number = 1): Promise<AxiosResponse<IMyCompetitions>> => {
  return fetch({ url: `/users/private_competitions?page=${page}`})
}

export const createCompetitionRequest = async (payload: ICompetition): Promise<AxiosResponse<ICompetition>> => {
  const url = '/competitions'
  return fetch({ url, method: 'POST', body: payload })
}

export const updateCompetitionRequest = async (payload: ICompetition, username: string, seasonId?: string): Promise<AxiosResponse<ICompetition>> => {
  const url = `/competitions/${username}`
  return fetch({
    url,
    method: 'PUT',
    body: {
      ...payload,
      season_id: seasonId
    }
  })
}

export const savePayoutOptionsRequest = async (username: string, payload: IPayoutOptions): Promise<AxiosResponse<null>> => {
  const url = `/competitions/${username}/payout_options`
  return fetch({ url, method: 'POST', body: payload })
}

export const createContestantRequest = async (payload: ContestantWithBio): Promise<AxiosResponse<ContestantResponse>> => {
  return fetch({ url: '/contestants', method: 'POST', body: payload })
}

export const updateContestantRequest = async (payload: ContestantWithBio): Promise<AxiosResponse<ContestantResponse>> => {
  return fetch({ url: `contestants/${payload.username}`, method: 'PUT', body: payload })
}

export const deleteContestantRequest = async (payload: DeleteContestantRequest): Promise<AxiosResponse<null>> => {
  return fetch({ url: `/contestants/${payload.contestant_username}`, method: 'DELETE', body: payload })
}

export const evictContestantRequest = async ({ season_id, username }: {season_id: string, username: string}): Promise<AxiosResponse<ContestantWithBio>> => {
  return fetch({ url: `contestants/${username}/evict`, method: 'POST', body: { season_id }})
}

export const getUserCreditRequest = async (): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/payments/wallet_balance' })
}

export const getCreditSpendSummaryRequest = async (): Promise<AxiosResponse<ISpendSummary>> => {
  return fetch({ url: '/payments/user_spend_summary' })
}

export const transferCreditRequest = async (payload: ITransferCreditPayload): Promise<AxiosResponse<any>> => {
  const url = '/payments/transfer_credits'
  return fetch({ url, method: 'POST', body: payload })
}

export const getTransactionHistoryRequest = async (page: number = 1): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/payments/transaction_history?page=${page}` })
}

export const getPaystackInfoRequest = async (): Promise<AxiosResponse<IPaystackInfo>> => {
  return fetch({ url: '/payments/paystack_info' })
}

export const completeCreditPaymentRequest = async (payload: IPaymentCompletion): Promise<AxiosResponse<{balance: number}>> => {
  const url = '/payments/complete_credit_payment'
  return fetch({ url, method: 'POST', body: payload })
}

export const getPayoutSummaryRequest = async (competitionUsername: string, season_id: string): Promise<AxiosResponse<IPayoutSummary>> => {
  const url = `/payments/${competitionUsername}/payout_summary`
  return fetch({ url, method: 'POST', body: { season_id } })
}

export const getPayoutHistoryRequest = async (competitionUsername: string, season_id: string, page: number = 1): Promise<AxiosResponse<IPayoutHistoryResponse>> => {
  const url = `/payments/${competitionUsername}/payout_history?page=${page}`
  return fetch({ url, method: 'POST', body: { season_id } })
}

export const fetchCompetitionDetailsRequest = async (competitionUsername: string): Promise<AxiosResponse<ICompetition>> => {
  const url = `competition_dashboard/${competitionUsername}`
  return fetch({ url })
}

export const fetchContestantsRequest = async (competitionUsername: string, season_id?: string): Promise<AxiosResponse<ContestantWithBio[]>> => {
  const url = `seasons/${season_id}/contestants`
  return fetch({ url, method: 'GET' })
}

export const createVotingRoundRequest = async (season_id: string, payload: CreateVotingRoundRequest): Promise<AxiosResponse<IVotingRound>> => {
  const url = `/seasons/${season_id}/voting_rounds`
  return fetch({ url, method: 'POST', body: payload })
}

export const fetchVotingRoundsRequest = async (season_id: string): Promise<AxiosResponse<IVotingRound[]>> => {
  return fetch({ url: `/seasons/${season_id}/voting_rounds` })
}

export const setVotingRoundStatusRequest = async (season_id: string, payload: any): Promise<AxiosResponse<IVotingRound>> => {
  const url = `/seasons/${season_id}/voting_rounds/set_voting_round`
  return fetch({ url, method: 'POST', body: payload })
}

export const getCompetitionStaffRequest = async (competition_username: string): Promise<AxiosResponse<ICompetitionStaff[]>> => {
  return fetch({ url: `competition_dashboard/${competition_username}/staff` })
}

export const saveCompetitionStaffRequest = async (competition_username: string, payload: SaveStaffRequest): Promise<AxiosResponse<ICompetitionStaff>> => {
  return fetch({
    url: `/competition_dashboard/${competition_username}/staff`,
    method: 'POST',
    body: payload
  })
}

export const deleteCompetitionStaffRequest = async (competition_username: string, payload: {email: string}): Promise<AxiosResponse<null>> => {
  return fetch({
    url: `/competition_dashboard/${competition_username}/staff`,
    method: 'DELETE',
    body: payload
  })
}

export const fetchCompetitionRequest = async (competitionUsername: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/competitions/${competitionUsername}`})
}

export const deleteCompetitionRequest = async (competition_username: string): Promise<AxiosResponse<any>> => {
  return fetch({
    url: `/competitions/${competition_username}`,
    method: 'DELETE'
  })
}

export const fetchCompetitionCategoryRequest = async (): Promise<AxiosResponse<ICompetitionCategory[]>> => {
  return fetch({ url: '/competitions/categories' })
}

export const voteContestantRequest = async (contestantUsername: string, payload: any): Promise<AxiosResponse<any>> => {
  const url = `/contestants/${contestantUsername}/vote`
  return fetch({ url, method: 'POST', body: payload })
}

export const fetchPendingInvitationsRequest = async (seasonId: string, page: number = 1): Promise<AxiosResponse<PendingInvitationResponse>> => {
  const queryString = queryStringConstructor({ page })
  return fetch({ url:  `/seasons/${seasonId}/pending_invitations${queryString}` })
}

export const fetchEligibleVotersRequest = async (seasonId: string, page: number = 1): Promise<AxiosResponse<AllowedVotersResponse>> => {
  const queryString = queryStringConstructor({ page })
  return fetch({ url: `/seasons/${seasonId}/allowed_voters${queryString}` })
}

export const saveAllowedVotersRequest = async (seasonId: string, voter_emails: string[]): Promise<AxiosResponse<SaveAllowedVotersResponse>> => {
  const url = `/seasons/${seasonId}/allowed_voters`
  return fetch({ url, method: 'POST', body: { voter_emails } })
}

export const  removeEligibleVoterRequest= async (seasonId: string, voter_email: string): Promise<AxiosResponse<any>> => {
  const url = `/seasons/${seasonId}/allowed_voters`
  return fetch({ url, method: 'DELETE', body: { voter_email } })
}

export const revokePendingInvitationRequest = async (seasonId: string, voter_email: string): Promise<AxiosResponse<any>> => {
  const url = `/seasons/${seasonId}/pending_invitations`
  return fetch({ url, method: 'DELETE', body: { voter_email } })
}

export const closeSeasonRequest = async (seasonId: string): Promise<AxiosResponse<any>> => {
  const url = `/seasons/${seasonId}/close_season`
  return fetch({ url, method: 'POST' })
}

export const createSeasonRequest = async (competitionUsername: string): Promise<AxiosResponse<{message: string}>> => {
  return fetch({ 
    url: '/seasons',
    method: 'POST',
    body: { competition_username: competitionUsername }
  })
}

export const getAdvertsRequest = async(): Promise<AxiosResponse<{ data: IAdvert[] }>> => {
  return fetch({ url: '/ads' })
}

export const getPopularCompetitionsRequest = async (): Promise<AxiosResponse<{ data: ICompetition[] }>> => {
  return fetch({ url: '/competitions/popular_competitions' })
}

export const activateUserRequest = async (activationCode: string): Promise<AxiosResponse> => {
  return fetch({ url: `/users/activate/${activationCode}`, method: 'POST' })
}

export const resendActivationCodeRequest = async (expiredCode: string): Promise<AxiosResponse> => {
  return fetch({ url: `/users/resend_activation_code/${expiredCode}`, method: 'POST' })
}

export const sendPasswordResetRequest = async (username: string): Promise<AxiosResponse> => {
  return fetch({ url: '/users/forgot_password', method: 'POST', body: { username } })
}

export const resetUserPasswordRequest = async (payload: IResetPasswordPayload): Promise<AxiosResponse> => {
  return fetch({ url: '/users/reset_password', method: 'POST', body: payload  })
}

export const fetchFavouriteCompetitionRequest = async (): Promise<AxiosResponse> => {
  return fetch({ url: '/competitions/favourite_competitions' })
}

export const toggleCompetitionFavouriteRequest = async (competition_id: string): Promise<AxiosResponse> => {
  const body = { competition_id }
  return fetch({ url: '/competitions/toggle_favourites', method: 'POST', body })
}

export const sendHelpRequest = async (payload: IHelpRequest): Promise<AxiosResponse> => {
  return fetch({ url: '/contact_us', method: 'POST', body: payload })
}

export const setCompetitionSubscriptionPlanRequest = async (payload: ISetSubscriptionRequest): Promise<AxiosResponse> => {
  return fetch({ url: '/competitions/subscription_plan', method: 'POST', body: payload })
}

export const fetchUserDetailsRequest = async (): Promise<AxiosResponse> => {
  return fetch({ url: '/users/get_user_details' })
}

export const setSeasonPaymentRequest = async (seasonId: string, payload: IPaymentConfirmation): Promise<AxiosResponse> => {
  return fetch({
    url: `/payments/${seasonId}/complete_subscription_payment`,
    method: 'POST',
    body: payload
  })
}

export const getSeasonTransactionRequest = async (seasonId: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/seasons/${seasonId}/season_transactions` })
}

export const saveCreditCardRequest = async (reference_key: string, amount: number, refund_amount?: boolean): Promise<AxiosResponse<any>> => {
  return fetch ({ url: `/payments/credit_cards`, method: 'POST', body: { reference_key, amount, refund_amount } })
}

export const fetchSavedCardsRequest = async(): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/payments/credit_cards' })
}

export const deleteSavedCardRequest = async (card_id: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/payments/credit_cards', method: 'DELETE', body: { card_id } })
} 

export const setDefaultCardRequest = async (card_id: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/payments/credit_cards/default', method: 'POST', body: { card_id } })
}

export const chargeCardRequest = async (card_id: string, amount: number): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/payments/charge_card', method: 'POST', body: { card_id, amount } })
}

export const getFaqsRequest = async (): Promise<AxiosResponse<IFaqResponse>> => {
  return fetch({ url: '/faqs' })
}

export const getCompetitionInfoRequest = async (username: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/competitions/${username}/info`})
}

export const joinMailingListRequest = async (email: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/mailing_list', method: 'POST', body: { email } })
}

export const sendUIErrorRequest = async (error_message_report: string): Promise<AxiosResponse<null>> => {
  return fetch({ url: '/error_reporting', method: 'POST', body:{ error_message_report } })
}

export const getDemosRequest = async (): Promise<AxiosResponse<IDemosResponse>> => {
  return fetch({ url: '/demos' })
}

export const googleAuthRequest = async (id_token: string): Promise<AxiosResponse<any>> => {
  return fetch({ url: '/users/google_auth', method: 'POST', body: { id_token } })
}

export const setCompetitionTemplateRequest = async (username: string, template: number): Promise<AxiosResponse<any>> => {
  return fetch({ url: `/competition_dashboard/${username}/template`, method: 'POST', body: { template }})
}

export const createCategoriesRequest = async (username: string, name: string) => {
  return fetch({ url: `/competition_dashboard/${username}/categories`, method: 'POST', body: {name} })
}

export const deleteCategoriesRequest = async (username: string, categoryId: number) => {
  return fetch({ url: `/competition_dashboard/${username}/categories/${categoryId}`, method: 'DELETE'})
}

export const updateCategoryRequest = async (username:string, categoryId: number, name: string) => {
  return fetch({ url: `/competition_dashboard/${username}/categories/${categoryId}`, method: 'PUT', body: {name} })
}