import React, { FunctionComponent } from 'react'
import { AsideComponentsMapper } from './constants'

type ComponentsType = keyof typeof AsideComponentsMapper

interface IAside {
  components: ComponentsType[],
}

const Aside: FunctionComponent<IAside> = ({
  components
}) => {
  return (
    <aside>
      {components.map((componentName, i) => {
        return React.createElement(AsideComponentsMapper[componentName], { key: i })
      })}
    </aside>
  )
}

export default Aside
