import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing, device, colors } from '../styles/_var'
import Text, { BoldText } from './Text'
import flexibilityIcon from '../assets/images/flexibility-icon.png'
import securityIcon from '../assets/images/security-icon.png'
import settingsIcon from '../assets/images/settings-icon.png'
import PageContent from './PageContent'

interface IFeaturesProps {
  title: string;
  icon: string;
}

const Features: FunctionComponent<IFeaturesProps> = React.memo(({ title, icon, children }) => {
  return (
    <FeatureItem>
      <FeatureHeader>
        <img src={icon} alt="feature-icon" />
        <BoldText variant="large">{title}</BoldText>
      </FeatureHeader>
      <FeatureText variant="regular">{children}</FeatureText>
    </FeatureItem>
  )
})


const LandingSectionOne = React.memo(() => {
  return (
    <SectionWrapper>
      <TitleText variant="large">Modern, Secure and Effective</TitleText>
      <SubText variant="medium">Matuskii’s flexible contest and promotion tool gives you complete control over every aspect of your campaign. </SubText>

      <FeatureSection>
        <Features title="Flexibility" icon={flexibilityIcon}>
          Matuskii's flexible contest and promotion tools give you complete control over every aspect of your campaign.
        </Features>
        <Features title="Security" icon={securityIcon}>
          Our secure polling software helps with the management of complex voting events and contests and a wide range of e-voting solutions
        </Features>
        <Features title="Accessibility" icon={settingsIcon}>
          A variety of customization options are available on our fair and easy to use voting platform, accessible on any device
        </Features>
      </FeatureSection>
    </SectionWrapper>
  )
})

const SectionWrapper = styled(PageContent)`
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  text-align: center;

  @media ${device.tablet} {
    padding-top: ${spacing.rg};
    padding-bottom: ${spacing.rg};
  }

  @media ${device.laptop} {
    padding-top: ${spacing.xl};
    padding-bottom: ${spacing.xl};
  }
`

const TitleText = styled(BoldText)`
  color: ${colors.darkpurple};
  line-height: 5rem;
  padding: 0 ${spacing.sm};
  box-sizing: border-box;
  margin-bottom: ${spacing.xxs};
`

const SubText = styled(Text)`
  margin: auto;
  width: 80%;
  line-height: 3.3rem;

  @media ${device.tablet} {
    width: 70%;
  }
`

const FeatureSection = styled.div`
  margin-top: ${spacing.sm};
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xs};
  & > img {
    height: 4rem;
    margin-right: ${spacing.xxs};
  }
`

const FeatureItem = styled.div`
  max-width: 35rem;
  margin-top: ${spacing.sm};

  @media ${device.tablet} {
    max-width: 25rem;
  }

  @media ${device.laptopL} {
    max-width: 35rem;
  }
`

const FeatureText = styled(Text)`
  line-height: 3.3rem;
  text-align: justify;
`


export default LandingSectionOne
