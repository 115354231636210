import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'


const Message = ({
  message,
  type="loading"
} : {
  message: string,
  type?: "loading" | "error"
}) => {
  const iconProps = {} as any

  if (type === "loading") {
    iconProps.icon = faSpinner
    iconProps.spin = true
  } else {
    iconProps.icon = faTimesCircle
  }

  return (
    <MessageWrapper style={{color: type === 'loading' ? colors.purple : colors.red }}>
      <FontAwesomeIcon {...iconProps} size="3x" />
      <Text variant='medium'>{message}</Text>
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing.sm};
`

export default Message
