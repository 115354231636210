import React, { FunctionComponent } from 'react'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { colors, spacing } from '../styles/_var'
import Text from './Text'
import { ComponentSpacing } from '../utils/interfaces'
import { getStyleSpacing } from '../utils/helpers'

interface ILinkWithIcon extends React.HTMLAttributes<HTMLDivElement>, ComponentSpacing {
  icon?: IconProp,
  size?: SizeProp,
  tooltip?: string,
}

const LinkWithIcon: FunctionComponent<ILinkWithIcon> = ({
  style={},
  size = "2x",
  tooltip,
  children,
  icon,
  mt,
  mb,
  ml,
  mr,
}) => {
  const spacingComponents = { mr, mb, ml, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  return (
    <Wrapper style={componentStyle}>
      {icon && <FontAwesomeIcon icon={icon} size={size} color={colors.lightpurple} />}
      {!icon && <FontAwesomeIcon icon={faCheckDouble} color={colors.lightpurple} size={size} />}
      <Text variant="regular" tooltip={tooltip}>{children}</Text>
    </Wrapper>
  )
}

export default LinkWithIcon

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${spacing.xxs};
  }
`
