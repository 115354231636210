import { useEffect } from 'react'
import { setPageTitle } from '../utils/helpers';


const usePageTitle = (title: string) => {
  useEffect(() => {
    setPageTitle(title)

    return () => setPageTitle('')
  }, [title]);
}

export default usePageTitle