import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps
} from 'react-router-dom'
import { Amplify } from '@aws-amplify/core'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Navbar from './components/Navbar'
import ModalBody from './components/ModalBody'
import LandingPage from './pages/LandingPage'
import { device, spacing } from './styles/_var'
import Signup from './pages/Signup'
import Login from './pages/Login'
import { routes } from './utils/constants'
import DashboardRoutes from './pages/DashboardRoutes'
import Categories from './pages/Categories'
import Competitions from './pages/Competitions'
import CompetitionPage from './pages/CompetitionPage/CompetitionPage'
import LoadingScreen from './components/LoadingScreen'
import { observer } from 'mobx-react-lite'
import uiStore from './stores/uiStore'
import CompetitionDashboardRoutes from './pages/CompetitionDashboardRoutes'
import authStore from './stores/authStore'
import Auth from './utils/auth'
import Modal from './components/Modal'
import Logout from './pages/Logout'
import ConfirmDialogBody from './components/ConfirmDialogBody'
import competitionStore from './stores/competitionStore'
import ErrorPage from './pages/ErrorPages/ErrorPage'
import Toast from './components/Toast'
import AccountVerification from './pages/AccountVerification'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import ContactUs from './pages/ContactUs'
import Pricing from './pages/Pricing'
import paymentStore from './stores/paymentStore'
import CreditCardSelector from './components/CreditCardSelector'
import ComingSoon from './pages/ComingSoon'
import Faqs from './pages/FAQS/Faqs'
import Features from './pages/Features/index'
import Demos from './pages/Demos'
import PrivacyPolicy from './pages/PrivacyPolicy'
import * as gen from './utils/generated'

const PaystackButton = require('react-paystack').PaystackButton

const runAppPreflight = () => {
  if (isEmpty(authStore.userData)) {
    const userDetails = Auth.fetchUserDetails()
    if (!isEmpty(userDetails)) {
      authStore.setUserDetails(userDetails)
    }

    if (Auth.isLoggedIn()) {
      authStore.fetchUserDetails()
    }
  }

  competitionStore.fetchCompetitionCategories()
  Amplify.configure(gen.config)
}

const NonAuthRoutes = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path={routes.LANDING} component={LandingPage} />
        <Route exact path={routes.CATEGORIES} component={Categories} />
        <Route exact path={routes.COMPETITIONS} component={Competitions} />
        <Route exact path={routes.SIGNUP} component={StyledSignup} />
        <Route exact path={routes.LOGIN} component={StyledLogin} />
        <Route exact path={routes.LOGOUT} component={Logout} />
        <Route exact path={routes.ERROR_PAGE} component={ErrorPage} />
        <Route exact path={routes.ACCOUNT_VERIFICATION} component={AccountVerification} />
        <Route exact path={routes.FORGOT_PASSWORD} component={StyledForgotPassword} />
        <Route exact path={routes.RESET_PASSWORD} component={StyledResetPassword} />
        <Route exact path={routes.CONTACT_US} component={ContactUs} />
        <Route exact path={routes.PRICING} component={Pricing} />
        <Route exact path={routes.COMING_SOON} component={ComingSoon} />
        <Route exact path={routes.FAQS} component={Faqs} />
        <Route exact path={routes.FEATURES} component={Features} />
        <Route exact path={routes.DEMOS} component={Demos} />
        <Route exact path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path='/:competition_id' component={CompetitionPage} />
      </Switch>
    </>
  )
}

const Routes:FunctionComponent<RouteComponentProps> = ({
  location,
}) => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [location])

  const [loadApp, setLoadApp] = useState(false)
  const { displayLoadingScreen, loadingScreenMessage, showModal, modalPayload } = uiStore

  useEffect(() => {
    runAppPreflight()
    setLoadApp(true)
  }, [])

  if (!loadApp) return null

  return (
    <>
      {!!uiStore.toastMessage && <Toast />}
      <Modal style={{zIndex: 6 }} onClose={() => uiStore.closeModal()} showModal={showModal}>
        <ModalBody {...modalPayload} />
      </Modal>
      <Modal
        onClose={() => uiStore.closeConfirmDialog()}
        showModal={uiStore.showConfirmDialog}
      >
        <ConfirmDialogBody
          message={uiStore.confirmMessage}
          onClose={() => uiStore.closeConfirmDialog()}
          denyAction={() => uiStore.denyAction && uiStore.denyAction()}
          confirmAction={() => uiStore.confirmAction && uiStore.confirmAction()} />
      </Modal>
      <CreditCardSelector
        onCardSelected={(card) => paymentStore.proceedPaymentWithCard(card)}
        triggerNewPayment={() => paymentStore.triggerNewCardPayment()}
        creditCards={paymentStore.savedCards}
        show={paymentStore.showCreditCardSelector}
        close={() => paymentStore.closeCreditCardSelector()}
      />
      {(paymentStore.showPaystackModal && paymentStore.hasActivePayment) && (
        <PaystackButtonWrapper>
          <PaystackButton
            {...paymentStore.paymentPayload}
            className="paystack-btn"
            onClose={() => paymentStore.setShowPaystackModal(false)}
            onSuccess={(data: any) => paymentStore.onPaystackSuccessful(data)} />
        </PaystackButtonWrapper>
      )}
      {displayLoadingScreen && <LoadingScreen message={loadingScreenMessage} />}
      <Switch>
        <Route path='/:competition_username/dashboard' component={CompetitionDashboardRoutes} />
        <Route path='/dashboard' component={DashboardRoutes} />
        <Route path='/' component={NonAuthRoutes} />
      </Switch>
    </>
  )
}

export default withRouter(observer(Routes))

const AuthStyle = `
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-55%);

  @media ${device.laptop} {
    position: absolute;
    right: ${spacing.xxl};
  } 
`

const StyledSignup = styled(Signup)`${AuthStyle}`

const StyledLogin = styled(Login)`${AuthStyle}`

const StyledForgotPassword = styled(ForgotPassword)`${AuthStyle}`

const StyledResetPassword = styled(ResetPassword)`${AuthStyle}`

const PaystackButtonWrapper = styled.div`
  visibility: hidden;
  position: absolute;
`