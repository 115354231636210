import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { spacing, device, colors, size } from '../../../styles/_var'
import Text from '../../../components/Text'

export const Wrapper = styled.div`
`

export const NewCompetitionSection = styled.div`
  margin-bottom: ${spacing.rg};
  background-image: linear-gradient(to bottom, rgba(151,126,247,0.1), ${colors.white});
  padding: ${spacing.sm} ${spacing.sm};

  @media ${device.tablet} {
    padding: ${spacing.sm} ${spacing.sm};
  }

  & .slick-next {
    right: -3px;
    @media ${device.tablet} {
      right: -25px;
    }
  }

  & .slick-prev {
    left: -3px;
    @media ${device.tablet} {
      left: -25px;
    }
  }

  & .slick-prev:before, .slick-next:before {
    color: grey;
  }
`

export const InvolvedCompetitionSection = styled(NewCompetitionSection)`
  padding: ${spacing.sm} ${spacing.sm};

  @media ${device.tablet} {
    padding: ${spacing.sm} ${spacing.sm};
  }
`

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};

  p {
    margin-left: ${spacing.xxs};
  }
`

export const Slide = styled.div<{img: string, small?: boolean}>`
  ${({ img }) => `
    background-image: url(${img});
  `}
  background-size: cover;
  width: ${({ small }) => small ? '17rem' : '25rem'};
  height: ${({ small }) => small ? '17rem' : '25rem'};
  display: block;
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 4px 3px ${colors.lightgrey};
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: ${spacing.sm};
`

export const SlideName = styled(Text)`
  padding: ${spacing.xxs};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: ${colors.white} !important;
  box-sizing: border-box;
  background-color: rgba(10, 10, 10, 0.8);
  font-family: gilroy-bold;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`


export const TextWithIcon = styled(({ color, ...rest }) => <div {...rest} />)`
  ${({ color }) => color && `color: ${color};`}
  display: flex;
  align-items: center;
  & a {
    text-decoration: none;
    color: ${({ color }) => color || colors.black};
  }
`

export const Label = styled(Text)`
  background-color: ${colors.yellow};
  color: ${colors.darkyellow};
  padding: 0.5rem 1rem;
  position: absolute;
  top: ${spacing.xxs};
  right: ${spacing.xxs};
  font-family: Gilroy-bold;
  border-radius: 0.3rem;
`

export const MoreCompetition = styled(({ hideInMobile, ...rest }) => <TextWithIcon {...rest} />)`
  ${({ hideInMobile }) => hideInMobile && `
    display: none;
  `}

  @media ${device.mobileL} {
    display: flex;
  }

  a {
    margin-left: ${spacing.xxs};
  }
`

export const FooterSection = styled.div`
  margin-top: ${spacing.sm};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    display: none;
  }
`

const animation1 = keyframes`
  0% { opacity: 1 }

  23% { opacity: 1 }

  25% { opacity: 0 }
`

const animation2 = keyframes`
  0% { opacity: 0 }

  7% { opacity: 1 }

  23% { opacity: 1 }

  25% { opacity: 0 }
`

const animateSummary = css`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-right: none;

  &:nth-child(1) {
    animation: ${animation1} 20s linear infinite;
  }

  &:nth-child(2) {
    animation: ${animation2} 20s linear 5s infinite;
  }

  &:nth-child(3) {
    animation: ${animation2} 20s linear 10s infinite;
  }

  &:nth-child(4) {
    animation: ${animation2} 20s linear 15s infinite;
  }
`

export const SpendSummary = styled.div`
  margin: ${spacing.xxs} ${spacing.sm};
  height: 20rem;
  background-image: url("https://matusky.s3.us-west-2.amazonaws.com/assets/images/bg.png");
  background-size: cover;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 ${spacing.rg};
  position: relative;

  & > div {
    border-right: solid 1px ${colors.white};
    
    &:last-child {
      border-right: none;
    }
  
    @media(max-width: ${size.mobileVL}) {
      ${animateSummary}
    }

    @media(min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
      ${animateSummary}
    }
  }
`

export const SummaryItem = styled.div`
  color: ${colors.white};
  text-align: center;
  width: 30rem;

  p:first-child {
    color: ${colors.white};
  }
`

export const VotingCreditsWrapper = styled.div`
  position: relative;
`

export const CreditSpendSummaryWrapper = styled.div`
  position: relative;
`

export const VotingCreditsAction = styled.div`
  width: 80%;
  max-width: 40rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 60%);
  display: flex;
  flex-direction: column;

  background: ${colors.white};
  border: 1px solid rgba(223, 224, 235, 0.5);
  box-shadow: 0px 2px 10px rgba(116, 140, 173, 0.1);
  border-radius: 6px;

  & > div:first-child {
    border-bottom: dashed 1px ${colors.purple};
  }

  @media ${device.tablet} {
    width: unset;
    max-width: unset;
    flex-direction: row;

    & > div:first-child {
      border-right: solid 1px ${colors.purple};
      border-bottom: none;
    }
  }
`

export const ActionButtonWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease .2s;
  padding: ${spacing.xs} 0;

  @media ${device.tablet} {
    width: 30rem;
    & > p {
      letter-spacing: 0.2rem;
    }
  }

  & > p {
    color: ${colors.darkpurple};
  }

  &:hover {
    background-color: ${colors.lightpurple};

    & > p {
      color: ${colors.white};
      letter-spacing: 0.2rem;
    }
  }

  & > svg {
    width: 3rem;
    height: 3rem;
    margin-right: ${spacing.xxs};
    padding: 1rem;
    border-radius: 50%;
    background-color: ${colors.lightpurple};
  }
`

export const TransactionSection = styled.div`
  padding: ${spacing.sm};
  margin-top: ${spacing.xxl};

  @media ${device.tablet} {
    margin-top: ${spacing.xl};
  }
`

export const Table = styled.table`
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  
  width: 100%;
  border-collapse: collapse;

  tbody tr {
    border-bottom: solid 1px #A4AFC1;
    &:last-child {
      border-bottom: none;
    }
  }

  thead tr {
    border-bottom: solid 1px #A4AFC1;
  }

  td {
    padding: ${spacing.xs};
  }
`

export const TD = styled.td`
  display: flex;
  color: ${colors.purple};
  align-items: center;

  & p {
    margin-right: ${spacing.xxs};
    cursor: pointer;
  }
`

export const TableWrapper = styled.div`
  margin-top: 2rem;
  padding: 0 ${spacing.sm};
  border: 0.5px solid #A4AFC1;
  box-sizing: border-box;
  border-radius: 10px;
`