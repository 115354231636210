import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import uiStore from '../../stores/uiStore'
import InvolvedCompetitions from './components/InvolvedCompetitions'
import MyFavourites from './components/MyFavourites'
import NewCompetitions from './components/NewCompetitions'
import { Wrapper } from './components/VoterDashboardStyled'
import competitionStore from '../../stores/competitionStore'
import creditStore from '../../stores/creditStore'
import DashboardDefaultState from './components/DefaultState'
import usePageTitle from '../../hooks/usePageTitle'

interface IVoterDashboard {}

const VoterDashboard: FunctionComponent<IVoterDashboard> = () => {
  usePageTitle('Dashboard')

  useEffect(() => {
    !competitionStore.voterDashboardHomeLoaded && uiStore.setLoadingScreenMessage('Loading your dashboard')

    uiStore.setNavTitle('Home')
    creditStore.getUserCredit()

    Promise.allSettled([
      competitionStore.fetchFavouriteCompetitions(),
      competitionStore.fetchDashboardCompetitions('involved'),
      competitionStore.fetchDashboardCompetitions('new')
    ]).then(() => {
      uiStore.setLoadingScreenMessage('')
    })
  }, [])

  return (
    <Wrapper>
      <NewCompetitions competitions={competitionStore.dashboardCompetitions.new || []} />
      {competitionStore.shouldShowDefaultStateOnDashboard && <DashboardDefaultState />}
      <InvolvedCompetitions competitions={competitionStore.dashboardCompetitions.involved || []} />
      <MyFavourites competitions={competitionStore.favouriteCompetitions || []} />
    </Wrapper>
  )
}

export default observer(VoterDashboard)

