import React, { FunctionComponent } from 'react'
import { LinkProps, Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { colors, fontSize } from '../styles/_var'
import { getStyleSpacing } from '../utils/helpers'
import { ComponentSpacing } from '../utils/interfaces'
import { getStyle, ButtonVariant} from './Button'

export interface ILinkprops extends LinkProps, ComponentSpacing {
  useButton?: boolean;
  variant?: ButtonVariant;
  invertOnHover?: boolean;
  boldLink?: boolean;
  block?: boolean;
}

export const Link: FunctionComponent<ILinkprops> = React.memo(({
  useButton,
  variant='primary',
  invertOnHover=false,
  block,
  mt,
  mb,
  mr,
  ml,
  ...props
}) => {
  const spacingStyle = getStyleSpacing({mt, mb, mr, ml})
  if (useButton) {
    return (
      <StyledButtonLink
        role="button"
        variant={variant}
        invertOnHover={invertOnHover}
        style={spacingStyle}
        block={block}
        {...props}
      />
    )
  } else {
    return <StyledLink {...props} style={spacingStyle} />
  }
})


const StyledButtonLink = styled(({ variant, invertOnHover, block, ...rest }) => <RouterLink {...rest} />)`
  ${({ variant, invertOnHover, block }) => {
    return getStyle(variant, invertOnHover, block)
  }}
`

const StyledLink = styled(({ boldLink, ...rest }) => <RouterLink {...rest} />)`
  ${({ boldLink }) => boldLink && `
    text-decoration: none;
    font-weight: bold;
    font-size: ${fontSize.sm};
    color: ${colors.darkpurple};
  `}
`

Link.defaultProps = {
  variant: 'primary'
}

export default Link
