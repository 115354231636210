import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons'
import { colors, device, fontSize, spacing } from '../styles/_var'
import Text from './Text'
import Link from './Link'

interface IAvatarProps {
  width?: number;
  height?: number;
  noBorder?: boolean;
  borderColor?: string;
  hideInDesktop?: boolean;
  hideInMobile?: boolean;
}

export const ButtonWrapper = styled.div`
  width: 80%;
  padding: ${spacing.xs} ${spacing.xxs};
  border-radius: 1rem;
  border: solid 1px ${colors.purple};
  margin: ${spacing.xs} auto 0;
  transition: all ease .2s;
  cursor: pointer;
  color: ${colors.purple};

  &:hover {
    background-color: ${colors.purple};
    color: ${colors.white};
  }
`

export const BackButton: FunctionComponent<{
  onClick: () => void,
  className?: string,
}> = React.memo(({ onClick, className }) => {
  return (
    <StyledBackButton variant="small" className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faHandPointLeft} />
      Go Back
    </StyledBackButton>
  )
})

export const ErrorMessage: FunctionComponent<React.HTMLAttributes<HTMLParagraphElement>> = ({ children, className, ...rest }) => {
  if (!children) return null
  
  return (
    <AuthFormErrorText role="alert" variant="subtext" className={className} {...rest}>
      {children}
    </AuthFormErrorText>
  )
}

export const AuthFormErrorText = styled(Text)`
  color: ${colors.red};
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
`

export const Avatar = styled.img<IAvatarProps>`
  display: block;
  border-radius: 50%;
  box-sizing: border-box;

  ${({ noBorder, borderColor }) => !noBorder && `
    border: solid 2px ${borderColor || colors.white};
  `}

  width: ${({ width }) => `${width}px` || '4rem'};
  height: ${({ height }) => `${height}px` || '4rem'};

  ${({ hideInDesktop }) => hideInDesktop && `
    @media ${device.tablet} {
      display: none;
    }
  `}

  ${({ hideInMobile }) => hideInMobile && `
    display: none;
    @media ${device.tablet} {
      display: block;
    }
  `}
`

export const StyledBackButton = styled(Text)`
  color: ${colors.purple};
  text-align: left;
  position: absolute;
  top: 2rem;
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};
`

export const SectionBody = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media ${device.tablet} {
    justify-content: space-between;
  }
`

export const AuthenticationView = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-image: url("https://matusky.s3.us-west-2.amazonaws.com/assets/images/auth-background.png");
margin-top: 8rem;
`

export const TextAndIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`

export const VerySmallSpace = styled.div`
  height: ${spacing.xxs};
`

export const ComponentView = styled.div`
  background-color: ${colors.white};
  padding: ${spacing.rg} ${spacing.sm};
  width: 80%;
  max-width: 47.6rem;
  border-radius: 0.8rem;
`

export const HiddenFileInput = styled.input`
  position: absolute;
  visibility: hidden;
  display: block;
`

export const InputStyle = css`
  background: ${colors.white};
  border: 1px solid ${colors.lightgrey};
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: ${spacing.xs};
  width: 100%;

  font-family: 'Gilroy';
  font-size: ${fontSize.sm};
  color: ${colors.black};

  &:focus {
    outline: none;
    border-color: ${colors.purple};
  }
`

export const TabItem = styled(({ selected, ...rest }) => <Link {...rest} />)`
  text-decoration: none;
  color: ${colors.black};
  width: 30%;
  max-width: 30rem;
  cursor: pointer;
  text-align: center;
  border-bottom: solid 2px ${colors.lightgrey};
  text-transform: uppercase;
  transition: all ease .2s;

  &:hover {
    color: ${colors.purple};
    border-bottom-color: ${colors.purple};
  }

  ${({ selected }) => selected && `
    color: ${colors.purple};
    border-bottom-color: ${colors.purple};
  `}
`

export const TextWithIconHorizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.purple};
  margin-bottom: ${spacing.sm};

  & svg {
    margin-right: ${spacing.xxs};
  }
`