import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { spacing, device, colors } from '../styles/_var'
import Text, { BoldText } from './Text'
import PageContent from './PageContent'
import Button from './Button'
import Input from './Input'
import mailingListStore from '../stores/mailingListStore'
import { isEmail } from '../utils/helpers'

interface IMailingList {
 joinList: () => void;
 email: string;
 changeValue: (value: string) => void;
 errorMessage: string;
 clearError: () => void;
}

const MailingListInput: React.FC<IMailingList> = ({ joinList, email, changeValue, errorMessage, clearError }) => {
  return (
    <InputWrapper>
      <StyledInput 
        type='email' 
        errorMessage={errorMessage} 
        onFocus={clearError}  
        value={email} 
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeValue(e.target.value)} 
        placeholder='Enter Email Address' />
      <Button onClick={joinList}>Subscribe</Button>
    </InputWrapper>
  )
}

const LandingSectionFour: FunctionComponent= React.memo(() => {
  const [ email, setEmail ] = useState<string>('')
  const [ errorMessage, setErrorMessage ] = useState<string>("")

  const joinList = async() => {
     if (isEmail(email)) {
       await mailingListStore.joinMailingList(email) 
       setEmail('')
     } else {
        setErrorMessage("Invalid email!")
     }
  }
  const changeValue = (value: string) => {
    setEmail(value)
  }
  const clearError = () => {
    setErrorMessage('')
  }

  return (
    <SectionWrapper>
      <TitleText variant="large">Join our Mailing List!</TitleText>
      <Subtext variant="medium">Subscribe to our newsletter and be among the first to get news and product updates</Subtext>
      <MailingListInput 
        clearError={clearError} 
        errorMessage={errorMessage} 
        email={email} 
        joinList={joinList} 
        changeValue={changeValue}  />
    </SectionWrapper>
  )
})

const StyledInput = styled(Input)`
  width: 30rem;

  @media ${device.tablet} {
    width: 45rem;
  }
`


const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 60rem;
  margin: auto;

  & input {
    height: 5rem;
  }
  
  & a {
    height: 5rem;
    width: 35%;
    position: relative;
    left: -5px;
  }

  button {
    height: 5rem;
  }
`

const SectionWrapper = styled(PageContent)`
  background-color: ${colors.verylightpurple};
  position: relative;
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  text-align: center;

  @media ${device.tablet} {
    padding-top: ${spacing.rg};
    padding-bottom: ${spacing.rg};
  }

  @media ${device.laptop} {
    padding-top: ${spacing.xl};
    padding-bottom: ${spacing.xl};
  }

  & a {
    margin-right: ${spacing.xxs};
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const TitleText = styled(BoldText)`
  color: ${colors.darkpurple};
  line-height: 4rem;
  box-sizing: border-box;
  margin-bottom: ${spacing.sm};
`

const Subtext = styled(Text)`
  margin: 0 auto ${spacing.sm};

  @media ${device.mobileL} {
    width: 80%;
  }

  @media ${device.laptop} {
    width: 70%;
  }
`


export default LandingSectionFour
