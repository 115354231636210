import { makeAutoObservable, runInAction } from "mobx";
import { joinMailingListRequest } from "../utils/apiRequests";
import { status } from "../utils/constants";
import { processError, retrieveErrorMessage } from "../utils/helpers";
import { Status } from "../utils/interfaces";
import uiStore from "./uiStore";

class MailingListStore {
  mailingListStatus: Status = null
  constructor() {
    makeAutoObservable(this)
  }
  async joinMailingList(email: string) {
    if (this.joiningMailingList) return
    runInAction(() => {
      this.mailingListStatus = status.LOADING
    })

    try {
      await joinMailingListRequest(email)

      runInAction(() => {
        this.mailingListStatus = status.COMPLETE
      })

      uiStore.showToastMessage({type: "success", message: 'Thank you for subscribing to our newsletter.'})
    } catch(e) {
      runInAction(() => {
        this.mailingListStatus = status.FAILED
      })

      processError(e)
      uiStore.showToastMessage({type: "error", message: retrieveErrorMessage(e)})
    }
  }

  get joiningMailingList() {
    return this.mailingListStatus === status.LOADING
  }

  get joinedMailingList() {
    return this.mailingListStatus === status.COMPLETE
  }

  get failedToJoinedMailingList() {
    return this.mailingListStatus === status.FAILED
  }
}

export default new MailingListStore()