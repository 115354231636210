import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import authStore from '../stores/authStore'
import { routes } from '../utils/constants'


const useRenderOnNotAuthenticated = () => {
  const history = useHistory()

  useEffect(() => {
    if (authStore.userIsLoggedIn) history.push(routes.DASHBOARD)
  }, [history])

  return !authStore.userIsLoggedIn
}

export default useRenderOnNotAuthenticated

