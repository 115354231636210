import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import imageIcon from '../assets/images/camera-icon.png'


const ImageEditButton: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = React.memo(props => {
  return (
    <Container {...props}>
      <CameraIcon src={imageIcon} width="18px" height="14px" />
    </Container>
  )
})

export default ImageEditButton

const Container = styled.div`
  width: 4.4rem;
  height: 4.4rem;
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgba(240, 244, 249, 0.7);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity ease .2s;

  &:hover {
    opacity: 0.4;
  }
`

const CameraIcon = styled.img`
`