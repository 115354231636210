import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import VoterNavbar from '../components/VoterNavbar'
import { device, spacing } from '../styles/_var'
import VoterDashboard from './VoterDashboard/VoterDashboard'
import VotingCredits from './VoterDashboard/VotingCredits'
import CreateCompetition from './VoterDashboard/CreateCompetition/CreateCompetition'
import { menuItems, routes } from '../utils/constants'
import MyCompetitions from './VoterDashboard/MyCompetitions/MyCompetitions'
import MyAccount from './VoterDashboard/MyAccount'
import VoterSettings from './VoterDashboard/VoterSettings/VoterSettings'
import useRenderOnAuthenticated from '../hooks/useRenderOnAuthenticated'
import competitionStore from '../stores/competitionStore'
import authStore from '../stores/authStore'
import { observer } from 'mobx-react-lite'
import InActiveUserNotification from '../components/InActiveUserNotification'

const DashboardRoutes = () => {
  useEffect(() => {
    competitionStore.resetCompetitionDashboardData()
  }, [])
  const shouldLoadPage = useRenderOnAuthenticated()
  if (!shouldLoadPage) return null

  const renderRoutes = () => {
    return (
      <Switch>
        <Route exact path='/dashboard'>
          <Redirect to={routes.DASHBOARD} />
        </Route>
        <Route exact path={routes.DASHBOARD} component={VoterDashboard} />
        <Route exact path={routes.VOTING_CREDITS} component={VotingCredits} />
        <Route exact path={routes.MY_COMPETITIONS} component={MyCompetitions} />
        <Route exact path={routes.MY_ACCOUNT} component={MyAccount} />
        <Route exact path={routes.SETTINGS} component={VoterSettings} />
        <Route exact path={routes.CREATE_COMPETITION} component={CreateCompetition} />
      </Switch>
    )
  }

  return (
    <DashboardWrapper>
      <Sidebar
        menuItems={menuItems}
        disabled={!authStore.isUserActive}
        firstName={authStore.userFirstName}
        avatar={authStore.userAvatar} />
      <BodySection>
        <VoterNavbar />
        <DashboardContainer>
          {authStore.isUserActive ? renderRoutes() : <InActiveUserNotification />}
        </DashboardContainer>
      </BodySection>
    </DashboardWrapper>
  )
}

export default observer(DashboardRoutes)

export const BodySection = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  position: absolute;

  @media ${device.tablet} {
    width: calc(100% - 28rem);
    right: 0;
  }
`

export const DashboardWrapper = styled.div`
  position: absolute;
  width: 100%;
`

export const DashboardContainer = styled.div`
  padding: ${spacing.sm} 0;
  max-width: 1200px;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: ${spacing.rg} 0;
  }
`