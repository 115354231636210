import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import SeasonTypeSelector from '../../components/SeasonTypeSelector'
import Text, { BoldText } from '../../components/Text'
import competitionStore from '../../stores/competitionStore'
import { Table } from '../VoterDashboard/components/VoterDashboardStyled'
import { CompetitionPageWrapper } from './StyledComponents'
import Box from '@material-ui/core/Box'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/_var'
import { seasonTypes, subscriptionOptions } from '../../utils/constants'
import paymentStore from '../../stores/paymentStore'
import uiStore from '../../stores/uiStore'
import { beautifyDate, getPaystackReference, toCurrency } from '../../utils/helpers'
import authStore from '../../stores/authStore'
import seasonStore from '../../stores/seasonStore'
import votingRoundStore from '../../stores/votingRoundStore'
import usePageTitle from '../../hooks/usePageTitle'


const CompetitionAccount = () => {
  const currentPlan = competitionStore.subscriptionPlan
  const competitionUsername = competitionStore.competitionDetails.username
  const latestSeason: any = (competitionStore.competitionDetails.seasons || [])[0]
  usePageTitle('Competition Account')

  useEffect(() => {
    seasonStore.fetchSeasonTransactions(latestSeason.uuid)
  }, [latestSeason])

  const onSelectSubscriptionPlan = (plan: number) => {
    if (plan === currentPlan || currentPlan === undefined) return

    if (plan === seasonTypes.PREMIER) {
      uiStore.openConfirmDialog('Are you sure you want to select this plan?', () => {
        competitionStore.updateSubscriptionPlan({ competitionUsername, subscriptionPlan: plan})
      })
      return 
    }

    const previousSubscriptionCost = subscriptionOptions[currentPlan].PRICE
    const subscriptionCost = subscriptionOptions[plan].PRICE
    const amountToPay = subscriptionCost - previousSubscriptionCost


    uiStore.openConfirmDialog('Are you sure you want to select this plan?', () => {
      paymentStore.startPayment({
        email: authStore.userEmail,
        reference: getPaystackReference(authStore.username),
        amount: amountToPay,
        onSuccess: (data) => {
          const { status, reference } = data
          if (status === 'success') {
            competitionStore.updateSubscriptionPlan({
              competitionUsername,
              subscriptionPlan: plan,
              amount: amountToPay,
              seasonId: latestSeason?.uuid,
              reference,
            })
          }
        }
      })
    })
  }

  return (
    <CompetitionPageWrapper>
      {seasonStore.hasTransactions && <Box mb={8}>
        <Table>
          <thead>
            <tr>
              <td><BoldText variant="regular">S/N</BoldText></td>
              <td><BoldText variant="regular">Amount Paid</BoldText></td>
              <td><BoldText variant="regular">Transaction Type</BoldText></td>
              <td><BoldText variant="regular">Status</BoldText></td>
              <td><BoldText variant="regular">Date</BoldText></td>
            </tr>
          </thead>
          <tbody>
            {seasonStore.transactions.map((transaction, i) => (
              <tr>
                <td><Text variant="regular">{i+1}</Text></td>
                <td><Text variant="regular">{toCurrency(transaction.amount/100)}</Text></td>
                <td><Text variant="regular">{transaction.transaction_type}</Text></td>
                <td><Text variant="regular">Paid</Text></td>
                <td><Text variant="regular">{beautifyDate(transaction.created_at)}</Text></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>}
      <Divider />
      <BoldText variant="large" mt={3}>Upgrade Plan</BoldText>
      <Note mt={2}>
        <Text variant="regular">
          Please not that you are not allowed to downgrade your account, you can only upgrade your account. You can downgrade to a different subscription plan when you begin a new season.
        </Text>
      </Note>
      <SeasonTypeSelector 
        canSelectPremier={!votingRoundStore.hasVotingRounds}
        onChange={onSelectSubscriptionPlan}
        subscriptionPlan={currentPlan}
        cannotDowngrade
        controlled
      />
    </CompetitionPageWrapper>
  )
}

export default observer(CompetitionAccount)

const Note = styled(Box)`
  padding: ${spacing.xs};
  background-color: ${colors.subtlylightpurple};
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};
`
