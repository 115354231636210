import React, { FunctionComponent, useState } from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components'
import Text from './Text';
import FormInput from './FormInput';
import Button, { ButtonVariant } from './Button';
import { isSame } from '../utils/helpers';
import LocationInput from './LocationInput';
import { colors, size, spacing } from '../styles/_var';

export interface IActionButton {
  onClick?: () => void, 
  isSubmit?: boolean,
  label: string,
  loading?: boolean,
  variant?: ButtonVariant,
  invertOnHover?: boolean,
  disabled?: boolean,
}

interface IFormProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  formFields: any[],
  actionButtons: IActionButton[] | IActionButton,
  formTitle?: string,
  initialValues?: any,
  landscape?: boolean,
  submitForm: (payload: any, form?: any) => void,
  className?: string,
  style?: any,
  id?: string,
  fieldsToShow?: number,
  disabled?: boolean,
  formStyle?: any,
}

const SimpleForm: FunctionComponent<IFormProps> = ({
  formTitle,
  formFields,
  actionButtons,
  initialValues = undefined,
  submitForm,
  fieldsToShow,
  landscape,
  disabled,
  formStyle={},
  ...rest
}) => {
  let submit: any;

  const [addressComponents, setAddressComponents] = useState<any>({})
  const [showAllFields, setShowAllFields] = useState(false)

  const onSubmit = (values: any, form: any) => {
    const formValues = {...values}
    if (formValues.confirm_password) delete formValues.confirm_password
    submitForm({...formValues, ...addressComponents}, form)
  }

  const saveAddressComponents = (addressName: string) => (addressComponent: any) => {
    setAddressComponents({[addressName]: addressComponent})
  }

  const shouldRenderComponent = (dependencies: any = {}, values: any) => {
    const getDependencyTypeAndValue = (payload: any) => {
      const type = Object.keys(payload)[0]
      const value = payload[type]
      return [type, value]
    }

    return Object.keys(dependencies).every((dependency: string) => {
      const [dependencyType, value] = getDependencyTypeAndValue(dependencies[dependency])
      const formValue = values[dependency]

      switch (dependencyType) {
        case 'eq':
          return value === formValue
        case 'neq':
          return value !== formValue
        case 'lt':
          return value < formValue
        case 'lte':
          return value <= formValue
        case 'gt':
          return value > formValue
        case 'gte':
          return value >= formValue
        default:
          return true
      }
    })
  }

  const onInputKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode || e.charCode
    const key = e.code || e.key 

    if (keyCode === 13 || key === 'Enter') {
      submit(e)
    }
  }

  const renderComponent = (errors: any, touched: any, values: any) => (fieldProps: any, index: number) => {
    const { name, component, label, validate, dependencies=[] } = fieldProps;
    
    if (!shouldRenderComponent(dependencies, values)) return null

    if (component === 'location') {
      return (
        <LocationField key={index}>
            <Text>{label}</Text>
            <Field name={name} validate={validate}>
              {({ input }) => (
                <LocationInput
                  debounce={400}
                  value={input?.value?.full_address || input?.value}
                  onChange={input.onChange}
                  storeAddressComponents={saveAddressComponents(name)} />
              )}
            </Field>
            {(errors[name] && (touched || {})[name] && !addressComponents[name]) && (
                <Text variant="small" color="red" role="alert">
                    {errors[name]}
                </Text>
            )}
        </LocationField>
      )
    }

    if (fieldProps.validate) {
      const { name } = fieldProps;
      fieldProps.error = errors[name]
      fieldProps.touched = touched[name]
    }

    if (name === 'confirm_password') {
      fieldProps.validate = isSame(values?.password)
    }

    return <FormInput key={index} {...fieldProps} onKeyPress={onInputKeyPress} />
  }

  const renderActionButtons = (submitButton: any) => ({
    onClick, 
    isSubmit,
    label,
    loading,
    variant,
    invertOnHover,
    disabled
  }: IActionButton, i: number=1) => {
    return (
      <ActionButton
        role="button"
        mt={2}
        disabled={disabled}
        key={i}
        invertOnHover={invertOnHover}
        variant={variant || 'primary'}
        onClick={isSubmit ? submitButton : onClick}
        loading={loading}>
        {label}
      </ActionButton>
    )
  }

  const renderForm = (errors: any, touched: any, values: any) => {
    if (!fieldsToShow) return formFields.map(renderComponent(errors, touched, values))

    return (
      <>
        {formFields.slice(0, fieldsToShow).map(renderComponent(errors, touched, values))}
        <ShowMoreText role="switch" variant="small" onClick={() => setShowAllFields(!showAllFields)}>
          { !showAllFields ? 'Show optional fields' : 'Hide optional fields' }
        </ShowMoreText>
        <RemainingFields role="section" hidden={!showAllFields}>
          {formFields.slice(fieldsToShow).map(renderComponent(errors, touched, values))}
        </RemainingFields>
      </>
    )
  }

  return (
    <SimpleFormWrapper disabled={disabled} {...rest}>
      {!!formTitle && <Text variant="regular" role="heading">{formTitle}</Text>}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}>
        {({ handleSubmit, errors, touched, values }) => {
          submit = handleSubmit
          return (
            <>
              <FormWrapper landscape={landscape} style={formStyle}>
                {renderForm(errors, touched, values)}
              </FormWrapper>
              {!disabled && (
                <ActionButtonWrapper>
                  {Array.isArray(actionButtons) ? actionButtons.map(renderActionButtons(handleSubmit)) : renderActionButtons(handleSubmit)(actionButtons)}
                </ActionButtonWrapper>
              )}
            </>
          )
        }}
      </Form>
    </SimpleFormWrapper>
  )
};


export default SimpleForm;

const RemainingFields = styled(({ hidden, ...rest }) => <div {...rest} />)`
  transition: all ease .2s;
  overflow: hidden;

  ${({ hidden }) => hidden ? `
    max-height: 1px;
    opacity: 0;
  ` : `
    max-height: 1000px;
    opacity: 1;
  `}
`

const ShowMoreText = styled(Text)`
  position: absolute;
  right: 0;
  margin-top: -${spacing.sm} !important;
  color: ${colors.purple};
  transition: opacity ease .2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const SimpleFormWrapper = styled(({ disabled, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ disabled }) => disabled && `
    filter: grayscale(100%);
    pointer-events: none;
  `}
`

const FormWrapper = styled(({ landscape, ...rest }) => <div {...rest} />)`
  ${({ landscape }) => landscape && `
    @media (min-width: ${size.tablet}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${spacing.xs};
    }
  `}
`

const ActionButtonWrapper = styled.div`
  text-align: center;
`

const ActionButton = styled(Button)`
  min-width: 30rem;
`

const LocationField = styled.div`
  margin-bottom: 3rem;
`