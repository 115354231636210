import React from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import Text from '../components/Text'
import { colors, spacing } from '../styles/_var'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'


const InActiveUserNotification = () => {
  return (
    <Wrapper
      maxWidth={400}
      padding={4}
      marginTop={17}
      textAlign="center"
    >
      <FontAwesomeIcon icon={faInfoCircle} size="3x" color={colors.red} />
      <Text variant="regular" color={colors.red}>
        Your Account has not been activated, please check your email and click on the activation link.
      </Text>
      <MessageDivider />
      <Text variant='regular' color={colors.red} mt={1}>
        Please check your <strong>"Spam"</strong> or <strong>"Promotions"</strong> folders if you cannot find the activation email in your <strong>"Inbox"</strong>.
      </Text>
    </Wrapper>
  )
}

export default InActiveUserNotification


const MessageDivider = styled(Divider)`
  background-color: ${colors.grey} !important;
  opacity: 0.3;
  margin-top: 6px !important;
`

const Wrapper = styled(({ state, ...rest}) => <Box {...rest} />)`
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-bottom: ${spacing.xxs};
  box-shadow: ${colors.red} 0 0 6px;
  background-color: ${colors.white};
`
