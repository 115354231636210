import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { faBan, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors, spacing } from '../../styles/_var'
import Text from '../../components/Text'
import AllContestants from './AllContestants'
import { ContestantWithBio } from '../../utils/interfaces'
import { TextWithIconHorizontal } from '../../components/StyledComponents'
import CompetitionCategoryDropdown from '../../components/CompetitionCategoryDropdown'
import Box from '@material-ui/core/Box'


interface ICompetitionContestants {
  contestants: ContestantWithBio[],
  loading?: boolean,
  onVote: (contestantUsername: string) => () => void,
  votingClosed?: boolean,
  userIsLoggedIn?: boolean,
  seasonConcluded?: boolean,
  showVoteCount?: boolean
}

const CompetitionContestants: FunctionComponent<ICompetitionContestants> = ({
  contestants,
  loading,
  onVote,
  votingClosed,
  userIsLoggedIn,
  seasonConcluded,
  showVoteCount
}) => {
  return (
    loading ? (
      <TextWithIcon>
        <FontAwesomeIcon icon={faSpinner} spin size="2x"/> 
        <Text variant="medium">Fetching Contestants.</Text>
      </TextWithIcon>
    ) : (
      <>
        <CompetitionCategoryDropdown isCompetitionPage />
        <Box mt={4}>
          {(votingClosed || seasonConcluded) && (
            <TextWithIconHorizontal>
              <FontAwesomeIcon
                icon={seasonConcluded ? faInfoCircle : faBan}
                size="2x"
                color={colors.red}
              />
              <Text 
                color={colors.red}
                variant="medium"
                role="alert"
                aria-label="There are no open voting rounds"
              >
                {seasonConcluded ? 'This Season is over!' : 'There are no open voting rounds'}
              </Text>
            </TextWithIconHorizontal>
          )}
          {!votingClosed && !userIsLoggedIn && (
            <TextWithIconHorizontal>
              <FontAwesomeIcon icon={faInfoCircle} size="2x" />
              <Text
                variant="medium"
                role="alert"
                aria-label="You need to login first to vote">
                You need to login first to vote
              </Text>
            </TextWithIconHorizontal>
          )}
          <AllContestants
            votingClosed={votingClosed}
            onVote={onVote}
            contestants={contestants}
            variant="default"
            showVoteCount={showVoteCount}
          />
        </Box>
      </>
    )
  )
}

export default CompetitionContestants

const TextWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing.sm};
  color: ${colors.purple};
`