import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { colors, spacing } from '../styles/_var'
import Text from './Text'

interface IRadioOption {
  label: string,
  value: string | number,
}

interface IRadioButton {
  options: IRadioOption[] | string[],
  placeholder?: string,
  value?: string | number,
  onChange?: (value: any) => void,
  className?: string,
  style?: any,
  horizontal?: boolean,
  "aria-label"?: string, 
}

const RadioButton: FunctionComponent<IRadioButton> = React.memo(({
  placeholder,
  onChange,
  value=null,
  options=[],
  style={},
  className='',
  horizontal,
  ...rest
}) => {
  const renderRadioOption = (option: IRadioOption, index: number) => {
    const radioValue = option.value !== undefined ? option.value : option
    const radioLabel = option.label !== undefined ? option.label : option

    return (
      <RadioOption
        selected={value === radioValue}
        key={index}
        onClick={() => onChange && onChange(radioValue)}>
        <RadioIcon />
        <Text inline variant="regular">{radioLabel}</Text>
      </RadioOption>
    )
  }

  return (
    <RadioButtonWrapper 
      style={style}
      role="radiogroup"
      className={className}
      {...rest}
    >
      {!!placeholder && <StyledLabel variant="regular">{placeholder}</StyledLabel>}
      <OptionsWrapper horizontal={horizontal}>
        {(options as any).map(renderRadioOption)}
      </OptionsWrapper>
    </RadioButtonWrapper>
  )
})

export default RadioButton


const OptionsWrapper = styled(({ horizontal, ...rest }) => <div {...rest} />)`
  ${({ horizontal }) => horizontal && `
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      min-width: 43%;
    }
  `}
`

const radioOptionActive = css`
  border-color: ${colors.purple};
  & > div {
    border-color: ${colors.purple};
  }
  & > p {
    color: ${colors.purple};
  }
`

const radioIconSelected = css`
  & > div {
    box-shadow: 0 0 0 1px ${colors.purple};
    background-color: ${colors.purple};
    border: solid 3px ${colors.white};
  }
`

const RadioButtonWrapper = styled.div``

const StyledLabel = styled(Text)``

const RadioOption = styled(({ selected, ...rest }) => <div {...rest} />)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${spacing.xs};
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.lightgrey};
  padding: 0.5rem ${spacing.xxs};
  transition: all ease .2s;
  background: ${colors.white};

  ${({ selected }) => selected && radioOptionActive}
  ${({ selected }) => selected && radioIconSelected}
  &:hover {
    ${({ selected }) => !selected && radioOptionActive}
  }
`

const RadioIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.lightgrey};
  display: inline-block;
  margin-right: ${spacing.xxs};
  box-sizing: border-box;
`