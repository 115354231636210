import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ContestantWithBio } from '../utils/interfaces'
import AddContestantForm from './AddContestantForm'
import Modal from './Modal'
import Text from './Text'


interface IEditContestant {
  contestantToEdit: ContestantWithBio,
  showModal: boolean,
  closeModal: () => void,
  setAvatar: (avatar: string) => void,
  onSubmit: (data: ContestantWithBio, onComplete?: () => void) => void,
  saving?: boolean,
  formTitle?: string,
}

const EditContestant: FunctionComponent<IEditContestant> = ({
  contestantToEdit,
  showModal,
  closeModal,
  setAvatar,
  onSubmit,
  saving,
  formTitle='Update Contestant'
}) => {
  return (
    <EditContestantModal
      onClose={closeModal}
      showModal={showModal}
    >
      <Text variant="medium" mb={2} center>{formTitle}</Text>
      <AddContestantForm
        initialValues={contestantToEdit}
        editMode
        onAvatarUploaded={setAvatar}
        submit={onSubmit}
        submitting={saving} />
    </EditContestantModal>
  )
}

export default EditContestant


const EditContestantModal = styled(Modal)`
  max-width: 70rem;

  & > div:last-child {
    overflow: scroll;
    max-height: 70vh;
  }
`
