import { makeAutoObservable } from "mobx";
import { getAdvertsRequest } from "../utils/apiRequests";
import { status } from "../utils/constants";
import { retrieveErrorMessage } from "../utils/helpers";
import { IAdvert, Status } from "../utils/interfaces";

class AdvertStore {
  adverts: IAdvert[] = []
  advertStatus: Status = null

  constructor() {
    makeAutoObservable(this)
  }

  async fetchAdverts() {
    if (this.fetchingAdverts || !this.adsPristine) return

    this.setFetchAdvertsStatus(status.LOADING)

    try {
      const response = await getAdvertsRequest()
      this.storeAdverts(response.data.data)
      this.setFetchAdvertsStatus(status.COMPLETE)
    } catch (e) {
      retrieveErrorMessage(e)
      this.setFetchAdvertsStatus(status.FAILED)
    }
  }

  setFetchAdvertsStatus(status: Status) {
    this.advertStatus = status
  }

  storeAdverts(adverts: IAdvert[]) {
    this.adverts = adverts
  }

  get fetchingAdverts() {
    return this.advertStatus === status.LOADING
  }

  get fetchedAdverts() {
    return this.advertStatus === status.COMPLETE
  }

  get failedToFetchAdverts() {
    return this.advertStatus === status.FAILED
  }

  get adsPristine() {
    return this.advertStatus === null && this.adverts.length === 0
  }
}

const advertStore = new AdvertStore()
export default advertStore
