import { useEffect, useState } from 'react'
import { getCompetitionInfoRequest } from '../utils/apiRequests'


const useCompetitionNotes = (competitionUsername: string) => {
  const [competitionInfo, setCompetitionInfo] = useState(null)

  useEffect(() => {
    getCompetitionInfoRequest(competitionUsername).then(response => {
      setCompetitionInfo(response.data.data)
    }).catch(e => console.log(e))
  }, [competitionUsername])

  return competitionInfo
}

export default useCompetitionNotes
