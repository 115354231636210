import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components'
import { colors, fontSize } from '../styles/_var';
import { getStyleSpacing } from '../utils/helpers';
import { ComponentSpacing } from '../utils/interfaces';

export type TextVariant = 'title' | 'small' | 'medium' | 'regular' | 'subtext' | 'large'

export interface ITextProps extends React.HTMLAttributes<HTMLParagraphElement>, ComponentSpacing {
    variant?: TextVariant,
    className?: string,
    nowrap?: boolean,
    color?: string,
    truncate?: boolean,
    inline?: boolean,
    tooltip?: string,
    center?: boolean,
    maxWidth?: number,
    opacity?: number,
    lighten?: boolean,
}

const Text: FunctionComponent<ITextProps> = React.memo(({
  children,
  tooltip,
  mt,
  mb,
  mr,
  ml,
  color,
  maxWidth,
  style={},
  opacity,
  lighten,
  ...rest
}) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const setToolTip = (status: boolean) => (e: React.MouseEvent) => {
    if (!tooltip) return
    setShowToolTip(status);
  }

  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  if (color) componentStyle.color = color

  if (maxWidth) {
    componentStyle.maxWidth = maxWidth
    componentStyle.marginLeft = 'auto'
    componentStyle.marginRight = 'auto'
  }

  if (opacity || opacity === 0) {
    componentStyle.opacity = opacity
  }

  if (lighten) {
    componentStyle.opacity = 0.6
  }

  if (!tooltip) return <StyledText {...rest} style={componentStyle}>{children}</StyledText>

  return (
    <TextWrapper inline={rest.inline} style={spacingStyle}>
      <StyledText 
        {...rest}
        style={style}
        onMouseEnter={setToolTip(true)}
        onMouseLeave={setToolTip(false)}
        hasTooltip={!!tooltip}
      >
        {children}
      </StyledText>
      {!!tooltip && <Tooltip showToolTip={showToolTip}>{tooltip}</Tooltip>}
    </TextWrapper>
  )
})


const TextWrapper = styled(({ inline, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ inline }) => inline && 'display: inline-block;'}
`

const StyledText = styled(({
  center,
  variant,
  inline,
  nowrap,
  truncate,
  hasTooltip,
  ...rest
}) => <p {...rest} />)`
  margin: 0;
  ${({ center }) => center && 'text-align: center;'}
  ${({ variant }) => {
    if (variant === 'title') {
      return `
        font-size: ${fontSize.xl};
        line-height: 3.6rem;
        letter-spacing: 0.03rem;
      `
    } else if (variant === 'small') {
      return `
        font-size: ${fontSize.xs};
        line-height: 1.8rem;
      `
    } else if (variant === 'medium') {
      return `
        font-size: ${fontSize.md};
        line-height: 3.5rem;  
      `
    } else if (variant === 'subtext') {
      return `
        font-size: ${fontSize.sm};
        line-height: 2.9rem;
        letter-spacing: 0.01rem;
        color: ${colors.grey};
      `
    } else if (variant === 'regular') {
      return `
        font-size: ${fontSize.rg};
        line-height: 3.3rem;
      `
    } else if (variant === 'large') {
      return `
        font-size: ${fontSize.lg};
        line-height: 4.5rem;
        letter-spacing: 0.1rem;      
      `
    }
  }}
  ${({ nowrap }) => nowrap && `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  ${({ truncate }) => truncate && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ inline }) => inline && `
    display: inline-block;
  `}
  ${({ hasTooltip }) => hasTooltip && `
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  `}
`;

export const BoldText = styled(Text)`
  font-family: 'Gilroy-bold';
`;

export default Text;

const Tooltip = styled(({ showToolTip, ...rest }) => <div {...rest} />)`
  background-color: ${colors.lightpurple};
  border: solid 1.2px ${colors.subtlylightpurple};
  color: ${colors.white};
  position: absolute;
  border-radius: 5px;
  padding: 1rem;
  line-height: 2rem;
  transition: all ease .4s;
  font-size: 14px;

  ${({ showToolTip }) => showToolTip ? `
    opacity: 1;
    z-index: 3;
  ` : `
    z-index: 0;
    opacity: 0;
  `}
`